import React from 'react'
import { TextField } from '@mui/material'
import { useField } from 'formik'

const TextFieldValidated = ({ name, ...otherProps }) => {
    const [field, meta] = useField(name)

    const configTextField = {
        ...field,
        ...otherProps,
        fullWidth: true,
        variant: 'outlined',
        size: 'small',
    }

    if (meta && meta.touched && meta.error) {
        configTextField.error = true
        configTextField.helperText = meta.error
    }

    return <TextField {...configTextField} />
}

export default TextFieldValidated
