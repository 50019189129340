import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form, FieldArray } from 'formik'
import * as Yup from 'yup'
import { Button, Divider, Grid, IconButton, MenuItem } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import dayjs from 'dayjs'

import InputLabelStyled from '../../fields/InputLabelStyled'
import TextFieldValidated from '../../fields/TextFieldValidated'
import PercentFieldValidated from '../../fields/PercentFieldValidated'
import MoneyFieldValidated from '../../fields/MoneyFieldValidated'
import DatePickerValidated from '../../fields/DatePickerValidated'
import SelectValidated from '../../fields/select/SelectValidated'
import BorrowerSelect from '../../fields/select/BorrowerSelect'
import FacilitySelect from '../../fields/select/FacilitySelect'
import FundSelect from '../../fields/select/FundSelect'
import PositionSelect from '../../fields/select/PositionSelect'

import {
    getBorrowerName,
    getFacilityNumber,
    getFundCode,
    getTaId,
} from '../../../../utils/helpers/getDetails'
import { createTransactionTypeList } from '../../../../utils/lists/transactionTypeList'
import { formatDatePickerDate } from '../../../../utils/helpers/formatValue'

import { selectCurrentUser } from '../../../../app/api/auth/authSlice'
import { useGetAllFundsQuery } from '../../../../app/api/records/fundsApiSlice'
import { useGetAllBorrowersQuery } from '../../../../app/api/records/borrowersApiSlice'
import { useGetAllFacilitiesQuery } from '../../../../app/api/records/facilitiesApiSlice'
import { useGetAllPositionsQuery } from '../../../../app/api/records/positionsApiSlice'
import { useGetAllParticipationsQuery } from '../../../../app/api/records/participationsApiSlice'

import DrawdownParticipationModal from '../../modals/DrawdownParticipationModal'
import InvalidDateModal from '../../modals/InvalidDateModal'

const positionFilterFormValidation = Yup.object().shape({
    borrowerId: Yup.string().required('Required'),
    facilityId: Yup.string().required('Required'),
    positionId: Yup.string().required('Required'),
    drawdownDate: Yup.date().nullable().required('Required'),
    originalMaturityDate: Yup.date().nullable().required('Required'),
    currency: Yup.string().required('Required'),
    participations: Yup.array()
        .of(
            Yup.object().shape({
                fundId: Yup.string().required('Required'),
                participationRatio: Yup.number()
                    .required('Required')
                    .min(0, 'Participation Ratio must be greater than 0')
                    .max(100, 'Participation Ratio must be less than or equal to 100'),
                transactionPrincipal: Yup.number().required('Required'),
                relationshipType: Yup.string().required('Required'),
                spreadRate: Yup.number()
                    .required('Required')
                    .min(0, 'Spread Rate must be greater than or equal to 0'),
            })
        )
        .test(
            'sum-100',
            'Total participation ratios must equal 100%',
            (values) => values.reduce((sum, val) => sum + val.participationRatio, 0) === 100
        ),
})

const CreateTransactionForm = () => {
    const initialPositionFilterFormState = {
        transactionType: '',
        borrowerId: '',
        facilityId: '',
        positionId: '',
        lenderCode: '',
        drawdownDate: null,
        originalMaturityDate: null,
        notionalAmount: 0,
        currency: '',
        participations: [
            {
                fundId: '',
                participationRatio: 0,
                transactionPrincipal: 0,
                relationshipType: 'Parent',
                spreadRate: 0,
            },
        ],
        remarks: '',
    }

    const { firstName, lastName } = useSelector(selectCurrentUser)
    const userFullName = `${firstName} ${lastName}`

    const { data: funds } = useGetAllFundsQuery()
    const { data: borrowers } = useGetAllBorrowersQuery()
    const { data: facilities } = useGetAllFacilitiesQuery()
    const { data: positions } = useGetAllPositionsQuery()
    const { data: participations } = useGetAllParticipationsQuery()

    const [selectedTransactionType, setSelectedTransactionType] = useState('')
    const [selectedBorrower, setSelectedBorrower] = useState('')
    const [selectedFacility, setSelectedFacility] = useState('')
    const [selectedPosition, setSelectedPosition] = useState('')
    const [isBorrowerSelectDisabled, setIsBorrowerSelectDisabled] = useState(true)
    const [isFacilitySelectDisabled, setIsFacilitySelectDisabled] = useState(true)
    const [isPositionSelectDisabled, setIsPositionSelectDisabled] = useState(true)
    const [formattedParticipations, setFormattedParticipations] = useState([])
    const [isInvalidDateModalOpen, setIsInvalidDateModalOpen] = useState(false)
    const [isDrawdownParticipationModalOpen, setIsDrawdownParticipationModalOpen] = useState(false)

    const formikRef = useRef()

    let transactionPositions
    if (
        selectedTransactionType === 'Drawdown' ||
        selectedTransactionType === 'Restructure from Other'
    ) {
        transactionPositions = positions.filter(
            (position) => !position.isActive && !position.isDrawdown
        )
    } else if (selectedTransactionType === 'Participate from Existing') {
        transactionPositions = positions.filter(
            (position) => position.isActive && position.isDrawdown
        )
    } else {
        transactionPositions = positions
    }

    const borrowerFacilities = selectedBorrower
        ? facilities.filter((facility) => facility.borrowerId === selectedBorrower)
        : facilities

    const facilityPositions = selectedFacility
        ? transactionPositions.filter(
              (transactionPosition) => transactionPosition.facilityId === selectedFacility
          )
        : transactionPositions

    const positionParticipations = selectedPosition
        ? participations.filter((participation) => participation.positionId === selectedPosition)
        : participations

    const formatParticipations = (values) => {
        const { borrowerId, facilityId, positionId, participations, ...remainingValues } = values

        const formattedCommonValues = {
            ...remainingValues,
            borrowerName: getBorrowerName(borrowers, borrowerId),
            facilityNumber: getFacilityNumber(facilities, facilityId),
            positionId: positionId,
            taId: getTaId(positions, positionId),
            drawdownDate: formatDatePickerDate(remainingValues.drawdownDate),
            originalMaturityDate: formatDatePickerDate(remainingValues.originalMaturityDate),
            transactionInterest: 0,
            transactionFee: 0,
            createdBy: userFullName,
            lastUpdatedBy: userFullName,
        }

        const formattedParticipations = participations
            .map((participation) => {
                const fundCode =
                    participation.fundId != null ? getFundCode(funds, participation.fundId) : ''
                return {
                    ...formattedCommonValues,
                    ...participation,
                    fundCode: fundCode,
                    fundTaId: `${fundCode} - ${formattedCommonValues.taId}`,
                }
            })
            .map(({ fundId, ...remainingValues }) => remainingValues)

        setFormattedParticipations(formattedParticipations)
    }

    const handleOpenInvalidDateModal = () => {
        setIsInvalidDateModalOpen(true)
    }

    const handleCloseInvalidDateModal = () => {
        setIsInvalidDateModalOpen(false)
    }

    const handleCreateTransactionFormSubmit = (values) => {
        const drawdownDate = formatDatePickerDate(values.drawdownDate)
        const originalMaturityDate = formatDatePickerDate(values.originalMaturityDate)
        if (drawdownDate >= originalMaturityDate) {
            setIsInvalidDateModalOpen(true)
        } else {
            formatParticipations(values)
            setIsDrawdownParticipationModalOpen(true)
        }
    }

    const handleCloseDrawdownParticipationModal = () => {
        setIsDrawdownParticipationModalOpen(false)
    }

    return (
        <Formik
            initialValues={{ ...initialPositionFilterFormState }}
            validationSchema={positionFilterFormValidation}
            onSubmit={handleCreateTransactionFormSubmit}
            validateOnMount={true}
            innerRef={formikRef}
        >
            {(formik) => (
                <Form>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <InputLabelStyled htmlFor='transactionType'>
                                Transaction Type
                            </InputLabelStyled>
                            <SelectValidated
                                name='transactionType'
                                id='transactionType'
                                onChange={(event) => {
                                    formik.setFieldValue('transactionType', event.target.value)
                                    setSelectedTransactionType(event.target.value)
                                    setIsBorrowerSelectDisabled(!event.target.value)
                                    formik.setFieldValue('borrowerId', '')
                                    formik.setFieldValue('facilityId', '')
                                    formik.setFieldValue('positionId', '')
                                    formik.setFieldValue('lenderCode', '')
                                    formik.setFieldValue('notionalAmount', '')
                                    formik.setFieldValue('drawdownDate', null)
                                    formik.setFieldValue('originalMaturityDate', null)
                                    setSelectedBorrower(event.target.value)
                                    setIsFacilitySelectDisabled(!event.target.value)
                                    setIsPositionSelectDisabled(true)
                                }}
                            >
                                {createTransactionTypeList.map((createTransactionTypeListItem) => (
                                    <MenuItem
                                        key={createTransactionTypeListItem}
                                        value={createTransactionTypeListItem}
                                        disabled={
                                            createTransactionTypeListItem ===
                                            'Participate from Existing (Future)'
                                        }
                                        style={{
                                            fontStyle:
                                                createTransactionTypeListItem ===
                                                'Participate from Existing (Future)'
                                                    ? 'italic'
                                                    : 'normal',
                                        }}
                                    >
                                        {createTransactionTypeListItem}
                                    </MenuItem>
                                ))}
                            </SelectValidated>
                        </Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={4}>
                            <InputLabelStyled htmlFor='borrowerId'>Borrower Name</InputLabelStyled>
                            <BorrowerSelect
                                borrowers={borrowers || []}
                                isBorrowerSelectDisabled={isBorrowerSelectDisabled}
                                onChange={(event) => {
                                    formik.setFieldValue('borrowerId', event.target.value)
                                    formik.setFieldValue('facilityId', '')
                                    formik.setFieldValue('positionId', '')
                                    formik.setFieldValue('lenderCode', '')
                                    formik.setFieldValue('notionalAmount', '')
                                    formik.setFieldValue('drawdownDate', null)
                                    formik.setFieldValue('originalMaturityDate', null)
                                    setSelectedBorrower(event.target.value)
                                    setIsFacilitySelectDisabled(!event.target.value)
                                    setIsPositionSelectDisabled(true)
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabelStyled htmlFor='facilityId'>
                                Facility Number
                            </InputLabelStyled>
                            <FacilitySelect
                                facilities={borrowerFacilities || []}
                                isFacilitySelectDisabled={isFacilitySelectDisabled}
                                onChange={(event) => {
                                    formik.setFieldValue('facilityId', event.target.value)
                                    setSelectedFacility(event.target.value)
                                    setIsPositionSelectDisabled(!event.target.value)
                                    const selectedFacility = facilities.find(
                                        (facility) => facility._id === event.target.value
                                    )
                                    if (selectedFacility) {
                                        formik.setFieldValue(
                                            'lenderCode',
                                            selectedFacility.lenderCode || ''
                                        )
                                    }
                                    formik.setFieldValue('positionId', '')
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <InputLabelStyled disabled htmlFor='lenderCode'>
                                Lender Code
                            </InputLabelStyled>
                            <TextFieldValidated disabled name='lenderCode' id='lenderCode' />
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabelStyled htmlFor='positionId'>TA ID</InputLabelStyled>
                            <PositionSelect
                                positions={borrowerFacilities ? facilityPositions : []}
                                isPositionSelectDisabled={isPositionSelectDisabled}
                                onChange={(event) => {
                                    formik.setFieldValue('positionId', event.target.value)
                                    setSelectedPosition(event.target.value)
                                    const selectedPosition = positions.find(
                                        (position) => position._id === event.target.value
                                    )
                                    if (selectedPosition) {
                                        formik.setFieldValue(
                                            'notionalAmount',
                                            selectedPosition.notionalAmount || 0
                                        )
                                        formik.setFieldValue(
                                            'currency',
                                            selectedPosition.currency || ''
                                        )
                                        formik.setFieldValue('drawdownDate', null)
                                        if (
                                            formik.values.transactionType ===
                                            'Participate from Existing'
                                        ) {
                                            formik.setFieldValue(
                                                'originalMaturityDate',
                                                dayjs(
                                                    new Date(
                                                        positionParticipations[0].originalMaturityDate
                                                    )
                                                )
                                            )
                                        } else {
                                            formik.setFieldValue('originalMaturityDate', null)
                                        }
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <InputLabelStyled htmlFor='drawdownDate'>
                                Drawdown Date
                            </InputLabelStyled>
                            <DatePickerValidated name='drawdownDate' id='drawdownDate' />
                        </Grid>
                        <Grid item xs={4}>
                            {formik.values.transactionType === 'Participate from Existing' ? (
                                <>
                                    <InputLabelStyled disabled htmlFor='originalMaturityDate'>
                                        Maturity Date
                                    </InputLabelStyled>
                                    <DatePickerValidated
                                        disabled
                                        name='originalMaturityDate'
                                        id='originalMaturityDate'
                                    />
                                </>
                            ) : (
                                <>
                                    <InputLabelStyled htmlFor='originalMaturityDate'>
                                        Maturity Date
                                    </InputLabelStyled>
                                    <DatePickerValidated
                                        name='originalMaturityDate'
                                        id='originalMaturityDate'
                                    />
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ mt: '0.5rem', mb: '0.5rem' }} />
                        </Grid>
                        <Grid item xs={2}>
                            <InputLabelStyled disabled htmlFor='notionalAmount'>
                                Notional Amount
                            </InputLabelStyled>
                            <MoneyFieldValidated
                                disabled
                                name='notionalAmount'
                                id='notionalAmount'
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <InputLabelStyled disabled htmlFor='currency'>
                                Currency
                            </InputLabelStyled>
                            <TextFieldValidated disabled name='currency' id='currency' />
                        </Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={2}>
                            <InputLabelStyled htmlFor='participatingFund'>
                                Participating Fund(s)
                            </InputLabelStyled>
                        </Grid>
                        <Grid item xs={2}>
                            <InputLabelStyled htmlFor='participationRatio'>
                                Participation Ratio
                            </InputLabelStyled>
                        </Grid>
                        <Grid item xs={2}>
                            <InputLabelStyled disabled htmlFor='transactionPrincipal'>
                                Participation Amount
                            </InputLabelStyled>
                        </Grid>
                        <Grid item xs={2}>
                            <InputLabelStyled htmlFor='spreadRate'>Spread Rate</InputLabelStyled>
                        </Grid>
                        <Grid item xs={2}>
                            <InputLabelStyled disabled htmlFor='relationshipType'>
                                Relationship
                            </InputLabelStyled>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <FieldArray name='participations'>
                            {({ push, remove }) => (
                                <>
                                    {formik.values.participations.map((participation, index) => (
                                        <React.Fragment key={index}>
                                            <Grid item xs={2}>
                                                <FundSelect
                                                    name={`participations.${index}.fundId`}
                                                    funds={funds}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <PercentFieldValidated
                                                    name={`participations.${index}.participationRatio`}
                                                    onChange={(event) => {
                                                        const value = parseFloat(event.target.value)
                                                        formik.setFieldValue(
                                                            `participations.${index}.participationRatio`,
                                                            value
                                                        )
                                                        const notionalAmount = parseFloat(
                                                            formik.values.notionalAmount
                                                        )
                                                        formik.setFieldValue(
                                                            `participations.${index}.transactionPrincipal`,
                                                            (value / 100) * notionalAmount
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <MoneyFieldValidated
                                                    name={`participations.${index}.transactionPrincipal`}
                                                    value={
                                                        formik.values.participations[index]
                                                            .transactionPrincipal
                                                    }
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <PercentFieldValidated
                                                    name={`participations.${index}.spreadRate`}
                                                    disabled={
                                                        formik.values.participations[index]
                                                            .relationshipType === 'Parent'
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextFieldValidated
                                                    name={`participations.${index}.relationshipType`}
                                                    value={participation.relationshipType}
                                                    disabled
                                                ></TextFieldValidated>
                                            </Grid>
                                            <Grid item xs={2}>
                                                {index === 0 ? (
                                                    <IconButton
                                                        onClick={() =>
                                                            push({
                                                                fundId: '',
                                                                participationRatio: 0,
                                                                transactionPrincipal: 0,
                                                                relationshipType: 'Child',
                                                                spreadRate: 0,
                                                            })
                                                        }
                                                    >
                                                        <Add />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton onClick={() => remove(index)}>
                                                        <Delete />
                                                    </IconButton>
                                                )}
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                    {formik.errors.participations &&
                                        typeof formik.errors.participations === 'string' && (
                                            <Grid item xs={12}>
                                                <div
                                                    style={{
                                                        color: '#d32f2f',
                                                        fontWeight: 'bold',
                                                        marginTop: '0.5rem',
                                                    }}
                                                >
                                                    {formik.errors.participations}
                                                </div>
                                            </Grid>
                                        )}
                                </>
                            )}
                        </FieldArray>
                        <Grid item xs={10}>
                            <InputLabelStyled htmlFor='remarks'>Remarks (If Any)</InputLabelStyled>
                            <TextFieldValidated name='remarks' id='remarks' />
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12} container>
                            <Button type='submit' variant='contained' disabled={!formik.isValid}>
                                Review Participation(s)
                            </Button>
                        </Grid>
                    </Grid>
                    <DrawdownParticipationModal
                        formattedParticipations={formattedParticipations}
                        isDrawdownParticipationModalOpen={isDrawdownParticipationModalOpen}
                        handleCloseDrawdownParticipationModal={
                            handleCloseDrawdownParticipationModal
                        }
                    />
                    <InvalidDateModal
                        isInvalidDateModalOpen={isInvalidDateModalOpen}
                        handleCloseInvalidDateModal={handleCloseInvalidDateModal}
                    />
                </Form>
            )}
        </Formik>
    )
}

export default CreateTransactionForm
