import { apiSlice } from '../apiSlice'

export const fundsApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getAllFunds: build.query({
            query: () => ({ url: `records/funds`, method: 'GET' }),
            providesTags: ['Funds'],
        }),
        createFund: build.mutation({
            query: (newRow) => ({
                url: `records/funds`,
                method: 'POST',
                body: newRow,
            }),
            invalidatesTags: ['Funds', 'Participations', 'Holdings'],
        }),
        updateFund: build.mutation({
            query: (updatedRow) => ({
                url: `records/funds`,
                method: 'PUT',
                body: updatedRow,
            }),
            invalidatesTags: ['Funds', 'Participations', 'Holdings'],
        }),
        deleteFund: build.mutation({
            query: (id) => ({
                url: `records/funds/${id}`,
                method: 'DELETE',
                body: id,
            }),
            invalidatesTags: ['Funds', 'Participations', 'Holdings'],
        }),
    }),
})

export const {
    useCreateFundMutation,
    useGetAllFundsQuery,
    useUpdateFundMutation,
    useDeleteFundMutation,
} = fundsApiSlice
