import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Box,
    Typography,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import React, { useState, useEffect } from 'react'

const ProcessFilesModal = ({
    isGenerating,
    isZipping,
    isDownloading,
    setIsDownloading,
    isDownloadClicked,
}) => {
    const [wasZipping, setWasZipping] = useState(false)

    useEffect(() => {
        // Detect when isGenerating changes from true to false
        if (wasZipping && !isGenerating) {
            if (!isZipping && isDownloadClicked) {
                // Start download timer when generating has completed
                setIsDownloading(true)
                const downloadTimer = setTimeout(() => {
                    setIsDownloading(false)
                }, 4000)
                return () => clearTimeout(downloadTimer)
            }
        }
        setWasZipping(isZipping)
    }, [isGenerating, isZipping, isDownloadClicked])

    const getStepIcon = (isComplete) => {
        return isComplete ? (
            <CheckCircleIcon color='success' />
        ) : (
            <CircularProgress size={20} color='inherit' sx={{ color: 'grey.500' }} />
        )
    }

    return (
        <Dialog open={isGenerating || isZipping || isDownloading}>
            <DialogTitle>Processing Files</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Please wait while your files are being processed.
                    <br /> This may take a minute.
                </DialogContentText>

                <Box display='flex' alignItems='center' mt={2}>
                    <Box width={24} display='flex' justifyContent='center'>
                        {getStepIcon(!isGenerating)}
                    </Box>
                    <Typography
                        variant='body1'
                        sx={{
                            ml: 2,
                            color: isGenerating ? 'text.primary' : 'text.disabled',
                            opacity: isGenerating ? 1 : 0.5,
                        }}
                    >
                        Step 1: Generating SOAs
                    </Typography>
                </Box>

                <Box display='flex' alignItems='center' mt={2}>
                    <Box width={24} display='flex' justifyContent='center'>
                        {!isGenerating && getStepIcon(!isZipping)}
                    </Box>
                    <Typography
                        variant='body1'
                        sx={{
                            ml: 2,
                            color: isZipping ? 'text.primary' : 'text.disabled',
                            opacity: isZipping ? 1 : 0.5,
                        }}
                    >
                        Step 2: Zipping SOAs
                    </Typography>
                </Box>

                <Box display='flex' alignItems='center' mt={2}>
                    <Box width={24} display='flex' justifyContent='center'>
                        {!isGenerating && !isZipping && getStepIcon(!isDownloading)}
                    </Box>
                    <Typography
                        variant='body1'
                        sx={{
                            ml: 2,
                            color: isDownloading ? 'text.primary' : 'text.disabled',
                            opacity: isDownloading ? 1 : 0.5,
                        }}
                    >
                        Step 3: Downloading Zip File
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ProcessFilesModal
