import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Button,
    MenuItem,
} from '@mui/material'
import dayjs from 'dayjs'

import TextFieldValidated from '../../fields/TextFieldValidated'
import MoneyFieldValidated from '../../fields/MoneyFieldValidated'
import InputLabelStyled from '../../fields/InputLabelStyled'
import SelectValidated from '../../fields/select/SelectValidated'
import DatePickerValidated from '../../fields/DatePickerValidated'
import PreSubmitButton from '../../buttons/PreSubmitButton'

import LenderSelect from '../../fields/select/LenderSelect'
import BorrowerSelect from '../../fields/select/BorrowerSelect'
import BankAccountSelect from '../../fields/select/BankAccountSelect'

import { getLenderCode, getBorrowerName } from '../../../../utils/helpers/getDetails'
import { formatDatePickerDate } from '../../../../utils/helpers/formatValue'
import { borrowerTypeList } from '../../../../utils/lists/borrowerTypeList'
import { facilityRoleList } from '../../../../utils/lists/facilityRoleList'
import { currencyList } from '../../../../utils/lists/currencyList'
import { accrualMethodList } from '../../../../utils/lists/accrualMethodList'

import { selectCurrentUser } from '../../../../app/api/auth/authSlice'
import { useCreateFacilityMutation } from '../../../../app/api/records/facilitiesApiSlice'
import { useGetAllLendersQuery } from '../../../../app/api/records/lendersApiSlice'
import { useGetAllBorrowersQuery } from '../../../../app/api/records/borrowersApiSlice'
import { useGetAllBankAccountsQuery } from '../../../../app/api/records/bankAccountsApiSlice'

import DuplicateErrorModal from '../../modals/DuplicateErrorModal'
import ConfirmSubmitModal from '../../modals/ConfirmSubmitModal'
import CreateSuccessModal from '../../modals/CreateSuccessModal'
import MissingFieldModal from '../../modals/MissingFieldModal'
import InvalidDateModal from '../../modals/InvalidDateModal'

const facilityFormValidation = Yup.object().shape({
    lenderId: Yup.string().required('Required'),
    borrowerId: Yup.string().required('Required'),
    facilityNumber: Yup.string().required('Required'),
    facilityLegalId: Yup.string().required('Required'),
    limit: Yup.number().required('Required'),
    limitCurrency: Yup.string().required('Required'),
    accrualMethod: Yup.string().required('Required'),
    creditEnhancement: Yup.string().required('Required'),
    role: Yup.string().required('Required'),
    firstDrawdownDate: Yup.date().nullable().required('Required'),
    expiryDate: Yup.date().nullable().required('Required'),
    beneficiaryBankId: Yup.string(),
    beneficiaryName: Yup.string(),
    accountNumber: Yup.string(),
})

const CreateFacilityForm = ({ isFormOpen, handleCloseForm }) => {
    const { firstName, lastName } = useSelector(selectCurrentUser)
    const userFullname = `${firstName} ${lastName}`

    const initialFacilityFormState = {
        lenderId: '',
        borrowerId: '',
        borrowerType: '',
        facilityNumber: '',
        facilityLegalId: '',
        limit: '',
        limitCurrency: '',
        accrualMethod: '',
        creditEnhancement: '',
        role: '',
        firstDrawdownDate: dayjs(new Date().toISOString()),
        expiryDate: dayjs(new Date().toISOString()),
        beneficiaryBankId: '',
        beneficiaryName: '',
        accountNumber: '',
        createdBy: userFullname,
    }

    const [createFacility] = useCreateFacilityMutation()

    const [duplicateEntry, setDuplicateEntry] = useState([])
    const [selectedBorrowerType, setSelectedBorrowerType] = useState([])
    const [isBorrowerSelectDisabled, setIsBorrowerSelectDisabled] = useState(true)
    const [selectedBankAccount, setSelectedBankAccount] = useState('')

    const [isDuplicateErrorModalOpen, setIsDuplicateErrorModalOpen] = useState(false)
    const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] = useState(false)
    const [isCreateSuccessModalOpen, setIsCreateSuccessModalOpen] = useState(false)
    const [isMissingFieldModalOpen, setIsMissingFieldModalOpen] = useState(false)
    const [isInvalidDateModalOpen, setIsInvalidDateModalOpen] = useState(false)

    const formikRef = useRef()

    const { data: lenders = [] } = useGetAllLendersQuery()
    const { data: borrowers = [] } = useGetAllBorrowersQuery()
    const { data: bankAccounts = [] } = useGetAllBankAccountsQuery()

    const handleOpenDuplicateErrorModal = ({ existingData }) => {
        setIsDuplicateErrorModalOpen(true)
        setDuplicateEntry(existingData)
    }

    const handleCloseDuplicateErrorModal = () => {
        setIsDuplicateErrorModalOpen(false)
    }

    const handleOpenConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(true)
    }

    const handleCloseConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(false)
    }

    const handleOpenCreateSuccessModal = () => {
        setIsCreateSuccessModalOpen(true)
    }

    const handleCloseCreateSuccessModal = () => {
        setIsCreateSuccessModalOpen(false)
        handleCloseForm()
    }

    const handleOpenMissingFieldModal = () => {
        setIsMissingFieldModalOpen(true)
    }

    const handleCloseMissingFieldModal = () => {
        setIsMissingFieldModalOpen(false)
    }

    const handleOpenInvalidDateModal = () => {
        setIsInvalidDateModalOpen(true)
    }

    const handleCloseInvalidDateModal = () => {
        setIsInvalidDateModalOpen(false)
    }

    const matchedBorrowers = selectedBorrowerType
        ? borrowers.filter((borrower) => borrower.borrowerType === selectedBorrowerType)
        : borrowers

    const handleSelectBankAccount = (beneficiaryBankId, formik) => {
        formik.setFieldValue('beneficiaryBankId', beneficiaryBankId)
        setSelectedBankAccount(beneficiaryBankId)
        const selectedBankAccountDetails = bankAccounts.find(
            (selectedBankAccount) => selectedBankAccount._id === beneficiaryBankId
        )
        formik.setFieldValue('beneficiaryName', selectedBankAccountDetails.beneficiaryName)
        formik.setFieldValue('accountNumber', selectedBankAccountDetails.accountNumber)
        return
    }

    const handleCreateFacilityFormSubmit = async (values) => {
        const {
            lenderId,
            borrowerId,
            facilityNumber,
            facilityLegalId,
            limit,
            limitCurrency,
            accrualMethod,
            creditEnhancement,
            role,
            firstDrawdownDate,
            expiryDate,
            beneficiaryBankId,
            beneficiaryName,
            accountNumber,
            createdBy,
        } = values

        try {
            const response = await createFacility({
                lenderId,
                borrowerId,
                facilityNumber,
                facilityLegalId,
                limit,
                limitCurrency,
                accrualMethod,
                creditEnhancement,
                role,
                firstDrawdownDate,
                expiryDate,
                beneficiaryBankId,
                beneficiaryName,
                accountNumber,
                createdBy,
            })

            if (response.error && response.error.data && response.error.data.existingData) {
                const { error, existingData, newData } = response.error.data

                handleOpenDuplicateErrorModal({ existingData })
            } else if (response.data && response.data.newData) {
                const { error, existingData, newData } = response.data
                handleOpenCreateSuccessModal()
            }
        } catch (error) {
            console.error('Error creating facility:', error)
        }
    }

    const handleCreateFacilityFormClose = () => {
        handleCloseForm()
        setSelectedBorrowerType('')
        setIsBorrowerSelectDisabled(true)
        if (formikRef.current) {
            formikRef.current.resetForm()
        }
    }

    return (
        <Dialog open={isFormOpen} onClose={handleCreateFacilityFormClose}>
            <DialogTitle>Create New Facility</DialogTitle>
            <Formik
                initialValues={{ ...initialFacilityFormState }}
                validationSchema={facilityFormValidation}
                onSubmit={(values) => {
                    handleCreateFacilityFormSubmit(values)
                }}
                validateOnMount={true}
                innerRef={formikRef}
            >
                {(formik) => (
                    <Form>
                        <DialogContent dividers>
                            <DialogContentText sx={{ mb: 2 }}>
                                Please fill in details of the New Facility. Ensure that both
                                Facility Number and Facility Legal ID are not duplicated.
                            </DialogContentText>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='lenderId'>Lender *</InputLabelStyled>
                                    <LenderSelect active lenders={lenders} />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='borrowerType'>
                                        Borrower Type *
                                    </InputLabelStyled>
                                    <SelectValidated
                                        name='borrowerType'
                                        id='borrowerType'
                                        onChange={(event) => {
                                            formik.setFieldValue('borrowerType', event.target.value)
                                            setSelectedBorrowerType(event.target.value)
                                            setIsBorrowerSelectDisabled(!event.target.value)
                                            formik.setFieldValue('borrowerId', '')
                                        }}
                                    >
                                        {borrowerTypeList.map((borrowerTypeListItem) => (
                                            <MenuItem
                                                key={borrowerTypeListItem}
                                                value={borrowerTypeListItem}
                                            >
                                                {borrowerTypeListItem}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='borrowerId'>
                                        Borrower *
                                    </InputLabelStyled>
                                    <BorrowerSelect
                                        active
                                        borrowers={matchedBorrowers}
                                        isBorrowerSelectDisabled={isBorrowerSelectDisabled}
                                        onChange={(event) =>
                                            formik.setFieldValue('borrowerId', event.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='facilityNumber'>
                                        Facility Number *
                                    </InputLabelStyled>
                                    <TextFieldValidated name='facilityNumber' id='facilityNumber' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='facilityLegalId'>
                                        Facility Legal ID *
                                    </InputLabelStyled>
                                    <TextFieldValidated
                                        name='facilityLegalId'
                                        id='facilityLegalId'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='limit'>Limit *</InputLabelStyled>
                                    <MoneyFieldValidated name='limit' id='limit' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='limitCurrency'>
                                        Currency *
                                    </InputLabelStyled>
                                    <SelectValidated name='limitCurrency' id='limitCurrency'>
                                        {currencyList.map((currencyListItem) => (
                                            <MenuItem
                                                key={currencyListItem}
                                                value={currencyListItem}
                                            >
                                                {currencyListItem}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='accrualMethod'>
                                        Accrual Method *
                                    </InputLabelStyled>
                                    <SelectValidated name='accrualMethod' id='accrualMethod'>
                                        {accrualMethodList.map((accrualMethodListItem) => (
                                            <MenuItem
                                                key={accrualMethodListItem}
                                                value={accrualMethodListItem}
                                            >
                                                {accrualMethodListItem}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='role'>Role *</InputLabelStyled>
                                    <SelectValidated name='role' id='role'>
                                        {facilityRoleList.map((facilityRoleListItem) => (
                                            <MenuItem
                                                key={facilityRoleListItem}
                                                value={facilityRoleListItem}
                                            >
                                                {facilityRoleListItem}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='creditEnhancement'>
                                        Credit Enhancement *
                                    </InputLabelStyled>
                                    <TextFieldValidated
                                        name='creditEnhancement'
                                        id='creditEnhancement'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='beneficiaryBankId'>
                                        Beneficiary Bank
                                    </InputLabelStyled>
                                    <BankAccountSelect
                                        bankAccounts={bankAccounts}
                                        onChange={(event) => {
                                            handleSelectBankAccount(event.target.value, formik)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='firstDrawdownDate'>
                                        First Drawdown Date *
                                    </InputLabelStyled>
                                    <DatePickerValidated name='firstDrawdownDate' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='expiryDate'>
                                        Expiry Date *
                                    </InputLabelStyled>
                                    <DatePickerValidated name='expiryDate' />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' onClick={handleCloseForm}>
                                Cancel
                            </Button>
                            <PreSubmitButton
                                values={{
                                    ...formik,
                                    values: {
                                        ...formik.values,
                                        firstDrawdownDate: formatDatePickerDate(
                                            formik.values.firstDrawdownDate
                                        ),
                                        expiryDate: formatDatePickerDate(formik.values.expiryDate),
                                    },
                                }}
                                handleOpenMissingFieldModal={handleOpenMissingFieldModal}
                                handleOpenInvalidDateModal={handleOpenInvalidDateModal}
                                handleOpenConfirmSubmitModal={handleOpenConfirmSubmitModal}
                            >
                                Submit
                            </PreSubmitButton>
                        </DialogActions>
                        <DuplicateErrorModal
                            duplicateEntry={duplicateEntry}
                            lenders={lenders}
                            borrowers={borrowers}
                            values={{
                                ...formik,
                                values: {
                                    ...formik.values,
                                    // Lender & borrower _id converted in duplicate error modal to handle backend returned _id
                                    lenderCode: getLenderCode(lenders, formik.values.lenderId),
                                    borrowerName: getBorrowerName(
                                        borrowers,
                                        formik.values.borrowerId
                                    ),
                                    firstDrawdownDate: formatDatePickerDate(
                                        formik.values.firstDrawdownDate
                                    ),
                                    expiryDate: formatDatePickerDate(formik.values.expiryDate),
                                },
                            }}
                            isDuplicateErrorModalOpen={isDuplicateErrorModalOpen}
                            handleCloseDuplicateErrorModal={handleCloseDuplicateErrorModal}
                        />
                        <ConfirmSubmitModal
                            values={{
                                ...formik,
                                values: {
                                    ...formik.values,
                                    lenderCode: getLenderCode(lenders, formik.values.lenderId),
                                    borrowerName: getBorrowerName(
                                        borrowers,
                                        formik.values.borrowerId
                                    ),
                                    firstDrawdownDate: formatDatePickerDate(
                                        formik.values.firstDrawdownDate
                                    ),
                                    expiryDate: formatDatePickerDate(formik.values.expiryDate),
                                },
                            }}
                            isConfirmSubmitModalOpen={isConfirmSubmitModalOpen}
                            handleCloseConfirmSubmitModal={handleCloseConfirmSubmitModal}
                        />
                        <CreateSuccessModal
                            isCreateSuccessModalOpen={isCreateSuccessModalOpen}
                            handleCloseCreateSuccessModal={handleCloseCreateSuccessModal}
                        />
                        <InvalidDateModal
                            isInvalidDateModalOpen={isInvalidDateModalOpen}
                            handleCloseInvalidDateModal={handleCloseInvalidDateModal}
                        />
                        <MissingFieldModal
                            isMissingFieldModalOpen={isMissingFieldModalOpen}
                            handleCloseMissingFieldModal={handleCloseMissingFieldModal}
                        />
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default CreateFacilityForm
