import { Add } from '@mui/icons-material'
import { Button, Divider, Grid } from '@mui/material'
import { useTheme } from '@mui/material'
import {
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
} from '@mui/x-data-grid'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import RegisterUserForm from '../forms/auth/RegisterUserForm'
import CreateLenderForm from '../forms/records/CreateLenderForm'
import CreateFundForm from '../forms/records/CreateFundForm'
import CreateBorrowerForm from '../forms/records/CreateBorrowerForm'
import CreateFacilityForm from '../forms/records/CreateFacilityForm'
import CreatePositionForm from '../forms/records/CreatePositionForm'
import CreateExchangeRateForm from '../forms/records/CreateExchangeRateForm'
import CreateSupplementaryInterestRateForm from '../forms/records/CreateSupplementaryInterestRateForm'
import CreateBankAccountForm from '../forms/records/CreateBankAccountForm'

import CustomExport from './CustomExport'
import { selectCurrentUser } from '../../../app/api/auth/authSlice'

const CustomToolbar = () => {
    const [isFormOpen, setIsFormOpen] = useState(false)
    const location = useLocation()
    const theme = useTheme()
    const { role } = useSelector(selectCurrentUser)

    const handleOpenForm = () => {
        setIsFormOpen(true)
    }

    const handleCloseForm = () => {
        setIsFormOpen(false)
    }

    // Common props for create form
    const commonProps = {
        isFormOpen,
        handleCloseForm,
    }

    // Determine form component based on location
    let formComponent
    if (location.pathname.includes('/records/funds')) {
        formComponent = <CreateFundForm {...commonProps} />
    } else if (location.pathname.includes('/records/lenders')) {
        formComponent = <CreateLenderForm {...commonProps} />
    } else if (location.pathname.includes('/records/borrowers')) {
        formComponent = <CreateBorrowerForm {...commonProps} />
    } else if (location.pathname.includes('/records/facilities')) {
        formComponent = <CreateFacilityForm {...commonProps} />
    } else if (location.pathname.includes('/records/positions')) {
        formComponent = <CreatePositionForm {...commonProps} />
    } else if (location.pathname.includes('/records/exchange-rates')) {
        formComponent = <CreateExchangeRateForm {...commonProps} />
    } else if (location.pathname.includes('/records/supplementary-interest-rates')) {
        formComponent = <CreateSupplementaryInterestRateForm {...commonProps} />
    } else if (location.pathname.includes('/records/bank-accounts')) {
        formComponent = <CreateBankAccountForm {...commonProps} />
    } else if (location.pathname.includes('/auth/users')) {
        formComponent = <RegisterUserForm {...commonProps} />
    } else {
        formComponent = null
    }

    return (
        <GridToolbarContainer>
            {formComponent}
            <Grid container spacing={1}>
                {(role === 'Admin' || role === 'Editor') &&
                !location.pathname.includes('/records/transactions') &&
                !location.pathname.includes('/records/participations') &&
                !location.pathname.includes('/reports/holdings') &&
                !location.pathname.includes('/logs/user-logs') ? (
                    <>
                        <Grid item xs={12}>
                            <Button startIcon={<Add />} onClick={() => handleOpenForm()}>
                                Add Record
                            </Button>
                            <Divider />
                        </Grid>
                    </>
                ) : null}
                <Grid item xs={12}>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <CustomExport />
                </Grid>
            </Grid>
        </GridToolbarContainer>
    )
}

export default CustomToolbar
