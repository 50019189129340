import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Button,
    MenuItem,
} from '@mui/material'
import TextFieldValidated from '../../fields/TextFieldValidated'
import InputLabelStyled from '../../fields/InputLabelStyled'
import PasswordField from '../../fields/PasswordField'
import SelectValidated from '../../fields/select/SelectValidated'
import PreSubmitButton from '../../buttons/PreSubmitButton'

import { passwordRule, passwordHelper } from '../../../../utils/helpers/passwordDef'
import { roleList } from '../../../../utils/lists/roleList'

import { useRegisterUserMutation } from '../../../../app/api/auth/usersApiSlice'

import DuplicateErrorModal from '../../modals/DuplicateErrorModal'
import ConfirmSubmitModal from '../../modals/ConfirmSubmitModal'
import CreateSuccessModal from '../../modals/CreateSuccessModal'
import MissingFieldModal from '../../modals/MissingFieldModal'

const registerFormValidation = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    role: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().matches(passwordRule, passwordHelper).required('Required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required'),
})

const initialRegisterFormState = {
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    password: '',
    confirmPassword: '',
}

const RegisterUserForm = ({ isFormOpen, handleCloseForm }) => {
    const [registerUser] = useRegisterUserMutation()

    const [duplicateEntry, setDuplicateEntry] = useState([])

    const [isDuplicateErrorModalOpen, setIsDuplicateErrorModalOpen] = useState(false)
    const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] = useState(false)
    const [isCreateSuccessModalOpen, setIsCreateSuccessModalOpen] = useState(false)
    const [isMissingFieldModalOpen, setIsMissingFieldModalOpen] = useState(false)

    const handleOpenDuplicateErrorModal = ({ existingData }) => {
        setIsDuplicateErrorModalOpen(true)
        setDuplicateEntry(existingData)
    }

    const handleCloseDuplicateErrorModal = () => {
        setIsDuplicateErrorModalOpen(false)
    }

    const handleOpenConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(true)
    }

    const handleCloseConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(false)
    }

    const handleOpenCreateSuccessModal = () => {
        setIsCreateSuccessModalOpen(true)
    }

    const handleCloseCreateSuccessModal = () => {
        setIsCreateSuccessModalOpen(false)
        handleCloseForm()
    }

    const handleOpenMissingFieldModal = () => {
        setIsMissingFieldModalOpen(true)
    }

    const handleCloseMissingFieldModal = () => {
        setIsMissingFieldModalOpen(false)
    }

    const handleRegisterFormSubmit = async (values) => {
        const { firstName, lastName, role, email, password } = values

        try {
            const response = await registerUser({ firstName, lastName, role, email, password })

            if (response.error && response.error.data && response.error.data.existingData) {
                const { error, existingData, newData } = response.error.data

                handleOpenDuplicateErrorModal({ existingData })
            } else if (response.data && response.data.newData) {
                const { error, existingData, newData } = response.data

                handleOpenCreateSuccessModal()
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Dialog open={isFormOpen} onClose={handleCloseForm}>
            <DialogTitle>Register New User</DialogTitle>
            <Formik
                initialValues={{ ...initialRegisterFormState }}
                validationSchema={registerFormValidation}
                onSubmit={(values) => {
                    handleRegisterFormSubmit(values)
                }}
                validateOnMount={true}
            >
                {(values) => (
                    <Form>
                        <DialogContent dividers>
                            <DialogContentText sx={{ mb: 2 }}>
                                Please fill in details of the New User. Ensure that the email is
                                unique.
                            </DialogContentText>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='firstName'>
                                        First Name *
                                    </InputLabelStyled>
                                    <TextFieldValidated name='firstName' id='firstName' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='lastName'>
                                        Last Name *
                                    </InputLabelStyled>
                                    <TextFieldValidated name='lastName' id='lastName' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='role'>Role *</InputLabelStyled>
                                    <SelectValidated name='role' id='role'>
                                        {roleList.map((roleListItem) => (
                                            <MenuItem key={roleListItem} value={roleListItem}>
                                                {roleListItem}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='email'>Email *</InputLabelStyled>
                                    <TextFieldValidated name='email' id='email' />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='password'>
                                        Password *
                                    </InputLabelStyled>
                                    <PasswordField name='password' id='password' />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='confirmPassword'>
                                        Confirm Password *
                                    </InputLabelStyled>
                                    <PasswordField name='confirmPassword' id='confirmPassword' />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' onClick={handleCloseForm}>
                                Cancel
                            </Button>
                            <PreSubmitButton
                                values={values}
                                handleOpenMissingFieldModal={handleOpenMissingFieldModal}
                                handleOpenConfirmSubmitModal={handleOpenConfirmSubmitModal}
                            >
                                Submit
                            </PreSubmitButton>
                        </DialogActions>
                        <DuplicateErrorModal
                            duplicateEntry={duplicateEntry}
                            values={values}
                            isDuplicateErrorModalOpen={isDuplicateErrorModalOpen}
                            handleCloseDuplicateErrorModal={handleCloseDuplicateErrorModal}
                        />
                        <ConfirmSubmitModal
                            values={values}
                            isConfirmSubmitModalOpen={isConfirmSubmitModalOpen}
                            handleCloseConfirmSubmitModal={handleCloseConfirmSubmitModal}
                        />
                        <CreateSuccessModal
                            values={values}
                            isCreateSuccessModalOpen={isCreateSuccessModalOpen}
                            handleCloseCreateSuccessModal={handleCloseCreateSuccessModal}
                        />
                        <MissingFieldModal
                            isMissingFieldModalOpen={isMissingFieldModalOpen}
                            handleCloseMissingFieldModal={handleCloseMissingFieldModal}
                        />
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default RegisterUserForm
