import { Typography, Link } from '@mui/material'

const Copyright = (props) => {
    return (
        <Typography {...props}>
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color='inherit' href='https://www.linkedin.com/in/lee-zong-han/'>
                Lee Zong Han
            </Link>
        </Typography>
    )
}

export default Copyright
