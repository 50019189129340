import React, { useState } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { Outlet } from 'react-router-dom'
import Navbar from '../components/layout/Navbar'
import Sidebar from '../components/layout/Sidebar'
import Footer from '../components/layout/Footer'

const Layout = () => {
    const isNonMobile = useMediaQuery('(min-width: 600px)')
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const drawerWidth = '200px'
    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar
                isNonMobile={isNonMobile}
                drawerWidth={drawerWidth}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
            />
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    overflow: 'hidden',
                }}
            >
                <Navbar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                    }}
                >
                    <Outlet />
                </Box>
                <Footer />
            </Box>
        </Box>
    )
}

export default Layout
