import { apiSlice } from '../apiSlice'

export const statementOfAccountsApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getStatementOfAccount: build.query({
            query: (filterParams) => ({
                url: `reports/statement-of-accounts`,
                method: 'GET',
                params: filterParams,
            }),
            providesTags: ['StatementOfAccounts'],
        }),
        getMultipleStatementOfAccount: build.query({
            query: (filterParams) => ({
                url: `reports/statement-of-accounts/download-multiple`,
                method: 'GET',
                params: filterParams,
            }),
            providesTags: ['StatementOfAccounts'],
        }),
    }),
})

export const { useGetStatementOfAccountQuery, useGetMultipleStatementOfAccountQuery } =
    statementOfAccountsApiSlice
