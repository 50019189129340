import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import { EditOutlined, DeleteOutlined } from '@mui/icons-material'
import Header from '../../components/layout/Header'
import CustomToolbar from '../../components/ui/datagrid/CustomToolbar'
import CustomFilterForm from '../../components/ui/datagrid/CustomFilterForm'
import CustomNoRowOverlay from '../../components/ui/datagrid/CustomNoRowOverlay'

import { formatDataGridDate } from '../../utils/helpers/formatValue'

import { selectCurrentUser } from '../../app/api/auth/authSlice'
import {
    useDeleteBankAccountMutation,
    useGetAllBankAccountsQuery,
} from '../../app/api/records/bankAccountsApiSlice'
import ConfirmDeleteModal from '../../components/ui/modals/ConfirmDeleteModal'
import DeleteSuccessModal from '../../components/ui/modals/DeleteSuccessModal'
import ExistingRelationshipModal from '../../components/ui/modals/ExistingRelationshipModal'
import CreateBankAccountForm from '../../components/ui/forms/records/CreateBankAccountForm'
import UpdateBankAccountForm from '../../components/ui/forms/records/UpdateBankAccountForm'

const initialBankAccountState = {
    _id: '',
    beneficiaryName: '',
    accountNumber: '',
    bankName: '',
    bankAddress: '',
    swiftCode: '',
    reference: '',
}

const BankAccounts = () => {
    const { data: initialRows, isLoading } = useGetAllBankAccountsQuery()
    const [deleteBankAccount] = useDeleteBankAccountMutation()

    const { role } = useSelector(selectCurrentUser)

    const [isFormOpen, setIsFormOpen] = useState(false)
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false)
    const [isDeleteSuccessModalOpen, setIsDeleteSuccessModalOpen] = useState(false)
    const [isExistingRelationshipModalOpen, setIsExistingRelationshipModalOpen] = useState(false)
    const [toBeUpdated, setToBeUpdated] = useState(initialBankAccountState)
    const [isEditClicked, setIsEditClicked] = useState(false)
    const [toBeDeleted, setToBeDeleted] = useState([])

    const handleOpenForm = () => {
        setIsFormOpen(true)
    }

    const handleCloseForm = () => {
        setIsFormOpen(false)
        setToBeUpdated(initialBankAccountState)
        setIsEditClicked(false)
    }

    const handleOpenConfirmDeleteModal = () => {
        setIsConfirmDeleteModalOpen(true)
    }

    const handleCloseConfirmDeleteModal = () => {
        setIsConfirmDeleteModalOpen(false)
    }

    const handleOpenDeleteSuccessModal = () => {
        setIsDeleteSuccessModalOpen(true)
    }

    const handleCloseDeleteSuccessModal = () => {
        setIsDeleteSuccessModalOpen(false)
    }

    const handleOpenExistingRelationshipModal = () => {
        setIsExistingRelationshipModalOpen(true)
    }

    const handleCloseExistingRelationshipModal = () => {
        setIsExistingRelationshipModalOpen(false)
    }

    const handleEdit = (params) => {
        const { row } = params

        setIsEditClicked(true)
        setToBeUpdated(row)
        handleOpenForm()
    }

    const handlePreDelete = (params) => {
        const { row } = params
        handleOpenConfirmDeleteModal()
        setToBeDeleted(row)
    }

    const handleDelete = async () => {
        try {
            const { _id } = toBeDeleted
            const response = await deleteBankAccount(_id)
            if (response.data) {
                handleOpenDeleteSuccessModal()
            } else if (response.error.data.relatedData) {
                handleOpenExistingRelationshipModal()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const columns = [
        ...(role === 'Admin' || role === 'Editor'
            ? [
                  {
                      field: 'actions',
                      headerName: 'Actions',
                      type: 'actions',
                      getActions: (params) => {
                          return [
                              <GridActionsCellItem
                                  icon={<EditOutlined />}
                                  label='Edit'
                                  onClick={() => {
                                      handleEdit(params)
                                  }}
                                  color='inherit'
                              />,
                              <GridActionsCellItem
                                  icon={<DeleteOutlined />}
                                  label='Delete'
                                  onClick={() => {
                                      handlePreDelete(params)
                                  }}
                                  color='inherit'
                              />,
                          ]
                      },
                  },
              ]
            : []),
        { field: '_id', headerName: 'Id' },
        {
            field: 'beneficiaryName',
            headerName: 'Beneficiary Name',
            width: 550,
        },
        {
            field: 'accountNumber',
            headerName: 'Account Number',
            width: 150,
        },
        {
            field: 'bankName',
            headerName: 'Bank Name',
            width: 200,
        },
        {
            field: 'bankAddress',
            headerName: 'Bank Address',
            width: 200,
        },
        {
            field: 'swiftCode',
            headerName: 'SWIFT Code',
            width: 120,
        },
        {
            field: 'reference',
            headerName: 'Reference',
            width: 120,
        },
        {
            field: 'createdBy',
            headerName: 'Created By',
            width: 150,
        },
        {
            field: 'lastUpdatedBy',
            headerName: 'Last Updated By',
            width: 150,
        },
        {
            field: 'validFrom',
            headerName: 'Valid From',
            type: 'date',
            width: 150,
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
    ]

    return (
        <Box>
            <Header title='Bank Accounts' subtitle='List of Bank Accounts'></Header>
            <Box
                sx={{
                    m: '1.5rem 2.5rem',
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    height: '80vh',
                    '& .MuiDataGrid-root': {
                        maxWidth: '95vw',
                    },
                }}
            >
                <DataGrid
                    loading={isLoading}
                    getRowId={(row) => row._id}
                    rows={initialRows || []}
                    columns={columns}
                    pageSizeOptions={[20, 50, 100]}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 20 } },
                        columns: {
                            columnVisibilityModel: {
                                // validFrom: false,
                            },
                        },
                        sorting: { sortModel: [{ field: 'beneficiaryName', sort: 'asc' }] },
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                        noRowsOverlay: CustomNoRowOverlay,
                    }}
                    slotProps={{
                        filterPanel: {
                            filterFormProps: CustomFilterForm,
                        },
                    }}
                />
            </Box>
            <ConfirmDeleteModal
                handleDelete={handleDelete}
                toBeDeleted={toBeDeleted}
                isConfirmDeleteModalOpen={isConfirmDeleteModalOpen}
                handleCloseConfirmDeleteModal={handleCloseConfirmDeleteModal}
            />
            <DeleteSuccessModal
                isDeleteSuccessModalOpen={isDeleteSuccessModalOpen}
                handleCloseDeleteSuccessModal={handleCloseDeleteSuccessModal}
                handleCloseConfirmDeleteModal={handleCloseConfirmDeleteModal}
            />
            <ExistingRelationshipModal
                isExistingRelationshipModalOpen={isExistingRelationshipModalOpen}
                handleCloseExistingRelationshipModal={handleCloseExistingRelationshipModal}
            />
            {isEditClicked ? (
                <UpdateBankAccountForm
                    isFormOpen={isFormOpen}
                    handleCloseForm={handleCloseForm}
                    toBeUpdated={toBeUpdated}
                    isEditClicked={isEditClicked}
                    setIsEditClicked={setIsEditClicked}
                />
            ) : (
                <CreateBankAccountForm
                    isFormOpen={isFormOpen}
                    handleCloseForm={handleCloseForm}
                    toBeUpdated={toBeUpdated}
                    isEditClicked={isEditClicked}
                    setIsEditClicked={setIsEditClicked}
                />
            )}
        </Box>
    )
}

export default BankAccounts
