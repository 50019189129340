import React, { useEffect, useState } from 'react'
import {
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme,
    Collapse,
} from '@mui/material'
import { ChevronLeft, ChevronRightOutlined, ExpandMoreOutlined } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import { recordsMenu, reportsMenu } from '../../utils/lists/menuItem'
import logoLight from '../../assets/images/logo_light.png'
import logoDark from '../../assets/images/logo_dark.png'

const Sidebar = ({ drawerWidth, isSidebarOpen, setIsSidebarOpen, isNonMobile }) => {
    const { pathname } = useLocation()
    const [active, setActive] = useState('')
    const [isRecordsMenuOpen, setIsRecordsMenuOpen] = useState(false)
    const [isReportsMenuOpen, setIsReportsMenuOpen] = useState(false)

    const navigate = useNavigate()
    const theme = useTheme()

    useEffect(() => {
        setActive(pathname.substring(1))
    }, [pathname])
    return (
        <Box component='nav'>
            {isSidebarOpen && (
                <Drawer
                    open={isSidebarOpen}
                    onClose={() => setIsSidebarOpen(false)}
                    variant='persistent'
                    anchor='left'
                    sx={{
                        width: drawerWidth,
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                        },
                    }}
                >
                    <Box width='100%'>
                        <Box display='flex' justifyContent='center' m='1rem 0.5rem 1rem 0.5rem'>
                            <img
                                src={theme.palette.mode === 'light' ? logoLight : logoDark}
                                style={{ width: '120px' }}
                            />
                        </Box>
                        {!isNonMobile && (
                            <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                                <ChevronLeft />
                            </IconButton>
                        )}
                    </Box>
                    <List>
                        {recordsMenu.map(({ text, type }) => {
                            if (type === 'header') {
                                return (
                                    <ListItem key={text} sx={{ height: '2rem' }} disableGutters>
                                        <ListItemButton
                                            onClick={() => {
                                                setIsRecordsMenuOpen(!isRecordsMenuOpen)
                                            }}
                                        >
                                            <ListItemIcon>
                                                {isRecordsMenuOpen ? (
                                                    <ExpandMoreOutlined />
                                                ) : (
                                                    <ChevronRightOutlined />
                                                )}
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography fontWeight='bold'>{text}</Typography>
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                )
                            }

                            const lcText = text.toLowerCase().replace(/\s+/g, '-')
                            return (
                                <Collapse key={text} in={isRecordsMenuOpen}>
                                    <ListItem sx={{ height: '2rem' }} disableGutters>
                                        <ListItemButton
                                            onClick={() => {
                                                navigate(`/records/${lcText}`)
                                                setActive(lcText)
                                            }}
                                            sx={{ ml: '2.5rem' }}
                                        >
                                            <ListItemText>
                                                <Typography variant='body2'>{text}</Typography>
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                </Collapse>
                            )
                        })}
                    </List>
                    <List>
                        {reportsMenu.map(({ text, type }) => {
                            if (type === 'header') {
                                return (
                                    <ListItem key={text} sx={{ height: '2rem' }} disableGutters>
                                        <ListItemButton
                                            onClick={() => {
                                                setIsReportsMenuOpen(!isReportsMenuOpen)
                                            }}
                                        >
                                            <ListItemIcon>
                                                {isReportsMenuOpen ? (
                                                    <ExpandMoreOutlined />
                                                ) : (
                                                    <ChevronRightOutlined />
                                                )}
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography fontWeight='bold'>{text}</Typography>
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                )
                            }
                            const lcText = text.toLowerCase().replace(/\s+/g, '-')
                            return (
                                <Collapse key={text} in={isReportsMenuOpen}>
                                    <ListItem sx={{ height: '2rem' }} disableGutters>
                                        <ListItemButton
                                            onClick={() => {
                                                navigate(`/reports/${lcText}`)
                                                setActive(lcText)
                                            }}
                                            sx={{ ml: '2rem' }}
                                        >
                                            <ListItemText>
                                                <Typography variant='body2'>{text}</Typography>
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                </Collapse>
                            )
                        })}
                    </List>
                </Drawer>
            )}
        </Box>
    )
}

export default Sidebar
