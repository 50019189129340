import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { useField } from 'formik'

const NumberField = ({ name, ...otherProps }) => {
    const [customError, setCustomError] = useState(null)
    const [field, meta, helpers] = useField(name)

    const handleInputChange = (event) => {
        const inputValue = event.target.value

        const numericValue = parseFloat(inputValue)

        if (isNaN(numericValue) && inputValue.trim() !== '') {
            setCustomError('Enter a Numeric Value')
            helpers.setValue('')
        } else {
            setCustomError(null)
            helpers.setValue(inputValue.trim() === '' ? '' : numericValue)
        }
    }

    const configNumberField = {
        ...field,
        ...otherProps,
        fullWidth: true,
        variant: 'outlined',
        size: 'small',
        onChange: handleInputChange,
    }

    if (meta && meta.touched) {
        if (customError) {
            configNumberField.error = true
            configNumberField.helperText = customError
        } else if (meta.error) {
            configNumberField.error = true
            configNumberField.helperText = meta.error
        }
    }

    return <TextField {...configNumberField} />
}

export default NumberField
