import { apiSlice } from '../apiSlice'

export const lendersApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getAllLenders: build.query({
            query: () => ({ url: `records/lenders`, method: 'GET' }),
            providesTags: ['Lenders'],
        }),
        createLender: build.mutation({
            query: (newRow) => ({
                url: `records/lenders`,
                method: 'POST',
                body: newRow,
            }),
            invalidatesTags: ['Lenders', 'Facilities', 'Positions'],
        }),
        updateLender: build.mutation({
            query: (updatedRow) => ({
                url: `records/lenders`,
                method: 'PUT',
                body: updatedRow,
            }),
            invalidatesTags: ['Lenders', 'Facilities', 'Positions'],
        }),
        deleteLender: build.mutation({
            query: (id) => ({
                url: `records/lenders/${id}`,
                method: 'DELETE',
                body: id,
            }),
            invalidatesTags: ['Lenders', 'Facilities', 'Positions'],
        }),
    }),
})

export const {
    useCreateLenderMutation,
    useGetAllLendersQuery,
    useUpdateLenderMutation,
    useDeleteLenderMutation,
} = lendersApiSlice
