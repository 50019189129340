import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import { EditOutlined, DeleteOutlined } from '@mui/icons-material'
import dayjs from 'dayjs'
import Header from '../layout/Header'
import CustomToolbar from '../ui/datagrid/CustomToolbar'
import CustomFilterForm from '../ui/datagrid/CustomFilterForm'
import CustomNoRowOverlay from '../ui/datagrid/CustomNoRowOverlay'

import {
    formatDataGridDate,
    formatDatePickerDate,
    formatMoney,
} from '../../utils/helpers/formatValue'

import { selectCurrentUser } from '../../app/api/auth/authSlice'
import {
    useGetFilteredTransactionsQuery,
    useDeleteTransactionMutation,
} from '../../app/api/records/transactionsApiSlice'

import TransactionsFilterForm from '../ui/forms/filters/TransactionsFilterForm'
import UpdateTransactionForm from '../ui/forms/records/UpdateTransactionForm'
import InvalidDateModal from '../ui/modals/InvalidDateModal'
import ConfirmDeleteModal from '../ui/modals/ConfirmDeleteModal'
import DeleteSuccessModal from '../ui/modals/DeleteSuccessModal'

const ManageTransactionTab = () => {
    const { role } = useSelector(selectCurrentUser)

    const initialTransactionFilterFormState = {
        transactionFromDate: dayjs(new Date().toISOString()).subtract(30, 'days'),
        transactionToDate: dayjs(new Date().toISOString()),
    }

    const initialTransactionFormState = {
        sumRepayment: 0,
        sumWriteOff: 0,
        remainingRepayment: 0,
        sumPrincipal: 0,
        sumInterest: 0,
        sumFee: 0,
        transactionPrincipal: 0,
        transactionInterest: 0,
        transactionFee: 0,
        transactionType: 'Partial Repayment',
        allocateFirst: 'Fee',
        allocateSecond: 'Interest',
        allocateThird: 'Principal',
        remarks: '',
    }

    const [transactionFilterParams, setTransactionFilterParams] = useState({
        ...initialTransactionFilterFormState,
        transactionFromDate: formatDatePickerDate(
            initialTransactionFilterFormState.transactionFromDate
        ),
        transactionToDate: formatDatePickerDate(
            initialTransactionFilterFormState.transactionToDate
        ),
    })
    const [isUpdateTransactionFormOpen, setIsUpdateTransactionFormOpen] = useState(false)
    const [isInvalidDateModalOpen, setIsInvalidDateModalOpen] = useState(false)
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false)
    const [isDeleteSuccessModalOpen, setIsDeleteSuccessModalOpen] = useState(false)
    const [toBeUpdated, setToBeUpdated] = useState(initialTransactionFormState)
    const [isEditClicked, setIsEditClicked] = useState(false)
    const [toBeDeleted, setToBeDeleted] = useState([])

    const {
        data: filteredTransactions,
        isFetching,
        error,
    } = useGetFilteredTransactionsQuery(transactionFilterParams, {
        skip:
            !transactionFilterParams.transactionFromDate &&
            !transactionFilterParams.transactionToDate,
    })
    const [deleteTransaction] = useDeleteTransactionMutation()

    const handleOpenUpdateTransactionForm = () => {
        setIsUpdateTransactionFormOpen(true)
    }

    const handleCloseUpdateTransactionForm = () => {
        setIsUpdateTransactionFormOpen(false)
        setToBeUpdated(initialTransactionFormState)
        setIsEditClicked(false)
    }

    const handleOpenConfirmDeleteModal = () => {
        setIsConfirmDeleteModalOpen(true)
    }

    const handleCloseConfirmDeleteModal = () => {
        setIsConfirmDeleteModalOpen(false)
    }

    const handleOpenDeleteSuccessModal = () => {
        setIsDeleteSuccessModalOpen(true)
    }

    const handleCloseDeleteSuccessModal = () => {
        setIsDeleteSuccessModalOpen(false)
    }

    const handleEdit = (params) => {
        const { row } = params
        setIsEditClicked(true)
        setToBeUpdated(row)
        handleOpenUpdateTransactionForm()
    }

    const handlePreDelete = (params) => {
        const { row } = params
        setToBeDeleted(row)
        handleOpenConfirmDeleteModal()
    }

    const handleDelete = async () => {
        try {
            const { _id } = toBeDeleted
            const response = await deleteTransaction(_id)
            if (response.data) {
                handleOpenDeleteSuccessModal()
            }
        } catch (error) {}
    }

    const columns = [
        ...(role === 'Admin' || role === 'Editor'
            ? [
                  {
                      field: 'actions',
                      headerName: 'Actions',
                      type: 'actions',
                      getActions: (params) => {
                          return [
                              <GridActionsCellItem
                                  icon={<EditOutlined />}
                                  label='Edit'
                                  onClick={() => {
                                      handleEdit(params)
                                  }}
                                  color='inherit'
                              />,
                              <GridActionsCellItem
                                  icon={<DeleteOutlined />}
                                  label='Delete'
                                  onClick={() => {
                                      handlePreDelete(params)
                                  }}
                                  color='inherit'
                              />,
                          ]
                      },
                  },
              ]
            : []),
        {
            field: 'transactionDate',
            headerName: 'Transaction Date',
            width: 150,
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
        { field: 'borrowerName', headerName: 'Borrower Name', width: 250 },
        { field: 'facilityNumber', headerName: 'Facility Number', width: 120 },
        { field: 'lenderCode', headerName: 'Lender Code', width: 120 },
        { field: 'fundTaId', headerName: 'Fund - Loan', width: 300 },
        { field: 'currency', headerName: 'Currency', width: 90 },
        {
            field: 'transactionPrincipal',
            headerName: 'Principal',
            width: 140,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'transactionInterest',
            headerName: 'Interest',
            width: 140,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'transactionFee',
            headerName: 'Fee',
            width: 140,
            valueFormatter: (params) => formatMoney(params.value),
        },
        { field: 'transactionType', headerName: 'Transaction Type', width: 150 },
        { field: 'remarks', headerName: 'Remarks', width: 250 },
        { field: 'lastUpdatedBy', headerName: 'Last Updated By', width: 160 },
    ]

    const handleTransactionsFilterFormSubmit = (values) => {
        const { transactionFromDate, transactionToDate } = values
        setTransactionFilterParams({ transactionFromDate, transactionToDate })
    }

    const handleOpenInvalidDateModal = () => {
        setIsInvalidDateModalOpen(true)
    }

    const handleCloseInvalidDateModal = () => {
        setIsInvalidDateModalOpen(false)
    }

    return (
        <>
            <Header
                subtitle={
                    <>
                        Note:
                        <br />
                        1. Transactions from last 30 days are shown by default.
                        <br />
                        2. Select date range for Transactions to manage (View, Edit or Delete).
                        <br />
                        3. When deleting Drawdowns, remember to delete the relevant Transactions for
                        all Participations.
                    </>
                }
            />
            <Box
                sx={{
                    m: '1.5rem 2.5rem',
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    '& .MuiDataGrid-root': {
                        maxWidth: '95vw',
                    },
                }}
            >
                <TransactionsFilterForm
                    onSubmit={handleTransactionsFilterFormSubmit}
                    isFetching={isFetching}
                    initialTransactionFilterFormState={initialTransactionFilterFormState}
                    handleOpenInvalidDateModal={handleOpenInvalidDateModal}
                />
            </Box>
            <Box
                sx={{
                    m: '1.5rem 2.5rem',
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    height: '80vh',
                    '& .MuiDataGrid-root': {
                        maxWidth: '95vw',
                    },
                }}
            >
                <DataGrid
                    loading={isFetching}
                    getRowId={(row) => row._id}
                    rows={error ? [] : filteredTransactions || []}
                    columns={columns}
                    pageSizeOptions={[20, 50, 100]}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 100 } },
                        columns: {
                            columnVisibilityModel: {
                                // validFrom: false,
                            },
                        },
                        sorting: {
                            sortModel: [{ field: 'transactionDate', sort: 'asc' }],
                        },
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                        noRowsOverlay: CustomNoRowOverlay,
                    }}
                    slotProps={{
                        filterPanel: {
                            filterFormProps: CustomFilterForm,
                        },
                    }}
                />
            </Box>
            <InvalidDateModal
                isInvalidDateModalOpen={isInvalidDateModalOpen}
                handleCloseInvalidDateModal={handleCloseInvalidDateModal}
            />
            <ConfirmDeleteModal
                handleDelete={handleDelete}
                toBeDeleted={toBeDeleted}
                isConfirmDeleteModalOpen={isConfirmDeleteModalOpen}
                handleCloseConfirmDeleteModal={handleCloseConfirmDeleteModal}
            />
            <DeleteSuccessModal
                isDeleteSuccessModalOpen={isDeleteSuccessModalOpen}
                handleCloseDeleteSuccessModal={handleCloseDeleteSuccessModal}
                handleCloseConfirmDeleteModal={handleCloseConfirmDeleteModal}
            />
            <UpdateTransactionForm
                isUpdateTransactionFormOpen={isUpdateTransactionFormOpen}
                handleCloseUpdateTransactionForm={handleCloseUpdateTransactionForm}
                toBeUpdated={toBeUpdated}
                isEditClicked={isEditClicked}
            />
        </>
    )
}

export default ManageTransactionTab
