import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Button,
} from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'

const InvalidDateModal = ({ isInvalidDateModalOpen, handleCloseInvalidDateModal }) => {
    const location = useLocation()

    let invalidDateMessage
    if (location.pathname.includes('/records/facilities')) {
        invalidDateMessage = 'Invalid expiry date. Expiry should be after first drawdown date.'
    } else if (location.pathname.includes('/records/participations')) {
        invalidDateMessage =
            'Invalid approved maturity date. Approved maturity date should be on or after original maturity date.'
    } else if (location.pathname.includes('/records/transactions')) {
        invalidDateMessage = "Invalid date range. 'To Date' should be after 'From Date'."
    } else if (location.pathname.includes('/records/supplementary-interest-rates')) {
        invalidDateMessage =
            'Submitted effective date is invalid. This date will modify historical holdings reports.'
    } else if (location.pathname.includes('/logs/user-logs')) {
        invalidDateMessage = "Invalid date range. 'To Date' should be after 'From Date'."
    }
    return (
        <Dialog open={isInvalidDateModalOpen} onClose={handleCloseInvalidDateModal}>
            <DialogTitle color='error'>Warning</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>{invalidDateMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleCloseInvalidDateModal}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default InvalidDateModal
