import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Button,
} from '@mui/material'
import React from 'react'

const CreateSuccessModal = ({ isCreateSuccessModalOpen, handleCloseCreateSuccessModal }) => {
    return (
        <Dialog open={isCreateSuccessModalOpen} onClose={handleCloseCreateSuccessModal}>
            <DialogTitle sx={{ color: '#4caf50' }}>Create Success</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>New record has been successfully created!</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleCloseCreateSuccessModal}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default CreateSuccessModal
