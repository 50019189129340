export const getLenderCode = (lenders, lenderId) => {
    const lender = lenders.find((lender) => lender._id === lenderId)
    if (lender) {
        const { lenderCode } = lender
        return `${lenderCode}`
    }
    return ''
}

export const getBorrowerName = (borrowers, borrowerId) => {
    const borrower = borrowers.find((borrower) => borrower._id === borrowerId)
    if (borrower) {
        const { borrowerName } = borrower
        return `${borrowerName}`
    }
    return ''
}

export const getFacilityNumber = (facilities, facilityId) => {
    const facility = facilities.find((facility) => facility._id === facilityId)
    if (facility) {
        const { facilityNumber } = facility
        return `${facilityNumber}`
    }
    return ''
}

export const getFundCode = (funds, fundId) => {
    const fund = funds.find((fund) => fund._id === fundId)
    if (fund) {
        const { fundCode } = fund
        return `${fundCode}`
    }
    return ''
}

export const getTaId = (positions, positionId) => {
    const position = positions.find((position) => position._id === positionId)
    if (position) {
        const { taId } = position
        return `${taId}`
    }
    return ''
}

export const getUserFullName = (users, userId) => {
    const user = users.find((user) => user._id === userId)
    if (user) {
        const { firstName, lastName } = user
        return `${firstName} ${lastName}`
    }
    return ''
}
