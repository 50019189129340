import React from 'react'
import { Box, Grid } from '@mui/material'
import InputLabelStyled from '../../fields/InputLabelStyled'
import { formatMoney } from '../../../../utils/helpers/formatValue'

const StatementOfAccountSummaryForm = ({
    facilityOutstandingPrincipal,
    facilityTotalInterest,
    facilityTotalFee,
    facilityNetDue,
    facilityLimit,
    availableLimit,
    lenderName,
    beneficiaryName,
    accountNumber,
    bankName,
    bankAddress,
    swiftCode,
    reference,
}) => {
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box
                        style={{
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            marginTop: '1.5rem',
                        }}
                    >
                        {`Facility Summary`}
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box style={{ marginTop: '1.5rem' }}>
                        <InputLabelStyled htmlFor='outstandingPrincipal'>
                            Outstanding Principal:
                        </InputLabelStyled>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <Box style={{ marginTop: '1.5rem' }}>{`${formatMoney(
                        facilityOutstandingPrincipal
                    )}`}</Box>
                </Grid>
                <Grid item xs={2}>
                    <InputLabelStyled htmlFor='outstandingPrincipal'>
                        Total Interest:
                    </InputLabelStyled>
                </Grid>
                <Grid item xs={10}>
                    {`${formatMoney(facilityTotalInterest)}`}
                </Grid>
                <Grid item xs={2}>
                    <InputLabelStyled htmlFor='outstandingPrincipal'>Total Fee:</InputLabelStyled>
                </Grid>
                <Grid item xs={10}>
                    {`${formatMoney(facilityTotalFee)}`}
                </Grid>
                <Grid item xs={2}>
                    <InputLabelStyled htmlFor='outstandingPrincipal'>Net Due:</InputLabelStyled>
                </Grid>
                <Grid item xs={10}>
                    {`${formatMoney(facilityNetDue)}`}
                </Grid>
                <Grid item xs={2}>
                    <Box style={{ marginTop: '1.5rem' }}>
                        <InputLabelStyled htmlFor='facilityLimit'>Facility Limit:</InputLabelStyled>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <Box style={{ marginTop: '1.5rem' }}>{`${formatMoney(facilityLimit)}`}</Box>
                </Grid>
                <Grid item xs={2}>
                    <InputLabelStyled htmlFor='facilityLimit'>Available Limit:</InputLabelStyled>
                </Grid>
                <Grid item xs={10}>
                    <Box
                        style={{
                            color: availableLimit < 0 ? 'red' : 'inherit',
                        }}
                    >
                        {`${formatMoney(availableLimit)}`}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        style={{
                            color: 'red',
                            fontWeight: 'bold',
                            marginTop: '1.5rem',
                        }}
                    >
                        {'STATEMENTS ARE DEEMED CORRECT UNLESS QUERIED WITHIN 14 DAYS OF ISSUANCE'}
                    </Box>
                </Grid>

                {beneficiaryName ? (
                    // {selectedFacility.beneficiaryBankId ? (
                    <>
                        <Grid item xs={12}>
                            {`For repaying loans of ${lenderName}, please remit to your Primary Account among the accounts below.`}
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    marginTop: '1.5rem',
                                }}
                            >
                                <InputLabelStyled htmlFor='disclaimer'>Disclaimer</InputLabelStyled>
                                {` The below accounts are the only accounts owned by ${lenderName}. If you receive any e-mail requesting that you transfer any amount to an account other than an account specified below, please do not comply with such e-mail and call your Relationship Manager.`}
                                <div style={{ marginBottom: '0.5em' }} />
                                <div style={{ marginBottom: '0.5em' }} />
                                {`We shall not be liable for any funds transferred to an account other than an account specified below, including without limitation in reliance on any e-mail purporting to be from ${lenderName}.`}
                                <div style={{ marginBottom: '0.5em' }} />
                                <div style={{ marginBottom: '0.5em' }} />
                                {`If you require any clarification on our bank account details, please contact your Relationship Manager or operations@taprivatecapital.com.`}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box style={{ marginTop: '1.5rem' }}>
                                <InputLabelStyled htmlFor='primaryAccount'>
                                    Primary Account
                                </InputLabelStyled>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <InputLabelStyled htmlFor='beneficiaryName'>
                                Beneficiary Name:
                            </InputLabelStyled>
                        </Grid>
                        <Grid item xs={10}>
                            {`${beneficiaryName}`}
                        </Grid>
                        <Grid item xs={2}>
                            <InputLabelStyled htmlFor='accountNumber'>
                                Account Number:
                            </InputLabelStyled>
                        </Grid>
                        <Grid item xs={10}>
                            {`${accountNumber}`}
                        </Grid>
                        <Grid item xs={2}>
                            <InputLabelStyled htmlFor='bankName'>Bank Name:</InputLabelStyled>
                        </Grid>
                        <Grid item xs={10}>
                            {`${bankName}`}
                        </Grid>
                        <Grid item xs={2}>
                            <InputLabelStyled htmlFor='bankAddress'>Bank Address:</InputLabelStyled>
                        </Grid>
                        <Grid item xs={10}>
                            {`${bankAddress}`}
                        </Grid>
                        <Grid item xs={2}>
                            <InputLabelStyled htmlFor='swiftCode'>SWIFT Code:</InputLabelStyled>
                        </Grid>
                        <Grid item xs={10}>
                            {`${swiftCode}`}
                        </Grid>
                        <Grid item xs={2}>
                            <InputLabelStyled htmlFor='reference'>Reference:</InputLabelStyled>
                        </Grid>
                        <Grid item xs={10}>
                            {reference ? `${reference}` : '-'}
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Box
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    marginTop: '1.5rem',
                                }}
                            >
                                {`If you require any clarification, please contact your Relationship Manager or operations@taprivatecapital.com.`}
                            </Box>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    )
}

export default StatementOfAccountSummaryForm
