import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Header from '../../components/layout/Header'
import { Box, Tab, Tabs } from '@mui/material'

import { selectCurrentUser } from '../../app/api/auth/authSlice'

import StatementOfAccountSingleTab from '../../components/tabs/StatementOfAccountSingleTab'
import StatementOfAccountMultiTab from '../../components/tabs/StatementOfAccountMultiTab'

const StatementOfAccounts = () => {
    const [tabIndex, setTabIndex] = useState(1)
    const location = useLocation()

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue)
    }

    const tabs = [
        // { label: 'Single View / Download', link: `${location.pathname}/single-view-download` },
        { label: 'Single View / Download', link: `` },
        { label: 'Multi Download', link: `` },
    ]

    return (
        <Box>
            <Header title='Statement Of Accounts' />
            <Box
                sx={{
                    mt: '-1rem',
                    ml: '2.5rem',
                    mr: '2.5rem',
                }}
            >
                <Tabs value={tabIndex} onChange={handleTabChange}>
                    {tabs.map((tab, index) => (
                        <Tab key={index} label={tab.label} href={tab.link} />
                    ))}
                </Tabs>
            </Box>
            {tabIndex === 0 && <StatementOfAccountSingleTab />}
            {tabIndex === 1 && <StatementOfAccountMultiTab />}
        </Box>
    )
}

export default StatementOfAccounts
