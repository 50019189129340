import React from 'react'
import { MenuItem } from '@mui/material'
import SelectValidated from './SelectValidated'

const booleanList = [true, false]

const BooleanSelect = ({ ...otherProps }) => {
    const configBooleanSelect = {
        ...otherProps,
    }
    return (
        <>
            <SelectValidated {...configBooleanSelect}>
                {booleanList.map((booleanListItem) => (
                    <MenuItem key={booleanListItem} value={booleanListItem}>
                        {booleanListItem ? 'True' : 'False'}
                    </MenuItem>
                ))}
            </SelectValidated>
        </>
    )
}

export default BooleanSelect
