import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Button,
    MenuItem,
    Switch,
    FormControlLabel,
} from '@mui/material'
import TextFieldValidated from '../../fields/TextFieldValidated'
import InputLabelStyled from '../../fields/InputLabelStyled'
import PasswordField from '../../fields/PasswordField'
import SelectValidated from '../../fields/select/SelectValidated'
import BooleanSelect from '../../fields/select/BooleanSelect'
import PreSubmitButton from '../../buttons/PreSubmitButton'

import { passwordRule, passwordHelper } from '../../../../utils/helpers/passwordDef'
import { roleList } from '../../../../utils/lists/roleList'

import { useUpdateUserMutation } from '../../../../app/api/auth/usersApiSlice'

import DuplicateErrorModal from '../../modals/DuplicateErrorModal'
import ConfirmSubmitModal from '../../modals/ConfirmSubmitModal'
import UpdateSuccessModal from '../../modals/UpdateSuccessModal'
import MissingFieldModal from '../../modals/MissingFieldModal'

const initialUserFormState = {
    _id: '',
    firstName: '',
    lastName: '',
    role: '',
    isActive: null,
    email: '',
    password: '',
    confirmPassword: '',
}

const UpdateUserForm = ({ isFormOpen, handleCloseForm, toBeUpdated, isEditClicked }) => {
    if (toBeUpdated && isEditClicked) {
        initialUserFormState._id = toBeUpdated._id
        initialUserFormState.firstName = toBeUpdated.firstName
        initialUserFormState.lastName = toBeUpdated.lastName
        initialUserFormState.role = toBeUpdated.role
        initialUserFormState.isActive = toBeUpdated.isActive
        initialUserFormState.email = toBeUpdated.email
        initialUserFormState.password = ''
        initialUserFormState.confirmPassword = ''
    }

    if (!isEditClicked) {
        initialUserFormState._id = ''
        initialUserFormState.firstName = ''
        initialUserFormState.lastName = ''
        initialUserFormState.role = ''
        initialUserFormState.isActive = ''
        initialUserFormState.email = ''
        initialUserFormState.password = ''
        initialUserFormState.confirmPassword = ''
    }

    const [updateUser] = useUpdateUserMutation()

    const [duplicateEntry, setDuplicateEntry] = useState([])
    const [isChangePassword, setIsChangePassword] = useState(false)
    const [isDuplicateErrorModalOpen, setIsDuplicateErrorModalOpen] = useState(false)
    const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] = useState(false)
    const [isUpdateSuccessModalOpen, setIsUpdateSuccessModalOpen] = useState(false)
    const [isMissingFieldModalOpen, setIsMissingFieldModalOpen] = useState(false)

    const handleOpenDuplicateErrorModal = ({ duplicateData }) => {
        setIsDuplicateErrorModalOpen(true)
        setDuplicateEntry(duplicateData)
    }

    const handleCloseDuplicateErrorModal = () => {
        setIsDuplicateErrorModalOpen(false)
    }

    const handleOpenConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(true)
    }

    const handleCloseConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(false)
    }

    const handleOpenUpdateSuccessModal = () => {
        setIsUpdateSuccessModalOpen(true)
    }

    const handleCloseUpdateSuccessModal = () => {
        setIsUpdateSuccessModalOpen(false)
        handleCloseForm()
    }

    const handleOpenMissingFieldModal = () => {
        setIsMissingFieldModalOpen(true)
    }

    const handleCloseMissingFieldModal = () => {
        setIsMissingFieldModalOpen(false)
    }

    const handleUserFormSubmit = async (values) => {
        const { _id, firstName, lastName, role, isActive, password } = values

        try {
            const response = await updateUser({
                _id,
                firstName,
                lastName,
                role,
                isActive,
                password,
            })

            if (response.error && response.error.data && response.error.data.duplicateData) {
                const { error, duplicateData, newData } = response.error.data

                handleOpenDuplicateErrorModal({ duplicateData })
            } else if (response.data && response.data.newData) {
                const { error, duplicateData, newData } = response.data

                handleOpenUpdateSuccessModal()
            }
        } catch (error) {
            console.error('Error updating user:', error)
        }
    }

    const getUserValidationSchema = (isChangePassword) => {
        let userFormValidation = Yup.object().shape({
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            role: Yup.string().required('Required'),
            isActive: Yup.boolean().required('Required'),
            password: Yup.string().matches(passwordRule, passwordHelper),
            confirmPassword: Yup.string().oneOf(
                [Yup.ref('password'), null],
                'Passwords must match'
            ),
        })

        if (isChangePassword) {
            userFormValidation = userFormValidation.shape({
                password: Yup.string().matches(passwordRule, passwordHelper).required('Required'),
                confirmPassword: Yup.string()
                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
                    .required('Required'),
            })
        }
        return userFormValidation
    }

    return (
        <Dialog open={isFormOpen} onClose={handleCloseForm}>
            <DialogTitle>Update User</DialogTitle>
            <Formik
                initialValues={{ ...initialUserFormState }}
                validationSchema={getUserValidationSchema(isChangePassword)}
                onSubmit={(values) => {
                    handleUserFormSubmit(values)
                }}
                validateOnMount={true}
            >
                {(values) => (
                    <Form>
                        <DialogContent dividers>
                            <DialogContentText sx={{ mb: 2 }}>
                                Please fill in details of the Updated User.
                            </DialogContentText>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='firstName'>
                                        First Name *
                                    </InputLabelStyled>
                                    <TextFieldValidated name='firstName' id='firstName' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='lastName'>
                                        Last Name *
                                    </InputLabelStyled>
                                    <TextFieldValidated name='lastName' id='lastName' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='role'>Role *</InputLabelStyled>
                                    <SelectValidated name='role' id='role'>
                                        {roleList.map((roleListItem) => (
                                            <MenuItem key={roleListItem} value={roleListItem}>
                                                {roleListItem}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='isActive'>
                                        Is Active? *
                                    </InputLabelStyled>
                                    <BooleanSelect name='isActive' id='isActive' />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled disabled htmlFor='email'>
                                        Email
                                    </InputLabelStyled>
                                    <TextFieldValidated disabled name='email' id='email' />
                                </Grid>
                                {isChangePassword && (
                                    <>
                                        <Grid item xs={12}>
                                            <InputLabelStyled htmlFor='password'>
                                                Password *
                                            </InputLabelStyled>
                                            <PasswordField name='password' id='password' />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabelStyled htmlFor='confirmPassword'>
                                                Confirm Password *
                                            </InputLabelStyled>
                                            <PasswordField
                                                name='confirmPassword'
                                                id='confirmPassword'
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isChangePassword}
                                            onChange={() => setIsChangePassword(!isChangePassword)}
                                        />
                                    }
                                    label='Change Password'
                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' onClick={handleCloseForm}>
                                Cancel
                            </Button>
                            <PreSubmitButton
                                values={values}
                                handleOpenMissingFieldModal={handleOpenMissingFieldModal}
                                handleOpenConfirmSubmitModal={handleOpenConfirmSubmitModal}
                            >
                                Submit
                            </PreSubmitButton>
                        </DialogActions>
                        <DuplicateErrorModal
                            duplicateEntry={duplicateEntry}
                            values={values}
                            isDuplicateErrorModalOpen={isDuplicateErrorModalOpen}
                            handleCloseDuplicateErrorModal={handleCloseDuplicateErrorModal}
                        />
                        <ConfirmSubmitModal
                            values={values}
                            isConfirmSubmitModalOpen={isConfirmSubmitModalOpen}
                            handleCloseConfirmSubmitModal={handleCloseConfirmSubmitModal}
                        />
                        <UpdateSuccessModal
                            values={values}
                            isUpdateSuccessModalOpen={isUpdateSuccessModalOpen}
                            handleCloseUpdateSuccessModal={handleCloseUpdateSuccessModal}
                        />
                        <MissingFieldModal
                            isMissingFieldModalOpen={isMissingFieldModalOpen}
                            handleCloseMissingFieldModal={handleCloseMissingFieldModal}
                        />
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default UpdateUserForm
