import React, { useState } from 'react'
import { Box } from '@mui/material'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import { EditOutlined, DeleteOutlined } from '@mui/icons-material'
import Header from '../../components/layout/Header'
import CustomToolbar from '../../components/ui/datagrid/CustomToolbar'
import CustomFilterForm from '../../components/ui/datagrid/CustomFilterForm'
import CustomNoRowOverlay from '../../components/ui/datagrid/CustomNoRowOverlay'
// import CustomColumnsMenu from '../../components/ui/datagrid/CustomColumnsMenu'

import { formatDataGridDate, formatDataGridDateTime } from '../../utils/helpers/formatValue'

import { useDeleteUserMutation, useGetAllUsersQuery } from '../../app/api/auth/usersApiSlice'
import ConfirmDeleteModal from '../../components/ui/modals/ConfirmDeleteModal'
import DeleteSuccessModal from '../../components/ui/modals/DeleteSuccessModal'
import RegisterUserForm from '../../components/ui/forms/auth/RegisterUserForm'
import UpdateUserForm from '../../components/ui/forms/auth/UpdateUserForm'

const Users = () => {
    const { data: initialRows, isFetching } = useGetAllUsersQuery()
    const [deleteUser] = useDeleteUserMutation()

    const [isFormOpen, setIsFormOpen] = useState(false)
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false)
    const [isDeleteSuccessModalOpen, setIsDeleteSuccessModalOpen] = useState(false)
    const [toBeUpdated, setToBeUpdated] = useState({ _id: '', userCode: '', userName: '' })
    const [isEditClicked, setIsEditClicked] = useState(false)
    const [toBeDeleted, setToBeDeleted] = useState([])

    const handleOpenForm = () => {
        setIsFormOpen(true)
    }

    const handleCloseForm = () => {
        setIsFormOpen(false)
        setToBeUpdated({ _id: '', userCode: '', userName: '' })
        setIsEditClicked(false)
    }

    const handleOpenConfirmDeleteModal = () => {
        setIsConfirmDeleteModalOpen(true)
    }

    const handleCloseConfirmDeleteModal = () => {
        setIsConfirmDeleteModalOpen(false)
    }

    const handleOpenDeleteSuccessModal = () => {
        setIsDeleteSuccessModalOpen(true)
    }

    const handleCloseDeleteSuccessModal = () => {
        setIsDeleteSuccessModalOpen(false)
    }

    const handleEdit = (params) => {
        const { row } = params

        setIsEditClicked(true)
        setToBeUpdated(row)
        handleOpenForm()
    }

    const handlePreDelete = (params) => {
        const { row } = params
        handleOpenConfirmDeleteModal()
        setToBeDeleted(row)
    }

    const handleDelete = async () => {
        try {
            const { _id } = toBeDeleted
            const response = await deleteUser(_id)
            if (response.data) {
                handleOpenDeleteSuccessModal()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const columns = [
        {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<EditOutlined />}
                        label='Edit'
                        onClick={() => {
                            handleEdit(params)
                        }}
                        color='inherit'
                    />,
                    <GridActionsCellItem
                        icon={<DeleteOutlined />}
                        label='Delete'
                        onClick={() => {
                            handlePreDelete(params)
                        }}
                        color='inherit'
                    />,
                ]
            },
        },
        { field: 'firstName', headerName: 'First Name', width: 150 },
        { field: 'lastName', headerName: 'Last Name', width: 150 },
        { field: 'email', headerName: 'Email', width: 230 },
        { field: 'role', headerName: 'Role', width: 100 },
        { field: 'isActive', headerName: 'Is Active?', width: 140 },
        {
            field: 'lastLogin',
            headerName: 'Last Login',
            type: 'date',
            width: 200,
            valueFormatter: (params) => formatDataGridDateTime(params.value),
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            type: 'date',
            width: 150,
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
        {
            field: 'updatedAt',
            headerName: 'Last Updated At',
            type: 'date',
            width: 150,
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
    ]

    return (
        <Box>
            <Header
                title='Users'
                subtitle="List of Users. There must be at least one 'Admin'."
            ></Header>
            <Box
                sx={{
                    m: '1.5rem 2.5rem',
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    height: '80vh',
                    '& .MuiDataGrid-root': {
                        maxWidth: '95vw',
                    },
                }}
            >
                <DataGrid
                    loading={isFetching}
                    getRowId={(row) => row._id}
                    rows={initialRows || []}
                    columns={columns}
                    pageSizeOptions={[20, 50, 100]}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 20 } },
                        columns: {
                            columnVisibilityModel: {
                                // createdAt: false,
                                // updatedAt: false,
                            },
                        },
                        sorting: { sortModel: [{ field: 'userCode', sort: 'asc' }] },
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                        noRowsOverlay: CustomNoRowOverlay,
                    }}
                    slotProps={{
                        filterPanel: {
                            filterFormProps: CustomFilterForm,
                        },
                    }}
                />
            </Box>
            <ConfirmDeleteModal
                handleDelete={handleDelete}
                toBeDeleted={toBeDeleted}
                isConfirmDeleteModalOpen={isConfirmDeleteModalOpen}
                handleCloseConfirmDeleteModal={handleCloseConfirmDeleteModal}
            />
            <DeleteSuccessModal
                isDeleteSuccessModalOpen={isDeleteSuccessModalOpen}
                handleCloseDeleteSuccessModal={handleCloseDeleteSuccessModal}
                handleCloseConfirmDeleteModal={handleCloseConfirmDeleteModal}
            />
            {isEditClicked ? (
                <UpdateUserForm
                    isFormOpen={isFormOpen}
                    handleCloseForm={handleCloseForm}
                    toBeUpdated={toBeUpdated}
                    isEditClicked={isEditClicked}
                    setIsEditClicked={setIsEditClicked}
                />
            ) : (
                <RegisterUserForm
                    isFormOpen={isFormOpen}
                    handleCloseForm={handleCloseForm}
                    toBeUpdated={toBeUpdated}
                    isEditClicked={isEditClicked}
                    setIsEditClicked={setIsEditClicked}
                />
            )}
        </Box>
    )
}

export default Users
