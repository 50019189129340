import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { themeSettings } from './theme'

import Layout from './pages/Layout'

import LoginPage from './pages/auth/LoginPage'
import Users from './pages/auth/Users'
import UserLogs from './pages/logs/UserLogs'

import Lenders from './pages/records/Lenders'
import Funds from './pages/records/Funds'
import Borrowers from './pages/records/Borrowers'
import Facilities from './pages/records/Facilities'
import Positions from './pages/records/Positions'
import Participations from './pages/records/Participations'
import Transactions from './pages/records/Transactions'
import ExchangeRates from './pages/records/ExchangeRates'
import SupplementaryInterestRates from './pages/records/SupplementaryInterestRates'
import BankAccounts from './pages/records/BankAccounts'

import Holdings from './pages/reports/Holdings'
import StatementOfAccounts from './pages/reports/StatementOfAccounts'

import PageNotFound from './pages/error/PageNotFound'
import Forbidden from './pages/error/Forbidden'

function App() {
    const mode = useSelector((state) => state.global.mode)
    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode])

    const accessToken = useSelector((state) => state.auth.accessToken)
    const userRole = useSelector((state) => state.auth.user.role)

    const isAuth = Boolean(accessToken)
    const isAuthAdmin = Boolean(userRole === 'Admin' && accessToken)

    return (
        <div className='app'>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Routes>
                        <Route path='/' element={<Navigate to='/auth/login' replace />} />
                        <Route
                            path='/auth/login'
                            element={isAuth ? <Navigate to='/reports/holdings' /> : <LoginPage />}
                        />
                        <Route element={<Layout />}>
                            <Route
                                path='/auth/users'
                                element={isAuthAdmin ? <Users /> : <Navigate to='/forbidden' />}
                            />
                            <Route
                                path='/records'
                                element={<Navigate to='/records/lenders' replace />}
                            />
                            <Route
                                path='/records/lenders'
                                element={isAuth ? <Lenders /> : <Navigate to='/' />}
                            />
                            <Route
                                path='/records/funds'
                                element={isAuth ? <Funds /> : <Navigate to='/' />}
                            />
                            <Route
                                path='/records/borrowers'
                                element={isAuth ? <Borrowers /> : <Navigate to='/' />}
                            />
                            <Route
                                path='/records/facilities'
                                element={isAuth ? <Facilities /> : <Navigate to='/' />}
                            />
                            <Route
                                path='/records/positions'
                                element={isAuth ? <Positions /> : <Navigate to='/' />}
                            />
                            <Route
                                path='/records/participations'
                                element={isAuth ? <Participations /> : <Navigate to='/' />}
                            />
                            <Route
                                path='/records/transactions'
                                element={isAuth ? <Transactions /> : <Navigate to='/' />}
                            />
                            <Route
                                path='/records/exchange-rates'
                                element={isAuth ? <ExchangeRates /> : <Navigate to='/' />}
                            />
                            <Route
                                path='/records/supplementary-interest-rates'
                                element={
                                    isAuth ? <SupplementaryInterestRates /> : <Navigate to='/' />
                                }
                            />
                            <Route
                                path='/records/bank-accounts'
                                element={isAuth ? <BankAccounts /> : <Navigate to='/' />}
                            />
                            <Route
                                path='/reports/holdings'
                                element={isAuth ? <Holdings /> : <Navigate to='/' />}
                            />
                            <Route
                                path='/reports/statement-of-accounts'
                                element={isAuth ? <StatementOfAccounts /> : <Navigate to='/' />}
                            />
                            <Route
                                path='/logs/user-logs'
                                element={isAuthAdmin ? <UserLogs /> : <Navigate to='/forbidden' />}
                            />
                            <Route
                                path='/forbidden'
                                element={isAuth ? <Forbidden /> : <Navigate to='/' />}
                            />
                            <Route
                                path='/page-not-found'
                                element={isAuth ? <PageNotFound /> : <Navigate to='/' />}
                            />
                            <Route
                                path='*'
                                element={
                                    isAuth ? (
                                        <Navigate to='/page-not-found' replace />
                                    ) : (
                                        <Navigate to='/' />
                                    )
                                }
                            />
                        </Route>
                    </Routes>
                </ThemeProvider>
            </BrowserRouter>
        </div>
    )
}

export default App
