import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

import { useGetFilteredUserLogsQuery } from '../../app/api/logs/userLogsApiSlice'
import { formatDatePickerDate, formatDataGridDateTime } from '../../utils/helpers/formatValue'

import Header from '../../components/layout/Header'
import CustomToolbar from '../../components/ui/datagrid/CustomToolbar'
import CustomFilterForm from '../../components/ui/datagrid/CustomFilterForm'
import CustomNoRowOverlay from '../../components/ui/datagrid/CustomNoRowOverlay'

import UserLogsFilterForm from '../../components/ui/forms/filters/UserLogsFilterForm'
import InvalidDateModal from '../../components/ui/modals/InvalidDateModal'

const UserLogs = () => {
    const initialUserLogsFilterFormState = {
        userLogsFromDate: dayjs(new Date().toISOString()).subtract(7, 'days'),
        userLogsToDate: dayjs(new Date().toISOString()),
        userIds: [],
        actions: [],
        url: '',
    }

    const [isInvalidDateModalOpen, setIsInvalidDateModalOpen] = useState(false)

    const [userLogFilterParams, setUserLogFilterParams] = useState({
        ...initialUserLogsFilterFormState,
        userLogsFromDate: formatDatePickerDate(initialUserLogsFilterFormState.userLogsFromDate),
        userLogsToDate: formatDatePickerDate(initialUserLogsFilterFormState.userLogsToDate),
    })

    const {
        data: filteredUserLogs,
        isFetching,
        error,
    } = useGetFilteredUserLogsQuery(userLogFilterParams, { skip: !userLogFilterParams })

    const columns = [
        { field: 'fullName', headerName: 'User', width: 180 },
        { field: 'action', headerName: 'Action', width: 150 },
        { field: 'method', headerName: 'Method', width: 80 },
        { field: 'url', headerName: 'URL', width: 320 },
        { field: 'remarks', headerName: 'Remarks', width: 300 },
        { field: 'ipAddress', headerName: 'IP Address', width: 200 },
        {
            field: 'loggedAt',
            headerName: 'Logged At',
            width: 200,
            valueFormatter: (params) => formatDataGridDateTime(params.value),
        },
    ]

    const handleUserLogFilterFormSubmit = (values) => {
        const { userLogsFromDate, userLogsToDate, userIds, actions, url } = values
        setUserLogFilterParams({ userLogsFromDate, userLogsToDate, userIds, actions, url })
    }

    const handleOpenInvalidDateModal = () => {
        setIsInvalidDateModalOpen(true)
    }

    const handleCloseInvalidDateModal = () => {
        setIsInvalidDateModalOpen(false)
    }

    return (
        <>
            <Header
                title='User Logs'
                subtitle={
                    <>
                        Note:
                        <br />
                        1. This page is only accessible to Admins.
                        <br />
                        2. Logs are kept for 120 days.
                    </>
                }
            />
            <Box
                sx={{
                    m: '1.5rem 2.5rem',
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    '& .MuiDataGrid-root': {
                        maxWidth: '95vw',
                    },
                }}
            >
                <UserLogsFilterForm
                    onSubmit={handleUserLogFilterFormSubmit}
                    isFetching={isFetching}
                    initialUserLogsFilterFormState={initialUserLogsFilterFormState}
                    handleOpenInvalidDateModal={handleOpenInvalidDateModal}
                />
            </Box>
            <Box
                sx={{
                    m: '1.5rem 2.5rem',
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    height: '80vh',
                    '& .MuiDataGrid-root': {
                        maxWidth: '95vw',
                    },
                }}
            >
                <DataGrid
                    loading={isFetching}
                    getRowId={(row) => row._id}
                    rows={error ? [] : filteredUserLogs || []}
                    columns={columns}
                    pageSizeOptions={[20, 50, 100]}
                    disableRowSelectionOnClick
                    initialState={{
                        pagination: { paginationModel: { pageSize: 100 } },
                        sorting: {
                            sortModel: [{ field: 'loggedAt', sort: 'desc' }],
                        },
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                        noRowsOverlay: CustomNoRowOverlay,
                    }}
                    slotProps={{
                        filterPanel: {
                            filterFormProps: CustomFilterForm,
                        },
                    }}
                />
            </Box>
            <InvalidDateModal
                isInvalidDateModalOpen={isInvalidDateModalOpen}
                handleCloseInvalidDateModal={handleCloseInvalidDateModal}
            />
        </>
    )
}

export default UserLogs
