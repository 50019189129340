import { apiSlice } from '../apiSlice'

export const transactionsApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        createTransaction: build.mutation({
            query: (newRow) => ({
                url: `records/transactions`,
                method: 'POST',
                body: newRow,
            }),
            invalidatesTags: ['Transactions', 'Holdings'],
        }),
        createDrawdown: build.mutation({
            query: (newRow) => ({
                url: `records/transactions/drawdown`,
                method: 'POST',
                body: newRow,
            }),
            invalidatesTags: ['Transactions', 'Holdings', 'Participations'],
        }),
        getFilteredTransactions: build.query({
            query: (transactionFilterParams) => ({
                url: `records/transactions`,
                method: 'GET',
                params: transactionFilterParams,
            }),
            providesTags: ['Transactions'],
        }),
        updateTransaction: build.mutation({
            query: (updatedRow) => ({
                url: `records/transactions`,
                method: 'PUT',
                body: updatedRow,
            }),
            invalidatesTags: ['Transactions', 'Holdings'],
        }),
        deleteTransaction: build.mutation({
            query: (id) => ({
                url: `records/transactions/${id}`,
                method: 'DELETE',
                body: id,
            }),
            invalidatesTags: ['Transactions', 'Holdings'],
        }),
    }),
})

export const {
    useCreateTransactionMutation,
    useCreateDrawdownMutation,
    useGetFilteredTransactionsQuery,
    useUpdateTransactionMutation,
    useDeleteTransactionMutation,
} = transactionsApiSlice
