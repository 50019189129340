import React, { useEffect, useState } from 'react'
import { Box, MenuItem, Typography, ListItemText, Checkbox, Chip } from '@mui/material'
import SelectValidated from './SelectValidated'

const BorrowerMultiSelect = ({ borrowers, value, onChange, ...otherProps }) => {
    const [selectedBorrowers, setSelectedBorrowers] = useState([])

    const sortedBorrowers = borrowers
        ? borrowers.slice().sort((a, b) => a.borrowerName.localeCompare(b.borrowerName))
        : []

    const allBorrowerIds = sortedBorrowers.map((borrower) => borrower._id)

    const allValue = allBorrowerIds.join(',') // String of all borrower ids

    const handleSelectionChange = (event) => {
        const selected = event.target.value

        const isAllSelected = allBorrowerIds.every((id) => selected.includes(id))

        if (isAllSelected && selected.includes(allValue)) {
            setSelectedBorrowers([])
            onChange({ target: { name: 'borrowerIds', value: [] } }) // Deselect all
        } else if (selected.includes(allValue)) {
            setSelectedBorrowers(allBorrowerIds)
            onChange({ target: { name: 'borrowerIds', value: allBorrowerIds } }) // Select all borrowers
        } else {
            const updatedSelection = selected.filter((id) => id !== allValue)

            setSelectedBorrowers(updatedSelection)
            onChange({ target: { name: 'borrowerIds', value: updatedSelection } }) // Update selection
        }
    }

    const handleChipRender = (selected) => {
        const selectedSet = new Set(selected)
        const allBorrowerIdsSet = new Set(allBorrowerIds)

        const isAllSelected =
            allBorrowerIdsSet.size === selectedSet.size &&
            [...allBorrowerIdsSet].every((id) => selectedSet.has(id))

        if (isAllSelected) {
            return <Chip key='all borrowers' label='All Borrowers' sx={{ margin: '2px' }} />
        }

        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((id) => {
                    const borrower = sortedBorrowers.find((b) => b._id === id)
                    return borrower ? <Chip key={id} label={borrower.borrowerName} /> : null
                })}
            </Box>
        )
    }

    useEffect(() => {
        if (value) {
            setSelectedBorrowers(value)
        }
    }, [value])

    return (
        <SelectValidated
            multiple
            name='borrowerIds'
            id='borrowerIds'
            value={selectedBorrowers} // Ensure correct value is used
            onChange={handleSelectionChange} // Handle onChange event
            renderValue={handleChipRender} // Render selected values
            {...otherProps}
        >
            {sortedBorrowers.length > 0 ? (
                [
                    <MenuItem key='all' value={allValue}>
                        <Checkbox checked={selectedBorrowers.length === allBorrowerIds.length} />
                        <ListItemText
                            primary={
                                <Typography variant='body1' sx={{ fontStyle: 'italic' }}>
                                    All Borrowers
                                </Typography>
                            }
                        />
                    </MenuItem>,
                    ...sortedBorrowers.map((borrower) => (
                        <MenuItem key={borrower._id} value={borrower._id}>
                            <Checkbox checked={selectedBorrowers.indexOf(borrower._id) > -1} />
                            <ListItemText primary={borrower.borrowerName} />
                        </MenuItem>
                    )),
                ]
            ) : (
                <MenuItem value='' disabled>
                    No Borrowers
                </MenuItem>
            )}
        </SelectValidated>
    )
}

export default BorrowerMultiSelect
