import React from 'react'
import { MenuItem } from '@mui/material'
import SelectValidated from './SelectValidated'

const BankAccountSelect = ({ bankAccounts, ...otherProps }) => {
    const sortedBankAccounts = bankAccounts
        ? bankAccounts.slice().sort((a, b) => a.beneficiaryName.localeCompare(b.beneficiaryName))
        : []
    return (
        <SelectValidated name='beneficiaryBankId' id='beneficiaryBankId' {...otherProps}>
            <MenuItem value=''>
                <em>None</em>
            </MenuItem>
            {sortedBankAccounts &&
                sortedBankAccounts.map((bankAccount) => (
                    <MenuItem key={bankAccount.accountNumber} value={bankAccount._id}>
                        {`${bankAccount.beneficiaryName} (${bankAccount.accountNumber})`}
                    </MenuItem>
                ))}
        </SelectValidated>
    )
}

export default BankAccountSelect
