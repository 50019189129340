import React, { useRef, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Button, CircularProgress, Grid, MenuItem } from '@mui/material'

import { levelList } from '../../../../utils/lists/levelList'
import InputLabelStyled from '../../fields/InputLabelStyled'
import SelectValidated from '../../fields/select/SelectValidated'
import DatePickerValidated from '../../fields/DatePickerValidated'
import BorrowerSelect from '../../fields/select/BorrowerSelect'
import FacilityMultiSelect from '../../fields/select/FacilityMultiSelect'
import PositionSelect from '../../fields/select/PositionSelect'
import { formatDatePickerDate } from '../../../../utils/helpers/formatValue'

import { useGetAllBorrowersQuery } from '../../../../app/api/records/borrowersApiSlice'
import { useGetAllFacilitiesQuery } from '../../../../app/api/records/facilitiesApiSlice'
import { useGetAllPositionsQuery } from '../../../../app/api/records/positionsApiSlice'

const holdingFilterFormValidation = Yup.object().shape({
    transactionDate: Yup.date().nullable().required('Required'),
    level: Yup.string().required('Required'),
    borrowerId: Yup.string().required('Required'),
    facilityIds: Yup.array(),
    positionId: Yup.string(),
})

const TransactionHoldingsFilterForm = ({
    onSubmit,
    isGenerating,
    initialHoldingFilterFormState,
    selectedLevel,
    setSelectedLevel,
    setSelectedTransactionDate,
}) => {
    // To pass on proper names to the modals. Entities are read as ObjectId
    const { data: borrowers } = useGetAllBorrowersQuery()
    const { data: facilities } = useGetAllFacilitiesQuery()
    const { data: positions = [] } = useGetAllPositionsQuery()

    // const [selectedLevel, setSelectedLevel] = useState('')
    const [selectedBorrower, setSelectedBorrower] = useState('')
    const [selectedFacilities, setSelectedFacilities] = useState([])
    const [isFacilityMultiSelectDisabled, setIsFacilityMultiSelectDisabled] = useState(true)
    const [isPositionSelectDisabled, setIsPositionSelectDisabled] = useState(true)

    const formikRef = useRef()

    const borrowerFacilities = selectedBorrower
        ? facilities.filter((facility) => facility.borrowerId === selectedBorrower)
        : facilities

    const facilityPositions = selectedFacilities
        ? positions.filter((position) => selectedFacilities.includes(position.facilityId))
        : positions

    const handleLevelSelect = (event) => {
        const level = event.target.value
        setSelectedLevel(level)

        // Reset selected borrower and facilities when level changes
        setSelectedBorrower('')
        setIsFacilityMultiSelectDisabled(true)
        setIsPositionSelectDisabled(true)
        formikRef.current.setFieldValue('borrowerId', '')
        formikRef.current.setFieldValue('facilityIds', [])
        formikRef.current.setFieldValue('positionId', '')
    }

    const handleButtonDisable = (formik) => {
        if (!formik.values.transactionDate || !formik.values.level) {
            return true
        }
        if (selectedLevel === 'Borrower' && !formik.values.borrowerId) {
            return true
        }
        if (
            selectedLevel === 'Facility' &&
            (!formik.values.borrowerId || formik.values.facilityIds.length === 0)
        ) {
            return true
        }
        if (
            (selectedLevel === 'Position' || selectedLevel === 'Participation') &&
            (!formik.values.borrowerId ||
                formik.values.facilityIds.length === 0 ||
                !formik.values.positionId)
        ) {
            return true
        }
        return false
    }

    return (
        <Formik
            initialValues={{ ...initialHoldingFilterFormState }}
            validationSchema={holdingFilterFormValidation}
            onSubmit={(values) => {
                const formattedValues = {
                    ...values,
                    transactionDate: formatDatePickerDate(values.transactionDate),
                }
                setSelectedTransactionDate(formatDatePickerDate(values.transactionDate))
                onSubmit(formattedValues)
            }}
            validateOnMount={true}
            innerRef={formikRef}
        >
            {(formik) => (
                <Form>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <InputLabelStyled htmlFor='transactionDate'>
                                Transaction Date
                            </InputLabelStyled>
                            <DatePickerValidated name='transactionDate' />
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={3}>
                            <InputLabelStyled htmlFor='level'>Level</InputLabelStyled>
                            <SelectValidated
                                name='level'
                                id='level'
                                placeholder='Transactions apply to'
                                onChange={handleLevelSelect}
                            >
                                {levelList.map((levelListItem) => (
                                    <MenuItem key={levelListItem} value={levelListItem}>
                                        {levelListItem}
                                    </MenuItem>
                                ))}
                            </SelectValidated>
                        </Grid>
                        {selectedLevel &&
                            (selectedLevel === 'Borrower' ||
                                selectedLevel === 'Facility' ||
                                selectedLevel === 'Position' ||
                                selectedLevel === 'Participation') && (
                                <Grid item xs={3}>
                                    <InputLabelStyled htmlFor='borrowerId'>
                                        Borrower Name
                                    </InputLabelStyled>
                                    <BorrowerSelect
                                        borrowers={borrowers}
                                        onChange={(event) => {
                                            formik.setFieldValue('borrowerId', event.target.value)
                                            setSelectedBorrower(event.target.value)
                                            setIsFacilityMultiSelectDisabled(false)
                                            formik.setFieldValue('facilityIds', [])
                                            formik.setFieldValue('positionId', '')
                                        }}
                                    />
                                </Grid>
                            )}
                        {selectedLevel &&
                            (selectedLevel === 'Facility' ||
                                selectedLevel === 'Position' ||
                                selectedLevel === 'Participation') && (
                                <Grid item xs={3}>
                                    <InputLabelStyled htmlFor='facilityIds'>
                                        {selectedLevel === 'Facility'
                                            ? 'Facility Number(s)'
                                            : 'Facility Number'}
                                    </InputLabelStyled>
                                    <FacilityMultiSelect
                                        borrowerFacilities={borrowerFacilities}
                                        isFacilityMultiSelectDisabled={
                                            isFacilityMultiSelectDisabled
                                        }
                                        onChange={(event) => {
                                            formik.setFieldValue('facilityIds', event.target.value)
                                            setSelectedFacilities(event.target.value)
                                            setIsPositionSelectDisabled(false)
                                            formik.setFieldValue('positionId', '')
                                        }}
                                    />
                                </Grid>
                            )}
                        {selectedLevel &&
                            (selectedLevel === 'Position' || selectedLevel === 'Participation') && (
                                <Grid item xs={3}>
                                    <InputLabelStyled htmlFor='positionId'>TA ID</InputLabelStyled>
                                    <PositionSelect
                                        positions={facilityPositions}
                                        isPositionSelectDisabled={isPositionSelectDisabled}
                                        onChange={(event) => {
                                            formik.setFieldValue('positionId', event.target.value)
                                        }}
                                    />
                                </Grid>
                            )}
                        <Grid item xs={12}></Grid>
                        <Grid item xs={6}>
                            {isGenerating ? (
                                <Button
                                    disabled
                                    variant='contained'
                                    onClick={formik.submitForm}
                                    endIcon={
                                        <CircularProgress
                                            size={16}
                                            sx={{
                                                color: 'inherit',
                                                height: '16px',
                                                width: '16px',
                                            }}
                                        />
                                    }
                                >
                                    Show Holding(s)
                                </Button>
                            ) : (
                                <Button
                                    variant='contained'
                                    onClick={formik.submitForm}
                                    disabled={handleButtonDisable(formik)}
                                >
                                    Show Holding(s)
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}

export default TransactionHoldingsFilterForm
