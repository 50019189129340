import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Button,
    MenuItem,
} from '@mui/material'
import dayjs from 'dayjs'

import NumberFieldValidated from '../../fields/NumberFieldValidated'
import InputLabelStyled from '../../fields/InputLabelStyled'
import SelectValidated from '../../fields/select/SelectValidated'
import DatePickerValidated from '../../fields/DatePickerValidated'
import PreSubmitButton from '../../buttons/PreSubmitButton'

import { formatDatePickerDate } from '../../../../utils/helpers/formatValue'
import { currencyList } from '../../../../utils/lists/currencyList'

import { selectCurrentUser } from '../../../../app/api/auth/authSlice'
import { useUpdateExchangeRateMutation } from '../../../../app/api/records/exchangeRatesApiSlice'

import DuplicateErrorModal from '../../modals/DuplicateErrorModal'
import ConfirmSubmitModal from '../../modals/ConfirmSubmitModal'
import UpdateSuccessModal from '../../modals/UpdateSuccessModal'
import MissingFieldModal from '../../modals/MissingFieldModal'

const exchangeRateFormValidation = Yup.object().shape({
    asOfDate: Yup.date().nullable().required('Required'),
    currency: Yup.string().required('Required'),
    exchangeRate: Yup.number().required('Required'),
})

const UpdateExchangeRateForm = ({ isFormOpen, handleCloseForm, toBeUpdated, isEditClicked }) => {
    const { firstName, lastName } = useSelector(selectCurrentUser)
    const userFullname = `${firstName} ${lastName}`

    const initialExchangeRateFormState = {
        _id: '',
        asOfDate: dayjs(new Date().toISOString()),
        currency: '',
        exchangeRate: '',
        lastUpdatedBy: '',
    }

    if (toBeUpdated && isEditClicked) {
        initialExchangeRateFormState._id = toBeUpdated._id
        initialExchangeRateFormState.asOfDate = dayjs(new Date(toBeUpdated.asOfDate))
        initialExchangeRateFormState.currency = toBeUpdated.currency
        initialExchangeRateFormState.exchangeRate = toBeUpdated.exchangeRate
        initialExchangeRateFormState.lastUpdatedBy = userFullname
    }

    if (!isEditClicked) {
        initialExchangeRateFormState._id = ''
        initialExchangeRateFormState.asOfDate = dayjs(new Date().toISOString())
        initialExchangeRateFormState.currency = ''
        initialExchangeRateFormState.exchangeRate = ''
        initialExchangeRateFormState.lastUpdatedBy = ''
    }

    const [updateExchangeRate] = useUpdateExchangeRateMutation()

    const [duplicateEntry, setDuplicateEntry] = useState([])

    const [isDuplicateErrorModalOpen, setIsDuplicateErrorModalOpen] = useState(false)
    const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] = useState(false)
    const [isUpdateSuccessModalOpen, setIsUpdateSuccessModalOpen] = useState(false)
    const [isMissingFieldModalOpen, setIsMissingFieldModalOpen] = useState(false)

    const formikRef = useRef()

    const handleOpenDuplicateErrorModal = ({ duplicateData }) => {
        setIsDuplicateErrorModalOpen(true)
        setDuplicateEntry(duplicateData)
    }

    const handleCloseDuplicateErrorModal = () => {
        setIsDuplicateErrorModalOpen(false)
    }

    const handleOpenConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(true)
    }

    const handleCloseConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(false)
    }

    const handleOpenUpdateSuccessModal = () => {
        setIsUpdateSuccessModalOpen(true)
    }

    const handleCloseUpdateSuccessModal = () => {
        setIsUpdateSuccessModalOpen(false)
        handleCloseForm()
    }

    const handleOpenMissingFieldModal = () => {
        setIsMissingFieldModalOpen(true)
    }

    const handleCloseMissingFieldModal = () => {
        setIsMissingFieldModalOpen(false)
    }

    const handleUpdateExchangeRateFormSubmit = async (values) => {
        const { _id, asOfDate, currency, exchangeRate, lastUpdatedBy } = values

        try {
            const response = await updateExchangeRate({
                _id,
                asOfDate,
                currency,
                exchangeRate,
                lastUpdatedBy,
            })

            if (response.error && response.error.data && response.error.data.duplicateData) {
                const { error, duplicateData, newData } = response.error.data
                handleOpenDuplicateErrorModal({ duplicateData })
            } else if (response.data && response.data.newData) {
                const { error, duplicateData, newData } = response.data
                handleOpenUpdateSuccessModal()
            }
        } catch (error) {
            console.error('Error updating exchange rate:', error)
        }
    }

    const handleUpdateExchangeRateFormClose = () => {
        handleCloseForm()
    }

    return (
        <Dialog open={isFormOpen} onClose={handleUpdateExchangeRateFormClose}>
            <DialogTitle>Update New Exchange Rate</DialogTitle>
            <Formik
                initialValues={{ ...initialExchangeRateFormState }}
                validationSchema={exchangeRateFormValidation}
                onSubmit={(values) => {
                    handleUpdateExchangeRateFormSubmit(values)
                }}
                validateOnMount={true}
                innerRef={formikRef}
            >
                {(formik) => (
                    <Form>
                        <DialogContent dividers>
                            <DialogContentText sx={{ mb: 2 }}>
                                Please fill in details of the Updated Exchange Rate.
                            </DialogContentText>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='asOfDate'>
                                        As Of Date *
                                    </InputLabelStyled>
                                    <DatePickerValidated
                                        name='asOfDate'
                                        maxDate={dayjs(new Date())}
                                    />
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='currency'>
                                        Currency *
                                    </InputLabelStyled>
                                    <SelectValidated name='currency' id='currency'>
                                        {currencyList
                                            .filter(
                                                (currencyListItem) => currencyListItem !== 'USD'
                                            )
                                            .map((currencyListItem) => (
                                                <MenuItem
                                                    key={currencyListItem}
                                                    value={currencyListItem}
                                                >
                                                    {currencyListItem}
                                                </MenuItem>
                                            ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='exchangeRate'>
                                        Exchange Rate *
                                    </InputLabelStyled>
                                    <NumberFieldValidated name='exchangeRate' id='exchangeRate' />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' onClick={handleCloseForm}>
                                Cancel
                            </Button>
                            <PreSubmitButton
                                values={{
                                    ...formik,
                                    values: {
                                        ...formik.values,
                                        asOfDate: formatDatePickerDate(formik.values.asOfDate),
                                    },
                                }}
                                handleOpenMissingFieldModal={handleOpenMissingFieldModal}
                                handleOpenConfirmSubmitModal={handleOpenConfirmSubmitModal}
                            >
                                Submit
                            </PreSubmitButton>
                        </DialogActions>
                        <DuplicateErrorModal
                            duplicateEntry={duplicateEntry}
                            values={{
                                ...formik,
                                values: {
                                    ...formik.values,
                                    asOfDate: formatDatePickerDate(formik.values.asOfDate),
                                },
                            }}
                            isDuplicateErrorModalOpen={isDuplicateErrorModalOpen}
                            handleCloseDuplicateErrorModal={handleCloseDuplicateErrorModal}
                        />
                        <ConfirmSubmitModal
                            values={{
                                ...formik,
                                values: {
                                    ...formik.values,
                                    asOfDate: formatDatePickerDate(formik.values.asOfDate),
                                },
                            }}
                            isConfirmSubmitModalOpen={isConfirmSubmitModalOpen}
                            handleCloseConfirmSubmitModal={handleCloseConfirmSubmitModal}
                        />
                        <UpdateSuccessModal
                            isUpdateSuccessModalOpen={isUpdateSuccessModalOpen}
                            handleCloseUpdateSuccessModal={handleCloseUpdateSuccessModal}
                        />
                        <MissingFieldModal
                            isMissingFieldModalOpen={isMissingFieldModalOpen}
                            handleCloseMissingFieldModal={handleCloseMissingFieldModal}
                        />
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default UpdateExchangeRateForm
