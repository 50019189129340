import React from 'react'
import { Typography, Box } from '@mui/material'

const Header = ({ title, subtitle }) => {
    return (
        <Box sx={{ m: '1.5rem 2.5rem' }}>
            <Typography variant='h4' fontWeight='bold' sx={{ mb: '0.5rem' }}>
                {title}
            </Typography>
            <Typography variant='h7'>{subtitle}</Typography>
        </Box>
    )
}

export default Header
