import React, { useState } from 'react'
import { TextField, InputAdornment } from '@mui/material'
import { useField } from 'formik'

const MoneyFieldValidated = ({ name, ...otherProps }) => {
    const [customError, setCustomError] = useState(null)
    const [field, meta, helpers] = useField(name)

    const handleInputChange = (event) => {
        const inputValue = event.target.value

        if (inputValue.trim() === '') {
            setCustomError(null)
            helpers.setValue('')
        } else if (!isNaN(inputValue)) {
            setCustomError(null)
            helpers.setValue(inputValue)
        } else {
            setCustomError('Enter a Numeric Value')
            helpers.setValue('')
        }
    }

    const inputProps = {
        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
    }

    const configMoneyField = {
        ...field,
        ...otherProps,
        fullWidth: true,
        variant: 'outlined',
        size: 'small',
        InputProps: inputProps,
        onChange: handleInputChange,
    }

    if (meta && meta.touched) {
        if (field.value === undefined || field.value === null || field.value === '') {
            configMoneyField.error = true
            configMoneyField.helperText = 'Required'
        } else if (customError) {
            configMoneyField.error = true
            configMoneyField.helperText = customError
        } else if (meta.error) {
            configMoneyField.error = true
            configMoneyField.helperText = meta.error
        }
    }

    return <TextField {...configMoneyField} />
}

export default MoneyFieldValidated
