import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import CustomNoRowsOverlay from '../datagrid/CustomNoRowOverlay'
import { formatPercent, formatMoney } from '../../../utils/helpers/formatValue'
import { useCreateDrawdownMutation } from '../../../app/api/records/transactionsApiSlice'

import CreateSuccessModal from './CreateSuccessModal'
import DuplicateErrorModal from './DuplicateErrorModal'

const DrawdownParticipationModal = ({
    formattedParticipations: participationData,
    isDrawdownParticipationModalOpen,
    handleCloseDrawdownParticipationModal,
}) => {
    const [createDrawdown] = useCreateDrawdownMutation()

    const [duplicateEntry, setDuplicateEntry] = useState([])
    const [duplicateParticipation, setDuplicateParticipation] = useState([])
    const [isDuplicateErrorModalOpen, setIsDuplicateErrorModalOpen] = useState(false)
    const [isCreateSuccessModalOpen, setIsCreateSuccessModalOpen] = useState(false)

    const columns = [
        { field: 'fundCode', headerName: 'Fund Code', width: 120 },
        { field: 'taId', headerName: 'TA ID', width: 220 },
        {
            field: 'participationRatio',
            headerName: 'Ratio',
            width: 120,
            valueFormatter: (params) => formatPercent(params.value),
        },
        { field: 'currency', headerName: 'Currency', width: 90 },
        {
            field: 'transactionPrincipal',
            headerName: 'Amount',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'spreadRate',
            headerName: 'Spread Rate',
            width: 120,
            valueFormatter: (params) => formatPercent(params.value),
        },
        { field: 'relationshipType', headerName: 'Relationship', width: 120 },
    ]

    useEffect(() => {
        if (duplicateEntry) {
            const duplicate = participationData.find(
                (item) => item.fundTaId === duplicateEntry.fundTaId
            )
            setDuplicateParticipation(duplicate)
        }
    }, [duplicateEntry, participationData])

    const handleConfirm = async () => {
        try {
            const response = await createDrawdown(participationData)

            if (response.error && response.error.data && response.error.data.existingData) {
                const { existingData } = response.error.data
                handleOpenDuplicateErrorModal({ existingData })
            } else if (!response.error && response.data.newData.length > 0) {
                handleOpenCreateSuccessModal()
            }
        } catch (error) {
            console.error('Error creating drawdown:', error)
        }
    }

    const handleOpenCreateSuccessModal = () => {
        setIsCreateSuccessModalOpen(true)
    }

    const handleCloseCreateSuccessModal = () => {
        setIsCreateSuccessModalOpen(false)
        handleCloseDrawdownParticipationModal()
    }

    const handleOpenDuplicateErrorModal = ({ existingData }) => {
        setIsDuplicateErrorModalOpen(true)
        setDuplicateEntry(existingData)
    }

    const handleCloseDuplicateErrorModal = () => {
        setIsDuplicateErrorModalOpen(false)
    }

    return (
        <Dialog
            open={isDrawdownParticipationModalOpen}
            onClose={handleCloseDrawdownParticipationModal}
            maxWidth={false}
        >
            <DialogTitle>Drawdown Participation</DialogTitle>
            <DialogContent dividers>
                <Box
                    sx={{
                        m: '1.5rem 2.5rem',
                        '& .MuiDataGrid-cell': {
                            borderBottom: 'none',
                        },
                        height: '40vh',
                        '& .MuiDataGrid-root': {
                            maxWidth: '95vw',
                        },
                    }}
                >
                    <DataGrid
                        getRowId={(row) => row.fundTaId}
                        rows={participationData}
                        columns={columns || []}
                        pageSizeOptions={[20, 50, 100]}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 20 } },
                        }}
                        slots={{
                            noRowsOverlay: CustomNoRowsOverlay,
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='outlined'
                    onClick={() => {
                        handleCloseDrawdownParticipationModal()
                    }}
                >
                    Cancel
                </Button>
                <Button variant='contained' onClick={handleConfirm}>
                    Confirm
                </Button>
            </DialogActions>
            <CreateSuccessModal
                isCreateSuccessModalOpen={isCreateSuccessModalOpen}
                handleCloseCreateSuccessModal={handleCloseCreateSuccessModal}
            />
            <DuplicateErrorModal
                duplicateEntry={duplicateEntry}
                duplicateParticipation={duplicateParticipation || []}
                isDuplicateErrorModalOpen={isDuplicateErrorModalOpen}
                handleCloseDuplicateErrorModal={handleCloseDuplicateErrorModal}
            />
        </Dialog>
    )
}

export default DrawdownParticipationModal
