import { useEffect } from 'react'
import { useFormikContext } from 'formik'

const useTransactionTypeHandler = (sums) => {
    const { sumPrincipal, sumInterest, sumFee } = sums
    const { values, setFieldValue } = useFormikContext()

    useEffect(() => {
        if (
            values.transactionType === 'Full Repayment' ||
            values.transactionType === 'Full Write Off' ||
            values.transactionType === 'Restructure to Other'
        ) {
            setFieldValue('transactionPrincipal', -sumPrincipal || 0)
            setFieldValue('transactionInterest', -sumInterest || 0)
            setFieldValue('transactionFee', -sumFee || 0)
        } else if (
            values.transactionType === 'Partial Repayment' ||
            values.transactionType === 'Partial Write Off' ||
            values.transactionType === 'Waive'
        ) {
            if (values.transactionPrincipal >= 0) {
                setFieldValue('transactionPrincipal', -Math.abs(values.transactionPrincipal || 0))
            }
            if (values.transactionInterest >= 0) {
                setFieldValue('transactionInterest', -Math.abs(values.transactionInterest || 0))
            }
            if (values.transactionFee >= 0) {
                setFieldValue('transactionFee', -Math.abs(values.transactionFee || 0))
            }
        } else if (values.transactionType === 'Charge Fee') {
            if (values.transactionPrincipal < 0) {
                setFieldValue('transactionPrincipal', Math.abs(values.transactionPrincipal || 0))
            }
            if (values.transactionInterest < 0) {
                setFieldValue('transactionInterest', Math.abs(values.transactionInterest || 0))
            }
            if (values.transactionFee < 0) {
                setFieldValue('transactionFee', Math.abs(values.transactionFee || 0))
            }
        } else if (values.transactionType === 'Capitalise') {
            const capitalisedPrincipal = -(
                parseFloat(values.transactionInterest || 0) + parseFloat(values.transactionFee || 0)
            )
            setFieldValue('transactionPrincipal', capitalisedPrincipal)
            if (values.transactionInterest >= 0) {
                setFieldValue('transactionInterest', -Math.abs(values.transactionInterest || 0))
            }
            if (values.transactionFee >= 0) {
                setFieldValue('transactionFee', -Math.abs(values.transactionFee || 0))
            }
        }
    }, [
        values.transactionType,
        values.transactionPrincipal,
        values.transactionInterest,
        values.transactionFee,
        setFieldValue,
        sumPrincipal,
        sumInterest,
        sumFee,
    ])

    return null
}

export default useTransactionTypeHandler
