const CustomFilterForm = {
    columnInputProps: {
        variant: 'outlined',
        size: 'small',
        sx: { mt: 'auto' },
    },
    operatorInputProps: {
        variant: 'outlined',
        size: 'small',
        sx: { mt: 'auto' },
    },
    valueInputProps: {
        InputComponentProps: {
            variant: 'outlined',
            size: 'small',
            sx: { mt: 'auto' },
        },
    },
    deleteIconProps: {
        sx: {
            '& .MuiSvgIcon-root': { color: '#d32f2f' },
        },
    },
    sx: {
        '& .MuiDataGrid-filterForm': { p: 2 },
        '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
        '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
        '& .MuiDataGrid-filterFormValueInput': { width: 200 },
    },
}

export default CustomFilterForm
