import React, { useState, useEffect } from 'react'
import { TextField, InputAdornment } from '@mui/material'
import { useField } from 'formik'

const PercentFieldValidated = ({ name, onChange, ...otherProps }) => {
    const [customError, setCustomError] = useState(null)
    const [field, meta, helpers] = useField(name)
    const [rawValue, setRawValue] = useState(field.value || '')

    useEffect(() => {
        if (field.value === 0) {
            setRawValue('0')
        }
    }, [field.value])

    const handleInputChange = (event) => {
        const inputValue = event.target.value
        setRawValue(inputValue)

        const numericValue = parseFloat(inputValue)

        if (isNaN(numericValue) && inputValue.trim() !== '') {
            setCustomError('Enter a Numeric Value')
            helpers.setValue('')
        } else {
            setCustomError(null)
            helpers.setValue(inputValue.trim() === '' ? '' : numericValue)
        }

        if (onChange) {
            onChange(event)
        }
    }

    const inputProps = {
        endAdornment: <InputAdornment position='end'>%</InputAdornment>,
    }

    const configPercentField = {
        ...field,
        ...otherProps,
        fullWidth: true,
        variant: 'outlined',
        size: 'small',
        InputProps: inputProps,
        value: rawValue,
        onChange: handleInputChange,
    }

    if (meta && meta.touched) {
        if (field.value === undefined || field.value === null || field.value === '') {
            configPercentField.error = true
            configPercentField.helperText = 'Required'
        } else if (customError) {
            configPercentField.error = true
            configPercentField.helperText = customError
        } else if (meta.error) {
            configPercentField.error = true
            configPercentField.helperText = meta.error
        }
    }

    return <TextField {...configPercentField} />
}

export default PercentFieldValidated
