import React, { useEffect, useState } from 'react'
import { Box, Grid, Divider, Button, CircularProgress } from '@mui/material'
import { FileDownloadOutlined } from '@mui/icons-material'
import { DataGrid } from '@mui/x-data-grid'
import Header from '../layout/Header'
import CustomFilterForm from '../ui/datagrid/CustomFilterForm'
import CustomNoRowOverlay from '../ui/datagrid/CustomNoRowOverlay'
import dayjs from 'dayjs'
import { PDFDownloadLink } from '@react-pdf/renderer'

import { formatDataGridDate, formatMoney, formatPercent } from '../../utils/helpers/formatValue'

import { useGetStatementOfAccountQuery } from '../../app/api/reports/statementOfAccountsApislice'
import StatementHoldingsFilterForm from '../ui/forms/filters/StatementHoldingsFilterForm'
import StatementOfAccountSummaryForm from '../ui/forms/reports/StatementOfAccountSummaryForm'

import StatementOfAccountPDF from '../templates/StatementOfAccountPDF'

const StatementOfAccountSingleTab = () => {
    const initialStatementHoldingsFilterFormState = {
        asOfDate: null,
        borrowerId: '',
        facilityId: '',
    }

    const [filterParams, setFilterParams] = useState(initialStatementHoldingsFilterFormState)
    const [isShowStatementOfAccount, setIsShowStatementOfAccount] = useState(false)
    const [statementOfAccountArray, setStatementOfAccountArray] = useState([])
    const [statementOfAccountSummary, setStatementOfAccountSummary] = useState({})

    const {
        data: statementOfAccountData,
        isFetching: isGenerating,
        error,
    } = useGetStatementOfAccountQuery(filterParams, {
        skip: !filterParams.asOfDate && !filterParams.borrowerId && !filterParams.facilityId,
    })

    useEffect(() => {
        if (statementOfAccountData) {
            setStatementOfAccountArray(statementOfAccountData.statementOfAccountArrays[0] || [])
            setStatementOfAccountSummary(
                statementOfAccountData.statementOfAccountSummaries[0] || {}
            )
        }
    }, [statementOfAccountData])

    const columns = [
        {
            field: 'taId',
            headerName: 'TA ID',
            width: 250,
        },
        {
            field: 'drawdownDate',
            headerName: 'Drawdown Date',
            type: 'date',
            width: 150,
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
        {
            field: 'approvedMaturityDate',
            headerName: 'Maturity Date',
            type: 'date',
            width: 150,
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
        {
            field: 'currency',
            headerName: 'Currency',
            width: 90,
        },
        {
            field: 'effectiveInterestRate',
            headerName: 'Interest Rate',
            width: 120,
            valueFormatter: (params) => formatPercent(params.value),
        },
        {
            field: 'sumOutstandingPrincipal',
            headerName: 'Outstanding Principal',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'sumTotalInterest',
            headerName: 'Total Interest',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'sumTotalFee',
            headerName: 'Total Fee',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'sumNetDue',
            headerName: 'Net Due',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
    ]

    const handleStatementHoldingsFilterFormSubmit = (values) => {
        const { asOfDate, borrowerId, facilityId } = values
        setFilterParams({ asOfDate, borrowerId, facilityId })
    }

    const {
        facilityOutstandingPrincipal,
        facilityTotalInterest,
        facilityTotalFee,
        facilityNetDue,
        asOfDate: statementOfAccountAsOfDate,
        lenderName,
        borrowerName,
        facilityNumber,
        facilityLegalId,
        facilityLimit,
        availableLimit,
        beneficiaryName,
        accountNumber,
        bankName,
        bankAddress,
        swiftCode,
        reference,
    } = statementOfAccountSummary || {}

    return (
        <Box>
            <Header
                subtitle={
                    <>
                        Note:
                        <br />
                        1. Select the Facility SOA to be viewed or downloaded.
                    </>
                }
            />
            <Box
                sx={{
                    m: '1.5rem 2.5rem',
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                }}
            >
                <StatementHoldingsFilterForm
                    onSubmit={handleStatementHoldingsFilterFormSubmit}
                    initialStatementHoldingsFilterFormState={
                        initialStatementHoldingsFilterFormState
                    }
                    isGenerating={isGenerating}
                    setIsShowStatementOfAccount={setIsShowStatementOfAccount}
                    isShowStatementOfAccount={isShowStatementOfAccount}
                />
            </Box>
            {isShowStatementOfAccount ? (
                <>
                    <Box
                        sx={{
                            m: '1.5rem 2.5rem',
                            '& .MuiDataGrid-cell': {
                                borderBottom: 'none',
                            },
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Divider sx={{ mt: '0.5rem', mb: '0.5rem' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '1.2rem',
                                        marginBottom: '1rem',
                                    }}
                                >{`${lenderName} - ${borrowerName} - ${facilityLegalId} - Statement of Account - Snapshot as on ${dayjs(
                                    statementOfAccountAsOfDate
                                ).format('DD MMM YYYY')}`}</Box>
                                <PDFDownloadLink
                                    fileName={`SOA_${facilityNumber}_${dayjs(
                                        statementOfAccountAsOfDate
                                    ).format('YYYY-MM-DD')}.pdf`}
                                    document={
                                        <StatementOfAccountPDF
                                            data={{
                                                statementOfAccountArray,
                                                statementOfAccountSummary,
                                            }}
                                        />
                                    }
                                >
                                    {isGenerating ? (
                                        <Button
                                            disabled
                                            variant='contained'
                                            endIcon={
                                                <CircularProgress
                                                    size={16}
                                                    sx={{
                                                        color: 'inherit',
                                                        height: '16px',
                                                        width: '16px',
                                                    }}
                                                />
                                            }
                                        >
                                            Download
                                        </Button>
                                    ) : (
                                        <Button
                                            variant='contained'
                                            endIcon={<FileDownloadOutlined />}
                                        >
                                            Download
                                        </Button>
                                    )}
                                </PDFDownloadLink>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box
                        sx={{
                            m: '1.5rem 2.5rem',
                            '& .MuiDataGrid-cell': {
                                borderBottom: 'none',
                            },
                        }}
                    >
                        <DataGrid
                            loading={isGenerating}
                            getRowId={(row) => row._id}
                            rows={error ? [] : statementOfAccountArray || []}
                            columns={columns}
                            autoHeight
                            initialState={{
                                pagination: { paginationModel: { pageSize: 100 } },
                                sorting: { sortModel: [{ field: 'drawdownDate', sort: 'asc' }] },
                            }}
                            disableRowSelectionOnClick
                            hideFooter
                            slots={{
                                noRowsOverlay: CustomNoRowOverlay,
                            }}
                            slotProps={{
                                filterPanel: {
                                    filterFormProps: CustomFilterForm,
                                },
                            }}
                            sx={{ '--DataGrid-overlayHeight': '300px' }}
                        />
                        {isGenerating ? (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: '1.2rem',
                                            marginTop: '1.5rem',
                                        }}
                                    >
                                        {`Facility Summary`}
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <CircularProgress />
                                </Grid>
                            </Grid>
                        ) : (
                            <StatementOfAccountSummaryForm
                                facilityOutstandingPrincipal={facilityOutstandingPrincipal}
                                facilityTotalInterest={facilityTotalInterest}
                                facilityTotalFee={facilityTotalFee}
                                facilityNetDue={facilityNetDue}
                                facilityLimit={facilityLimit}
                                availableLimit={availableLimit}
                                beneficiaryName={beneficiaryName}
                                accountNumber={accountNumber}
                                bankName={bankName}
                                bankAddress={bankAddress}
                                swiftCode={swiftCode}
                                reference={reference}
                            />
                        )}
                    </Box>
                </>
            ) : null}
        </Box>
    )
}

export default StatementOfAccountSingleTab
