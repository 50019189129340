import { Box, useTheme, useMediaQuery } from '@mui/material'
import LoginForm from '../../components/ui/forms/auth/LoginForm'
import Copyright from '../../components/layout/Copyright'
import logoLight from '../../assets/images/logo_light.png'
import logoDark from '../../assets/images/logo_dark.png'

const LoginPage = () => {
    const theme = useTheme()
    const isNonMobile = useMediaQuery('(min-width: 1000px)')

    return (
        <Box>
            <Box
                sx={{
                    marginTop: '2rem',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <img src={logoLight} style={{ width: '250px' }} />
            </Box>
            <Box
                width={isNonMobile ? '50%' : '80%'}
                padding='2rem'
                margin='2rem auto'
                borderRadius='1.5rem'
            >
                <LoginForm />
                <Box sx={{ marginTop: '4rem' }}>
                    <Copyright align='center' />
                </Box>
            </Box>
        </Box>
    )
}

export default LoginPage
