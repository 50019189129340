import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Button,
    MenuItem,
    Divider,
} from '@mui/material'
import dayjs from 'dayjs'

import TextFieldValidated from '../../fields/TextFieldValidated'
import MoneyFieldValidated from '../../fields/MoneyFieldValidated'
import PercentFieldValidated from '../../fields/PercentFieldValidated'
import NumberField from '../../fields/NumberField'
import InputLabelStyled from '../../fields/InputLabelStyled'
import SelectValidated from '../../fields/select/SelectValidated'
import BooleanSelect from '../../fields/select/BooleanSelect'
import PreSubmitButton from '../../buttons/PreSubmitButton'

import FacilitySelect from '../../fields/select/FacilitySelect'
import BorrowerSelect from '../../fields/select/BorrowerSelect'

import { selectCurrentUser } from '../../../../app/api/auth/authSlice'
import { getFacilityNumber, getBorrowerName } from '../../../../utils/helpers/getDetails'
import { formatDatePickerDate } from '../../../../utils/helpers/formatValue'
import { currencyList } from '../../../../utils/lists/currencyList'
import { countryList } from '../../../../utils/lists/countryList'
import {
    assetTypes,
    tradeTypes,
    sectors,
    lgtSectors,
} from '../../../../utils/lists/positionAttributeLists'

import { useUpdatePositionMutation } from '../../../../app/api/records/positionsApiSlice'
import { useGetAllBorrowersQuery } from '../../../../app/api/records/borrowersApiSlice'
import { useGetAllFacilitiesQuery } from '../../../../app/api/records/facilitiesApiSlice'

import DuplicateErrorModal from '../../modals/DuplicateErrorModal'
import ConfirmSubmitModal from '../../modals/ConfirmSubmitModal'
import UpdateSuccessModal from '../../modals/UpdateSuccessModal'
import MissingFieldModal from '../../modals/MissingFieldModal'
import DatePickerValidated from '../../fields/DatePickerValidated'

const initialPositionFormState = {
    // Reference Parent Entities
    borrowerId: '',
    borrowerName: '',
    facilityId: '',
    facilityNumber: '',
    lenderCode: '',
    taId: '',
    loanId: '',
    notionalAmount: '',
    currency: '',
    financingRatio: '',
    ddFeeRate: '',
    originalInterestRate: '',
    // Position Attributes
    assetType: '',
    tradeType: '',
    goods: '',
    sector: '',
    sectorLgt: '',
    originCountry: '',
    destinationCountry: '',
    isActive: null,
    isDrawdown: null,
    lastUpdatedBy: '',
}

const UpdatePositionForm = ({ isFormOpen, handleCloseForm, toBeUpdated, isEditClicked }) => {
    const { firstName, lastName } = useSelector(selectCurrentUser)
    const userFullname = `${firstName} ${lastName}`

    const [updatePosition] = useUpdatePositionMutation()

    const [duplicateEntry, setDuplicateEntry] = useState([])
    const [selectedBorrower, setSelectedBorrower] = useState(toBeUpdated.borrower)
    const [isFacilitySelectDisabled, setIsFacilitySelectDisabled] = useState(false)

    const [isDuplicateErrorModalOpen, setIsDuplicateErrorModalOpen] = useState(false)
    const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] = useState(false)
    const [isUpdateSuccessModalOpen, setIsUpdateSuccessModalOpen] = useState(false)
    const [isMissingFieldModalOpen, setIsMissingFieldModalOpen] = useState(false)

    const { data: borrowers = [] } = useGetAllBorrowersQuery()
    const { data: facilities = [] } = useGetAllFacilitiesQuery()

    const formikRef = useRef()

    const handleOpenDuplicateErrorModal = ({ duplicateData }) => {
        setIsDuplicateErrorModalOpen(true)
        setDuplicateEntry(duplicateData)
    }

    const handleCloseDuplicateErrorModal = () => {
        setIsDuplicateErrorModalOpen(false)
    }

    const handleOpenConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(true)
    }

    const handleCloseConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(false)
    }

    const handleOpenUpdateSuccessModal = () => {
        setIsUpdateSuccessModalOpen(true)
    }

    const handleCloseUpdateSuccessModal = () => {
        setIsUpdateSuccessModalOpen(false)
        handleCloseForm()
    }

    const handleOpenMissingFieldModal = () => {
        setIsMissingFieldModalOpen(true)
    }

    const handleCloseMissingFieldModal = () => {
        setIsMissingFieldModalOpen(false)
    }

    const borrowerFacilities = selectedBorrower
        ? facilities.filter((facility) => facility.borrowerId === selectedBorrower)
        : facilities

    if (toBeUpdated && isEditClicked) {
        initialPositionFormState._id = toBeUpdated._id
        initialPositionFormState.borrowerId = toBeUpdated.borrowerId
        initialPositionFormState.borrowerName = toBeUpdated.borrowerName
        initialPositionFormState.facilityId = toBeUpdated.facilityId
        initialPositionFormState.facilityNumber = toBeUpdated.facilityNumber
        initialPositionFormState.lenderCode = toBeUpdated.lenderCode
        initialPositionFormState.taId = toBeUpdated.taId
        initialPositionFormState.loanId = toBeUpdated.loanId
        initialPositionFormState.notionalAmount = toBeUpdated.notionalAmount
        initialPositionFormState.currency = toBeUpdated.currency
        initialPositionFormState.financingRatio = toBeUpdated.financingRatio
        initialPositionFormState.ddFeeRate = toBeUpdated.ddFeeRate
        initialPositionFormState.originalInterestRate = toBeUpdated.originalInterestRate
        initialPositionFormState.effectiveDate = dayjs(new Date(toBeUpdated.effectiveDate))
        initialPositionFormState.supplementaryInterestRate = toBeUpdated.supplementaryInterestRate

        initialPositionFormState.assetType = toBeUpdated.assetType
        initialPositionFormState.tradeType = toBeUpdated.tradeType
        initialPositionFormState.goods = toBeUpdated.goods
        initialPositionFormState.sector = toBeUpdated.sector
        initialPositionFormState.sectorLgt = toBeUpdated.sectorLgt
        initialPositionFormState.originCountry = toBeUpdated.originCountry
        initialPositionFormState.destinationCountry = toBeUpdated.destinationCountry
        initialPositionFormState.isActive = toBeUpdated.isActive
        initialPositionFormState.isDrawdown = toBeUpdated.isDrawdown
        initialPositionFormState.lastUpdatedBy = userFullname
    }

    if (!isEditClicked) {
        initialPositionFormState._id = ''
        initialPositionFormState.borrowerId = ''
        initialPositionFormState.borrowerName = ''
        initialPositionFormState.facilityId = ''
        initialPositionFormState.facilityNumber = ''
        initialPositionFormState.lenderCode = ''
        initialPositionFormState.taId = ''
        initialPositionFormState.loanId = ''
        initialPositionFormState.notionalAmount = ''
        initialPositionFormState.currency = ''
        initialPositionFormState.financingRatio = ''
        initialPositionFormState.ddFeeRate = ''
        initialPositionFormState.originalInterestRate = ''
        initialPositionFormState.effectiveDate = dayjs(new Date().toISOString())
        initialPositionFormState.supplementaryInterestRate = ''
        initialPositionFormState.assetType = ''
        initialPositionFormState.tradeType = ''
        initialPositionFormState.goods = ''
        initialPositionFormState.sector = ''
        initialPositionFormState.sectorLgt = ''
        initialPositionFormState.originCountry = ''
        initialPositionFormState.destinationCountry = ''
        initialPositionFormState.isActive = ''
        initialPositionFormState.isDrawdown = ''
        initialPositionFormState.lastUpdatedBy = ''
    }

    const handlePositionFormSubmit = async (values) => {
        const {
            _id,
            // Reference Parent Entities
            borrowerId,
            borrowerName,
            facilityId,
            facilityNumber,
            // Position Details
            lenderCode,
            taId,
            loanId,
            notionalAmount,
            currency,
            financingRatio,
            ddFeeRate,
            originalInterestRate,
            // Position Attributes
            assetType,
            tradeType,
            goods,
            sector,
            sectorLgt,
            originCountry,
            destinationCountry,
            isActive,
            isDrawdown,
            lastUpdatedBy,
        } = values

        try {
            const response = await updatePosition({
                _id,
                // Reference Parent Entities
                borrowerId,
                borrowerName,
                facilityId,
                facilityNumber,
                // Position Details
                lenderCode,
                taId,
                loanId,
                notionalAmount,
                currency,
                financingRatio,
                ddFeeRate,
                originalInterestRate,
                // Position Attributes
                assetType,
                tradeType,
                goods,
                sector,
                sectorLgt,
                originCountry,
                destinationCountry,
                isActive,
                isDrawdown,
                lastUpdatedBy,
            })

            if (response.error && response.error.data && response.error.data.duplicateData) {
                const { error, duplicateData, newData } = response.error.data
                handleOpenDuplicateErrorModal({ duplicateData })
            } else if (response.data && response.data.newData) {
                const { error, duplicateData, newData } = response.data
                handleOpenUpdateSuccessModal()
            }
        } catch (error) {
            console.error('Error updating position:', error)
        }
    }

    const positionFormValidation = Yup.object().shape({
        // Reference Parent Entities
        borrowerId: Yup.string().required('Required'),
        borrowerName: Yup.string().required('Required'),
        facilityId: Yup.string().required('Required'),
        facilityNumber: Yup.string().required('Required'),
        // Position Details
        lenderCode: Yup.string().required('Required'),
        taId: Yup.string().required('Required'),
        loanId: Yup.number().nullable(),
        notionalAmount: Yup.number().required('Required'),
        currency: Yup.string().required('Required'),
        financingRatio: Yup.number().required('Required'),
        ddFeeRate: Yup.number().required('Required'),
        originalInterestRate: Yup.number().required('Required'),
        // Position Attributes
        assetType: Yup.string().required('Required'),
        tradeType: Yup.string().required('Required'),
        goods: Yup.string().required('Required'),
        sector: Yup.string().required('Required'),
        sectorLgt: Yup.string().required('Required'),
        originCountry: Yup.string().required('Required'),
        destinationCountry: Yup.string().required('Required'),
        isActive: Yup.boolean().required('Required'),
        isDrawdown: Yup.boolean().required('Required'),
    })

    const handlePositionsFormClose = () => {
        handleCloseForm()
        setSelectedBorrower('')
        setIsFacilitySelectDisabled(true)
        if (formikRef.current) {
            formikRef.current.resetForm()
        }
    }

    return (
        <Dialog open={isFormOpen} onClose={handlePositionsFormClose}>
            <DialogTitle>Update Position</DialogTitle>
            <Formik
                initialValues={{ ...initialPositionFormState }}
                validationSchema={positionFormValidation}
                onSubmit={(values) => {
                    handlePositionFormSubmit(values)
                }}
                validateOnMount={true}
                innerRef={formikRef}
            >
                {(formik) => (
                    <Form>
                        <DialogContent dividers>
                            <DialogContentText sx={{ mb: 2 }}>
                                Please fill in details of the Updated Position.
                                <br />
                                <br />
                                Ensure that:
                                <br />
                                TA ID is not duplicated.
                                <br />
                                <br />
                                Note:
                                <br />
                                Create or update supplementary interest rates{' '}
                                <a href='/records/supplementary-interest-rates'>here</a>.
                            </DialogContentText>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled disabled htmlFor='borrower'>
                                        Borrower
                                    </InputLabelStyled>
                                    <BorrowerSelect
                                        disabled
                                        borrowers={borrowers}
                                        onChange={(event) => {
                                            formik.setFieldValue('borrowerId', event.target.value)
                                            setSelectedBorrower(event.target.value)
                                            setIsFacilitySelectDisabled(!event.target.value)
                                            // Clear FacilitySelect when new Borrower selected
                                            formik.setFieldValue('facilityId', '')
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled disabled htmlFor='facility'>
                                        Facility
                                    </InputLabelStyled>
                                    <FacilitySelect
                                        disabled
                                        facilities={borrowerFacilities}
                                        isFacilitySelectDisabled={isFacilitySelectDisabled}
                                        onChange={(event) => {
                                            formik.setFieldValue('facilityId', event.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{ mt: '0.5rem' }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled disabled htmlFor='lenderCode'>
                                        Lender Code
                                    </InputLabelStyled>
                                    <TextFieldValidated
                                        disabled
                                        name='lenderCode'
                                        id='lenderCode'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='taId'>
                                        TransAsia ID *
                                    </InputLabelStyled>
                                    <TextFieldValidated name='taId' id='taId' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='loanId'>Loan ID</InputLabelStyled>
                                    <NumberField name='loanId' id='loanId' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='notionalAmount'>
                                        Notional Amount *
                                    </InputLabelStyled>
                                    <MoneyFieldValidated
                                        name='notionalAmount'
                                        id='notionalAmount'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='currency'>
                                        Currency *
                                    </InputLabelStyled>
                                    <SelectValidated name='currency' id='currency'>
                                        {currencyList.map((currencyListItem) => (
                                            <MenuItem
                                                key={currencyListItem}
                                                value={currencyListItem}
                                            >
                                                {currencyListItem}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='financingRatio'>
                                        Financing Ratio *
                                    </InputLabelStyled>
                                    <PercentFieldValidated
                                        name='financingRatio'
                                        id='financingRatio'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='ddFeeRate'>
                                        DD Fee Rate *
                                    </InputLabelStyled>
                                    <PercentFieldValidated name='ddFeeRate' id='ddFeeRate' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled
                                        disabled={formik.values.supplementaryInterestRate > 0}
                                        htmlFor='originalInterestRate'
                                    >
                                        {formik.values.supplementaryInterestRate > 0
                                            ? 'Original Interest Rate'
                                            : 'Original Interest Rate *'}
                                    </InputLabelStyled>
                                    <PercentFieldValidated
                                        disabled={formik.values.supplementaryInterestRate > 0}
                                        name='originalInterestRate'
                                        id='originalInterestRate'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled disabled htmlFor='supplementaryInterestRate'>
                                        Supplementary Interest Rate
                                    </InputLabelStyled>
                                    <PercentFieldValidated
                                        disabled
                                        name='supplementaryInterestRate'
                                        id='supplementaryInterestRate'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled disabled htmlFor='effectiveDate'>
                                        Effective Date
                                    </InputLabelStyled>
                                    <DatePickerValidated
                                        disabled
                                        name='effectiveDate'
                                        id='effectiveDate'
                                    />
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{ mt: '0.5rem' }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='assetType'>
                                        Asset Type *
                                    </InputLabelStyled>
                                    <SelectValidated name='assetType' id='assetType'>
                                        {assetTypes.map((assetType) => (
                                            <MenuItem key={assetType} value={assetType}>
                                                {assetType}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='tradeType'>
                                        Trade Type *
                                    </InputLabelStyled>
                                    <SelectValidated name='tradeType' id='tradeType'>
                                        {tradeTypes.map((tradeType) => (
                                            <MenuItem key={tradeType} value={tradeType}>
                                                {tradeType}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='goods'>Goods *</InputLabelStyled>
                                    <TextFieldValidated name='goods' id='goods' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='sector'>Sector *</InputLabelStyled>
                                    <SelectValidated name='sector' id='sector'>
                                        {sectors.map((sector) => (
                                            <MenuItem key={sector} value={sector}>
                                                {sector}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='sectorLgt'>
                                        Sector LGT *
                                    </InputLabelStyled>
                                    <SelectValidated name='sectorLgt' id='sectorLgt'>
                                        {lgtSectors.map((lgtSector) => (
                                            <MenuItem key={lgtSector} value={lgtSector}>
                                                {lgtSector}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='originCountry'>
                                        Country of Origin *
                                    </InputLabelStyled>
                                    <SelectValidated name='originCountry' id='originCountry'>
                                        {countryList.map((country) => (
                                            <MenuItem
                                                key={country.countryCode}
                                                value={country.countryName}
                                            >
                                                {country.countryName}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='destinationCountry'>
                                        Country of Destination *
                                    </InputLabelStyled>
                                    <SelectValidated
                                        name='destinationCountry'
                                        id='destinationCountry'
                                    >
                                        {countryList.map((country) => (
                                            <MenuItem
                                                key={country.countryCode}
                                                value={country.countryName}
                                            >
                                                {country.countryName}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled disabled htmlFor='isActive'>
                                        Is Active?
                                    </InputLabelStyled>
                                    <BooleanSelect disabled name='isActive' id='isActive' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled disabled htmlFor='isDrawdown'>
                                        Is Drawdown?
                                    </InputLabelStyled>
                                    <BooleanSelect disabled name='isDrawdown' id='isDrawdown' />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' onClick={handlePositionsFormClose}>
                                Cancel
                            </Button>
                            <PreSubmitButton
                                values={formik}
                                handleOpenMissingFieldModal={handleOpenMissingFieldModal}
                                handleOpenConfirmSubmitModal={handleOpenConfirmSubmitModal}
                            >
                                Submit
                            </PreSubmitButton>
                        </DialogActions>
                        <DuplicateErrorModal
                            duplicateEntry={duplicateEntry}
                            borrowers={borrowers}
                            facilities={facilities}
                            values={{
                                ...formik,
                                values: {
                                    ...formik.values,
                                    effectiveDate: formik.values.effectiveDate
                                        ? formatDatePickerDate(formik.values.effectiveDate)
                                        : formik.values.effectiveDate,
                                },
                            }}
                            isDuplicateErrorModalOpen={isDuplicateErrorModalOpen}
                            handleCloseDuplicateErrorModal={handleCloseDuplicateErrorModal}
                        />
                        <ConfirmSubmitModal
                            values={{
                                ...formik,
                                values: {
                                    ...formik.values,
                                    borrower: getBorrowerName(borrowers, formik.values.borrowerId),
                                    facility: getFacilityNumber(
                                        facilities,
                                        formik.values.facilityId
                                    ),
                                    effectiveDate: formik.values.effectiveDate
                                        ? formatDatePickerDate(formik.values.effectiveDate)
                                        : formik.values.effectiveDate,
                                },
                            }}
                            isConfirmSubmitModalOpen={isConfirmSubmitModalOpen}
                            handleCloseConfirmSubmitModal={handleCloseConfirmSubmitModal}
                        />
                        <UpdateSuccessModal
                            isUpdateSuccessModalOpen={isUpdateSuccessModalOpen}
                            handleCloseUpdateSuccessModal={handleCloseUpdateSuccessModal}
                        />
                        <MissingFieldModal
                            isMissingFieldModalOpen={isMissingFieldModalOpen}
                            handleCloseMissingFieldModal={handleCloseMissingFieldModal}
                        />
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default UpdatePositionForm
