import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import { EditOutlined, DeleteOutlined } from '@mui/icons-material'
import Header from '../../components/layout/Header'
import CustomToolbar from '../../components/ui/datagrid/CustomToolbar'
import CustomFilterForm from '../../components/ui/datagrid/CustomFilterForm'
import CustomNoRowOverlay from '../../components/ui/datagrid/CustomNoRowOverlay'

import { formatDataGridDate, formatMoney, formatPercent } from '../../utils/helpers/formatValue'

import { selectCurrentUser } from '../../app/api/auth/authSlice'
import {
    useDeletePositionMutation,
    useGetAllPositionsQuery,
} from '../../app/api/records/positionsApiSlice'

import ConfirmDeleteModal from '../../components/ui/modals/ConfirmDeleteModal'
import DeleteSuccessModal from '../../components/ui/modals/DeleteSuccessModal'
import ExistingRelationshipModal from '../../components/ui/modals/ExistingRelationshipModal'
import CreatePositionForm from '../../components/ui/forms/records/CreatePositionForm'
import UpdatePositionForm from '../../components/ui/forms/records/UpdatePositionForm'

const initialPositionState = {
    // Reference Parent Entities
    borrowerId: '',
    facilityId: '',
    // Position Details
    lenderCode: '',
    taId: '',
    loanId: '',
    notionalAmount: '',
    currency: '',
    financingRatio: '',
    ddFeeRate: '',
    originalInterestRate: '',
    supplementaryInterestRate: '',
    effectiveDate: null,
    // Position Attributes
    assetType: '',
    tradeType: '',
    goods: '',
    sector: '',
    sectorLgt: '',
    originCountry: '',
    destinationCountry: '',
}

const Positions = () => {
    const { data: initialRows, isLoading } = useGetAllPositionsQuery()

    const [deletePosition] = useDeletePositionMutation()

    const { role } = useSelector(selectCurrentUser)

    const [isFormOpen, setIsFormOpen] = useState(false)
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false)
    const [isDeleteSuccessModalOpen, setIsDeleteSuccessModalOpen] = useState(false)
    const [isExistingRelationshipModalOpen, setIsExistingRelationshipModalOpen] = useState(false)
    const [toBeUpdated, setToBeUpdated] = useState(initialPositionState)
    const [isEditClicked, setIsEditClicked] = useState(false)
    const [toBeDeleted, setToBeDeleted] = useState([])

    const handleOpenForm = () => {
        setIsFormOpen(true)
    }

    const handleCloseForm = () => {
        setIsFormOpen(false)
        setToBeUpdated(initialPositionState)
        setIsEditClicked(false)
    }

    const handleOpenConfirmDeleteModal = () => {
        setIsConfirmDeleteModalOpen(true)
    }

    const handleCloseConfirmDeleteModal = () => {
        setIsConfirmDeleteModalOpen(false)
    }

    const handleOpenDeleteSuccessModal = () => {
        setIsDeleteSuccessModalOpen(true)
    }

    const handleCloseDeleteSuccessModal = () => {
        setIsDeleteSuccessModalOpen(false)
    }

    const handleOpenExistingRelationshipModal = () => {
        setIsExistingRelationshipModalOpen(true)
    }

    const handleCloseExistingRelationshipModal = () => {
        setIsExistingRelationshipModalOpen(false)
    }

    const handleEdit = (params) => {
        const { row } = params

        setIsEditClicked(true)
        setToBeUpdated(row)
        handleOpenForm()
    }

    const handlePreDelete = (params) => {
        const { row } = params
        const updatedRow = { ...row }
        setToBeDeleted(updatedRow)
        handleOpenConfirmDeleteModal()
    }

    const handleDelete = async () => {
        try {
            const { _id } = toBeDeleted
            const response = await deletePosition(_id)

            if (response.data) {
                handleOpenDeleteSuccessModal()
            } else if (response.error.data.relatedData) {
                handleOpenExistingRelationshipModal()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const columns = [
        ...(role === 'Admin' || role === 'Editor'
            ? [
                  {
                      field: 'actions',
                      headerName: 'Actions',
                      type: 'actions',
                      getActions: (params) => {
                          return [
                              <GridActionsCellItem
                                  icon={<EditOutlined />}
                                  label='Edit'
                                  onClick={() => {
                                      handleEdit(params)
                                  }}
                                  color='inherit'
                              />,
                              <GridActionsCellItem
                                  icon={<DeleteOutlined />}
                                  label='Delete'
                                  onClick={() => {
                                      handlePreDelete(params)
                                  }}
                                  color='inherit'
                              />,
                          ]
                      },
                  },
              ]
            : []),
        {
            field: '_id',
            headerName: '_id',
            width: 150,
        },
        {
            field: 'borrowerName',
            headerName: 'Borrower Name',
            width: 200,
        },
        {
            field: 'facilityNumber',
            headerName: 'Facility Number',
            width: 120,
        },
        { field: 'lenderCode', headerName: 'Lender Code', width: 120 },
        { field: 'taId', headerName: 'TA ID', width: 220 },
        { field: 'loanId', headerName: 'Loan ID', width: 90 },
        { field: 'currency', headerName: 'Currency', width: 90 },
        {
            field: 'notionalAmount',
            headerName: 'Notional',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'financingRatio',
            headerName: 'Financing Ratio',
            width: 90,
            valueFormatter: (params) => formatPercent(params.value),
        },
        {
            field: 'ddFeeRate',
            headerName: 'DD Fee Rate',
            width: 90,
            valueFormatter: (params) => formatPercent(params.value),
        },
        {
            field: 'originalInterestRate',
            headerName: 'Original Interest Rate',
            width: 90,
            valueFormatter: (params) => formatPercent(params.value),
        },
        // {
        //     field: 'supplementaryInterestRateId',
        //     headerName: 'Supp Id',
        //     width: 150,
        // },
        {
            field: 'supplementaryInterestRate',
            headerName: 'Supplementary Interest Rate',
            width: 90,
            valueFormatter: (params) => formatPercent(params.value),
        },
        {
            field: 'effectiveDate',
            headerName: 'Effective Date',
            type: 'date',
            width: 150,
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
        { field: 'assetType', headerName: 'Asset Type', width: 100 },
        { field: 'tradeType', headerName: 'Trade Type', width: 100 },
        { field: 'goods', headerName: 'Goods', width: 100 },
        { field: 'sector', headerName: 'Sector', width: 100 },
        { field: 'sectorLgt', headerName: 'Sector LGT', width: 100 },
        { field: 'originCountry', headerName: 'Country of Origin', width: 100 },
        { field: 'destinationCountry', headerName: 'Country of Destination', width: 100 },
        { field: 'isActive', headerName: 'Is Active?', width: 100 },
        { field: 'isDrawdown', headerName: 'Is Drawdown?', width: 100 },
        {
            field: 'createdBy',
            headerName: 'Created By',
            width: 150,
        },
        {
            field: 'lastUpdatedBy',
            headerName: 'Last Updated By',
            width: 150,
        },
        {
            field: 'validFrom',
            headerName: 'Valid From',
            type: 'date',
            width: 150,
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
    ]

    return (
        <Box>
            <Header
                title='Positions'
                subtitle={
                    <>
                        List of Positions
                        <br />
                        <br />
                        Note:
                        <br />
                        1. 'Live' Positions in Holdings or have Drawdown Transactions cannot be
                        deleted.
                        <br />
                        2. Drawdown or Restructure <a href='/records/transactions'>here</a> after
                        setting up Position.
                    </>
                }
            />
            <Box
                sx={{
                    m: '1.5rem 2.5rem',
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    height: '80vh',
                    '& .MuiDataGrid-root': {
                        maxWidth: '95vw',
                    },
                }}
            >
                <DataGrid
                    loading={isLoading}
                    getRowId={(row) => row._id}
                    rows={initialRows || []}
                    columns={columns}
                    pageSizeOptions={[20, 50, 100]}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 20 } },
                        columns: {
                            columnVisibilityModel: {
                                // validFrom: false,
                            },
                        },
                        sorting: {
                            sortModel: [{ field: 'borrowerName', sort: 'asc' }],
                        },
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                        noRowsOverlay: CustomNoRowOverlay,
                    }}
                    slotProps={{
                        filterPanel: {
                            filterFormProps: CustomFilterForm,
                        },
                    }}
                />
            </Box>
            <ConfirmDeleteModal
                handleDelete={handleDelete}
                toBeDeleted={toBeDeleted}
                isConfirmDeleteModalOpen={isConfirmDeleteModalOpen}
                handleCloseConfirmDeleteModal={handleCloseConfirmDeleteModal}
            />
            <DeleteSuccessModal
                isDeleteSuccessModalOpen={isDeleteSuccessModalOpen}
                handleCloseDeleteSuccessModal={handleCloseDeleteSuccessModal}
                handleCloseConfirmDeleteModal={handleCloseConfirmDeleteModal}
            />
            <ExistingRelationshipModal
                isExistingRelationshipModalOpen={isExistingRelationshipModalOpen}
                handleCloseExistingRelationshipModal={handleCloseExistingRelationshipModal}
            />
            {isEditClicked ? (
                <UpdatePositionForm
                    isFormOpen={isFormOpen}
                    handleCloseForm={handleCloseForm}
                    toBeUpdated={toBeUpdated}
                    isEditClicked={isEditClicked}
                    setIsEditClicked={setIsEditClicked}
                />
            ) : (
                <CreatePositionForm
                    isFormOpen={isFormOpen}
                    handleCloseForm={handleCloseForm}
                    toBeUpdated={toBeUpdated}
                    isEditClicked={isEditClicked}
                    setIsEditClicked={setIsEditClicked}
                />
            )}
        </Box>
    )
}

export default Positions
