import React from 'react'
import { MenuItem } from '@mui/material'
import SelectValidated from './SelectValidated'

const LenderSelect = ({ lenders, active, ...otherProps }) => {
    const sortedLenders = lenders
        ? lenders
              .filter((lender) => !active || lender.isActive)
              .slice()
              .sort((a, b) => a.lenderName.localeCompare(b.lenderName))
        : []
    return (
        <SelectValidated name='lenderId' id='lenderId' {...otherProps}>
            {sortedLenders &&
                sortedLenders.map((lender) => (
                    <MenuItem key={lender.lenderCode} value={lender._id}>
                        {lender.lenderName}
                    </MenuItem>
                ))}
        </SelectValidated>
    )
}

export default LenderSelect
