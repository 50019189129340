import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import Copyright from '../layout/Copyright'

const Footer = () => {
    const theme = useTheme()

    return (
        <Box
            sx={{
                width: '100%',
                position: 'static',
                bottom: 0,
                py: '1.2rem',
                px: '2rem',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.primary.main
                        : theme.palette.grey[900],
                zIndex: 1200,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography>TransAsia Private Capital Limited</Typography>
                <Copyright />
            </Box>
        </Box>
    )
}

export default Footer
