import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Button,
    MenuItem,
    Divider,
} from '@mui/material'

import TextFieldValidated from '../../fields/TextFieldValidated'
import MoneyFieldValidated from '../../fields/MoneyFieldValidated'
import PercentFieldValidated from '../../fields/PercentFieldValidated'
import NumberField from '../../fields/NumberField'
import InputLabelStyled from '../../fields/InputLabelStyled'
import SelectValidated from '../../fields/select/SelectValidated'
import PreSubmitButton from '../../buttons/PreSubmitButton'

import FacilitySelect from '../../fields/select/FacilitySelect'
import BorrowerSelect from '../../fields/select/BorrowerSelect'

import { getFacilityNumber, getBorrowerName } from '../../../../utils/helpers/getDetails'
import { currencyList } from '../../../../utils/lists/currencyList'
import { countryList } from '../../../../utils/lists/countryList'
import {
    assetTypes,
    tradeTypes,
    sectors,
    lgtSectors,
} from '../../../../utils/lists/positionAttributeLists'

import { selectCurrentUser } from '../../../../app/api/auth/authSlice'
import { useCreatePositionMutation } from '../../../../app/api/records/positionsApiSlice'
import { useGetAllBorrowersQuery } from '../../../../app/api/records/borrowersApiSlice'
import { useGetAllFacilitiesQuery } from '../../../../app/api/records/facilitiesApiSlice'

import DuplicateErrorModal from '../../modals/DuplicateErrorModal'
import ConfirmSubmitModal from '../../modals/ConfirmSubmitModal'
import CreateSuccessModal from '../../modals/CreateSuccessModal'
import MissingFieldModal from '../../modals/MissingFieldModal'

const positionFormValidation = Yup.object().shape({
    // Reference Parent Entities
    borrowerId: Yup.string().required('Required'),
    facilityId: Yup.string().required('Required'),
    lenderCode: Yup.string().required('Required'),
    taId: Yup.string().required('Required'),
    loanId: Yup.number().nullable(),
    notionalAmount: Yup.number().required('Required'),
    currency: Yup.string().required('Required'),
    financingRatio: Yup.number().required('Required'),
    ddFeeRate: Yup.number().required('Required'),
    originalInterestRate: Yup.number().required('Required'),
    // Position Attributes
    assetType: Yup.string().required('Required'),
    tradeType: Yup.string().required('Required'),
    goods: Yup.string().required('Required'),
    sector: Yup.string().required('Required'),
    sectorLgt: Yup.string().required('Required'),
    originCountry: Yup.string().required('Required'),
    destinationCountry: Yup.string().required('Required'),
})

const CreatePositionForm = ({ isFormOpen, handleCloseForm }) => {
    const { firstName, lastName } = useSelector(selectCurrentUser)
    const userFullname = `${firstName} ${lastName}`

    const initialPositionFormState = {
        // Reference Parent Entities
        borrowerId: '',
        facilityId: '',
        // Position Details
        lenderCode: '',
        taId: '',
        loanId: '',
        notionalAmount: '',
        currency: '',
        financingRatio: '',
        ddFeeRate: '',
        originalInterestRate: '',
        // Position Attributes
        assetType: '',
        tradeType: '',
        goods: '',
        sector: '',
        sectorLgt: '',
        originCountry: '',
        destinationCountry: '',
        createdBy: userFullname,
    }

    const [createPosition] = useCreatePositionMutation()

    // To pass on proper names to the modals. Entities are read as ObjectId
    const { data: borrowers = [] } = useGetAllBorrowersQuery()
    const { data: facilities = [] } = useGetAllFacilitiesQuery()

    const [duplicateEntry, setDuplicateEntry] = useState([])
    const [selectedBorrower, setSelectedBorrower] = useState('')
    const [selectedFacility, setSelectedFacility] = useState('')
    const [isFacilitySelectDisabled, setIsFacilitySelectDisabled] = useState(true)

    const [isDuplicateErrorModalOpen, setIsDuplicateErrorModalOpen] = useState(false)
    const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] = useState(false)
    const [isCreateSuccessModalOpen, setIsCreateSuccessModalOpen] = useState(false)
    const [isMissingFieldModalOpen, setIsMissingFieldModalOpen] = useState(false)

    const formikRef = useRef()

    const handleOpenDuplicateErrorModal = ({ existingData }) => {
        setIsDuplicateErrorModalOpen(true)
        setDuplicateEntry(existingData)
    }

    const handleCloseDuplicateErrorModal = () => {
        setIsDuplicateErrorModalOpen(false)
    }

    const handleOpenConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(true)
    }

    const handleCloseConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(false)
    }

    const handleOpenCreateSuccessModal = () => {
        setIsCreateSuccessModalOpen(true)
    }

    const handleCloseCreateSuccessModal = () => {
        setIsCreateSuccessModalOpen(false)
        handleCloseForm()
    }

    const handleOpenMissingFieldModal = () => {
        setIsMissingFieldModalOpen(true)
    }

    const handleCloseMissingFieldModal = () => {
        setIsMissingFieldModalOpen(false)
    }

    const borrowerFacilities = selectedBorrower
        ? facilities.filter((facility) => facility.borrowerId === selectedBorrower)
        : facilities

    const handlePositionFormSubmit = async (values) => {
        const {
            // Reference Parent Entities
            borrowerId,
            facilityId,
            // Position Details
            lenderCode,
            taId,
            loanId,
            notionalAmount,
            currency,
            financingRatio,
            ddFeeRate,
            originalInterestRate,
            // Position Attributes
            assetType,
            tradeType,
            goods,
            sector,
            sectorLgt,
            originCountry,
            destinationCountry,
            createdBy,
        } = values

        try {
            const response = await createPosition({
                // Reference Parent Entities
                borrowerId,
                facilityId,
                // Position Details
                lenderCode,
                taId,
                loanId,
                notionalAmount,
                currency,
                financingRatio,
                ddFeeRate,
                originalInterestRate,
                // Position Attributes
                assetType,
                tradeType,
                goods,
                sector,
                sectorLgt,
                originCountry,
                destinationCountry,
                createdBy,
            })

            if (response.error && response.error.data && response.error.data.existingData) {
                const { error, existingData, newData } = response.error.data

                handleOpenDuplicateErrorModal({ existingData })
            } else if (response.data && response.data.newData) {
                const { error, existingData, newData } = response.data
                handleOpenCreateSuccessModal()
            }
        } catch (error) {
            console.error('Error creating position:', error)
        }
    }

    const handlePositionsFormClose = () => {
        handleCloseForm()
        setSelectedBorrower('')
        setIsFacilitySelectDisabled(true)
        if (formikRef.current) {
            formikRef.current.resetForm()
        }
    }

    return (
        <Dialog open={isFormOpen} onClose={handlePositionsFormClose}>
            <DialogTitle>Create New Position</DialogTitle>
            <Formik
                initialValues={{ ...initialPositionFormState }}
                validationSchema={positionFormValidation}
                onSubmit={(values) => {
                    handlePositionFormSubmit(values)
                }}
                validateOnMount={true}
                innerRef={formikRef}
            >
                {(formik) => (
                    <Form>
                        <DialogContent dividers>
                            <DialogContentText sx={{ mb: 2 }}>
                                Please fill in details of the New Position. Ensure that specific TA
                                ID is not duplicated.
                                <br />
                                <br />A Position should be created before any booking in the
                                Transactions.
                            </DialogContentText>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Divider sx={{ mb: '0.5rem' }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='borrowerId'>
                                        Borrower *
                                    </InputLabelStyled>
                                    <BorrowerSelect
                                        borrowers={borrowers}
                                        onChange={(event) => {
                                            formik.setFieldValue('borrowerId', event.target.value)
                                            setSelectedBorrower(event.target.value)
                                            setIsFacilitySelectDisabled(!event.target.value)
                                            // Clear FacilitySelect when new Borrower selected
                                            formik.setFieldValue('facilityId', '')
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='facilityId'>
                                        Facility *
                                    </InputLabelStyled>
                                    <FacilitySelect
                                        facilities={borrowerFacilities}
                                        isFacilitySelectDisabled={isFacilitySelectDisabled}
                                        onChange={(event) => {
                                            formik.setFieldValue('facilityId', event.target.value)
                                            setSelectedFacility(event.target.value)
                                            const selectedFacility = facilities.find(
                                                (facility) => facility._id === event.target.value
                                            )
                                            if (selectedFacility) {
                                                formik.setFieldValue(
                                                    'lenderCode',
                                                    selectedFacility.lenderCode || ''
                                                )
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{ mt: '0.5rem', mb: '0.5rem' }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled disabled htmlFor='lenderCode'>
                                        Lender Code
                                    </InputLabelStyled>
                                    <TextFieldValidated
                                        disabled
                                        name='lenderCode'
                                        id='lenderCode'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='taId'>
                                        TransAsia ID *
                                    </InputLabelStyled>
                                    <TextFieldValidated name='taId' id='taId' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='loanId'>Loan ID</InputLabelStyled>
                                    <NumberField name='loanId' id='loanId' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='notionalAmount'>
                                        Notional Amount *
                                    </InputLabelStyled>
                                    <MoneyFieldValidated
                                        name='notionalAmount'
                                        id='notionalAmount'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='currency'>
                                        Currency *
                                    </InputLabelStyled>
                                    <SelectValidated name='currency' id='currency'>
                                        {currencyList.map((currencyListItem) => (
                                            <MenuItem
                                                key={currencyListItem}
                                                value={currencyListItem}
                                            >
                                                {currencyListItem}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='financingRatio'>
                                        Financing Ratio *
                                    </InputLabelStyled>
                                    <PercentFieldValidated
                                        name='financingRatio'
                                        id='financingRatio'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='ddFeeRate'>
                                        DD Fee Rate *
                                    </InputLabelStyled>
                                    <PercentFieldValidated name='ddFeeRate' id='ddFeeRate' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='originalInterestRate'>
                                        Interest Rate *
                                    </InputLabelStyled>
                                    <PercentFieldValidated
                                        name='originalInterestRate'
                                        id='originalInterestRate'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{ mt: '0.5rem', mb: '0.5rem' }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='assetType'>
                                        Asset Type *
                                    </InputLabelStyled>
                                    <SelectValidated name='assetType' id='assetType'>
                                        {assetTypes.map((assetType) => (
                                            <MenuItem key={assetType} value={assetType}>
                                                {assetType}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='tradeType'>
                                        Trade Type *
                                    </InputLabelStyled>
                                    <SelectValidated name='tradeType' id='tradeType'>
                                        {tradeTypes.map((tradeType) => (
                                            <MenuItem key={tradeType} value={tradeType}>
                                                {tradeType}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='goods'>Goods *</InputLabelStyled>
                                    <TextFieldValidated name='goods' id='goods' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='sector'>Sector *</InputLabelStyled>
                                    <SelectValidated name='sector' id='sector'>
                                        {sectors.map((sector) => (
                                            <MenuItem key={sector} value={sector}>
                                                {sector}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='sectorLgt'>
                                        Sector LGT *
                                    </InputLabelStyled>
                                    <SelectValidated name='sectorLgt' id='sectorLgt'>
                                        {lgtSectors.map((lgtSector) => (
                                            <MenuItem key={lgtSector} value={lgtSector}>
                                                {lgtSector}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='originCountry'>
                                        Country of Origin *
                                    </InputLabelStyled>
                                    <SelectValidated name='originCountry' id='originCountry'>
                                        {countryList.map((country) => (
                                            <MenuItem
                                                key={country.countryCode}
                                                value={country.countryName}
                                            >
                                                {country.countryName}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='destinationCountry'>
                                        Country of Destination *
                                    </InputLabelStyled>
                                    <SelectValidated
                                        name='destinationCountry'
                                        id='destinationCountry'
                                    >
                                        {countryList.map((country) => (
                                            <MenuItem
                                                key={country.countryCode}
                                                value={country.countryName}
                                            >
                                                {country.countryName}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' onClick={handlePositionsFormClose}>
                                Cancel
                            </Button>
                            <PreSubmitButton
                                values={formik}
                                handleOpenMissingFieldModal={handleOpenMissingFieldModal}
                                handleOpenConfirmSubmitModal={handleOpenConfirmSubmitModal}
                            >
                                Submit
                            </PreSubmitButton>
                        </DialogActions>
                        <DuplicateErrorModal
                            duplicateEntry={duplicateEntry}
                            borrowers={borrowers}
                            facilities={facilities}
                            values={{
                                ...formik,
                                values: {
                                    ...formik.values,
                                    borrowerName: getBorrowerName(
                                        borrowers,
                                        formik.values.borrowerId
                                    ),
                                    facilityNumber: getFacilityNumber(
                                        facilities,
                                        formik.values.facilityId
                                    ),
                                },
                            }}
                            isDuplicateErrorModalOpen={isDuplicateErrorModalOpen}
                            handleCloseDuplicateErrorModal={handleCloseDuplicateErrorModal}
                        />
                        <ConfirmSubmitModal
                            values={{
                                ...formik,
                                values: {
                                    ...formik.values,
                                    borrowerName: getBorrowerName(
                                        borrowers,
                                        formik.values.borrowerId
                                    ),
                                    facilityNumber: getFacilityNumber(
                                        facilities,
                                        formik.values.facilityId
                                    ),
                                },
                            }}
                            isConfirmSubmitModalOpen={isConfirmSubmitModalOpen}
                            handleCloseConfirmSubmitModal={handleCloseConfirmSubmitModal}
                        />
                        <CreateSuccessModal
                            isCreateSuccessModalOpen={isCreateSuccessModalOpen}
                            handleCloseCreateSuccessModal={handleCloseCreateSuccessModal}
                        />
                        <MissingFieldModal
                            isMissingFieldModalOpen={isMissingFieldModalOpen}
                            handleCloseMissingFieldModal={handleCloseMissingFieldModal}
                        />
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default CreatePositionForm
