import React, { useEffect, useState } from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Grid,
    MenuItem,
    Divider,
    DialogContentText,
} from '@mui/material'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

import InputLabelStyled from '../../fields/InputLabelStyled'
import MoneyFieldValidated from '../../fields/MoneyFieldValidated'
import SelectValidated from '../../fields/select/SelectValidated'
import TextFieldValidated from '../../fields/TextFieldValidated'

import { applyTransactionTypeList } from '../../../../utils/lists/transactionTypeList'
import useTransactionTypeHandler from '../../../../hooks/useTransactionTypeHandler'

import TransactionAllocationModal from '../../modals/TransactionAllocationModal'

const uniqueAllocationOrder = Yup.mixed().test(
    'unique-allocation-order',
    'Allocation order must be unique and contain all Principal, Interest, and Fee',
    function (value) {
        const { allocateFirst, allocateSecond, allocateThird } = this.parent
        const allocationSet = new Set([allocateFirst, allocateSecond, allocateThird])
        return allocationSet.size === 3
    }
)

const transactionFormValidation = Yup.object().shape({
    transactionPrincipal: Yup.number().required('Required'),
    transactionInterest: Yup.number().required('Required'),
    transactionFee: Yup.number().required('Required'),
    transactionType: Yup.string().required('Required'),
    allocateFirst: uniqueAllocationOrder,
    allocateSecond: uniqueAllocationOrder,
    allocateThird: uniqueAllocationOrder,
    remarks: Yup.string(),
})

const repaymentAllocationList = ['Principal', 'Interest', 'Fee']

const TransactionFormContent = ({
    formik,
    sums,
    selectedRowsData,
    selectedTransactionDate,
    handleCloseApplyTransactionForm,
    isTransactionAllocationModalOpen,
    handleCloseTransactionAllocationModal,
}) => {
    const { values, setFieldValue, submitForm } = useFormikContext()
    useTransactionTypeHandler(sums)

    useEffect(() => {
        const calculateRemainingRepayment = () => {
            let sumTransaction = 0
            let remainingRepayment = 0

            if (values.transactionType === 'Partial Repayment') {
                sumTransaction =
                    parseFloat(values.transactionPrincipal) +
                    parseFloat(values.transactionInterest) +
                    parseFloat(values.transactionFee)
                remainingRepayment = parseFloat(values.sumRepayment || 0) + sumTransaction
            } else if (values.transactionType === 'Full Repayment') {
                sumTransaction =
                    parseFloat(values.transactionPrincipal) +
                    parseFloat(values.transactionInterest) +
                    parseFloat(values.transactionFee)
                values.sumRepayment = -sumTransaction
                remainingRepayment = 0
            } else if (
                values.transactionType === 'Partial Write Off' ||
                values.transactionType === 'Full Write Off'
            ) {
                sumTransaction =
                    parseFloat(values.transactionPrincipal) +
                    parseFloat(values.transactionInterest) +
                    parseFloat(values.transactionFee)
                values.sumWriteOff = -sumTransaction
            }

            return remainingRepayment
        }

        const remainingRepayment = calculateRemainingRepayment()

        setFieldValue('remainingRepayment', remainingRepayment)
    }, [values, setFieldValue])

    const handleAllocate = () => {
        setFieldValue('transactionPrincipal', 0)
        setFieldValue('transactionInterest', 0)
        setFieldValue('transactionFee', 0)

        const allocationOrder = [values.allocateFirst, values.allocateSecond, values.allocateThird]

        let remainingRepayment = parseFloat(values.sumRepayment || 0)

        const allocateAmount = (type, amount) => {
            const transactionField = `transaction${type}`
            const sumField = `sum${type}`

            if (remainingRepayment >= values[sumField]) {
                setFieldValue(transactionField, values[sumField])
                remainingRepayment -= values[sumField]
            } else {
                setFieldValue(transactionField, remainingRepayment)
                remainingRepayment = 0
            }
        }

        allocationOrder.forEach((type) => {
            if (remainingRepayment > 0) {
                allocateAmount(type, remainingRepayment)
            }
        })

        setFieldValue('remainingRepayment', remainingRepayment)
    }

    return (
        <Form>
            <DialogContent dividers>
                <DialogContentText sx={{ mb: 2 }}>
                    1. Choose 'Transaction Type'
                    <br />
                    {values.transactionType === 'Partial Repayment' ? (
                        <>
                            2a. Allocate automatically by setting 'Allocation Order' or
                            <br />
                            2b. Allocate manually by keying in amounts
                            <br />
                            3. Click 'Submit' to view allocation to positions
                        </>
                    ) : values.transactionType === 'Partial Write Off' ? (
                        <>
                            2. Allocate manually by keying in amounts
                            <br />
                            3. Click 'Submit' to view allocation to positions
                        </>
                    ) : (
                        <>2. Click 'Submit' to view allocation to positions</>
                    )}
                </DialogContentText>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Divider sx={{ mb: '0.5rem' }} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabelStyled>Transaction Type</InputLabelStyled>
                        <SelectValidated
                            name='transactionType'
                            id='transactionType'
                            onChange={(event) => {
                                formik.setFieldValue('transactionPrincipal', 0)
                                formik.setFieldValue('transactionInterest', 0)
                                formik.setFieldValue('transactionFee', 0)
                                formik.setFieldValue('remarks', '')
                            }}
                        >
                            {applyTransactionTypeList.map((applyTransactionTypeListItem) => (
                                <MenuItem
                                    key={applyTransactionTypeListItem}
                                    value={applyTransactionTypeListItem}
                                >
                                    {applyTransactionTypeListItem}
                                </MenuItem>
                            ))}
                        </SelectValidated>
                    </Grid>
                    {values.transactionType === 'Full Repayment' ||
                    values.transactionType === 'Partial Repayment' ? (
                        <>
                            <Grid item xs={12}>
                                <Divider sx={{ mt: '0.5rem', mb: '0.5rem' }} />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabelStyled>Repayment Received</InputLabelStyled>
                                <MoneyFieldValidated
                                    name='sumRepayment'
                                    id='sumRepayment'
                                    disabled={values.transactionType === 'Full Repayment'}
                                    value={values.sumRepayment || 0}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabelStyled>To Be Allocated</InputLabelStyled>
                                <MoneyFieldValidated
                                    name='remainingRepayment'
                                    id='remainingRepayment'
                                    disabled
                                    value={values.remainingRepayment || 0}
                                />
                            </Grid>
                        </>
                    ) : values.transactionType === 'Partial Write Off' ||
                      values.transactionType === 'Full Write Off' ? (
                        <Grid item xs={12}>
                            <InputLabelStyled>Write Off Sum</InputLabelStyled>
                            <MoneyFieldValidated
                                name='sumWriteOff'
                                id='sumWriteOff'
                                disabled={
                                    values.transactionType === 'Partial Write Off' ||
                                    values.transactionType === 'Full Write Off'
                                }
                                value={values.sumWriteOff || 0}
                            />
                        </Grid>
                    ) : null}
                    {values.transactionType === 'Partial Repayment' ? (
                        <>
                            <Grid item xs={12} sx={{ mb: '-0.5rem' }}>
                                <InputLabelStyled>Allocation Order</InputLabelStyled>
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabelStyled sx={{ fontWeight: 'normal' }}>
                                    First
                                </InputLabelStyled>
                                <SelectValidated name='allocateFirst' id='allocateFirst'>
                                    {repaymentAllocationList.map((repaymentAllocationListItem) => (
                                        <MenuItem
                                            key={repaymentAllocationListItem}
                                            value={repaymentAllocationListItem}
                                        >
                                            {repaymentAllocationListItem}
                                        </MenuItem>
                                    ))}
                                </SelectValidated>
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabelStyled sx={{ fontWeight: 'normal' }}>
                                    Second
                                </InputLabelStyled>
                                <SelectValidated name='allocateSecond' id='allocateSecond'>
                                    {repaymentAllocationList.map((repaymentAllocationListItem) => (
                                        <MenuItem
                                            key={repaymentAllocationListItem}
                                            value={repaymentAllocationListItem}
                                        >
                                            {repaymentAllocationListItem}
                                        </MenuItem>
                                    ))}
                                </SelectValidated>
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabelStyled sx={{ fontWeight: 'normal' }}>
                                    Third
                                </InputLabelStyled>
                                <SelectValidated name='allocateThird' id='allocateThird'>
                                    {repaymentAllocationList.map((repaymentAllocationListItem) => (
                                        <MenuItem
                                            key={repaymentAllocationListItem}
                                            value={repaymentAllocationListItem}
                                        >
                                            {repaymentAllocationListItem}
                                        </MenuItem>
                                    ))}
                                </SelectValidated>
                            </Grid>
                            <Grid item xs={6} sx={{ mt: '0.3rem' }}>
                                <Button variant='contained' onClick={handleAllocate}>
                                    Allocate
                                </Button>
                            </Grid>
                        </>
                    ) : null}
                    <Grid item xs={12}>
                        <Divider sx={{ mt: '0.5rem', mb: '0.5rem' }} />
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                        <InputLabelStyled>Principal Sum</InputLabelStyled>
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabelStyled>Interest Sum</InputLabelStyled>
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabelStyled>Fee Sum</InputLabelStyled>
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabelStyled>Outstanding</InputLabelStyled>
                    </Grid>
                    <Grid item xs={3}>
                        <MoneyFieldValidated
                            name='sumPrincipal'
                            id='sumPrincipal'
                            disabled
                            value={values.sumPrincipal || 0}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MoneyFieldValidated
                            name='sumInterest'
                            id='sumInterest'
                            disabled
                            value={values.sumInterest || 0}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MoneyFieldValidated
                            name='sumFee'
                            id='sumFee'
                            disabled
                            value={values.sumFee || 0}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabelStyled>
                            {values.transactionType === 'Partial Repayment' ||
                            values.transactionType === 'Full Repayment'
                                ? 'Repayment'
                                : values.transactionType === 'Partial Write Off' ||
                                  values.transactionType === 'Full Write Off'
                                ? 'Write Off'
                                : values.transactionType === 'Restructure to Other'
                                ? 'Restructure'
                                : values.transactionType}
                        </InputLabelStyled>
                    </Grid>
                    <Grid item xs={3}>
                        <MoneyFieldValidated
                            name='transactionPrincipal'
                            id='transactionPrincipal'
                            disabled={
                                values.transactionType === 'Full Repayment' ||
                                values.transactionType === 'Full Write Off' ||
                                values.transactionType === 'Restructure to Other' ||
                                values.transactionType === 'Capitalise' ||
                                values.transactionType === 'Charge Fee' ||
                                values.transactionType === 'Mark to Market'
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MoneyFieldValidated
                            name='transactionInterest'
                            id='transactionInterest'
                            disabled={
                                values.transactionType === 'Full Repayment' ||
                                values.transactionType === 'Full Write Off' ||
                                values.transactionType === 'Restructure to Other' ||
                                values.transactionType === 'Charge Fee' ||
                                values.transactionType === 'Mark to Market'
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MoneyFieldValidated
                            name='transactionFee'
                            id='transactionFee'
                            disabled={
                                values.transactionType === 'Full Repayment' ||
                                values.transactionType === 'Full Write Off' ||
                                values.transactionType === 'Restructure to Other'
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ mt: '0.5rem', mb: '0.5rem' }} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabelStyled>Remarks</InputLabelStyled>
                        <TextFieldValidated name='remarks' id='remarks' />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleCloseApplyTransactionForm}>
                    Cancel
                </Button>
                <Button variant='contained' onClick={submitForm} disabled={!formik.isValid}>
                    Submit
                </Button>
            </DialogActions>
            <TransactionAllocationModal
                values={values}
                selectedRowsData={selectedRowsData}
                selectedTransactionDate={selectedTransactionDate}
                handleCloseApplyTransactionForm={handleCloseApplyTransactionForm}
                isTransactionAllocationModalOpen={isTransactionAllocationModalOpen}
                handleCloseTransactionAllocationModal={handleCloseTransactionAllocationModal}
            />
        </Form>
    )
}

const ApplyTransactionForm = ({
    isApplyTransactionFormOpen,
    handleCloseApplyTransactionForm,
    sums,
    selectedRowsData,
    selectedTransactionDate,
}) => {
    const { sumPrincipal, sumInterest, sumFee } = sums

    const initialTransactionFormState = {
        sumRepayment: 0,
        sumWriteOff: 0,
        remainingRepayment: 0,
        sumPrincipal: sumPrincipal || 0,
        sumInterest: sumInterest || 0,
        sumFee: sumFee || 0,
        transactionPrincipal: 0,
        transactionInterest: 0,
        transactionFee: 0,
        transactionType: 'Partial Repayment',
        allocateFirst: 'Fee',
        allocateSecond: 'Interest',
        allocateThird: 'Principal',
        remarks: '',
    }

    const [isTransactionAllocationModalOpen, setIsTransactionAllocationModalOpen] = useState(false)

    const handleTransactionFormSubmit = async (values) => {
        setIsTransactionAllocationModalOpen(true)
    }

    const handleCloseTransactionAllocationModal = () => {
        setIsTransactionAllocationModalOpen(false)
    }

    const parseTransactionValues = (values) => {
        values.transactionPrincipal = parseFloat(values.transactionPrincipal)
        values.transactionInterest = parseFloat(values.transactionInterest)
        values.transactionFee = parseFloat(values.transactionFee)

        return values
    }

    return (
        <Dialog open={isApplyTransactionFormOpen} onClose={handleCloseApplyTransactionForm}>
            <DialogTitle>Apply New Transactions</DialogTitle>
            <Formik
                initialValues={{ ...initialTransactionFormState }}
                validationSchema={transactionFormValidation}
                onSubmit={(values) => {
                    handleTransactionFormSubmit(parseTransactionValues(values))
                }}
                validateOnMount={true}
            >
                {(formik) => (
                    <TransactionFormContent
                        formik={formik}
                        sums={sums}
                        selectedRowsData={selectedRowsData}
                        selectedTransactionDate={selectedTransactionDate}
                        handleCloseApplyTransactionForm={handleCloseApplyTransactionForm}
                        isTransactionAllocationModalOpen={isTransactionAllocationModalOpen}
                        setIsTransactionAllocationModalOpen={setIsTransactionAllocationModalOpen}
                        handleCloseTransactionAllocationModal={
                            handleCloseTransactionAllocationModal
                        }
                    />
                )}
            </Formik>
        </Dialog>
    )
}

export default ApplyTransactionForm
