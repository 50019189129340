import { apiSlice } from '../apiSlice'

export const supplementaryInterestRatesApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getAllSupplementaryInterestRates: build.query({
            query: () => ({ url: `records/supplementary-interest-rates`, method: 'GET' }),
            providesTags: ['SupplementaryInterestRates'],
        }),
        createSupplementaryInterestRate: build.mutation({
            query: (newRow) => ({
                url: `records/supplementary-interest-rates`,
                method: 'POST',
                body: newRow,
            }),
            invalidatesTags: ['SupplementaryInterestRates', 'Positions', 'Holdings'],
        }),
        updateSupplementaryInterestRate: build.mutation({
            query: (updatedRow) => ({
                url: `records/supplementary-interest-rates`,
                method: 'PUT',
                body: updatedRow,
            }),
            invalidatesTags: ['SupplementaryInterestRates', 'Positions', 'Holdings'],
        }),
        deleteSupplementaryInterestRate: build.mutation({
            query: (id) => ({
                url: `records/supplementary-interest-rates/${id}`,
                method: 'DELETE',
                body: id,
            }),
            invalidatesTags: ['SupplementaryInterestRates', 'Positions', 'Holdings'],
        }),
    }),
})

export const {
    useCreateSupplementaryInterestRateMutation,
    useGetAllSupplementaryInterestRatesQuery,
    useUpdateSupplementaryInterestRateMutation,
    useDeleteSupplementaryInterestRateMutation,
} = supplementaryInterestRatesApiSlice
