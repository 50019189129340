import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Button,
} from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'

const ExistingRelationshipModal = ({
    isExistingRelationshipModalOpen,
    handleCloseExistingRelationshipModal,
}) => {
    const location = useLocation()

    let existingRelationship
    if (location.pathname.includes('/records/lenders')) {
        existingRelationship = 'Facility'
    } else if (location.pathname.includes('/records/borrowers')) {
        existingRelationship = 'Facility or Position'
    } else if (location.pathname.includes('/records/facilities')) {
        existingRelationship = 'Position'
    } else if (location.pathname.includes('/records/funds')) {
        existingRelationship = 'Participation'
    } else if (location.pathname.includes('/records/positions')) {
        existingRelationship = 'Drawdown or Holding'
    } else if (location.pathname.includes('/records/bank-accounts')) {
        existingRelationship = 'Facility'
    } else {
        existingRelationship = ''
    }

    return (
        <Dialog
            open={isExistingRelationshipModalOpen}
            onClose={handleCloseExistingRelationshipModal}
        >
            <DialogTitle color='error'>Warning</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Cannot delete! There is a {existingRelationship} associated with this record.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleCloseExistingRelationshipModal}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ExistingRelationshipModal
