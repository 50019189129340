export const assetTypes = ['Term Loans', 'Common Stock', 'Loan Notes']

export const tradeTypes = [
    'Asset / Equipment Based',
    'Goods',
    'Goods to Receivables',
    'Inventory',
    'Processing',
    'Receivables',
    'Working Capital',
]

export const sectors = [
    'Agricultural Commodities',
    'Appliances',
    'Automotive',
    'Aviation',
    'Chemicals',
    'Diversified',
    'Electronics',
    'Energy',
    'Equipment',
    'Furnitures',
    'Healthcare',
    'Industrial Materials',
    'Industrial Metals',
    'Industrial Products',
    'Infrastructure',
    'Manufacturing',
    'Marine Products',
    'Minerals',
    'Mixed Products',
    'Paper',
    'Precious & Minor Metals',
    'Precious Metals',
    'Real Estate',
    'Renewable Energy',
    'Scrap Metals',
    'Shipping',
    'Textiles',
    'Timber',
    'Trade Finance Platform',
]

export const lgtSectors = [
    'Consumer Discretionary',
    'Consumer Staples',
    'Diversified',
    'Energy',
    'Financials',
    'Health Care',
    'Industrials',
    'Infrastructure',
    'Materials',
    'Others',
    'Real Estate',
]
