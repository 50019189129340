import React from 'react'
import { Box } from '@mui/material'
import { SentimentVeryDissatisfiedOutlined } from '@mui/icons-material'
import Header from '../../components/layout/Header'

const PageNotFound = () => {
    return (
        <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            height='85vh'
            textAlign='center'
        >
            <Header
                title={<Box>404 Page not found</Box>}
                subtitle={
                    <>
                        Apologies, but the page you were looking for wasn't found.
                        <br /> Please look for the administrator if you think this is an error.
                    </>
                }
            />
            <SentimentVeryDissatisfiedOutlined style={{ fontSize: '8rem', marginTop: '2rem' }} />
        </Box>
    )
}

export default PageNotFound
