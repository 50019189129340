import React, { useState } from 'react'
import { MenuItem, Box, Chip, Checkbox, ListItemText } from '@mui/material'
import SelectValidated from './SelectValidated'

const UserActionMultiSelect = ({ userActions, onChange, ...otherProps }) => {
    const [selectedUserActions, setSelectedUserActions] = useState([])

    const handleSelectionChange = (event) => {
        const selected = event.target.value

        setSelectedUserActions(selected)
        onChange({ target: { name: 'userActions', value: selected } })
    }

    const handleChipRender = (selectedUserActions) => {
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selectedUserActions.map((action, index) => {
                    return action ? <Chip key={index} label={action} /> : null
                })}
            </Box>
        )
    }

    return (
        <SelectValidated
            multiple
            name='userActions'
            id='userActions'
            value={selectedUserActions}
            onChange={handleSelectionChange}
            renderValue={handleChipRender}
            {...otherProps}
        >
            {userActions.map((userAction) => (
                <MenuItem key={userAction} value={userAction}>
                    <Checkbox checked={selectedUserActions.indexOf(userAction) > -1} />
                    <ListItemText primary={userAction} />
                </MenuItem>
            ))}
        </SelectValidated>
    )
}

export default UserActionMultiSelect
