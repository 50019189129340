import React from 'react'
import { Button } from '@mui/material'
import { useFormikContext } from 'formik'

const LoginButton = ({ values, ...otherProps }) => {
    const { submitForm } = useFormikContext()

    const handleLogin = () => {
        submitForm()
    }

    const configSubmitButton = {
        variant: 'contained',
        color: 'primary',
        onClick: handleLogin,
    }

    return (
        <Button {...configSubmitButton} {...otherProps}>
            Login
        </Button>
    )
}

export default LoginButton
