import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import { EditOutlined } from '@mui/icons-material'
import Header from '../../components/layout/Header'
import CustomToolbar from '../../components/ui/datagrid/CustomToolbar'
import CustomFilterForm from '../../components/ui/datagrid/CustomFilterForm'
import CustomNoRowOverlay from '../../components/ui/datagrid/CustomNoRowOverlay'

import { formatDataGridDate, formatPercent } from '../../utils/helpers/formatValue'

import { selectCurrentUser } from '../../app/api/auth/authSlice'
import { useGetAllParticipationsQuery } from '../../app/api/records/participationsApiSlice'

import UpdateParticipationForm from '../../components/ui/forms/records/UpdateParticipationForm'

const initialParticipationState = {
    positionId: '',
    fundCode: '',
    taId: '',
    fundTaId: '',
    participationRatio: '',
    spreadRate: '',
    drawdownDate: null,
    originalMaturityDate: null,
    approvedMaturityDate: null,
    relationshipType: '',
}

const Participations = () => {
    const { data: initialRows, isLoading } = useGetAllParticipationsQuery()

    const { role } = useSelector(selectCurrentUser)

    const [isFormOpen, setIsFormOpen] = useState(false)
    const [toBeUpdated, setToBeUpdated] = useState(initialParticipationState)
    const [isEditClicked, setIsEditClicked] = useState(false)

    const handleOpenForm = () => {
        setIsFormOpen(true)
    }

    const handleCloseForm = () => {
        setIsFormOpen(false)
        setToBeUpdated(initialParticipationState)
        setIsEditClicked(false)
    }

    const handleEdit = (params) => {
        const { row } = params

        setIsEditClicked(true)
        setToBeUpdated(row)
        handleOpenForm()
    }

    const columns = [
        ...(role === 'Admin' || role === 'Editor'
            ? [
                  {
                      field: 'actions',
                      headerName: 'Actions',
                      type: 'actions',
                      getActions: (params) => {
                          return [
                              <GridActionsCellItem
                                  icon={<EditOutlined />}
                                  label='Edit'
                                  onClick={() => {
                                      handleEdit(params)
                                  }}
                                  color='inherit'
                              />,
                          ]
                      },
                  },
              ]
            : []),
        {
            field: '_id',
            headerName: '_id',
            width: 150,
        },
        {
            field: 'borrowerName',
            headerName: 'Borrower Name',
            width: 200,
        },
        {
            field: 'facilityNumber',
            headerName: 'Facility Number',
            width: 120,
        },
        { field: 'fundCode', headerName: 'Fund Code', width: 100 },
        { field: 'taId', headerName: 'TA ID', width: 220 },
        {
            field: 'participationRatio',
            headerName: 'Participation Ratio',
            width: 90,
            valueFormatter: (params) => formatPercent(params.value),
        },
        {
            field: 'spreadRate',
            headerName: 'Spread Rate',
            width: 90,
            valueFormatter: (params) => formatPercent(params.value),
        },
        {
            field: 'drawdownDate',
            headerName: 'Drawdown Date',
            type: 'date',
            width: 150,
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
        {
            field: 'originalMaturityDate',
            headerName: 'Original Maturity Date',
            type: 'date',
            width: 150,
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
        {
            field: 'approvedMaturityDate',
            headerName: 'Approved Maturity Date',
            type: 'date',
            width: 150,
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
        { field: 'relationshipType', headerName: 'Relationship Type', width: 100 },
        { field: 'isActive', headerName: 'Is Active?', width: 100 },
        {
            field: 'createdBy',
            headerName: 'Created By',
            width: 150,
        },
        {
            field: 'lastUpdatedBy',
            headerName: 'Last Updated By',
            width: 150,
        },
        {
            field: 'validFrom',
            headerName: 'Valid From',
            type: 'date',
            width: 150,
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
    ]

    return (
        <Box>
            <Header
                title='Participations'
                subtitle={
                    <>
                        List of Participations
                        <br />
                        <br />
                        Note:
                        <br />
                        1. Participations can only be deleted by deleting Drawdown or Participation
                        Transactions <a href='/records/transactions'>here</a>.
                        <br />
                        2. Participations can only be created via Drawdown or Participation{' '}
                        <a href='/records/transactions'>here</a>.
                    </>
                }
            />
            <Box
                sx={{
                    m: '1.5rem 2.5rem',
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    height: '80vh',
                    '& .MuiDataGrid-root': {
                        maxWidth: '95vw',
                    },
                }}
            >
                <DataGrid
                    loading={isLoading}
                    getRowId={(row) => row._id}
                    rows={initialRows || []}
                    columns={columns}
                    pageSizeOptions={[20, 50, 100]}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 20 } },
                        columns: {
                            columnVisibilityModel: {
                                // validFrom: false,
                            },
                        },
                        sorting: {
                            sortModel: [{ field: 'facilityNumber', sort: 'asc' }],
                        },
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                        noRowsOverlay: CustomNoRowOverlay,
                    }}
                    slotProps={{
                        filterPanel: {
                            filterFormProps: CustomFilterForm,
                        },
                    }}
                />
            </Box>
            <UpdateParticipationForm
                isFormOpen={isFormOpen}
                handleCloseForm={handleCloseForm}
                toBeUpdated={toBeUpdated}
                isEditClicked={isEditClicked}
                setIsEditClicked={setIsEditClicked}
            />
        </Box>
    )
}

export default Participations
