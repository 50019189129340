import React from 'react'
import { MenuItem } from '@mui/material'
import SelectValidated from './SelectValidated'

const BorrowerSelect = ({
    borrowers,
    active,
    onChange,
    isBorrowerSelectDisabled,
    ...otherProps
}) => {
    const sortedBorrowers = borrowers
        ? borrowers
              .filter((borrower) => !active || borrower.isActive)
              .slice()
              .sort((a, b) => a.borrowerName.localeCompare(b.borrowerName))
        : []
    return (
        <SelectValidated name='borrowerId' id='borrowerId' onChange={onChange} {...otherProps}>
            {isBorrowerSelectDisabled ? (
                <MenuItem value='' disabled>
                    Select Borrower Type first
                </MenuItem>
            ) : sortedBorrowers.length > 0 ? (
                sortedBorrowers.map((borrower) => (
                    <MenuItem key={borrower.borrowerCode} value={borrower._id}>
                        {borrower.borrowerName}
                    </MenuItem>
                ))
            ) : (
                <MenuItem value='' disabled>
                    No Borrowers with selected Type
                </MenuItem>
            )}
        </SelectValidated>
    )
}

export default BorrowerSelect
