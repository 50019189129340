import { apiSlice } from '../apiSlice'
import { logout } from './authSlice'

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        loginUser: build.mutation({
            query: ({ email, password }) => ({
                url: '/auth/login',
                method: 'POST',
                body: { email, password },
            }),
        }),
        logoutUser: build.mutation({
            query: () => ({
                url: '/auth/logout',
                method: 'POST',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(logout())
                    setTimeout(() => {
                        dispatch(apiSlice.util.resetApiState())
                    }, 1000)
                } catch (error) {
                    console.log(error)
                }
            },
        }),
        refreshUser: build.mutation({
            query: () => ({
                url: '/auth/refresh',
                method: 'GET',
            }),
        }),
    }),
})

export const { useLoginUserMutation, useLogoutUserMutation, useRefreshUserMutation } = authApiSlice
