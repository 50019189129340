import { apiSlice } from '../apiSlice'

export const positionsApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getAllPositions: build.query({
            query: () => ({ url: `records/positions`, method: 'GET' }),
            providesTags: ['Positions'],
        }),
        createPosition: build.mutation({
            query: (newRow) => ({
                url: `records/positions`,
                method: 'POST',
                body: newRow,
            }),
            invalidatesTags: ['Positions'],
        }),
        updatePosition: build.mutation({
            query: (updatedRow) => ({
                url: `records/positions`,
                method: 'PUT',
                body: updatedRow,
            }),
            invalidatesTags: ['Positions'],
        }),
        deletePosition: build.mutation({
            query: (id) => ({
                url: `records/positions/${id}`,
                method: 'DELETE',
                body: id,
            }),
            invalidatesTags: ['Positions'],
        }),
    }),
})

export const {
    useCreatePositionMutation,
    useGetAllPositionsQuery,
    useUpdatePositionMutation,
    useDeletePositionMutation,
} = positionsApiSlice
