import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Button,
} from '@mui/material'
import React from 'react'

const LoginFailModal = ({ isLoginFailModalOpen, handleCloseLoginFailModal }) => {
    return (
        <Dialog open={isLoginFailModalOpen} onClose={handleCloseLoginFailModal}>
            <DialogTitle color='error'>Warning</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>Invalid login credentials.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleCloseLoginFailModal}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default LoginFailModal
