import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Grid,
    Divider,
    DialogContentText,
} from '@mui/material'
import dayjs from 'dayjs'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import InputLabelStyled from '../../fields/InputLabelStyled'
import MoneyFieldValidated from '../../fields/MoneyFieldValidated'
import TextFieldValidated from '../../fields/TextFieldValidated'
import DatePickerValidated from '../../fields/DatePickerValidated'
import PreSubmitButton from '../../buttons/PreSubmitButton'

import { selectCurrentUser } from '../../../../app/api/auth/authSlice'
import { formatDatePickerDate } from '../../../../utils/helpers/formatValue'
import { useUpdateTransactionMutation } from '../../../../app/api/records/transactionsApiSlice'

import ConfirmSubmitModal from '../../modals/ConfirmSubmitModal'
import UpdateSuccessModal from '../../modals/UpdateSuccessModal'

const transactionFormValidation = Yup.object().shape({
    transactionDate: Yup.date().nullable().required('Required'),
    transactionType: Yup.string().required('Required'),
    borrowerName: Yup.string().required('Required'),
    facilityNumber: Yup.string().required('Required'),
    lenderCode: Yup.string().required('Required'),
    fundCode: Yup.string().required('Required'),
    taId: Yup.string().required('Required'),
    transactionPrincipal: Yup.number().required('Required'),
    transactionInterest: Yup.number().required('Required'),
    transactionFee: Yup.number().required('Required'),
    remarks: Yup.string(),
})

const TransactionFormContent = ({
    formik,
    handleCloseUpdateTransactionForm,
    isConfirmSubmitModalOpen,
    handleOpenConfirmSubmitModal,
    handleCloseConfirmSubmitModal,
    isUpdateSuccessModalOpen,
    handleCloseUpdateSuccessModal,
}) => {
    return (
        <Form>
            <DialogContent dividers>
                <DialogContentText sx={{ mb: 2 }}>
                    Note:
                    <br />
                    Disabled fields cannot be edited. Delete current Transaction and create new
                    Transaction
                </DialogContentText>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Divider sx={{ mb: '0.5rem' }} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabelStyled htmlFor='transactionDate'>
                            {formik.values.transactionType === 'Drawdown'
                                ? 'Drawdown Date'
                                : 'Transaction Date'}
                        </InputLabelStyled>
                        <DatePickerValidated name='transactionDate' />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabelStyled disabled htmlFor='transactionType'>
                            Transaction Type
                        </InputLabelStyled>
                        <TextFieldValidated disabled name='transactionType' id='transactionType' />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabelStyled disabled htmlFor='borrowerName'>
                            Borrower Name
                        </InputLabelStyled>
                        <TextFieldValidated disabled name='borrowerName' id='borrowerName' />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabelStyled disabled htmlFor='facilityNumber'>
                            Facility Number
                        </InputLabelStyled>
                        <TextFieldValidated disabled name='facilityNumber' id='facilityNumber' />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabelStyled disabled htmlFor='lenderCode'>
                            Lender Code
                        </InputLabelStyled>
                        <TextFieldValidated disabled name='lenderCode' id='lenderCode' />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabelStyled disabled htmlFor='fundCode'>
                            Fund Code
                        </InputLabelStyled>
                        <TextFieldValidated disabled name='fundCode' id='fundCode' />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabelStyled disabled htmlFor='taId'>
                            TA ID
                        </InputLabelStyled>
                        <TextFieldValidated disabled name='taId' id='taId' />
                    </Grid>
                    {formik.values.transactionType === 'Drawdown' ? (
                        <>
                            <Grid item xs={6}>
                                <InputLabelStyled disabled htmlFor='principal'>
                                    Participation Amount
                                </InputLabelStyled>
                                <MoneyFieldValidated
                                    name='transactionPrincipal'
                                    id='transactionPrincipal'
                                    disabled={formik.values.transactionType === 'Drawdown'}
                                />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={4}>
                                <InputLabelStyled
                                    htmlFor='principal'
                                    disabled={formik.values.transactionType === 'Drawdown'}
                                >
                                    Principal
                                </InputLabelStyled>
                                <MoneyFieldValidated
                                    name='transactionPrincipal'
                                    id='transactionPrincipal'
                                    disabled={formik.values.transactionType === 'Drawdown'}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabelStyled htmlFor='interest'>Interest</InputLabelStyled>
                                <MoneyFieldValidated
                                    name='transactionInterest'
                                    id='transactionInterest'
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabelStyled htmlFor='fee'>Fee</InputLabelStyled>
                                <MoneyFieldValidated
                                    name='transactionFee'
                                    id='transactionFee'
                                    disabled={formik.values.transactionType === 'Drawdown'}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <InputLabelStyled htmlFor='remarks'>Remarks</InputLabelStyled>
                        <TextFieldValidated name='remarks' id='remarks' />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleCloseUpdateTransactionForm}>
                    Cancel
                </Button>
                <PreSubmitButton
                    values={{
                        ...formik,
                        values: {
                            ...formik.values,
                            transactionDate: formatDatePickerDate(formik.values.transactionDate),
                        },
                    }}
                    handleOpenConfirmSubmitModal={handleOpenConfirmSubmitModal}
                >
                    Submit
                </PreSubmitButton>
            </DialogActions>
            <ConfirmSubmitModal
                values={{
                    ...formik,
                    values: {
                        ...formik.values,
                        transactionDate: formatDatePickerDate(formik.values.transactionDate),
                    },
                }}
                isConfirmSubmitModalOpen={isConfirmSubmitModalOpen}
                handleCloseConfirmSubmitModal={handleCloseConfirmSubmitModal}
            />
            <UpdateSuccessModal
                isUpdateSuccessModalOpen={isUpdateSuccessModalOpen}
                handleCloseUpdateSuccessModal={handleCloseUpdateSuccessModal}
            />
        </Form>
    )
}

const UpdateTransactionForm = ({
    isUpdateTransactionFormOpen,
    handleCloseUpdateTransactionForm,
    toBeUpdated,
    isEditClicked,
}) => {
    const { firstName, lastName } = useSelector(selectCurrentUser)
    const userFullname = `${firstName} ${lastName}`

    const initialTransactionFormState = {
        transactionDate: dayjs(new Date().toISOString()),
        transactionType: 'Partial Repayment',
        borrowerName: '',
        facilityNumber: '',
        lenderCode: '',
        fundCode: '',
        taId: '',
        transactionPrincipal: 0,
        transactionInterest: 0,
        transactionFee: 0,
        remarks: '',
        lastUpdatedBy: '',
    }

    if (toBeUpdated && isEditClicked) {
        initialTransactionFormState._id = toBeUpdated._id
        initialTransactionFormState.transactionDate = dayjs(new Date(toBeUpdated.transactionDate))
        initialTransactionFormState.transactionType = toBeUpdated.transactionType
        initialTransactionFormState.borrowerName = toBeUpdated.borrowerName
        initialTransactionFormState.facilityNumber = toBeUpdated.facilityNumber
        initialTransactionFormState.lenderCode = toBeUpdated.lenderCode
        initialTransactionFormState.fundCode = toBeUpdated.fundCode
        initialTransactionFormState.taId = toBeUpdated.taId
        initialTransactionFormState.transactionPrincipal = toBeUpdated.transactionPrincipal
        initialTransactionFormState.transactionInterest = toBeUpdated.transactionInterest
        initialTransactionFormState.transactionFee = toBeUpdated.transactionFee
        initialTransactionFormState.remarks = toBeUpdated.remarks
        initialTransactionFormState.lastUpdatedBy = userFullname
    }

    if (!isEditClicked) {
        initialTransactionFormState._id = ''
        initialTransactionFormState.transactionDate = dayjs(new Date().toISOString())
        initialTransactionFormState.transactionType = ''
        initialTransactionFormState.borrowerName = ''
        initialTransactionFormState.facilityNumber = ''
        initialTransactionFormState.lenderCode = ''
        initialTransactionFormState.fundCode = ''
        initialTransactionFormState.taId = ''
        initialTransactionFormState.transactionPrincipal = 0
        initialTransactionFormState.transactionInterest = 0
        initialTransactionFormState.transactionFee = 0
        initialTransactionFormState.remarks = ''
        initialTransactionFormState.lastUpdatedBy = ''
    }

    const [updateTransaction] = useUpdateTransactionMutation()

    const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] = useState(false)
    const [isUpdateSuccessModalOpen, setIsUpdateSuccessModalOpen] = useState(false)

    const handleOpenConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(true)
    }

    const handleCloseConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(false)
    }

    const handleOpenUpdateSuccessModal = () => {
        setIsUpdateSuccessModalOpen(true)
    }

    const handleCloseUpdateSuccessModal = () => {
        setIsUpdateSuccessModalOpen(false)
        handleCloseUpdateTransactionForm()
    }

    const handleUpdateTransactionFormSubmit = async (values) => {
        const {
            _id,
            transactionDate,
            transactionPrincipal,
            transactionInterest,
            transactionFee,
            remarks,
            lastUpdatedBy,
        } = values

        try {
            const response = await updateTransaction({
                _id,
                transactionDate,
                transactionPrincipal,
                transactionInterest,
                transactionFee,
                remarks,
                lastUpdatedBy,
            })

            if (response.data && response.data.newData) {
                handleOpenUpdateSuccessModal()
            }
        } catch (error) {
            console.error('Error updating transaction:', error)
        }
    }
    return (
        <Dialog open={isUpdateTransactionFormOpen} onClose={handleCloseUpdateTransactionForm}>
            <DialogTitle>Update Transaction</DialogTitle>
            <Formik
                initialValues={{ ...initialTransactionFormState }}
                validationSchema={transactionFormValidation}
                onSubmit={(values) => {
                    handleUpdateTransactionFormSubmit(values)
                }}
                validateOnMount={true}
            >
                {(formik) => (
                    <TransactionFormContent
                        formik={formik}
                        handleCloseUpdateTransactionForm={handleCloseUpdateTransactionForm}
                        isConfirmSubmitModalOpen={isConfirmSubmitModalOpen}
                        handleOpenConfirmSubmitModal={handleOpenConfirmSubmitModal}
                        handleCloseConfirmSubmitModal={handleCloseConfirmSubmitModal}
                        isUpdateSuccessModalOpen={isUpdateSuccessModalOpen}
                        handleOpenUpdateSuccessModal={handleOpenUpdateSuccessModal}
                        handleCloseUpdateSuccessModal={handleCloseUpdateSuccessModal}
                    />
                )}
            </Formik>
        </Dialog>
    )
}

export default UpdateTransactionForm
