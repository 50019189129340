import { Add } from '@mui/icons-material'
import { Button, Divider, Grid } from '@mui/material'
import { useTheme } from '@mui/material'
import { GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import ApplyTransactionForm from '../forms/records/ApplyTransactionForm'

import { selectCurrentUser } from '../../../app/api/auth/authSlice'

const TransactionToolbar = ({ isGenerating, selectedRowsData, selectedTransactionDate }) => {
    const [isApplyTransactionFormOpen, setIsApplyTransactionFormOpen] = useState(false)
    const [sums, setSums] = useState({ sumPrincipal: 0, sumInterest: 0, sumFee: 0 })
    const theme = useTheme()
    const { role } = useSelector(selectCurrentUser)

    const handleOpenApplyTransactionForm = () => {
        const sumPrincipal = selectedRowsData.reduce(
            (sum, row) => sum + row.outstandingPrincipal,
            0
        )
        const sumInterest = selectedRowsData.reduce((sum, row) => sum + row.totalInterest, 0)
        const sumFee = selectedRowsData.reduce((sum, row) => sum + row.totalFee, 0)

        setSums({ sumPrincipal, sumInterest, sumFee })
        setIsApplyTransactionFormOpen(true)
    }

    const handleCloseApplyTransactionForm = () => {
        setIsApplyTransactionFormOpen(false)
    }

    // Common props for create form
    const commonProps = {
        isApplyTransactionFormOpen,
        handleCloseApplyTransactionForm,
        sums,
        selectedRowsData,
        selectedTransactionDate,
    }

    return (
        <GridToolbarContainer>
            <ApplyTransactionForm {...commonProps} />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Button
                        disabled={isGenerating}
                        startIcon={<Add />}
                        onClick={() => handleOpenApplyTransactionForm()}
                    >
                        Apply Transaction
                    </Button>
                    <Divider />
                    <Grid item xs={12}>
                        <GridToolbarFilterButton />
                    </Grid>
                </Grid>
            </Grid>
        </GridToolbarContainer>
    )
}

export default TransactionToolbar
