import React from 'react'
import { Button } from '@mui/material'

const PreSubmitButton = ({
    values,
    handleOpenMissingFieldModal,
    handleOpenInvalidDateModal,
    handleOpenConfirmSubmitModal,
    children,
}) => {
    const {
        expiryDate,
        firstDrawdownDate,
        drawdownDate,
        originalMaturityDate,
        approvedMaturityDate,
    } = values.values

    const handlePreSubmit = () => {
        // Duplicate entries are not detected by the frontend validation
        // The duplicate entry modal is triggered by the response from backend

        if (Object.keys(values.errors).length > 0) {
            handleOpenMissingFieldModal()
            return
        }

        // Open confirm submit modal for all other pages
        if (!expiryDate && !originalMaturityDate) {
            handleOpenConfirmSubmitModal({ values, children })
            return
        }
        // Check facility dates
        if (firstDrawdownDate && expiryDate <= firstDrawdownDate) {
            handleOpenInvalidDateModal()
            return
        }
        // Check participation dates
        if (
            drawdownDate &&
            approvedMaturityDate &&
            (originalMaturityDate < drawdownDate || originalMaturityDate > approvedMaturityDate)
        ) {
            handleOpenInvalidDateModal()
            return
        }
        // If all validations pass, open the confirm modal
        handleOpenConfirmSubmitModal({ values, children })
    }

    const configPreSubmitButton = {
        variant: 'contained',
        color: 'primary',
        onClick: handlePreSubmit,
    }
    return <Button {...configPreSubmitButton}>{children}</Button>
}

export default PreSubmitButton
