import React, { useRef } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Button, CircularProgress, Grid } from '@mui/material'

import InputLabelStyled from '../../fields/InputLabelStyled'
import DatePickerValidated from '../../fields/DatePickerValidated'
import { formatDatePickerDate } from '../../../../utils/helpers/formatValue'

const transactionFilterFormValidation = Yup.object().shape({
    transactionFromDate: Yup.date().nullable().required('Required'),
    transactionToDate: Yup.date().nullable().required('Required'),
})

const TransactionsFilterForm = ({
    onSubmit,
    isFetching,
    initialTransactionFilterFormState,
    handleOpenInvalidDateModal,
}) => {
    const formikRef = useRef()

    return (
        <Formik
            initialValues={{ ...initialTransactionFilterFormState }}
            validationSchema={transactionFilterFormValidation}
            onSubmit={(values) => {
                if (values.transactionFromDate < values.transactionToDate) {
                    const formattedValues = {
                        ...values,
                        transactionFromDate: formatDatePickerDate(values.transactionFromDate),
                        transactionToDate: formatDatePickerDate(values.transactionToDate),
                    }
                    onSubmit(formattedValues)
                } else {
                    handleOpenInvalidDateModal()
                }
            }}
            validateOnMount={true}
            innerRef={formikRef}
        >
            {(formik) => (
                <Form>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <InputLabelStyled htmlFor='transactionFromDate'>
                                Transactions From
                            </InputLabelStyled>
                            <DatePickerValidated name='transactionFromDate' />
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabelStyled htmlFor='transactionToDate'>
                                Transactions To
                            </InputLabelStyled>
                            <DatePickerValidated name='transactionToDate' />
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            {isFetching ? (
                                <Button
                                    disabled
                                    variant='contained'
                                    endIcon={
                                        <CircularProgress
                                            size={16}
                                            sx={{
                                                color: 'inherit',
                                                height: '16px',
                                                width: '16px',
                                            }}
                                        />
                                    }
                                >
                                    Filter
                                </Button>
                            ) : (
                                <Button
                                    variant='contained'
                                    onClick={formik.submitForm}
                                    disabled={
                                        !formik.values.transactionFromDate ||
                                        !formik.values.transactionToDate
                                    }
                                >
                                    Filter
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}

export default TransactionsFilterForm
