import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Header from '../../components/layout/Header'
import { Box, Tab, Tabs } from '@mui/material'

import { selectCurrentUser } from '../../app/api/auth/authSlice'

import ManageTransactionTab from '../../components/tabs/ManageTransactionTab'
import ApplyTransactionTab from '../../components/tabs/ApplyTransactionTab'
import CreateTransactionTab from '../../components/tabs/CreateTransactionTab'

const Transactions = () => {
    const { role } = useSelector(selectCurrentUser)

    const [tabIndex, setTabIndex] = useState(0)

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue)
    }

    const isEditorOrAdmin = role === 'Admin' || role === 'Editor'
    const tabs = isEditorOrAdmin ? ['Manage', 'Apply', 'Create'] : ['View']

    return (
        <Box>
            <Header title='Transactions' />
            <Box
                sx={{
                    mt: '-1rem',
                    ml: '2.5rem',
                    mr: '2.5rem',
                }}
            >
                <Tabs value={tabIndex} onChange={handleTabChange}>
                    {tabs.map((tab, index) => (
                        <Tab key={index} label={tab} />
                    ))}
                </Tabs>
            </Box>
            {isEditorOrAdmin ? (
                <>
                    {tabIndex === 0 && <ManageTransactionTab />}
                    {tabIndex === 1 && <ApplyTransactionTab />}
                    {tabIndex === 2 && <CreateTransactionTab />}
                </>
            ) : (
                tabIndex === 0 && <ManageTransactionTab />
            )}
        </Box>
    )
}

export default Transactions
