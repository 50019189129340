import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Button,
    Divider,
} from '@mui/material'

import InputLabelStyled from '../../fields/InputLabelStyled'
import TextFieldValidated from '../../fields/TextFieldValidated'
import PreSubmitButton from '../../buttons/PreSubmitButton'

import { selectCurrentUser } from '../../../../app/api/auth/authSlice'

import { useUpdateBankAccountMutation } from '../../../../app/api/records/bankAccountsApiSlice'

import DuplicateErrorModal from '../../modals/DuplicateErrorModal'
import ConfirmSubmitModal from '../../modals/ConfirmSubmitModal'
import UpdateSuccessModal from '../../modals/UpdateSuccessModal'
import MissingFieldModal from '../../modals/MissingFieldModal'

const initialBankAccountFormState = {
    beneficiaryName: '',
    accountNumber: '',
    bankName: '',
    bankAddress: '',
    swiftCode: '',
    reference: '',
    lastUpdatedBy: '',
}

const UpdateBankAccountForm = ({ isFormOpen, handleCloseForm, toBeUpdated, isEditClicked }) => {
    const { firstName, lastName } = useSelector(selectCurrentUser)
    const userFullname = `${firstName} ${lastName}`

    const [updateBankAccount] = useUpdateBankAccountMutation()

    const [duplicateEntry, setDuplicateEntry] = useState([])

    const [isDuplicateErrorModalOpen, setIsDuplicateErrorModalOpen] = useState(false)
    const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] = useState(false)
    const [isUpdateSuccessModalOpen, setIsUpdateSuccessModalOpen] = useState(false)
    const [isMissingFieldModalOpen, setIsMissingFieldModalOpen] = useState(false)

    const formikRef = useRef()

    const handleOpenDuplicateErrorModal = ({ duplicateData }) => {
        setIsDuplicateErrorModalOpen(true)
        setDuplicateEntry(duplicateData)
    }

    const handleCloseDuplicateErrorModal = () => {
        setIsDuplicateErrorModalOpen(false)
    }

    const handleOpenConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(true)
    }

    const handleCloseConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(false)
    }

    const handleOpenUpdateSuccessModal = () => {
        setIsUpdateSuccessModalOpen(true)
    }

    const handleCloseUpdateSuccessModal = () => {
        setIsUpdateSuccessModalOpen(false)
        handleCloseForm()
    }

    const handleOpenMissingFieldModal = () => {
        setIsMissingFieldModalOpen(true)
    }

    const handleCloseMissingFieldModal = () => {
        setIsMissingFieldModalOpen(false)
    }

    if (toBeUpdated && isEditClicked) {
        initialBankAccountFormState._id = toBeUpdated._id
        initialBankAccountFormState.beneficiaryName = toBeUpdated.beneficiaryName
        initialBankAccountFormState.accountNumber = toBeUpdated.accountNumber
        initialBankAccountFormState.bankName = toBeUpdated.bankName
        initialBankAccountFormState.bankAddress = toBeUpdated.bankAddress
        initialBankAccountFormState.swiftCode = toBeUpdated.swiftCode
        initialBankAccountFormState.reference = toBeUpdated.reference
        initialBankAccountFormState.lastUpdatedBy = userFullname
    }

    if (!isEditClicked) {
        initialBankAccountFormState._id = ''
        initialBankAccountFormState.beneficiaryName = ''
        initialBankAccountFormState.accountNumber = ''
        initialBankAccountFormState.bankName = ''
        initialBankAccountFormState.bankAddress = ''
        initialBankAccountFormState.swiftCode = ''
        initialBankAccountFormState.reference = ''
        initialBankAccountFormState.lastUpdatedBy = ''
    }

    const handleBankAccountFormSubmit = async (values) => {
        const {
            _id,
            beneficiaryName,
            accountNumber,
            bankName,
            bankAddress,
            swiftCode,
            reference,
            lastUpdatedBy,
        } = values

        try {
            const response = await updateBankAccount({
                _id,
                beneficiaryName,
                accountNumber,
                bankName,
                bankAddress,
                swiftCode,
                reference,
                lastUpdatedBy,
            })

            if (response.error && response.error.data && response.error.data.duplicateData) {
                const { error, duplicateData, newData } = response.error.data
                handleOpenDuplicateErrorModal({ duplicateData })
            } else if (response.data && response.data.newData) {
                const { error, duplicateData, newData } = response.data
                handleOpenUpdateSuccessModal()
            }
        } catch (error) {
            console.error('Error updating bankAccount:', error)
        }
    }

    const bankAccountFormValidation = Yup.object().shape({
        beneficiaryName: Yup.string().required('Required'),
        accountNumber: Yup.string().required('Required'),
        bankName: Yup.string().required('Required'),
        bankAddress: Yup.string().required('Required'),
        swiftCode: Yup.string().required('Required'),
        reference: Yup.string(),
    })

    const handleBankAccountsFormClose = () => {
        handleCloseForm()
        if (formikRef.current) {
            formikRef.current.resetForm()
        }
    }

    return (
        <Dialog open={isFormOpen} onClose={handleBankAccountsFormClose}>
            <DialogTitle>Update Bank Account</DialogTitle>
            <Formik
                initialValues={{ ...initialBankAccountFormState }}
                validationSchema={bankAccountFormValidation}
                onSubmit={(values) => {
                    handleBankAccountFormSubmit(values)
                }}
                validateOnMount={true}
                innerRef={formikRef}
            >
                {(formik) => (
                    <Form>
                        <DialogContent dividers>
                            <DialogContentText sx={{ mb: 2 }}>
                                Please fill in details of the Updated Bank Account. Ensure that
                                Account Number is not duplicated.
                            </DialogContentText>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='beneficiaryName'>
                                        Beneficiary Name *
                                    </InputLabelStyled>
                                    <TextFieldValidated
                                        name='beneficiaryName'
                                        id='beneficiaryName'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='accountNumber'>
                                        Account Number *
                                    </InputLabelStyled>
                                    <TextFieldValidated name='accountNumber' id='accountNumber' />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='bankName'>
                                        Bank Name *
                                    </InputLabelStyled>
                                    <TextFieldValidated name='bankName' id='bankName' />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='bankAddress'>
                                        Bank Address *
                                    </InputLabelStyled>
                                    <TextFieldValidated name='bankAddress' id='bankAddress' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='swiftCode'>
                                        SWIFT Code *
                                    </InputLabelStyled>
                                    <TextFieldValidated name='swiftCode' id='swiftCode' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='reference'>
                                        Reference
                                    </InputLabelStyled>
                                    <TextFieldValidated name='reference' id='reference' />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' onClick={handleBankAccountsFormClose}>
                                Cancel
                            </Button>
                            <PreSubmitButton
                                values={formik}
                                handleOpenMissingFieldModal={handleOpenMissingFieldModal}
                                handleOpenConfirmSubmitModal={handleOpenConfirmSubmitModal}
                            >
                                Submit
                            </PreSubmitButton>
                        </DialogActions>
                        <DuplicateErrorModal
                            duplicateEntry={duplicateEntry}
                            values={formik}
                            isDuplicateErrorModalOpen={isDuplicateErrorModalOpen}
                            handleCloseDuplicateErrorModal={handleCloseDuplicateErrorModal}
                        />
                        <ConfirmSubmitModal
                            values={formik}
                            isConfirmSubmitModalOpen={isConfirmSubmitModalOpen}
                            handleCloseConfirmSubmitModal={handleCloseConfirmSubmitModal}
                        />
                        <UpdateSuccessModal
                            isUpdateSuccessModalOpen={isUpdateSuccessModalOpen}
                            handleCloseUpdateSuccessModal={handleCloseUpdateSuccessModal}
                        />
                        <MissingFieldModal
                            isMissingFieldModalOpen={isMissingFieldModalOpen}
                            handleCloseMissingFieldModal={handleCloseMissingFieldModal}
                        />
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default UpdateBankAccountForm
