import { apiSlice } from '../apiSlice'

export const holdingsApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getAsOfDateHoldings: build.query({
            query: (filterParams) => ({
                url: `reports/holdings`,
                method: 'GET',
                params: filterParams,
            }),
            providesTags: ['Holdings'],
        }),
        getTransactionHoldings: build.query({
            query: (filterParams) => ({
                url: `reports/holdings/transactions`,
                method: 'GET',
                params: filterParams,
            }),
            providesTags: ['Holdings'],
        }),
    }),
})

export const {
    // useCreateHoldingMutation,
    useGetAsOfDateHoldingsQuery,
    useGetTransactionHoldingsQuery,
} = holdingsApiSlice
