import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { useField } from 'formik'

const NumberFieldValidated = ({ name, ...otherProps }) => {
    const [customError, setCustomError] = useState(null)
    const [field, meta, helpers] = useField(name)

    const handleInputChange = (event) => {
        const inputValue = event.target.value

        if (inputValue.trim() === '') {
            setCustomError(null)
            helpers.setValue('')
        } else if (!isNaN(inputValue)) {
            setCustomError(null)
            helpers.setValue(inputValue)
        } else {
            setCustomError('Enter a Numeric Value')
            helpers.setValue('')
        }
    }

    const configNumberField = {
        ...field,
        ...otherProps,
        fullWidth: true,
        variant: 'outlined',
        size: 'small',
        onChange: handleInputChange,
    }

    if (meta && meta.touched) {
        if (field.value === undefined || field.value === null || field.value === '') {
            configNumberField.error = true
            configNumberField.helperText = 'Required'
        } else if (customError) {
            configNumberField.error = true
            configNumberField.helperText = customError
        } else if (meta.error) {
            configNumberField.error = true
            configNumberField.helperText = meta.error
        }
    }

    return <TextField {...configNumberField} />
}

export default NumberFieldValidated
