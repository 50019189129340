import React from 'react'
import { Page, Image, Text, View, Link, Document, StyleSheet, Font } from '@react-pdf/renderer'
import logo_light from '../../assets/images/logo_light.png'
import RobotoRegular from '../../assets/fonts/Roboto-Regular.ttf'
import RobotoBold from '../../assets/fonts/Roboto-Bold.ttf'

import { formatDataGridDate, formatMoney, formatPercent } from '../../utils/helpers/formatValue'
import dayjs from 'dayjs'

Font.register({
    family: 'Roboto',
    fonts: [
        {
            src: RobotoRegular,
            fontWeight: 'normal',
        },
        {
            src: RobotoBold,
            fontWeight: 'bold',
        },
    ],
})

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        fontFamily: 'Roboto',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 16,
        right: 32,
        textAlign: 'right',
        color: 'grey',
    },
    section: {
        margin: 32,
        flexGrow: 1,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    logo: {
        width: 74,
        height: 66,
    },
    title: {
        fontSize: 12,
        justifyContent: 'flex-end',
        marginBottom: 4,
        fontWeight: 'bold',
    },
    subtitle: {
        fontWeight: 'bold',
        fontSize: 12,
        marginTop: 12,
        marginBottom: 4,
    },
    table: {
        display: 'table',
        width: 'auto',
        marginTop: 8,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: '#BDBDBD',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#BDBDBD',
        borderBottomStyle: 'solid',
    },
    tableCol: {
        padding: 4,
        borderRightWidth: 1,
        borderRightColor: '#BDBDBD',
        borderRightStyle: 'solid',
    },
    tableRightCol: {
        padding: 4,
    },
    tableCell: {
        fontSize: 10,
        color: '#212121',
        textAlign: 'left',
    },
    headerRow: {
        backgroundColor: '#E0E0E0',
    },
    headerCell: {
        fontSize: 10,
        fontWeight: 'bold',
        color: '#212121',
    },
    footerRow: {
        flexDirection: 'row',
    },
    footerCell: {
        fontSize: 10,
        color: '#212121',
        borderBottomWidth: 1,
        borderBottomColor: '#1C1C1C',
        borderBottomStyle: 'solid',
        paddingBottom: 4,
    },
    colTaId: { width: '23 %' },
    colDrawdownDate: { width: '10%' },
    colApprovedMaturityDate: { width: '10%' },
    colCurrency: { width: '6%' },
    colEffectiveInterestRate: { width: '7%' },
    colOutstandingPrincipal: { width: '11%' },
    colTotalInterest: { width: '11%' },
    colTotalFee: { width: '11%' },
    colNetDue: { width: '11%' },
    rowLabel: { width: '12%' },
    rowContent: { width: '88%' },
    limitTable: {
        display: 'table',
        width: 'auto',
        marginTop: 8,
    },
    limitTableRow: {
        flexDirection: 'row',
    },
    limitTableCol: {
        marginTop: 4,
        marginRight: 4,
        marginBottom: 4,
    },
    limitTableCell: {
        fontSize: 10,
        color: '#212121',
        textAlign: 'left',
    },
    disclaimer: {
        marginTop: 8,
        fontSize: 10,
        textAlign: 'left',
        color: '#212121',
    },
})

const StatementOfAccountPDF = ({ data }) => {
    const { statementOfAccountArray, statementOfAccountSummary } = data

    const {
        facilityOutstandingPrincipal,
        facilityTotalInterest,
        facilityTotalFee,
        facilityNetDue,
        asOfDate,
        lenderName,
        borrowerName,
        facilityNumber,
        facilityLegalId,
        facilityLimit,
        availableLimit,
        beneficiaryName,
        accountNumber,
        bankName,
        bankAddress,
        swiftCode,
        reference,
    } = statementOfAccountSummary

    const asOfDateFormatted = statementOfAccountSummary.asOfDate
        ? dayjs(asOfDate).format('DD MMM YYYY')
        : ''
    const fileNameDate = statementOfAccountSummary.asOfDate
        ? dayjs(asOfDate).format('YYYY-MM-DD')
        : ''

    const TableRow = ({ item, formatDataGridDate, formatPercent, formatMoney, styles }) => (
        <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.colTaId]}>
                <Text style={styles.tableCell}>{item['taId']}</Text>
            </View>
            <View style={[styles.tableCol, styles.colDrawdownDate]}>
                <Text style={styles.tableCell}>{formatDataGridDate(item['drawdownDate'])}</Text>
            </View>
            <View style={[styles.tableCol, styles.colApprovedMaturityDate]}>
                <Text style={styles.tableCell}>
                    {formatDataGridDate(item['approvedMaturityDate'])}
                </Text>
            </View>
            <View style={[styles.tableCol, styles.colCurrency]}>
                <Text style={styles.tableCell}>{item['currency']}</Text>
            </View>
            <View style={[styles.tableCol, styles.colEffectiveInterestRate]}>
                <Text style={styles.tableCell}>{formatPercent(item['effectiveInterestRate'])}</Text>
            </View>
            <View style={[styles.tableCol, styles.colOutstandingPrincipal]}>
                <Text style={styles.tableCell}>{formatMoney(item['sumOutstandingPrincipal'])}</Text>
            </View>
            <View style={[styles.tableCol, styles.colTotalInterest]}>
                <Text style={styles.tableCell}>{formatMoney(item['sumTotalInterest'])}</Text>
            </View>
            <View style={[styles.tableCol, styles.colTotalFee]}>
                <Text style={styles.tableCell}>{formatMoney(item['sumTotalFee'])}</Text>
            </View>
            <View style={[styles.tableRightCol, styles.colNetDue]}>
                <Text style={styles.tableCell}>{formatMoney(item['sumNetDue'])}</Text>
            </View>
        </View>
    )

    const FooterRow = ({
        facilityOutstandingPrincipal,
        facilityTotalInterest,
        facilityTotalFee,
        facilityNetDue,
        formatMoney,
        styles,
    }) => (
        <View style={styles.footerRow}>
            <View style={[styles.tableCol, styles.colTaId]}>
                <Text style={[styles.tableCell, { fontWeight: 'bold' }]}>{'Grand Total'}</Text>
            </View>
            <View style={[styles.tableCol, styles.colDrawdownDate]}>
                <Text style={styles.tableCell}>{''}</Text>
            </View>
            <View style={[styles.tableCol, styles.colApprovedMaturityDate]}>
                <Text style={styles.tableCell}>{''}</Text>
            </View>
            <View style={[styles.tableCol, styles.colCurrency]}>
                <Text style={styles.tableCell}>{''}</Text>
            </View>
            <View style={[styles.tableCol, styles.colEffectiveInterestRate]}>
                <Text style={styles.tableCell}>{''}</Text>
            </View>
            <View style={[styles.tableCol, styles.colOutstandingPrincipal]}>
                <Text style={styles.footerCell}>{formatMoney(facilityOutstandingPrincipal)}</Text>
            </View>
            <View style={[styles.tableCol, styles.colTotalInterest]}>
                <Text style={styles.footerCell}>{formatMoney(facilityTotalInterest)}</Text>
            </View>
            <View style={[styles.tableCol, styles.colTotalFee]}>
                <Text style={styles.footerCell}>{formatMoney(facilityTotalFee)}</Text>
            </View>
            <View style={[styles.tableRightCol, styles.colNetDue]}>
                <Text style={styles.footerCell}>{formatMoney(facilityNetDue)}</Text>
            </View>
        </View>
    )

    const HeaderRow = ({ styles }) => (
        <View style={[styles.tableRow, styles.headerRow]}>
            <View style={[styles.tableCol, styles.colTaId]}>
                <Text style={styles.headerCell}>TA ID</Text>
            </View>
            <View style={[styles.tableCol, styles.colDrawdownDate]}>
                <Text style={styles.headerCell}>Drawdown Date</Text>
            </View>
            <View style={[styles.tableCol, styles.colApprovedMaturityDate]}>
                <Text style={styles.headerCell}>Maturity Date</Text>
            </View>
            <View style={[styles.tableCol, styles.colCurrency]}>
                <Text style={styles.headerCell}>CCY</Text>
            </View>
            <View style={[styles.tableCol, styles.colEffectiveInterestRate]}>
                <Text style={styles.headerCell}>Interest Rate</Text>
            </View>
            <View style={[styles.tableCol, styles.colOutstandingPrincipal]}>
                <Text style={styles.headerCell}>Outstanding Principal</Text>
            </View>
            <View style={[styles.tableCol, styles.colTotalInterest]}>
                <Text style={styles.headerCell}>Total Interest</Text>
            </View>
            <View style={[styles.tableCol, styles.colTotalFee]}>
                <Text style={styles.headerCell}>Total Fee</Text>
            </View>
            <View style={[styles.tableRightCol, styles.colNetDue]}>
                <Text style={styles.headerCell}>Net Due</Text>
            </View>
        </View>
    )

    const numberOfRows = statementOfAccountArray.length

    let statementOfAccountPage1 = []
    let statementOfAccountPage2 = []
    if (numberOfRows > 17) {
        statementOfAccountPage1 = statementOfAccountArray.slice(0, 17)
        statementOfAccountPage2 = statementOfAccountArray.slice(17, numberOfRows)
    }

    return (
        <Document
            title={`SOA_${facilityNumber}_${fileNameDate}`}
            author='Zong Han Lee'
            creator='Zong Han Lee'
        >
            <Page size='A4' orientation='landscape' style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.header}>
                        <Image src={logo_light} style={styles.logo} />
                        <View style={{ alignItems: 'flex-end', width: '100%' }}>
                            <Text style={[styles.title, { textAlign: 'right' }]}>
                                {`Statement of Account`}
                            </Text>
                            <Text style={[styles.title, { textAlign: 'right' }]}>
                                {`Snapshot as on ${asOfDateFormatted}`}
                            </Text>
                        </View>
                    </View>

                    <Text style={styles.subtitle}>
                        {`${lenderName} - ${borrowerName} - ${facilityLegalId}`}
                    </Text>

                    {numberOfRows <= 17 ? (
                        <View style={styles.table}>
                            <HeaderRow styles={styles} />
                            {statementOfAccountArray.map((item, index) => (
                                <TableRow
                                    key={index}
                                    item={item}
                                    formatDataGridDate={formatDataGridDate}
                                    formatPercent={formatPercent}
                                    formatMoney={formatMoney}
                                    styles={styles}
                                />
                            ))}
                            <FooterRow
                                facilityOutstandingPrincipal={facilityOutstandingPrincipal}
                                facilityTotalInterest={facilityTotalInterest}
                                facilityTotalFee={facilityTotalFee}
                                facilityNetDue={facilityNetDue}
                                formatMoney={formatMoney}
                                styles={styles}
                            />
                        </View>
                    ) : numberOfRows > 17 ? (
                        <>
                            <View style={styles.table}>
                                <HeaderRow styles={styles} />
                                {statementOfAccountPage1.map((item, index) => (
                                    <TableRow
                                        key={index}
                                        item={item}
                                        formatDataGridDate={formatDataGridDate}
                                        formatPercent={formatPercent}
                                        formatMoney={formatMoney}
                                        styles={styles}
                                    />
                                ))}
                            </View>
                            <Text
                                style={[styles.disclaimer, { marginTop: 8 }]}
                            >{`Continued on next page.`}</Text>
                            <View style={[styles.table, { marginTop: 20 }]} wrap={false}>
                                <HeaderRow styles={styles} />
                                {statementOfAccountPage2.map((item, index) => (
                                    <TableRow
                                        key={index}
                                        item={item}
                                        formatDataGridDate={formatDataGridDate}
                                        formatPercent={formatPercent}
                                        formatMoney={formatMoney}
                                        styles={styles}
                                    />
                                ))}
                                <FooterRow
                                    facilityOutstandingPrincipal={facilityOutstandingPrincipal}
                                    facilityTotalInterest={facilityTotalInterest}
                                    facilityTotalFee={facilityTotalFee}
                                    facilityNetDue={facilityNetDue}
                                    formatMoney={formatMoney}
                                    styles={styles}
                                />
                            </View>
                        </>
                    ) : null}

                    <View style={styles.limitTable} wrap={false}>
                        <View style={styles.limitTableRow}>
                            <View style={[styles.limitTableCol, styles.rowLabel]}>
                                <Text style={styles.headerCell}>Facility Limit:</Text>
                            </View>
                            <View style={[styles.limitTableCol, styles.rowContent]}>
                                <Text style={styles.tableCell}>{formatMoney(facilityLimit)}</Text>
                            </View>
                        </View>
                        <View style={styles.limitTableRow}>
                            <View style={[styles.limitTableCol, styles.rowLabel]}>
                                <Text style={styles.headerCell}>Available Limit:</Text>
                            </View>
                            <View style={[styles.limitTableCol, styles.rowContent]}>
                                {availableLimit < 0 ? (
                                    <Text style={[styles.tableCell, { color: 'red' }]}>
                                        {formatMoney(availableLimit)}
                                    </Text>
                                ) : (
                                    <Text style={styles.tableCell}>
                                        {formatMoney(availableLimit)}
                                    </Text>
                                )}
                            </View>
                        </View>
                    </View>

                    {beneficiaryName ? (
                        <>
                            <View style={styles.disclaimer} wrap={false}>
                                <Text
                                    style={{
                                        fontSize: 10,
                                        fontWeight: 'bold',
                                        color: 'red',
                                        marginTop: 8,
                                    }}
                                >
                                    {
                                        'STATEMENTS ARE DEEMED CORRECT UNLESS QUERIED WITHIN 14 DAYS OF ISSUANCE'
                                    }
                                </Text>
                                <Text style={{ marginTop: 8 }}>
                                    {`For repaying loans of ${lenderName}, please remit to your Primary Account among the accounts below.`}
                                </Text>
                                <Text
                                    style={{ marginTop: 16, fontWeight: 'bold' }}
                                >{`Disclaimer`}</Text>
                                <Text style={{ marginTop: 8 }}>
                                    {`The below accounts are the only accounts owned by ${lenderName}. If you receive any e-mail requesting that you transfer any amount to an account other than an account specified below, please do not comply with such e-mail and call your Relationship Manager.`}
                                </Text>
                                <Text style={{ marginTop: 8 }}>
                                    {`We shall not be liable for any funds transferred to an account other than an account specified below, including without limitation in reliance on any e-mail purporting to be from ${lenderName}.`}
                                </Text>
                                <Text style={{ marginTop: 8 }}>
                                    {`If you require any clarification on our bank account details, please contact your Relationship Manager or `}
                                    <Link style={{ color: '#212121' }}>
                                        operations@taprivatecapital.com
                                    </Link>
                                    {`.`}
                                </Text>
                            </View>

                            <View style={styles.disclaimer} wrap={false}>
                                <Text
                                    style={{ marginTop: 16, fontWeight: 'bold' }}
                                >{`Primary Account`}</Text>
                                <View style={styles.limitTable} wrap={false}>
                                    <View style={styles.limitTableRow}>
                                        <View style={[styles.limitTableCol, styles.rowLabel]}>
                                            <Text style={styles.headerCell}>Beneficiary Name:</Text>
                                        </View>
                                        <View style={[styles.limitTableCol, styles.rowContent]}>
                                            <Text style={styles.tableCell}>{beneficiaryName}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.limitTableRow}>
                                        <View style={[styles.limitTableCol, styles.rowLabel]}>
                                            <Text style={styles.headerCell}>Account Number:</Text>
                                        </View>
                                        <View style={[styles.limitTableCol, styles.rowContent]}>
                                            <Text style={styles.tableCell}>{accountNumber}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.limitTableRow}>
                                        <View style={[styles.limitTableCol, styles.rowLabel]}>
                                            <Text style={styles.headerCell}>Bank Name:</Text>
                                        </View>
                                        <View style={[styles.limitTableCol, styles.rowContent]}>
                                            <Text style={styles.tableCell}>{bankName}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.limitTableRow}>
                                        <View style={[styles.limitTableCol, styles.rowLabel]}>
                                            <Text style={styles.headerCell}>Bank Address:</Text>
                                        </View>
                                        <View style={[styles.limitTableCol, styles.rowContent]}>
                                            <Text style={styles.tableCell}>{bankAddress}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.limitTableRow}>
                                        <View style={[styles.limitTableCol, styles.rowLabel]}>
                                            <Text style={styles.headerCell}>SWIFT Code:</Text>
                                        </View>
                                        <View style={[styles.limitTableCol, styles.rowContent]}>
                                            <Text style={styles.tableCell}>{swiftCode}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.limitTableRow}>
                                        <View style={[styles.limitTableCol, styles.rowLabel]}>
                                            <Text style={styles.headerCell}>Reference:</Text>
                                        </View>
                                        <View style={[styles.limitTableCol, styles.rowContent]}>
                                            <Text style={styles.tableCell}>
                                                {reference ? reference : '-'}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </>
                    ) : (
                        <>
                            <View style={styles.disclaimer} wrap={false}>
                                <Text
                                    style={{
                                        fontSize: 10,
                                        fontWeight: 'bold',
                                        color: 'red',
                                        marginTop: 8,
                                    }}
                                >
                                    {
                                        'STATEMENTS ARE DEEMED CORRECT UNLESS QUERIED WITHIN 14 DAYS OF ISSUANCE'
                                    }
                                </Text>
                                <Text style={{ marginTop: 8 }}>
                                    {`If you require any clarification, please contact your Relationship Manager or operations@taprivatecapital.com.`}
                                </Text>
                            </View>
                        </>
                    )}
                </View>
                <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                    fixed // Ensures the page number remains on every page
                />
            </Page>
        </Document>
    )
}

export default StatementOfAccountPDF
