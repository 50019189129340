import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Button,
} from '@mui/material'
import TextFieldValidated from '../../fields/TextFieldValidated'
import InputLabelStyled from '../../fields/InputLabelStyled'
import PreSubmitButton from '../../buttons/PreSubmitButton'

import { selectCurrentUser } from '../../../../app/api/auth/authSlice'
import { useCreateFundMutation } from '../../../../app/api/records/fundsApiSlice'
import DuplicateErrorModal from '../../modals/DuplicateErrorModal'
import ConfirmSubmitModal from '../../modals/ConfirmSubmitModal'
import CreateSuccessModal from '../../modals/CreateSuccessModal'
import MissingFieldModal from '../../modals/MissingFieldModal'

const fundFormValidation = Yup.object().shape({
    fundCode: Yup.string().required('Required'),
    fundName: Yup.string().required('Required'),
})

const CreateFundForm = ({ isFormOpen, handleCloseForm }) => {
    const { firstName, lastName } = useSelector(selectCurrentUser)
    const userFullname = `${firstName} ${lastName}`

    const initialFundFormState = {
        fundCode: '',
        fundName: '',
        createdBy: userFullname,
    }

    const [createFund] = useCreateFundMutation()

    const [duplicateEntry, setDuplicateEntry] = useState([])

    const [isDuplicateErrorModalOpen, setIsDuplicateErrorModalOpen] = useState(false)
    const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] = useState(false)
    const [isCreateSuccessModalOpen, setIsCreateSuccessModalOpen] = useState(false)
    const [isMissingFieldModalOpen, setIsMissingFieldModalOpen] = useState(false)

    const handleOpenDuplicateErrorModal = ({ existingData }) => {
        setIsDuplicateErrorModalOpen(true)
        setDuplicateEntry(existingData)
    }

    const handleCloseDuplicateErrorModal = () => {
        setIsDuplicateErrorModalOpen(false)
    }

    const handleOpenConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(true)
    }

    const handleCloseConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(false)
    }

    const handleOpenCreateSuccessModal = () => {
        setIsCreateSuccessModalOpen(true)
    }

    const handleCloseCreateSuccessModal = () => {
        setIsCreateSuccessModalOpen(false)
        handleCloseForm()
    }

    const handleOpenMissingFieldModal = () => {
        setIsMissingFieldModalOpen(true)
    }

    const handleCloseMissingFieldModal = () => {
        setIsMissingFieldModalOpen(false)
    }

    const handleFundFormSubmit = async (values) => {
        const { fundCode, fundName, createdBy } = values

        try {
            const response = await createFund({ fundCode, fundName, createdBy })

            if (response.error && response.error.data && response.error.data.existingData) {
                const { error, existingData, newData } = response.error.data

                handleOpenDuplicateErrorModal({ existingData })
            } else if (response.data && response.data.newData) {
                const { error, existingData, newData } = response.data

                handleOpenCreateSuccessModal()
            }
        } catch (error) {
            console.error('Error creating fund:', error)
        }
    }

    return (
        <Dialog open={isFormOpen} onClose={handleCloseForm}>
            <DialogTitle>Create New Fund</DialogTitle>
            <Formik
                initialValues={{ ...initialFundFormState }}
                validationSchema={fundFormValidation}
                onSubmit={(values) => {
                    handleFundFormSubmit(values)
                }}
                validateOnMount={true}
            >
                {(values) => (
                    <Form>
                        <DialogContent dividers>
                            <DialogContentText sx={{ mb: 2 }}>
                                Please fill in details of the New Fund. Ensure that both Fund Code
                                and Fund Name are not duplicated.
                            </DialogContentText>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='fundCode'>
                                        Fund Code *
                                    </InputLabelStyled>
                                    <TextFieldValidated name='fundCode' id='fundCode' />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='fundName'>
                                        Fund Name *
                                    </InputLabelStyled>
                                    <TextFieldValidated name='fundName' id='fundName' />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' onClick={handleCloseForm}>
                                Cancel
                            </Button>
                            <PreSubmitButton
                                values={values}
                                handleOpenMissingFieldModal={handleOpenMissingFieldModal}
                                handleOpenConfirmSubmitModal={handleOpenConfirmSubmitModal}
                            >
                                Submit
                            </PreSubmitButton>
                        </DialogActions>
                        <DuplicateErrorModal
                            duplicateEntry={duplicateEntry}
                            values={values}
                            isDuplicateErrorModalOpen={isDuplicateErrorModalOpen}
                            handleCloseDuplicateErrorModal={handleCloseDuplicateErrorModal}
                        />
                        <ConfirmSubmitModal
                            values={values}
                            isConfirmSubmitModalOpen={isConfirmSubmitModalOpen}
                            handleCloseConfirmSubmitModal={handleCloseConfirmSubmitModal}
                        />
                        <CreateSuccessModal
                            values={values}
                            isCreateSuccessModalOpen={isCreateSuccessModalOpen}
                            handleCloseCreateSuccessModal={handleCloseCreateSuccessModal}
                        />
                        <MissingFieldModal
                            isMissingFieldModalOpen={isMissingFieldModalOpen}
                            handleCloseMissingFieldModal={handleCloseMissingFieldModal}
                        />
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default CreateFundForm
