import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Button,
    Typography,
} from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'

import SubmitButton from '../buttons/SubmitButton'
import { formatCase, formatModalValue } from '../../../utils/helpers/formatValue'
import {
    lenderKeys,
    fundKeys,
    borrowerKeys,
    facilityKeys,
    positionKeys,
    participationKeys,
    transactionKeys,
    exchangeRateKeys,
    supplementaryInterestRateKeys,
    bankAccountKeys,
    userKeys,
} from '../../../utils/lists/modalKeys'

const ConfirmSubmitModal = ({
    values,
    isConfirmSubmitModalOpen,
    handleCloseConfirmSubmitModal,
}) => {
    const location = useLocation()

    let newRecord = { ...values.values }

    let requiredKeys
    if (location.pathname.includes('/records/lenders')) {
        requiredKeys = lenderKeys
    } else if (location.pathname.includes('/records/funds')) {
        requiredKeys = fundKeys
    } else if (location.pathname.includes('/records/borrowers')) {
        requiredKeys = borrowerKeys
    } else if (location.pathname.includes('/records/facilities')) {
        requiredKeys = facilityKeys
    } else if (location.pathname.includes('/records/positions')) {
        requiredKeys = positionKeys
    } else if (location.pathname.includes('/records/participations')) {
        requiredKeys = participationKeys
    } else if (location.pathname.includes('/records/transactions')) {
        requiredKeys = transactionKeys
    } else if (location.pathname.includes('/records/exchange-rates')) {
        requiredKeys = exchangeRateKeys
    } else if (location.pathname.includes('/records/supplementary-interest-rates')) {
        requiredKeys = supplementaryInterestRateKeys
    } else if (location.pathname.includes('/records/bank-accounts')) {
        requiredKeys = bankAccountKeys
    } else if (location.pathname.includes('/auth/users')) {
        requiredKeys = userKeys
    } else {
        requiredKeys = []
    }

    const newRecordFilteredValues = Object.entries(newRecord).filter(([key]) =>
        requiredKeys.includes(key)
    )
    const newRecordSortedValues = requiredKeys
        .filter((key) => newRecord[key] !== undefined)
        .map((key) => [key, newRecord[key]])
        .concat(newRecordFilteredValues.filter(([key]) => !requiredKeys.includes(key)))

    return (
        <Dialog
            open={isConfirmSubmitModalOpen}
            onClose={handleCloseConfirmSubmitModal}
            maxWidth={false}
        >
            <DialogTitle color='error'>Warning</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>Confirm Submission?</DialogContentText>
                {newRecordSortedValues.map(([key, value]) => (
                    <div key={key} style={{ marginTop: '8px' }}>
                        <Typography component='span' fontWeight='bold'>
                            {formatCase(key)}:
                        </Typography>
                        <br />
                        {key === 'funds' && Array.isArray(value)
                            ? value.map((fund, index) => (
                                  <Typography key={index} component='span'>
                                      {formatModalValue(formatCase(key), fund)}
                                      <br />
                                  </Typography>
                              ))
                            : formatModalValue(formatCase(key), value)}
                    </div>
                ))}
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleCloseConfirmSubmitModal}>
                    Close
                </Button>
                <SubmitButton
                    values={values}
                    isConfirmSubmitModalOpen={isConfirmSubmitModalOpen}
                    handleCloseConfirmSubmitModal={handleCloseConfirmSubmitModal}
                >
                    Submit
                </SubmitButton>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmSubmitModal
