import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Button,
} from '@mui/material'
import React from 'react'

const DeleteSuccessModal = ({
    isDeleteSuccessModalOpen,
    handleCloseDeleteSuccessModal,
    handleCloseConfirmDeleteModal,
}) => {
    const handleClose = () => {
        handleCloseConfirmDeleteModal()
        handleCloseDeleteSuccessModal()
    }
    return (
        <Dialog open={isDeleteSuccessModalOpen} onClose={handleCloseDeleteSuccessModal}>
            <DialogTitle sx={{ color: '#4caf50' }}>Delete Success</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>Record has been successfully deleted!</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default DeleteSuccessModal
