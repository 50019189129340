export const themeSettings = (mode) => {
    return {
        palette: {
            mode: mode,
            ...(mode === 'light'
                ? {
                      // palette values for light mode
                      primary: {
                          main: '#896e52',
                          //   main: '#607D8B',
                      },
                  }
                : {
                      // palette values for dark mode
                      //   primary: {
                      //       main: '#bda996',
                      //   },
                      //   background: {
                      //       default: '#4c331f',
                      //       paper: '#4c331f',
                      //   },
                      //   text: {
                      //       primary: '#fff',
                      //       secondary: grey[500],
                      //   },
                  }),
        },
    }
}
