import React, { useRef, useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Box, CircularProgress, Grid, Button } from '@mui/material'
import { FileDownloadOutlined } from '@mui/icons-material'
import Header from '../layout/Header'

import InputLabelStyled from '../ui/fields/InputLabelStyled'
import DatePickerValidated from '../ui/fields/DatePickerValidated'
import BorrowerMultiSelect from '../ui/fields/select/BorrowerMultiSelect'
import FacilityMultiSelect from '../ui/fields/select/FacilityMultiSelect'
import { formatDatePickerDate } from '../../utils/helpers/formatValue'
import createZip from '../../utils/helpers/createZip'

import { useGetAllBorrowersQuery } from '../../app/api/records/borrowersApiSlice'
import { useGetAllFacilitiesQuery } from '../../app/api/records/facilitiesApiSlice'
import { useGetMultipleStatementOfAccountQuery } from '../../app/api/reports/statementOfAccountsApislice'
import ProcessFilesModal from '../ui/modals/ProcessFilesModal'

const statementOfAccountFilterFormValidation = Yup.object().shape({
    asOfDate: Yup.date().nullable().required('Required'),
    borrowerIds: Yup.array().min(1, 'Required'),
    facilityIds: Yup.array().min(1, 'Required'),
})

const StatementOfAccountMultiTab = () => {
    const initialStatementOfAccountFilterFormState = {
        asOfDate: null,
        borrowerIds: [],
        facilityIds: [],
    }

    const { data: borrowers } = useGetAllBorrowersQuery()
    const { data: facilities } = useGetAllFacilitiesQuery()

    const [filterParams, setFilterParams] = useState(initialStatementOfAccountFilterFormState)
    const [selectedBorrowerIds, setSelectedBorrowerIds] = useState([])
    const [selectedFacilityIds, setSelectedFacilityIds] = useState([])
    const [isFacilityMultiSelectDisabled, setIsFacilityMultiSelectDisabled] = useState(true)
    const [isZipping, setisZipping] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const [isDownloadClicked, setIsDownloadClicked] = useState(false)

    const {
        data: multipleStatementOfAccountData,
        isFetching: isGenerating,
        error,
    } = useGetMultipleStatementOfAccountQuery(filterParams, {
        skip:
            (!filterParams.asOfDate && filterParams.borrowerIds.length === 0) ||
            filterParams.facilityIds.length === 0,
    })

    const { statementOfAccountArrays, statementOfAccountSummaries } =
        multipleStatementOfAccountData || {}

    const formikRef = useRef()

    const borrowerFacilities =
        selectedBorrowerIds.length > 0
            ? facilities.filter((facility) => selectedBorrowerIds.includes(facility.borrowerId))
            : []

    // Automatically display facilityNumber when borrower selected
    useEffect(() => {
        const borrowerFacilityIds = borrowerFacilities.map((facility) => facility._id)
        setSelectedFacilityIds(borrowerFacilityIds)

        if (formikRef.current) {
            formikRef.current.setFieldValue('facilityIds', borrowerFacilityIds)
        }
        setIsFacilityMultiSelectDisabled(borrowerFacilityIds.length === 0)
    }, [selectedBorrowerIds, borrowerFacilities])

    // Create zip file when data is loaded
    useEffect(() => {
        if (!isGenerating && statementOfAccountArrays && statementOfAccountSummaries) {
            setisZipping(true)
            createZip(statementOfAccountArrays, statementOfAccountSummaries).then(() => {
                setisZipping(false)
            })
        }
    }, [isGenerating, statementOfAccountArrays, statementOfAccountSummaries])

    const handleStatementOfAccountFilterFormSubmit = (values) => {
        const { asOfDate, borrowerIds, facilityIds } = values
        setFilterParams({ asOfDate, borrowerIds, facilityIds })
    }

    return (
        <Box>
            <Header
                subtitle={
                    <>
                        Note:
                        <br />
                        1. Select the Borrower SOAs to be downloaded.
                    </>
                }
            />
            <Box
                sx={{
                    m: '1.5rem 2.5rem',
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                }}
            >
                <Formik
                    initialValues={{ ...initialStatementOfAccountFilterFormState }}
                    validationSchema={statementOfAccountFilterFormValidation}
                    onSubmit={(values) => {
                        const formattedValues = {
                            ...values,
                            asOfDate: formatDatePickerDate(values.asOfDate),
                        }
                        handleStatementOfAccountFilterFormSubmit(formattedValues)
                    }}
                    validateOnMount={true}
                    innerRef={formikRef}
                >
                    {(formik) => (
                        <Form>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <InputLabelStyled htmlFor='asOfDate'>
                                        As Of Date
                                    </InputLabelStyled>
                                    <DatePickerValidated name='asOfDate' />
                                </Grid>
                                <Grid item xs={9}></Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='borrowerIds'>
                                        Borrower Name(s)
                                    </InputLabelStyled>
                                    <BorrowerMultiSelect
                                        borrowers={borrowers}
                                        onChange={(event) => {
                                            formik.setFieldValue('borrowerIds', event.target.value)
                                            setSelectedBorrowerIds(event.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled disabled htmlFor='facilityIds'>
                                        Facility Number(s)
                                    </InputLabelStyled>
                                    <FacilityMultiSelect
                                        disabled
                                        borrowerFacilities={borrowerFacilities}
                                        isFacilityMultiSelectDisabled={
                                            isFacilityMultiSelectDisabled
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}>
                                    {isGenerating || isZipping || isDownloading ? (
                                        <Button
                                            disabled
                                            variant='contained'
                                            endIcon={
                                                <CircularProgress
                                                    size={16}
                                                    sx={{
                                                        color: 'inherit',
                                                        height: '16px',
                                                        width: '16px',
                                                    }}
                                                />
                                            }
                                        >
                                            Download
                                        </Button>
                                    ) : (
                                        <Button
                                            variant='contained'
                                            endIcon={<FileDownloadOutlined />}
                                            onClick={() => {
                                                setIsDownloadClicked(true)
                                                formik.submitForm()
                                            }}
                                            disabled={!formik.isValid}
                                        >
                                            Download
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
            <ProcessFilesModal
                isGenerating={isGenerating}
                isZipping={isZipping}
                isDownloading={isDownloading}
                setIsDownloading={setIsDownloading}
                isDownloadClicked={isDownloadClicked}
            />
        </Box>
    )
}

export default StatementOfAccountMultiTab
