import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Button,
} from '@mui/material'
import dayjs from 'dayjs'

import PercentFieldValidated from '../../fields/PercentFieldValidated'
import InputLabelStyled from '../../fields/InputLabelStyled'
import DatePickerValidated from '../../fields/DatePickerValidated'
import PreSubmitButton from '../../buttons/PreSubmitButton'

import { formatDatePickerDate } from '../../../../utils/helpers/formatValue'

import { selectCurrentUser } from '../../../../app/api/auth/authSlice'
import { useUpdateSupplementaryInterestRateMutation } from '../../../../app/api/records/supplementaryInterestRatesApiSlice'
import { useGetAllBorrowersQuery } from '../../../../app/api/records/borrowersApiSlice'
import { useGetAllFacilitiesQuery } from '../../../../app/api/records/facilitiesApiSlice'
import { useGetAllPositionsQuery } from '../../../../app/api/records/positionsApiSlice'

import DuplicateErrorModal from '../../modals/DuplicateErrorModal'
import ConfirmSubmitModal from '../../modals/ConfirmSubmitModal'
import CreateSuccessModal from '../../modals/CreateSuccessModal'
import MissingFieldModal from '../../modals/MissingFieldModal'
import InvalidDateModal from '../../modals/InvalidDateModal'
import TextFieldValidated from '../../fields/TextFieldValidated'

const supplementaryInterestRateFormValidation = Yup.object().shape({
    borrowerName: Yup.string().required('Required'),
    facilityNumber: Yup.string().required('Required'),
    taId: Yup.string().required('Required'),
    effectiveDate: Yup.date().nullable().required('Required'),
    supplementaryInterestRate: Yup.number().required('Required'),
})

const UpdateSupplementaryInterestRateForm = ({
    isFormOpen,
    handleCloseForm,
    toBeUpdated,
    isEditClicked,
}) => {
    const { firstName, lastName } = useSelector(selectCurrentUser)
    const userFullname = `${firstName} ${lastName}`

    const initialSupplementaryInterestRateFormState = {
        _id: '',
        borrowerName: '',
        facilityNumber: '',
        positionId: '',
        taId: '',
        effectiveDate: dayjs(new Date().toISOString()),
        supplementaryInterestRate: '',
        lastUpdatedBy: userFullname,
    }

    if (toBeUpdated && isEditClicked) {
        initialSupplementaryInterestRateFormState._id = toBeUpdated._id
        initialSupplementaryInterestRateFormState.borrowerName = toBeUpdated.borrowerName
        initialSupplementaryInterestRateFormState.facilityNumber = toBeUpdated.facilityNumber
        initialSupplementaryInterestRateFormState.positionId = toBeUpdated.positionId
        initialSupplementaryInterestRateFormState.taId = toBeUpdated.taId
        initialSupplementaryInterestRateFormState.supplementaryInterestRate =
            toBeUpdated.supplementaryInterestRate
        initialSupplementaryInterestRateFormState.effectiveDate = dayjs(
            new Date(toBeUpdated.effectiveDate)
        )
        initialSupplementaryInterestRateFormState.lastUpdatedBy = userFullname
    }

    if (!isEditClicked) {
        initialSupplementaryInterestRateFormState._id = ''
        initialSupplementaryInterestRateFormState.borrowerName = ''
        initialSupplementaryInterestRateFormState.facilityNumber = ''
        initialSupplementaryInterestRateFormState.positionId = ''
        initialSupplementaryInterestRateFormState.taId = ''
        initialSupplementaryInterestRateFormState.supplementaryInterestRate = ''
        initialSupplementaryInterestRateFormState.effectiveDate = dayjs(new Date().toISOString())
    }

    const [updateSupplementaryInterestRate] = useUpdateSupplementaryInterestRateMutation()

    const [duplicateEntry, setDuplicateEntry] = useState([])

    const [isDuplicateErrorModalOpen, setIsDuplicateErrorModalOpen] = useState(false)
    const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] = useState(false)
    const [isCreateSuccessModalOpen, setIsCreateSuccessModalOpen] = useState(false)
    const [isMissingFieldModalOpen, setIsMissingFieldModalOpen] = useState(false)
    const [isInvalidDateModalOpen, setIsInvalidDateModalOpen] = useState(false)

    const formikRef = useRef()

    const handleOpenDuplicateErrorModal = ({ duplicateData }) => {
        setIsDuplicateErrorModalOpen(true)
        setDuplicateEntry(duplicateData)
    }

    const handleCloseDuplicateErrorModal = () => {
        setIsDuplicateErrorModalOpen(false)
    }

    const handleOpenConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(true)
    }

    const handleCloseConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(false)
    }

    const handleOpenCreateSuccessModal = () => {
        setIsCreateSuccessModalOpen(true)
    }

    const handleCloseCreateSuccessModal = () => {
        setIsCreateSuccessModalOpen(false)
        handleCloseForm()
    }

    const handleOpenMissingFieldModal = () => {
        setIsMissingFieldModalOpen(true)
    }

    const handleCloseMissingFieldModal = () => {
        setIsMissingFieldModalOpen(false)
    }

    const handleOpenInvalidDateModal = () => {
        setIsInvalidDateModalOpen(true)
    }

    const handleCloseInvalidDateModal = () => {
        setIsInvalidDateModalOpen(false)
    }

    const handleUpdateSupplementaryInterestRateFormSubmit = async (values) => {
        const { _id, positionId, taId, effectiveDate, supplementaryInterestRate, lastUpdatedBy } =
            values

        try {
            const response = await updateSupplementaryInterestRate({
                _id,
                positionId,
                taId,
                effectiveDate,
                supplementaryInterestRate,
                lastUpdatedBy,
            })

            if (response.error && response.error.data && response.error.data.historicData) {
                handleOpenInvalidDateModal()
            } else if (response.error && response.error.data && response.error.data.existingData) {
                const { error, existingData, newData } = response.error.data
                handleOpenDuplicateErrorModal({ existingData })
            } else if (response.data && response.data.newData) {
                const { error, existingData, newData } = response.data
                handleOpenCreateSuccessModal()
            }
        } catch (error) {
            console.error('Error updating supplementary interest rate:', error)
        }
    }

    const handleUpdateSupplementaryInterestRateFormClose = () => {
        handleCloseForm()
        if (formikRef.current) {
            formikRef.current.resetForm()
        }
    }

    return (
        <Dialog open={isFormOpen} onClose={handleUpdateSupplementaryInterestRateFormClose}>
            <DialogTitle>Update Supplementary Interest Rate</DialogTitle>
            <Formik
                initialValues={{ ...initialSupplementaryInterestRateFormState }}
                validationSchema={supplementaryInterestRateFormValidation}
                onSubmit={(values) => {
                    handleUpdateSupplementaryInterestRateFormSubmit(values)
                }}
                validateOnMount={true}
                innerRef={formikRef}
            >
                {(formik) => (
                    <Form>
                        <DialogContent dividers>
                            <DialogContentText sx={{ mb: 2 }}>
                                Please fill in details of the Updated Supplementary Interest Rate.
                            </DialogContentText>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <InputLabelStyled disabled htmlFor='borrowerName'>
                                        Borrower Name
                                    </InputLabelStyled>
                                    <TextFieldValidated
                                        disabled
                                        name='borrowerName'
                                        id='borrowerName'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled disabled htmlFor='facilityNumber'>
                                        Facility Number
                                    </InputLabelStyled>
                                    <TextFieldValidated
                                        disabled
                                        name='facilityNumber'
                                        id='facilityNumber'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled disabled htmlFor='taId'>
                                        TA ID
                                    </InputLabelStyled>
                                    <TextFieldValidated disabled name='taId' id='taId' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='supplementaryInterestRate'>
                                        Supplementary Interest Rate
                                    </InputLabelStyled>
                                    <PercentFieldValidated
                                        name='supplementaryInterestRate'
                                        id='supplementaryInterestRate'
                                    />
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='effectiveDate'>
                                        Effective Date
                                    </InputLabelStyled>
                                    <DatePickerValidated
                                        name='effectiveDate'
                                        maxDate={dayjs(new Date())}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' onClick={handleCloseForm}>
                                Cancel
                            </Button>
                            <PreSubmitButton
                                values={{
                                    ...formik,
                                    values: {
                                        ...formik.values,
                                        effectiveDate: formatDatePickerDate(
                                            formik.values.effectiveDate
                                        ),
                                    },
                                }}
                                handleOpenMissingFieldModal={handleOpenMissingFieldModal}
                                handleOpenConfirmSubmitModal={handleOpenConfirmSubmitModal}
                            >
                                Submit
                            </PreSubmitButton>
                        </DialogActions>
                        <DuplicateErrorModal
                            duplicateEntry={duplicateEntry}
                            values={{
                                ...formik,
                                values: {
                                    ...formik.values,
                                    effectiveDate: formatDatePickerDate(
                                        formik.values.effectiveDate
                                    ),
                                },
                            }}
                            isDuplicateErrorModalOpen={isDuplicateErrorModalOpen}
                            handleCloseDuplicateErrorModal={handleCloseDuplicateErrorModal}
                        />
                        <ConfirmSubmitModal
                            values={{
                                ...formik,
                                values: {
                                    ...formik.values,
                                    effectiveDate: formatDatePickerDate(
                                        formik.values.effectiveDate
                                    ),
                                },
                            }}
                            isConfirmSubmitModalOpen={isConfirmSubmitModalOpen}
                            handleCloseConfirmSubmitModal={handleCloseConfirmSubmitModal}
                        />
                        <CreateSuccessModal
                            isCreateSuccessModalOpen={isCreateSuccessModalOpen}
                            handleCloseCreateSuccessModal={handleCloseCreateSuccessModal}
                        />
                        <MissingFieldModal
                            isMissingFieldModalOpen={isMissingFieldModalOpen}
                            handleCloseMissingFieldModal={handleCloseMissingFieldModal}
                        />
                        <InvalidDateModal
                            isInvalidDateModalOpen={isInvalidDateModalOpen}
                            handleCloseInvalidDateModal={handleCloseInvalidDateModal}
                        />
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default UpdateSupplementaryInterestRateForm
