import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import Header from '../../components/layout/Header'
import CustomToolbar from '../../components/ui/datagrid/CustomToolbar'
import CustomFilterForm from '../../components/ui/datagrid/CustomFilterForm'
import CustomNoRowOverlay from '../../components/ui/datagrid/CustomNoRowOverlay'

import { formatDataGridDate, formatMoney, formatPercent } from '../../utils/helpers/formatValue'

import { useGetAsOfDateHoldingsQuery } from '../../app/api/reports/holdingsApiSlice'
import HoldingsFilterForm from '../../components/ui/forms/filters/HoldingsFilterForm'

const Holdings = () => {
    const initialHoldingFilterFormState = {
        asOfDate: null,
    }

    const [filterParams, setFilterParams] = useState(initialHoldingFilterFormState)

    const {
        data: asOfDateHoldings,
        isFetching: isGenerating,
        error,
    } = useGetAsOfDateHoldingsQuery(filterParams, {
        skip: !filterParams.asOfDate,
    })

    const columns = [
        { field: 'borrowerType', headerName: 'Borrower Type', width: 100 },
        { field: 'loanId', headerName: 'Loan ID', width: 80 },
        { field: 'lenderCode', headerName: 'Lender Code', width: 100 },
        { field: 'fundCode', headerName: 'Fund Code', width: 100 },
        { field: 'taId', headerName: 'TA ID', width: 210 },
        { field: 'fundTaId', headerName: 'Fund - TA ID', width: 300 },
        { field: 'currency', headerName: 'Currency', width: 80 },
        {
            field: 'outstandingPrincipal',
            headerName: 'Outstanding Principal',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'limit',
            headerName: 'Facility Limit',
            width: 120,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'drawdownDate',
            headerName: 'Drawdown Date',
            type: 'date',
            width: 150,
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
        {
            field: 'approvedMaturityDate',
            headerName: 'Maturity Date',
            type: 'date',
            width: 150,
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
        { field: 'borrowerName', headerName: 'Borrower Name', width: 200 },
        { field: 'facilityNumber', headerName: 'Facility Number', width: 150 },
        { field: 'accrualMethod', headerName: 'Accrual Method', width: 150 },
        {
            field: 'asOfDate',
            headerName: 'As of Date',
            type: 'date',
            width: 120,
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
        {
            field: 'originalInterestRate',
            headerName: 'Original Interest Rate',
            width: 120,
            valueFormatter: (params) => formatPercent(params.value),
        },
        {
            field: 'supplementaryInterestRate',
            headerName: 'Supplementary Rate',
            width: 120,
            valueFormatter: (params) => formatPercent(params.value),
        },
        {
            field: 'effectiveInterestRate',
            headerName: 'Effective Interest Rate',
            width: 120,
            valueFormatter: (params) => formatPercent(params.value),
        },
        {
            field: 'spreadRate',
            headerName: 'Spread Rate',
            width: 120,
            valueFormatter: (params) => formatPercent(params.value),
        },
        {
            field: 'totalInterest',
            headerName: 'Total Interest',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'totalFee',
            headerName: 'Total Fee',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'interestAndFee',
            headerName: 'Interest and Fee',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'netDue',
            headerName: 'Net Due',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'relationshipType',
            headerName: 'Type',
            width: 80,
        },
        {
            field: 'exchangeRate',
            headerName: 'Exchange Rate',
            width: 100,
        },
    ]

    const handleHoldingsFilterFormSubmit = (values) => {
        const { asOfDate } = values
        setFilterParams({ asOfDate })
    }

    return (
        <Box>
            <Header title='Holdings' subtitle='List of Holdings.' />
            <Box
                sx={{
                    m: '1.5rem 2.5rem',
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                }}
            >
                <HoldingsFilterForm
                    onSubmit={handleHoldingsFilterFormSubmit}
                    initialHoldingFilterFormState={initialHoldingFilterFormState}
                    isGenerating={isGenerating}
                />
            </Box>
            <Box
                sx={{
                    m: '1.5rem 2.5rem',
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    height: '80vh',
                    '& .MuiDataGrid-root': {
                        maxWidth: '95vw',
                    },
                }}
            >
                <DataGrid
                    loading={isGenerating}
                    getRowId={(row) => row._id}
                    rows={error ? [] : asOfDateHoldings || []}
                    columns={columns}
                    pageSizeOptions={[20, 50, 100]}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 100 } },
                        columns: {
                            columnVisibilityModel: {
                                // createdAt: false,
                                // updatedAt: false,
                            },
                        },
                        sorting: { sortModel: [{ field: 'drawdownDate', sort: 'asc' }] },
                    }}
                    disableRowSelectionOnClick
                    slots={{
                        toolbar: CustomToolbar,
                        noRowsOverlay: CustomNoRowOverlay,
                    }}
                    slotProps={{
                        filterPanel: {
                            filterFormProps: CustomFilterForm,
                        },
                    }}
                />
            </Box>
        </Box>
    )
}

export default Holdings
