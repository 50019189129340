import { useLocation } from 'react-router-dom'
import { GridToolbarExport } from '@mui/x-data-grid'

const CustomExport = () => {
    const location = useLocation()

    const getFileName = () => {
        const pathname = location.pathname
        const segments = pathname.split('/')
        const fileName = segments[segments.length - 1]
        return `${fileName}`
    }

    return (
        <GridToolbarExport
            csvOptions={{
                fileName: getFileName(),
            }}
            printOptions={{ disableToolbarButton: true }}
        />
    )
}

export default CustomExport
