import { useState, useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { passwordRule, passwordHelper } from '../../../../utils/helpers/passwordDef'

import TextFieldValidated from '../../fields/TextFieldValidated'
import PasswordField from '../../fields/PasswordField'
import InputLabelStyled from '../../fields/InputLabelStyled'
import LoginButton from '../../buttons/LoginButton'

import { useLoginUserMutation } from '../../../../app/api/auth/authApiSlice'

import LoginFailModal from '../../modals/LoginFailModal'
import { loginSuccess } from '../../../../app/api/auth/authSlice'

const loginFormValidation = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().matches(passwordRule, passwordHelper).required('Required'),
})

const initialLoginFormState = {
    email: '',
    password: '',
}

const LoginForm = () => {
    const [loginUser] = useLoginUserMutation()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    // Check state.auth.accessToken
    const authState = useSelector((state) => state.auth)

    const [isLoginFailModalOpen, setIsLoginFailModalOpen] = useState(false)

    const handleOpenLoginFailModal = () => {
        setIsLoginFailModalOpen(true)
    }

    const handleCloseLoginFailModal = () => {
        setIsLoginFailModalOpen(false)
    }

    const handleLoginFormSubmit = async (values) => {
        const { email, password } = values

        try {
            const response = await loginUser({ email, password })

            const loggedIn = await response.data

            if (loggedIn) {
                dispatch(
                    loginSuccess({
                        accessToken: loggedIn.accessToken,
                        user: loggedIn.user,
                    })
                )
                // navigate to homepage
                navigate('/records/lenders')
            } else {
                handleOpenLoginFailModal()
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Box>
            <Formik
                initialValues={{ ...initialLoginFormState }}
                validationSchema={loginFormValidation}
                onSubmit={(values) => {
                    handleLoginFormSubmit(values)
                }}
                validateOnMount={true}
            >
                {(values) => (
                    <Form>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <InputLabelStyled htmlFor='email'>Email *</InputLabelStyled>
                                <TextFieldValidated name='email' id='email' />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabelStyled htmlFor='password'>Password *</InputLabelStyled>
                                <PasswordField name='password' id='password' />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{ mt: '1.5rem', display: 'flex', justifyContent: 'center' }}
                            >
                                <LoginButton values={values} sx={{ flexGrow: 1 }} />
                            </Grid>
                        </Grid>
                        <LoginFailModal
                            isLoginFailModalOpen={isLoginFailModalOpen}
                            handleCloseLoginFailModal={handleCloseLoginFailModal}
                        />
                    </Form>
                )}
            </Formik>
        </Box>
    )
}

export default LoginForm
