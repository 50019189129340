import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import { useTheme } from '@mui/material/styles'

const InputLabelStyled = (props) => {
    const theme = useTheme()
    const { disabled, sx, ...otherProps } = props
    // Customize styles for InputLabel
    const labelStyles = {
        fontWeight: 'bold',
        color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
        mb: '0.5rem',
    }

    return <InputLabel {...props} sx={{ ...labelStyles, ...props.sx }} />
}

export default InputLabelStyled
