import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Button,
} from '@mui/material'
import dayjs from 'dayjs'

import PercentFieldValidated from '../../fields/PercentFieldValidated'
import InputLabelStyled from '../../fields/InputLabelStyled'
import BorrowerSelect from '../../fields/select/BorrowerSelect'
import FacilitySelect from '../../fields/select/FacilitySelect'
import PositionSelect from '../../fields/select/PositionSelect'
import DatePickerValidated from '../../fields/DatePickerValidated'
import PreSubmitButton from '../../buttons/PreSubmitButton'

import { formatDatePickerDate } from '../../../../utils/helpers/formatValue'

import { selectCurrentUser } from '../../../../app/api/auth/authSlice'
import { useCreateSupplementaryInterestRateMutation } from '../../../../app/api/records/supplementaryInterestRatesApiSlice'
import { useGetAllBorrowersQuery } from '../../../../app/api/records/borrowersApiSlice'
import { useGetAllFacilitiesQuery } from '../../../../app/api/records/facilitiesApiSlice'
import { useGetAllPositionsQuery } from '../../../../app/api/records/positionsApiSlice'

import DuplicateErrorModal from '../../modals/DuplicateErrorModal'
import ConfirmSubmitModal from '../../modals/ConfirmSubmitModal'
import CreateSuccessModal from '../../modals/CreateSuccessModal'
import MissingFieldModal from '../../modals/MissingFieldModal'
import InvalidDateModal from '../../modals/InvalidDateModal'

const supplementaryInterestRateFormValidation = Yup.object().shape({
    borrowerId: Yup.string().required('Required'),
    facilityId: Yup.string().required('Required'),
    positionId: Yup.string().required('Required'),
    taId: Yup.string().required('Required'),
    effectiveDate: Yup.date().nullable().required('Required'),
    supplementaryInterestRate: Yup.number().required('Required'),
})

const CreateSupplementaryInterestRateForm = ({ isFormOpen, handleCloseForm }) => {
    const { firstName, lastName } = useSelector(selectCurrentUser)
    const userFullname = `${firstName} ${lastName}`

    const initialSupplementaryInterestRateFormState = {
        borrowerId: '',
        facilityId: '',
        positionId: '',
        taId: '',
        effectiveDate: dayjs(new Date().toISOString()),
        supplementaryInterestRate: '',
        createdBy: userFullname,
    }

    const [createSupplementaryInterestRate] = useCreateSupplementaryInterestRateMutation()

    const { data: borrowers = [] } = useGetAllBorrowersQuery()
    const { data: facilities = [] } = useGetAllFacilitiesQuery()
    const { data: positions = [] } = useGetAllPositionsQuery()

    const [duplicateEntry, setDuplicateEntry] = useState([])
    const [selectedBorrower, setSelectedBorrower] = useState('')
    const [selectedFacility, setSelectedFacility] = useState('')
    const [isFacilitySelectDisabled, setIsFacilitySelectDisabled] = useState(true)
    const [isPositionSelectDisabled, setIsPositionSelectDisabled] = useState(true)

    const [isDuplicateErrorModalOpen, setIsDuplicateErrorModalOpen] = useState(false)
    const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] = useState(false)
    const [isCreateSuccessModalOpen, setIsCreateSuccessModalOpen] = useState(false)
    const [isMissingFieldModalOpen, setIsMissingFieldModalOpen] = useState(false)
    const [isInvalidDateModalOpen, setIsInvalidDateModalOpen] = useState(false)

    const formikRef = useRef()

    const handleOpenDuplicateErrorModal = ({ existingData }) => {
        setIsDuplicateErrorModalOpen(true)
        setDuplicateEntry(existingData)
    }

    const handleCloseDuplicateErrorModal = () => {
        setIsDuplicateErrorModalOpen(false)
    }

    const handleOpenConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(true)
    }

    const handleCloseConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(false)
    }

    const handleOpenCreateSuccessModal = () => {
        setIsCreateSuccessModalOpen(true)
    }

    const handleCloseCreateSuccessModal = () => {
        setIsCreateSuccessModalOpen(false)
        handleCloseForm()
    }

    const handleOpenMissingFieldModal = () => {
        setIsMissingFieldModalOpen(true)
    }

    const handleCloseMissingFieldModal = () => {
        setIsMissingFieldModalOpen(false)
    }

    const handleOpenInvalidDateModal = () => {
        setIsInvalidDateModalOpen(true)
    }

    const handleCloseInvalidDateModal = () => {
        setIsInvalidDateModalOpen(false)
    }

    const activePositions = positions.filter((position) => position.isActive && position.isDrawdown)

    const borrowerFacilities = selectedBorrower
        ? facilities.filter((facility) => facility.borrowerId === selectedBorrower)
        : facilities

    const facilityPositions = selectedFacility
        ? activePositions.filter((activePosition) => activePosition.facilityId === selectedFacility)
        : activePositions

    const handleCreateSupplementaryInterestRateFormSubmit = async (values) => {
        const { effectiveDate, positionId, taId, supplementaryInterestRate, createdBy } = values

        try {
            const response = await createSupplementaryInterestRate({
                effectiveDate,
                positionId,
                taId,
                supplementaryInterestRate,
                createdBy,
            })

            if (response.error && response.error.data && response.error.data.historicData) {
                handleOpenInvalidDateModal()
            } else if (response.error && response.error.data && response.error.data.existingData) {
                const { error, existingData, newData } = response.error.data
                handleOpenDuplicateErrorModal({ existingData })
            } else if (response.data && response.data.newData) {
                const { error, existingData, newData } = response.data
                handleOpenCreateSuccessModal()
            }
        } catch (error) {
            console.error('Error creating supplementary interest rate:', error)
        }
    }

    const handleCreateSupplementaryInterestRateFormClose = () => {
        handleCloseForm()
        if (formikRef.current) {
            formikRef.current.resetForm()
        }
    }

    return (
        <Dialog open={isFormOpen} onClose={handleCreateSupplementaryInterestRateFormClose}>
            <DialogTitle>Create New Supplementary Interest Rate</DialogTitle>
            <Formik
                initialValues={{ ...initialSupplementaryInterestRateFormState }}
                validationSchema={supplementaryInterestRateFormValidation}
                onSubmit={(values) => {
                    handleCreateSupplementaryInterestRateFormSubmit(values)
                }}
                validateOnMount={true}
                innerRef={formikRef}
            >
                {(formik) => (
                    <Form>
                        <DialogContent dividers>
                            <DialogContentText sx={{ mb: 2 }}>
                                Please fill in details of the New Supplementary Interest Rate.
                            </DialogContentText>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='borrowerId'>
                                        Borrower Name
                                    </InputLabelStyled>
                                    <BorrowerSelect
                                        borrowers={borrowers}
                                        onChange={(event) => {
                                            formik.setFieldValue('borrowerId', event.target.value)
                                            setSelectedBorrower(event.target.value)
                                            setIsFacilitySelectDisabled(false)
                                            formik.setFieldValue('facilityId', '')
                                            formik.setFieldValue('positionId', '')
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='facilityId'>
                                        Facility Number
                                    </InputLabelStyled>
                                    <FacilitySelect
                                        facilities={borrowerFacilities}
                                        isFacilitySelectDisabled={isFacilitySelectDisabled}
                                        onChange={(event) => {
                                            formik.setFieldValue('facilityId', event.target.value)
                                            setSelectedFacility(event.target.value)
                                            setIsPositionSelectDisabled(false)
                                            formik.setFieldValue('positionId', '')
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='positionId'>TA ID</InputLabelStyled>
                                    <PositionSelect
                                        positions={facilityPositions}
                                        isPositionSelectDisabled={isPositionSelectDisabled}
                                        onChange={(event) => {
                                            formik.setFieldValue('positionId', event.target.value)
                                            const selectedPosition = facilityPositions.find(
                                                (position) => position._id === event.target.value
                                            )
                                            formik.setFieldValue('taId', selectedPosition.taId)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='supplementaryInterestRate'>
                                        Supplementary Interest Rate
                                    </InputLabelStyled>
                                    <PercentFieldValidated
                                        name='supplementaryInterestRate'
                                        id='supplementaryInterestRate'
                                    />
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='effectiveDate'>
                                        Effective Date
                                    </InputLabelStyled>
                                    <DatePickerValidated
                                        name='effectiveDate'
                                        maxDate={dayjs(new Date())}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' onClick={handleCloseForm}>
                                Cancel
                            </Button>
                            <PreSubmitButton
                                values={{
                                    ...formik,
                                    values: {
                                        ...formik.values,
                                        effectiveDate: formatDatePickerDate(
                                            formik.values.effectiveDate
                                        ),
                                    },
                                }}
                                handleOpenMissingFieldModal={handleOpenMissingFieldModal}
                                handleOpenConfirmSubmitModal={handleOpenConfirmSubmitModal}
                            >
                                Submit
                            </PreSubmitButton>
                        </DialogActions>
                        <DuplicateErrorModal
                            duplicateEntry={duplicateEntry}
                            values={{
                                ...formik,
                                values: {
                                    ...formik.values,
                                    effectiveDate: formatDatePickerDate(
                                        formik.values.effectiveDate
                                    ),
                                },
                            }}
                            isDuplicateErrorModalOpen={isDuplicateErrorModalOpen}
                            handleCloseDuplicateErrorModal={handleCloseDuplicateErrorModal}
                        />
                        <ConfirmSubmitModal
                            values={{
                                ...formik,
                                values: {
                                    ...formik.values,
                                    effectiveDate: formatDatePickerDate(
                                        formik.values.effectiveDate
                                    ),
                                },
                            }}
                            isConfirmSubmitModalOpen={isConfirmSubmitModalOpen}
                            handleCloseConfirmSubmitModal={handleCloseConfirmSubmitModal}
                        />
                        <CreateSuccessModal
                            isCreateSuccessModalOpen={isCreateSuccessModalOpen}
                            handleCloseCreateSuccessModal={handleCloseCreateSuccessModal}
                        />
                        <MissingFieldModal
                            isMissingFieldModalOpen={isMissingFieldModalOpen}
                            handleCloseMissingFieldModal={handleCloseMissingFieldModal}
                        />
                        <InvalidDateModal
                            isInvalidDateModalOpen={isInvalidDateModalOpen}
                            handleCloseInvalidDateModal={handleCloseInvalidDateModal}
                        />
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default CreateSupplementaryInterestRateForm
