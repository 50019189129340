import React, { useState } from 'react'
import { Button } from '@mui/material'

const DeleteButton = ({ handleDelete, children }) => {
    const configDeleteButton = {
        variant: 'contained',
        color: 'primary',
        onClick: handleDelete,
    }
    return (
        <>
            <Button {...configDeleteButton}>{children}</Button>
        </>
    )
}

export default DeleteButton
