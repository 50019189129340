import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Button,
} from '@mui/material'
import TextFieldValidated from '../../fields/TextFieldValidated'
import InputLabelStyled from '../../fields/InputLabelStyled'
import PreSubmitButton from '../../buttons/PreSubmitButton'

import { selectCurrentUser } from '../../../../app/api/auth/authSlice'
import { useUpdateLenderMutation } from '../../../../app/api/records/lendersApiSlice'
import DuplicateErrorModal from '../../modals/DuplicateErrorModal'
import ConfirmSubmitModal from '../../modals/ConfirmSubmitModal'
import UpdateSuccessModal from '../../modals/UpdateSuccessModal'
import MissingFieldModal from '../../modals/MissingFieldModal'
import BooleanSelect from '../../fields/select/BooleanSelect'

const lenderFormValidation = Yup.object().shape({
    lenderCode: Yup.string().required('Required'),
    lenderName: Yup.string().required('Required'),
    isActive: Yup.boolean().required('Required'),
})

const UpdateLenderForm = ({ isFormOpen, handleCloseForm, toBeUpdated, isEditClicked }) => {
    const { firstName, lastName } = useSelector(selectCurrentUser)
    const userFullname = `${firstName} ${lastName}`

    const initialLenderFormState = {
        _id: '',
        lenderCode: '',
        lenderName: '',
        isActive: null,
        lastUpdatedBy: '',
    }

    if (toBeUpdated && isEditClicked) {
        initialLenderFormState._id = toBeUpdated._id
        initialLenderFormState.lenderCode = toBeUpdated.lenderCode
        initialLenderFormState.lenderName = toBeUpdated.lenderName
        initialLenderFormState.isActive = toBeUpdated.isActive
        initialLenderFormState.lastUpdatedBy = userFullname
    }

    if (!isEditClicked) {
        initialLenderFormState._id = ''
        initialLenderFormState.lenderCode = ''
        initialLenderFormState.lenderName = ''
        initialLenderFormState.isActive = null
        initialLenderFormState.lastUpdatedBy = ''
    }

    const [updateLender] = useUpdateLenderMutation()

    const [duplicateEntry, setDuplicateEntry] = useState([])

    const [isDuplicateErrorModalOpen, setIsDuplicateErrorModalOpen] = useState(false)
    const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] = useState(false)
    const [isUpdateSuccessModalOpen, setIsUpdateSuccessModalOpen] = useState(false)
    const [isMissingFieldModalOpen, setIsMissingFieldModalOpen] = useState(false)

    const handleOpenDuplicateErrorModal = ({ duplicateData }) => {
        setIsDuplicateErrorModalOpen(true)
        setDuplicateEntry(duplicateData)
    }

    const handleCloseDuplicateErrorModal = () => {
        setIsDuplicateErrorModalOpen(false)
    }

    const handleOpenConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(true)
    }

    const handleCloseConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(false)
    }

    const handleOpenUpdateSuccessModal = () => {
        setIsUpdateSuccessModalOpen(true)
    }

    const handleCloseUpdateSuccessModal = () => {
        setIsUpdateSuccessModalOpen(false)
        handleCloseForm()
    }

    const handleOpenMissingFieldModal = () => {
        setIsMissingFieldModalOpen(true)
    }

    const handleCloseMissingFieldModal = () => {
        setIsMissingFieldModalOpen(false)
    }

    const handleLenderFormSubmit = async (values) => {
        const { _id, lenderCode, lenderName, isActive, lastUpdatedBy } = values

        try {
            const response = await updateLender({
                _id,
                lenderCode,
                lenderName,
                isActive,
                lastUpdatedBy,
            })

            if (response.error && response.error.data && response.error.data.duplicateData) {
                const { error, duplicateData, newData } = response.error.data
                handleOpenDuplicateErrorModal({ duplicateData })
            } else if (response.data && response.data.newData) {
                const { error, duplicateData, newData } = response.data
                handleOpenUpdateSuccessModal()
            }
        } catch (error) {
            console.error('Error updating lender:', error)
        }
    }

    return (
        <Dialog open={isFormOpen} onClose={handleCloseForm}>
            <DialogTitle>Update Lender</DialogTitle>
            <Formik
                initialValues={{ ...initialLenderFormState }}
                validationSchema={lenderFormValidation}
                onSubmit={(values) => {
                    handleLenderFormSubmit(values)
                }}
                validateOnMount={true}
            >
                {(values) => (
                    <Form>
                        <DialogContent dividers>
                            <DialogContentText sx={{ mb: 2 }}>
                                Please fill up details of the Updated Lender. Ensure that both
                                Lender Code and Lender Name are not duplicated.
                            </DialogContentText>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='lenderCode'>
                                        Lender Code *
                                    </InputLabelStyled>
                                    <TextFieldValidated name='lenderCode' id='lenderCode' />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='lenderName'>
                                        Lender Name *
                                    </InputLabelStyled>
                                    <TextFieldValidated name='lenderName' id='lenderName' />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled disabled htmlFor='isActive'>
                                        Is Active?
                                    </InputLabelStyled>
                                    <BooleanSelect disabled name='isActive' id='isActive' />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' onClick={handleCloseForm}>
                                Cancel
                            </Button>
                            <PreSubmitButton
                                values={values}
                                handleOpenMissingFieldModal={handleOpenMissingFieldModal}
                                handleOpenConfirmSubmitModal={handleOpenConfirmSubmitModal}
                            >
                                Submit
                            </PreSubmitButton>
                        </DialogActions>
                        <DuplicateErrorModal
                            duplicateEntry={duplicateEntry}
                            values={values}
                            isDuplicateErrorModalOpen={isDuplicateErrorModalOpen}
                            handleCloseDuplicateErrorModal={handleCloseDuplicateErrorModal}
                        />
                        <ConfirmSubmitModal
                            values={values}
                            isConfirmSubmitModalOpen={isConfirmSubmitModalOpen}
                            handleCloseConfirmSubmitModal={handleCloseConfirmSubmitModal}
                        />
                        <UpdateSuccessModal
                            values={values}
                            isUpdateSuccessModalOpen={isUpdateSuccessModalOpen}
                            handleCloseUpdateSuccessModal={handleCloseUpdateSuccessModal}
                        />
                        <MissingFieldModal
                            isMissingFieldModalOpen={isMissingFieldModalOpen}
                            handleCloseMissingFieldModal={handleCloseMissingFieldModal}
                        />
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default UpdateLenderForm
