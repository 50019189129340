import { apiSlice } from '../apiSlice'

export const facilitiesApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getAllFacilities: build.query({
            query: () => ({ url: `records/facilities`, method: 'GET' }),
            providesTags: ['Facilities'],
        }),
        createFacility: build.mutation({
            query: (newRow) => ({
                url: `records/facilities`,
                method: 'POST',
                body: newRow,
            }),
            invalidatesTags: ['Facilities', 'Positions'],
        }),
        updateFacility: build.mutation({
            query: (updatedRow) => ({
                url: `records/facilities`,
                method: 'PUT',
                body: updatedRow,
            }),
            invalidatesTags: ['Facilities', 'Positions'],
        }),
        deleteFacility: build.mutation({
            query: (id) => ({
                url: `records/facilities/${id}`,
                method: 'DELETE',
                body: id,
            }),
            invalidatesTags: ['Facilities', 'Positions'],
        }),
    }),
})

export const {
    useCreateFacilityMutation,
    useGetAllFacilitiesQuery,
    useUpdateFacilityMutation,
    useDeleteFacilityMutation,
} = facilitiesApiSlice
