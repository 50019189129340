import React, { useEffect, useRef, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Button, CircularProgress, Grid } from '@mui/material'

import InputLabelStyled from '../../fields/InputLabelStyled'
import DatePickerValidated from '../../fields/DatePickerValidated'
import BorrowerSelect from '../../fields/select/BorrowerSelect'
import FacilitySelect from '../../fields/select/FacilitySelect'
import { formatDatePickerDate } from '../../../../utils/helpers/formatValue'

import { useGetAllBorrowersQuery } from '../../../../app/api/records/borrowersApiSlice'
import { useGetAllFacilitiesQuery } from '../../../../app/api/records/facilitiesApiSlice'

const statementHoldingsFilterFormValidation = Yup.object().shape({
    asOfDate: Yup.date().nullable().required('Required'),
    borrowerId: Yup.string().required('Required'),
    facilityId: Yup.string().required('Required'),
})

const StatementHoldingsFilterForm = ({
    onSubmit,
    initialStatementHoldingsFilterFormState,
    isGenerating,
    setIsShowStatementOfAccount,
}) => {
    const { data: borrowers } = useGetAllBorrowersQuery()
    const { data: facilities } = useGetAllFacilitiesQuery()

    const [selectedBorrowerId, setSelectedBorrowerId] = useState('')
    const [selectedFacilityId, setSelectedFacilityId] = useState('')
    const [isFacilitySelectDisabled, setIsFacilitySelectDisabled] = useState(true)

    const prevIsGeneratingRef = useRef(isGenerating)
    useEffect(() => {
        // Detect change from true to false
        const prevIsGenerating = prevIsGeneratingRef.current

        if (prevIsGenerating && !isGenerating) {
            setIsShowStatementOfAccount(true)
        }
        prevIsGeneratingRef.current = isGenerating
    }, [isGenerating])

    const formikRef = useRef()

    const activeBorrowers = borrowers ? borrowers.filter((borrower) => borrower.isActive) : []

    const borrowerFacilities = selectedBorrowerId
        ? facilities.filter((facility) => facility.borrowerId === selectedBorrowerId)
        : facilities

    const handleButtonDisable = (formik) => {
        if (!formik.values.asOfDate || !formik.values.borrowerId || !formik.values.facilityId) {
            return true
        }
        return false
    }

    return (
        <Formik
            initialValues={{ ...initialStatementHoldingsFilterFormState }}
            validationSchema={statementHoldingsFilterFormValidation}
            onSubmit={(values) => {
                const formattedValues = {
                    ...values,
                    asOfDate: formatDatePickerDate(values.asOfDate),
                }
                onSubmit(formattedValues)
            }}
            validateOnMount={true}
            innerRef={formikRef}
        >
            {(formik) => (
                <Form>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <InputLabelStyled htmlFor='asOfDate'>As Of Date</InputLabelStyled>
                            <DatePickerValidated name='asOfDate' />
                        </Grid>
                        <Grid item xs={9}></Grid>

                        <Grid item xs={3}>
                            <InputLabelStyled htmlFor='borrowerId'>Borrower Name</InputLabelStyled>
                            <BorrowerSelect
                                borrowers={activeBorrowers}
                                onChange={(event) => {
                                    formik.setFieldValue('borrowerId', event.target.value)
                                    setSelectedBorrowerId(event.target.value)
                                    setIsFacilitySelectDisabled(false)
                                    formik.setFieldValue('facilityId', '')
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabelStyled htmlFor='facilityId'>
                                Facility Number
                            </InputLabelStyled>
                            <FacilitySelect
                                facilities={borrowerFacilities}
                                isFacilitySelectDisabled={isFacilitySelectDisabled}
                                onChange={(event) => {
                                    formik.setFieldValue('facilityId', event.target.value)
                                    setSelectedFacilityId(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            {isGenerating ? (
                                <Button
                                    disabled
                                    variant='contained'
                                    endIcon={
                                        <CircularProgress
                                            size={16}
                                            sx={{
                                                color: 'inherit',
                                                height: '16px',
                                                width: '16px',
                                            }}
                                        />
                                    }
                                >
                                    Generate
                                </Button>
                            ) : (
                                <Button
                                    variant='contained'
                                    onClick={() => {
                                        formik.submitForm()
                                    }}
                                    disabled={handleButtonDisable(formik)}
                                >
                                    Generate
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}

export default StatementHoldingsFilterForm
