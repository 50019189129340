import { apiSlice } from '../apiSlice'

export const bankAccountsApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getAllBankAccounts: build.query({
            query: () => ({ url: `records/bank-accounts`, method: 'GET' }),
            providesTags: ['BankAccounts'],
        }),
        createBankAccount: build.mutation({
            query: (newRow) => ({
                url: `records/bank-accounts`,
                method: 'POST',
                body: newRow,
            }),
            invalidatesTags: ['BankAccounts', 'Holdings'],
        }),
        updateBankAccount: build.mutation({
            query: (updatedRow) => ({
                url: `records/bank-accounts`,
                method: 'PUT',
                body: updatedRow,
            }),
            invalidatesTags: ['BankAccounts', 'Holdings'],
        }),
        deleteBankAccount: build.mutation({
            query: (id) => ({
                url: `records/bank-accounts/${id}`,
                method: 'DELETE',
                body: id,
            }),
            invalidatesTags: ['BankAccounts', 'Holdings'],
        }),
    }),
})

export const {
    useCreateBankAccountMutation,
    useGetAllBankAccountsQuery,
    useUpdateBankAccountMutation,
    useDeleteBankAccountMutation,
} = bankAccountsApiSlice
