import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    accessToken: null,
    isAuthenticated: false,
    isLoading: false,
    user: null,
    error: null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginStart(state) {
            state.isLoading = true
        },
        loginSuccess(state, action) {
            state.accessToken = action.payload.accessToken
            state.user = action.payload.user // include user details excluding password
            state.isAuthenticated = true
            state.isLoading = false
            state.error = null
        },
        loginFailure(state, action) {
            state.isLoading = false
            state.error = action.payload
        },
        logout(state) {
            state.accessToken = null
            state.isAuthenticated = false
        },
    },
})

export const { loginStart, loginSuccess, loginFailure, logout } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.user
