import React from 'react'
import { Box, MenuItem, Chip } from '@mui/material'
import SelectValidated from './SelectValidated'

const FacilityMultiSelect = ({
    borrowerFacilities,
    isFacilityMultiSelectDisabled,
    ...otherProps
}) => {
    const sortedFacilities = borrowerFacilities
        ? borrowerFacilities
              .slice()
              .sort((a, b) => a.facilityNumber.localeCompare(b.facilityNumber))
        : []

    const handleChipRender = (selected) => {
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((id) => {
                    const facility = sortedFacilities.find((b) => b._id === id)
                    return facility ? <Chip key={id} label={facility.facilityNumber} /> : null
                })}
            </Box>
        )
    }

    return (
        <SelectValidated
            multiple
            name='facilityIds'
            id='facilityIds'
            renderValue={handleChipRender}
            {...otherProps}
        >
            {isFacilityMultiSelectDisabled ? (
                <MenuItem value='' disabled>
                    Select Borrower first
                </MenuItem>
            ) : sortedFacilities.length > 0 ? (
                sortedFacilities.map((facility) => (
                    <MenuItem key={facility.facilityNumber} value={facility._id}>
                        {facility.facilityNumber}
                    </MenuItem>
                ))
            ) : (
                <MenuItem value='' disabled>
                    Borrower has no Facilities
                </MenuItem>
            )}
        </SelectValidated>
    )
}

export default FacilityMultiSelect
