import React from 'react'
import { MenuItem } from '@mui/material'
import SelectValidated from './SelectValidated'

const PositionSelect = ({ positions, onChange, isPositionSelectDisabled, ...otherProps }) => {
    const sortedPositions = positions
        ? positions.slice().sort((a, b) => a.taId.localeCompare(b.taId))
        : []
    return (
        <SelectValidated name='positionId' id='positionId' onChange={onChange} {...otherProps}>
            {isPositionSelectDisabled ? (
                <MenuItem value='' disabled>
                    Select Facility first
                </MenuItem>
            ) : sortedPositions.length > 0 ? (
                sortedPositions.map((position) => (
                    <MenuItem key={position.taId} value={position._id}>
                        {position.taId}
                    </MenuItem>
                ))
            ) : (
                <MenuItem value='' disabled>
                    Facility has no Positions
                </MenuItem>
            )}
        </SelectValidated>
    )
}

export default PositionSelect
