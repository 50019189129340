import React from 'react'
import { MenuItem } from '@mui/material'
import SelectValidated from './SelectValidated'

const FacilitySelect = ({ facilities, onChange, isFacilitySelectDisabled, ...otherProps }) => {
    const sortedFacilities = facilities
        ? facilities.slice().sort((a, b) => a.facilityNumber.localeCompare(b.facilityNumber))
        : []
    return (
        <SelectValidated name='facilityId' id='facilityId' onChange={onChange} {...otherProps}>
            {isFacilitySelectDisabled ? (
                <MenuItem value='' disabled>
                    Select Borrower first
                </MenuItem>
            ) : sortedFacilities.length > 0 ? (
                sortedFacilities.map((facility) => (
                    <MenuItem key={facility.facilityNumber} value={facility._id}>
                        {facility.facilityNumber}
                    </MenuItem>
                ))
            ) : (
                <MenuItem value='' disabled>
                    Borrower has no Facilities
                </MenuItem>
            )}
        </SelectValidated>
    )
}

export default FacilitySelect
