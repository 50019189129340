import React, { useState } from 'react'
import { TextField, IconButton, InputAdornment } from '@mui/material'
import { useField } from 'formik'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const PasswordField = ({ name, ...otherProps }) => {
    const [showPassword, setShowPassword] = useState(false)
    const [field, meta] = useField(name)

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev)
    }

    const configPasswordField = {
        ...field,
        ...otherProps,
        fullWidth: true,
        variant: 'outlined',
        size: 'small',
        type: showPassword ? 'text' : 'password',
        InputProps: {
            endAdornment: (
                <InputAdornment position='end'>
                    <IconButton onClick={togglePasswordVisibility} edge='end'>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
            ),
        },
    }

    if (meta && meta.touched && meta.error) {
        configPasswordField.error = true
        configPasswordField.helperText = meta.error
    }

    return <TextField {...configPasswordField} />
}

export default PasswordField
