import React, { useState } from 'react'
import { MenuItem, Box, Chip, Checkbox, ListItemText } from '@mui/material'
import SelectValidated from './SelectValidated'

const UserMultiSelect = ({ users, onChange, ...otherProps }) => {
    const [selectedUsers, setSelectedUsers] = useState([])

    const sortedUsers = users
        ? users.slice().sort((a, b) => a.firstName.localeCompare(b.firstName))
        : []

    const handleSelectionChange = (event) => {
        const selected = event.target.value

        setSelectedUsers(selected)
        onChange({ target: { name: 'userIds', value: selected } })
    }

    const handleChipRender = (selected) => {
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((id) => {
                    const user = sortedUsers.find((b) => b._id === id)
                    return user ? (
                        <Chip key={id} label={`${user.firstName} ${user.lastName}`} />
                    ) : null
                })}
            </Box>
        )
    }

    return (
        <SelectValidated
            multiple
            name='userIds'
            id='userIds'
            value={selectedUsers}
            onChange={handleSelectionChange}
            renderValue={handleChipRender}
            {...otherProps}
        >
            {sortedUsers.length > 0 ? (
                sortedUsers.map((user) => (
                    <MenuItem key={`${user.firstName} ${user.lastName}`} value={user._id}>
                        <Checkbox checked={selectedUsers.indexOf(user._id) > -1} />
                        <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                    </MenuItem>
                ))
            ) : (
                <MenuItem value='' disabled>
                    No Users
                </MenuItem>
            )}
        </SelectValidated>
    )
}

export default UserMultiSelect
