import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Button,
    MenuItem,
    Divider,
} from '@mui/material'
import dayjs from 'dayjs'

import TextFieldValidated from '../../fields/TextFieldValidated'

import { selectCurrentUser } from '../../../../app/api/auth/authSlice'
import { useUpdateParticipationMutation } from '../../../../app/api/records/participationsApiSlice'
import InputLabelStyled from '../../fields/InputLabelStyled'
import PercentFieldValidated from '../../fields/PercentFieldValidated'
import DatePickerValidated from '../../fields/DatePickerValidated'
import PreSubmitButton from '../../buttons/PreSubmitButton'
import { formatDatePickerDate } from '../../../../utils/helpers/formatValue'

import InvalidDateModal from '../../modals/InvalidDateModal'
import ConfirmSubmitModal from '../../modals/ConfirmSubmitModal'

const participationFormValidation = Yup.object().shape({
    positionId: Yup.string().required('Required'),
    fundCode: Yup.string().required('Required'),
    taId: Yup.string().required('Required'),
    fundTaId: Yup.string().required('Required'),
    participationRatio: Yup.number().required('Required'),
    spreadRate: Yup.number().required('Required'),
    drawdownDate: Yup.date().required('Required'),
    originalMaturityDate: Yup.date().required('Required'),
    approvedMaturityDate: Yup.date().required('Required'),
    relationshipType: Yup.string().required('Required'),
})

const UpdateParticipationForm = ({ isFormOpen, handleCloseForm, toBeUpdated, isEditClicked }) => {
    const { firstName, lastName } = useSelector(selectCurrentUser)
    const userFullname = `${firstName} ${lastName}`

    const initialParticipationFormState = {
        borrowerName: '',
        facilityNumber: '',
        positionId: '',
        fundCode: '',
        taId: '',
        fundTaId: '',
        participationRatio: '',
        spreadRate: '',
        drawdownDate: null,
        originalMaturityDate: null,
        approvedMaturityDate: null,
        relationshipType: '',
        lastUpdatedBy: '',
    }

    const [updateParticipation] = useUpdateParticipationMutation()

    const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] = useState(false)
    const [isUpdateSuccessModalOpen, setIsUpdateSuccessModalOpen] = useState(false)
    const [isMissingFieldModalOpen, setIsMissingFieldModalOpen] = useState(false)
    const [isInvalidDateModalOpen, setIsInvalidDateModalOpen] = useState(false)

    const formikRef = useRef()

    const handleOpenConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(true)
    }

    const handleCloseConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(false)
    }

    const handleOpenUpdateSuccessModal = () => {
        setIsUpdateSuccessModalOpen(true)
    }

    const handleCloseUpdateSuccessModal = () => {
        setIsUpdateSuccessModalOpen(false)
        handleCloseForm()
    }

    const handleOpenMissingFieldModal = () => {
        setIsMissingFieldModalOpen(true)
    }

    const handleCloseMissingFieldModal = () => {
        setIsMissingFieldModalOpen(false)
    }

    const handleOpenInvalidDateModal = () => {
        setIsInvalidDateModalOpen(true)
    }

    const handleCloseInvalidDateModal = () => {
        setIsInvalidDateModalOpen(false)
    }

    if (toBeUpdated && isEditClicked) {
        initialParticipationFormState._id = toBeUpdated._id
        initialParticipationFormState.borrowerName = toBeUpdated.borrowerName
        initialParticipationFormState.facilityNumber = toBeUpdated.facilityNumber
        initialParticipationFormState.positionId = toBeUpdated.positionId
        initialParticipationFormState.fundCode = toBeUpdated.fundCode
        initialParticipationFormState.taId = toBeUpdated.taId
        initialParticipationFormState.fundTaId = toBeUpdated.fundTaId
        initialParticipationFormState.participationRatio = toBeUpdated.participationRatio
        initialParticipationFormState.spreadRate = toBeUpdated.spreadRate
        initialParticipationFormState.drawdownDate = dayjs(new Date(toBeUpdated.drawdownDate))
        initialParticipationFormState.originalMaturityDate = dayjs(
            new Date(toBeUpdated.originalMaturityDate)
        )
        initialParticipationFormState.approvedMaturityDate = dayjs(
            new Date(toBeUpdated.approvedMaturityDate)
        )
        initialParticipationFormState.relationshipType = toBeUpdated.relationshipType
        initialParticipationFormState.lastUpdatedBy = userFullname
    }

    if (!isEditClicked) {
        initialParticipationFormState._id = ''
        initialParticipationFormState.borrowerName = ''
        initialParticipationFormState.facilityNumber = ''
        initialParticipationFormState.positionId = ''
        initialParticipationFormState.fundCode = ''
        initialParticipationFormState.taId = ''
        initialParticipationFormState.fundTaId = ''
        initialParticipationFormState.participationRatio = ''
        initialParticipationFormState.spreadRate = ''
        initialParticipationFormState.drawdownDate = null
        initialParticipationFormState.originalMaturityDate = null
        initialParticipationFormState.approvedMaturityDate = null
        initialParticipationFormState.relationshipType = ''
        initialParticipationFormState.lastUpdatedBy = ''
    }

    const handleParticipationFormSubmit = async (values) => {
        const {
            _id,
            positionId,
            fundCode,
            taId,
            fundTaId,
            participationRatio,
            spreadRate,
            drawdownDate,
            originalMaturityDate,
            approvedMaturityDate,
            relationshipType,
            lastUpdatedBy,
        } = values

        try {
            const response = await updateParticipation({
                _id,
                positionId,
                fundCode,
                taId,
                fundTaId,
                participationRatio,
                spreadRate,
                drawdownDate,
                originalMaturityDate,
                approvedMaturityDate,
                relationshipType,
                lastUpdatedBy,
            })

            if (response.data && response.data.newData) {
                const { error, duplicateData, newData } = response.data
                handleOpenUpdateSuccessModal()
            }
        } catch (error) {
            console.error('Error updating participation:', error)
        }
    }

    const handleParticipationsFormClose = () => {
        handleCloseForm()
        if (formikRef.current) {
            formikRef.current.resetForm()
        }
    }

    return (
        <Dialog open={isFormOpen} onClose={handleParticipationsFormClose}>
            <DialogTitle>Update Participation</DialogTitle>
            <Formik
                initialValues={{ ...initialParticipationFormState }}
                validationSchema={participationFormValidation}
                onSubmit={(values) => {
                    handleParticipationFormSubmit(values)
                }}
                validateOnMount={true}
                innerRef={formikRef}
            >
                {(formik) => (
                    <Form>
                        <DialogContent dividers>
                            <DialogContentText sx={{ mb: 2 }}>
                                Please fill in details of the Updated Participation.
                            </DialogContentText>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <InputLabelStyled disabled htmlFor='borrowerName'>
                                        Borrower Name
                                    </InputLabelStyled>
                                    <TextFieldValidated
                                        disabled
                                        name='borrowerName'
                                        id='borrowerName'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled disabled htmlFor='facilityNumber'>
                                        Facility Number
                                    </InputLabelStyled>
                                    <TextFieldValidated
                                        disabled
                                        name='facilityNumber'
                                        id='facilityNumber'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled disabled htmlFor='fundCode'>
                                        Fund Code
                                    </InputLabelStyled>
                                    <TextFieldValidated disabled name='fundCode' id='fundCode' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled disabled htmlFor='taId'>
                                        TA ID
                                    </InputLabelStyled>
                                    <TextFieldValidated disabled name='taId' id='taId' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled disabled htmlFor='participationRatio'>
                                        Participation Ratio
                                    </InputLabelStyled>
                                    <PercentFieldValidated
                                        disabled
                                        name='participationRatio'
                                        id='participationRatio'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {formik.values.relationshipType === 'Parent' ? (
                                        <>
                                            <InputLabelStyled disabled htmlFor='spreadRate'>
                                                Spread Rate
                                            </InputLabelStyled>
                                        </>
                                    ) : (
                                        <>
                                            <InputLabelStyled htmlFor='spreadRate'>
                                                Spread Rate *
                                            </InputLabelStyled>
                                        </>
                                    )}
                                    <PercentFieldValidated
                                        disabled={formik.values.relationshipType === 'Parent'}
                                        name='spreadRate'
                                        id='spreadRate'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled disabled htmlFor='relationshipType'>
                                        Relationship Type
                                    </InputLabelStyled>
                                    <TextFieldValidated
                                        disabled
                                        name='relationshipType'
                                        id='relationshipType'
                                    />
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled disabled htmlFor='drawdownDate'>
                                        Drawdown Date
                                    </InputLabelStyled>
                                    <DatePickerValidated
                                        disabled
                                        name='drawdownDate'
                                        id='drawdownDate'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled disabled htmlFor='originalMaturityDate'>
                                        Original Maturity Date
                                    </InputLabelStyled>
                                    <DatePickerValidated
                                        disabled
                                        name='originalMaturityDate'
                                        id='originalMaturityDate'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='approvedMaturityDate'>
                                        Approved Maturity Date *
                                    </InputLabelStyled>
                                    <DatePickerValidated
                                        name='approvedMaturityDate'
                                        id='approvedMaturityDate'
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' onClick={handleCloseForm}>
                                Cancel
                            </Button>
                            <PreSubmitButton
                                values={{
                                    ...formik,
                                    values: {
                                        ...formik.values,
                                        drawdownDate: formatDatePickerDate(
                                            formik.values.drawdownDate
                                        ),
                                        originalMaturityDate: formatDatePickerDate(
                                            formik.values.originalMaturityDate
                                        ),
                                        approvedMaturityDate: formatDatePickerDate(
                                            formik.values.approvedMaturityDate
                                        ),
                                    },
                                }}
                                handleOpenMissingFieldModal={handleOpenMissingFieldModal}
                                handleOpenInvalidDateModal={handleOpenInvalidDateModal}
                                handleOpenConfirmSubmitModal={handleOpenConfirmSubmitModal}
                            >
                                Submit
                            </PreSubmitButton>
                        </DialogActions>
                        <ConfirmSubmitModal
                            values={{
                                ...formik,
                                values: {
                                    ...formik.values,
                                    drawdownDate: formatDatePickerDate(formik.values.drawdownDate),
                                    originalMaturityDate: formatDatePickerDate(
                                        formik.values.originalMaturityDate
                                    ),
                                    approvedMaturityDate: formatDatePickerDate(
                                        formik.values.approvedMaturityDate
                                    ),
                                },
                            }}
                            isConfirmSubmitModalOpen={isConfirmSubmitModalOpen}
                            handleCloseConfirmSubmitModal={handleCloseConfirmSubmitModal}
                        />
                        <InvalidDateModal
                            isInvalidDateModalOpen={isInvalidDateModalOpen}
                            handleCloseInvalidDateModal={handleCloseInvalidDateModal}
                        />
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default UpdateParticipationForm
