import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { loginSuccess, logout } from './auth/authSlice'

const baseQuery = fetchBaseQuery({
    baseUrl:
        process.env.REACT_APP_ENVIRONMENT === 'development'
            ? process.env.REACT_APP_DEV_BASE_URL
            : process.env.REACT_APP_ENVIRONMENT === 'production'
            ? process.env.REACT_APP_PROD_BASE_URL
            : 'https://api.leezonghan.com',
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const accessToken = getState().auth.accessToken

        if (accessToken) {
            headers.set('authorization', `Bearer ${accessToken}`)
        }
        return headers
    },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)

    if (!result.error) {
        return result
    } else if (result.error.status === 403) {
        const refreshResult = await baseQuery('auth/refresh', api, extraOptions)

        if (refreshResult.data) {
            api.dispatch(
                loginSuccess({
                    accessToken: refreshResult.data.accessToken,
                    user: refreshResult.data.user,
                })
            )
            result = await baseQuery(args, api, extraOptions)
        } else {
            if (refreshResult.error.status === 401) {
                refreshResult.error.data.error = 'Your login has expired. '
                // jwt not cleared from local storage
                api.dispatch(logout())
                api.dispatch(apiSlice.util.resetApiState())
            }
            return refreshResult
        }
    }
    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        'Lenders',
        'Funds',
        'Borrowers',
        'Facilities',
        'Positions',
        'Participations',
        'Transactions',
        'ExchangeRates',
        'SupplementaryInterestRates',
        'BankAccounts',
        'Users',
        'Holdings',
        'StatementOfAccounts',
        'UserLogs',
    ],
    endpoints: (build) => ({}),
})
