import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import Header from '../layout/Header'
import TransactionToolbar from '../ui/datagrid/TransactionToolbar'
import CustomFilterForm from '../ui/datagrid/CustomFilterForm'
import CustomNoRowOverlay from '../ui/datagrid/CustomNoRowOverlay'
import { formatDataGridDate, formatMoney } from '../../utils/helpers/formatValue'

import { useGetTransactionHoldingsQuery } from '../../app/api/reports/holdingsApiSlice'

import TransactionHoldingsFilterForm from '../ui/forms/filters/TransactionHoldingsFilterForm'

const ApplyTransactionTab = () => {
    const initialHoldingFilterFormState = {
        level: '',
        borrowerId: '',
        facilityIds: [],
        positionId: '',
        transactionDate: null,
    }

    const [filterParams, setFilterParams] = useState(initialHoldingFilterFormState)
    const [selectedLevel, setSelectedLevel] = useState('')
    const [selectedRowsData, setSelectedRowsData] = useState([])
    const [selectionModel, setSelectionModel] = useState([])
    const [selectedTransactionDate, setSelectedTransactionDate] = useState(null)

    const {
        data: transactionHoldings,
        isFetching: isGenerating,
        error,
    } = useGetTransactionHoldingsQuery(filterParams, {
        skip:
            !filterParams.transactionDate &&
            !filterParams.level &&
            !filterParams.borrowerId &&
            !filterParams.facilityIds.length &&
            !filterParams.positionId,
    })

    useEffect(() => {
        if (
            selectedLevel === 'Borrower' ||
            selectedLevel === 'Facility' ||
            selectedLevel === 'Position'
        ) {
            const allRowIds = transactionHoldings ? transactionHoldings.map((row) => row._id) : []
            setSelectionModel(allRowIds)
            const selectedRowData = transactionHoldings ? transactionHoldings : []
            setSelectedRowsData(selectedRowData)
        } else {
            setSelectionModel([])
            setSelectedRowsData([])
        }
    }, [selectedLevel, setSelectionModel, setSelectedRowsData, transactionHoldings])

    const columns = [
        {
            field: 'lenderCode',
            headerName: 'Lender Code',
            width: 100,
        },
        {
            field: 'facilityNumber',
            headerName: 'Facility Number',
            width: 130,
        },
        {
            field: 'fundCode',
            headerName: 'Fund Code',
            width: 100,
        },
        {
            field: 'taId',
            headerName: 'TA ID',
            width: 240,
        },
        { field: 'currency', headerName: 'Currency', width: 90 },
        {
            field: 'outstandingPrincipal',
            headerName: 'Outstanding Principal',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'totalInterest',
            headerName: 'Total Interest',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'totalFee',
            headerName: 'Total Fee',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'asOfDate',
            headerName: 'As of Date',
            width: '100',
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
    ]

    const handleHoldingsFilterFormSubmit = (values) => {
        const { level, borrowerId, facilityIds, positionId, transactionDate } = values
        setFilterParams({ level, borrowerId, facilityIds, positionId, transactionDate })
    }

    return (
        <>
            <Header
                subtitle={
                    <>
                        Note:
                        <br />
                        1. Select Transaction Date.
                        <br />
                        2. Select Level to apply Transaction on (Borrower, Facility, Position or
                        Participation).
                        <br />
                        3. Select 'Participation' to apply Transactions manually for each Fund - TA
                        ID pair.
                    </>
                }
            />
            <Box
                sx={{
                    m: '1.5rem 2.5rem',
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    '& .MuiDataGrid-root': {
                        maxWidth: '95vw',
                    },
                }}
            >
                <TransactionHoldingsFilterForm
                    onSubmit={handleHoldingsFilterFormSubmit}
                    isGenerating={isGenerating}
                    initialHoldingFilterFormState={initialHoldingFilterFormState}
                    selectedLevel={selectedLevel}
                    setSelectedLevel={setSelectedLevel}
                    setSelectedTransactionDate={setSelectedTransactionDate}
                />
            </Box>
            <Box
                sx={{
                    m: '1.5rem 2.5rem',
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    height: '80vh',
                    '& .MuiDataGrid-root': {
                        maxWidth: '95vw',
                    },
                }}
            >
                <DataGrid
                    checkboxSelection={selectedLevel === 'Participation'}
                    disableRowSelectionOnClick
                    loading={isGenerating}
                    getRowId={(row) => row._id}
                    rows={error ? [] : transactionHoldings || []}
                    columns={columns}
                    pageSizeOptions={[20, 50, 100]}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 20 } },
                        columns: {
                            columnVisibilityModel: {
                                // validFrom: false,
                            },
                        },
                        sorting: {
                            sortModel: [{ field: 'taId', sort: 'asc' }],
                        },
                    }}
                    slots={{
                        toolbar: TransactionToolbar,
                        noRowsOverlay: CustomNoRowOverlay,
                    }}
                    slotProps={{
                        filterPanel: {
                            filterFormProps: CustomFilterForm,
                        },
                        toolbar: { isGenerating, selectedRowsData, selectedTransactionDate },
                    }}
                    selectionModel={selectionModel}
                    isRowSelectable={(params) => {
                        if (
                            selectedLevel === 'Facility' ||
                            selectedLevel === 'Borrower' ||
                            selectedLevel === 'Position'
                        ) {
                            return false
                        }
                        return true
                    }}
                    onRowSelectionModelChange={(newSelection) => {
                        const selectedRowIds = new Set(newSelection)
                        const selectedRowData = transactionHoldings.filter((row) =>
                            selectedRowIds.has(row._id)
                        )
                        setSelectedRowsData(selectedRowData)
                        setSelectionModel(newSelection)
                    }}
                />
            </Box>
        </>
    )
}

export default ApplyTransactionTab
