import {
    Box,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Button,
    Typography,
    Grid,
    Divider,
    useTheme,
} from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { formatCase, formatModalValue } from '../../../utils/helpers/formatValue'
import {
    lenderKeys,
    lenderFlagKeys,
    fundKeys,
    fundFlagKeys,
    borrowerKeys,
    borrowerFlagKeys,
    facilityKeys,
    facilityFlagKeys,
    positionKeys,
    positionFlagKeys,
    participationKeys,
    participationFlagKeys,
    transactionKeys,
    transactionFlagKeys,
    exchangeRateKeys,
    exchangeRateFlagKeys,
    supplementaryInterestRateKeys,
    supplementaryInterestRateFlagKeys,
    bankAccountKeys,
    bankAccountFlagKeys,
    userKeys,
    userFlagKeys,
} from '../../../utils/lists/modalKeys'

const DuplicateErrorModal = ({
    values,
    duplicateParticipation,
    duplicateEntry,
    isDuplicateErrorModalOpen,
    handleCloseDuplicateErrorModal,
}) => {
    const location = useLocation()
    const theme = useTheme()

    let newRecord
    if (duplicateParticipation) {
        const { fundCode, taId, fundTaId, participationRatio, spreadRate, relationshipType } =
            duplicateParticipation

        newRecord = {
            ...newRecord,
            fundCode,
            taId,
            fundTaId,
            participationRatio,
            spreadRate,
            relationshipType,
        }
    } else {
        newRecord = { ...values.values }
    }

    let requiredKeys
    let flagKeys
    let duplicateMessage
    if (location.pathname.includes('/records/lenders')) {
        requiredKeys = lenderKeys
        flagKeys = lenderFlagKeys
        duplicateMessage = 'Lender Name or Lender Code'
    } else if (location.pathname.includes('/records/funds')) {
        requiredKeys = fundKeys
        flagKeys = fundFlagKeys
        duplicateMessage = 'Fund Name or Fund Code'
    } else if (location.pathname.includes('/records/borrowers')) {
        requiredKeys = borrowerKeys
        flagKeys = borrowerFlagKeys
        duplicateMessage = 'Borrower Name or Borrower Code'
    } else if (location.pathname.includes('/records/facilities')) {
        requiredKeys = facilityKeys
        flagKeys = facilityFlagKeys
        duplicateMessage = 'Facility Number or Facility Legal ID'
    } else if (location.pathname.includes('/records/positions')) {
        requiredKeys = positionKeys
        flagKeys = positionFlagKeys
        duplicateMessage = 'TA ID or Loan ID'
    } else if (location.pathname.includes('/records/participations')) {
        requiredKeys = participationKeys
        flagKeys = participationFlagKeys
        duplicateMessage = 'Fund Code - TA ID pair'
    } else if (location.pathname.includes('/records/transactions')) {
        requiredKeys = transactionKeys
        flagKeys = transactionFlagKeys
        duplicateMessage = 'Fund Code - TA ID pair'
    } else if (location.pathname.includes('/records/exchange-rates')) {
        requiredKeys = exchangeRateKeys
        flagKeys = exchangeRateFlagKeys
        duplicateMessage = 'Exchange rate for as of date'
    } else if (location.pathname.includes('/records/supplementary-interest-rates')) {
        requiredKeys = supplementaryInterestRateKeys
        flagKeys = supplementaryInterestRateFlagKeys
        duplicateMessage = 'Supplementary interest rate for this effective date and TA ID'
    } else if (location.pathname.includes('/records/bank-accounts')) {
        requiredKeys = bankAccountKeys
        flagKeys = bankAccountFlagKeys
        duplicateMessage = 'Bank account number'
    } else if (location.pathname.includes('/auth/users')) {
        requiredKeys = userKeys
        flagKeys = userFlagKeys
        duplicateMessage = 'User with same email'
    } else {
        requiredKeys = []
        duplicateMessage = ''
    }

    const newRecordFilteredValues = Object.entries(newRecord).filter(([key]) =>
        requiredKeys.includes(key)
    )
    const newRecordSortedValues = requiredKeys
        .filter((key) => newRecord[key] !== undefined)
        .map((key) => [key, newRecord[key]])
        .concat(newRecordFilteredValues.filter(([key]) => !requiredKeys.includes(key)))

    const existingRecordFilteredValues = Object.entries(duplicateEntry).filter(([key]) =>
        requiredKeys.includes(key)
    )
    const existingRecordSortedValues = requiredKeys
        .filter((key) => duplicateEntry[key] !== undefined)
        .map((key) => [key, duplicateEntry[key]])
        .concat(existingRecordFilteredValues.filter(([key]) => !requiredKeys.includes(key)))

    return (
        <Dialog
            open={isDuplicateErrorModalOpen}
            onClose={handleCloseDuplicateErrorModal}
            maxWidth={false}
        >
            <DialogTitle color='error'>Warning - Duplicate Error</DialogTitle>
            <DialogContent dividers>
                <DialogContentText sx={{ mb: 2 }}>
                    {duplicateMessage} already exists!
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography fontWeight='bold'>New Record</Typography>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                        {newRecordSortedValues.map(([key, value]) => (
                            <Box
                                key={key}
                                sx={{
                                    marginTop: '4px',
                                    padding: '4px',
                                    backgroundColor: flagKeys.includes(key)
                                        ? // ? theme.palette.warning.light
                                          '#ffcdd2'
                                        : 'inherit',
                                    border: flagKeys.includes(key)
                                        ? '2px solid #e57373'
                                        : '2px solid transparent',
                                    borderRadius: '4px',
                                }}
                            >
                                <Typography component='span' fontWeight='bold'>
                                    {formatCase(key)}:
                                </Typography>
                                <br />
                                {key === 'funds' && Array.isArray(value)
                                    ? value.map((fund, index) => (
                                          <Typography key={index} component='span'>
                                              {formatModalValue(formatCase(key), fund)}
                                              <br />
                                          </Typography>
                                      ))
                                    : formatModalValue(formatCase(key), value)}
                            </Box>
                        ))}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography fontWeight='bold'>Existing Record</Typography>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                        {existingRecordSortedValues.map(([key, value]) => (
                            <Box
                                key={key}
                                sx={{
                                    marginTop: '4px',
                                    padding: '4px',
                                    backgroundColor: flagKeys.includes(key)
                                        ? // ? theme.palette.warning.light
                                          '#e0e0e0'
                                        : 'inherit',
                                    border: flagKeys.includes(key)
                                        ? '2px solid #9e9e9e'
                                        : '2px solid transparent',
                                    borderRadius: '4px',
                                }}
                            >
                                <Typography component='span' fontWeight='bold'>
                                    {formatCase(key)}:
                                </Typography>
                                <br />
                                {key === 'funds' && Array.isArray(value)
                                    ? value.map((fund, index) => (
                                          <Typography key={index} component='span'>
                                              {formatModalValue(formatCase(key), fund)}
                                              <br />
                                          </Typography>
                                      ))
                                    : formatModalValue(formatCase(key), value)}
                            </Box>
                        ))}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleCloseDuplicateErrorModal}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DuplicateErrorModal
