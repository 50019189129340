import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Button,
} from '@mui/material'
import React from 'react'

const UpdateSuccessModal = ({ isUpdateSuccessModalOpen, handleCloseUpdateSuccessModal }) => {
    return (
        <Dialog open={isUpdateSuccessModalOpen} onClose={handleCloseUpdateSuccessModal}>
            <DialogTitle sx={{ color: '#4caf50' }}>Update Success</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>Record has been successfully updated!</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleCloseUpdateSuccessModal}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default UpdateSuccessModal
