import { apiSlice } from '../apiSlice'

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getAllUsers: build.query({
            query: () => ({ url: `auth/users`, method: 'GET' }),
            providesTags: ['Users'],
        }),
        registerUser: build.mutation({
            query: (newRow) => ({
                url: `auth/users/register`,
                method: 'POST',
                body: newRow,
            }),
            invalidatesTags: ['Users'],
        }),
        updateUser: build.mutation({
            query: (updatedRow) => ({
                url: `auth/users`,
                method: 'PUT',
                body: updatedRow,
            }),
            invalidatesTags: ['Users'],
        }),
        deleteUser: build.mutation({
            query: (id) => ({
                url: `auth/users/${id}`,
                method: 'DELETE',
                body: id,
            }),
            invalidatesTags: ['Users'],
        }),
    }),
})

export const {
    useRegisterUserMutation,
    useGetAllUsersQuery,
    useUpdateUserMutation,
    useDeleteUserMutation,
} = usersApiSlice
