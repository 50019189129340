import React, { useRef } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Button, CircularProgress, Grid } from '@mui/material'

import InputLabelStyled from '../../fields/InputLabelStyled'
import DatePickerValidated from '../../fields/DatePickerValidated'
import { formatDatePickerDate } from '../../../../utils/helpers/formatValue'

const holdingsFilterFormValidation = Yup.object().shape({
    asOfDate: Yup.date().nullable().required('Required'),
})

const HoldingsFilterForm = ({ onSubmit, initialHoldingFilterFormState, isGenerating }) => {
    const formikRef = useRef()

    return (
        <Formik
            initialValues={{ ...initialHoldingFilterFormState }}
            validationSchema={holdingsFilterFormValidation}
            onSubmit={(values) => {
                const formattedValues = {
                    ...values,
                    asOfDate: formatDatePickerDate(values.asOfDate),
                }
                onSubmit(formattedValues)
            }}
            validateOnMount={true}
            innerRef={formikRef}
        >
            {(formik) => (
                <Form>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <InputLabelStyled htmlFor='asOfDate'>As Of Date</InputLabelStyled>
                            <DatePickerValidated name='asOfDate' />
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            {isGenerating ? (
                                <Button
                                    disabled
                                    variant='contained'
                                    endIcon={
                                        <CircularProgress
                                            size={16}
                                            sx={{
                                                color: 'inherit',
                                                height: '16px',
                                                width: '16px',
                                            }}
                                        />
                                    }
                                >
                                    Generate
                                </Button>
                            ) : (
                                <Button
                                    variant='contained'
                                    onClick={formik.submitForm}
                                    disabled={!formik.values.asOfDate}
                                >
                                    Generate
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}

export default HoldingsFilterForm
