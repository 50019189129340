import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Button,
    MenuItem,
} from '@mui/material'
import TextFieldValidated from '../../fields/TextFieldValidated'
import InputLabelStyled from '../../fields/InputLabelStyled'
import SelectValidated from '../../fields/select/SelectValidated'
import PreSubmitButton from '../../buttons/PreSubmitButton'

import { borrowerTypeList } from '../../../../utils/lists/borrowerTypeList'
import { countryList } from '../../../../utils/lists/countryList'

import { selectCurrentUser } from '../../../../app/api/auth/authSlice'
import { useCreateBorrowerMutation } from '../../../../app/api/records/borrowersApiSlice'
import DuplicateErrorModal from '../../modals/DuplicateErrorModal'
import ConfirmSubmitModal from '../../modals/ConfirmSubmitModal'
import CreateSuccessModal from '../../modals/CreateSuccessModal'
import MissingFieldModal from '../../modals/MissingFieldModal'

const borrowerFormValidation = Yup.object().shape({
    borrowerCode: Yup.string().required('Required'),
    borrowerName: Yup.string().required('Required'),
    borrowerDescription: Yup.string().required('Required'),
    borrowerLegalDomicile: Yup.string().required('Required'),
    borrowerType: Yup.string().required('Required'),
})

const CreateBorrowerForm = ({ isFormOpen, handleCloseForm }) => {
    const { firstName, lastName } = useSelector(selectCurrentUser)
    const userFullName = `${firstName} ${lastName}`

    const initialBorrowerFormState = {
        borrowerCode: '',
        borrowerName: '',
        borrowerDescription: '',
        borrowerLegalDomicile: '',
        borrowerType: '',
        createdBy: userFullName,
    }

    const [createBorrower] = useCreateBorrowerMutation()

    const [duplicateEntry, setDuplicateEntry] = useState([])

    const [isDuplicateErrorModalOpen, setIsDuplicateErrorModalOpen] = useState(false)
    const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] = useState(false)
    const [isCreateSuccessModalOpen, setIsCreateSuccessModalOpen] = useState(false)
    const [isMissingFieldModalOpen, setIsMissingFieldModalOpen] = useState(false)

    const handleOpenDuplicateErrorModal = ({ existingData }) => {
        setIsDuplicateErrorModalOpen(true)
        setDuplicateEntry(existingData)
    }

    const handleCloseDuplicateErrorModal = () => {
        setIsDuplicateErrorModalOpen(false)
    }

    const handleOpenConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(true)
    }

    const handleCloseConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(false)
    }

    const handleOpenCreateSuccessModal = () => {
        setIsCreateSuccessModalOpen(true)
    }

    const handleCloseCreateSuccessModal = () => {
        setIsCreateSuccessModalOpen(false)
        handleCloseForm()
    }

    const handleOpenMissingFieldModal = () => {
        setIsMissingFieldModalOpen(true)
    }

    const handleCloseMissingFieldModal = () => {
        setIsMissingFieldModalOpen(false)
    }

    const handleBorrowerFormSubmit = async (values) => {
        const {
            borrowerCode,
            borrowerName,
            borrowerDescription,
            borrowerLegalDomicile,
            borrowerType,
            createdBy,
        } = values

        try {
            const response = await createBorrower({
                borrowerCode,
                borrowerName,
                borrowerDescription,
                borrowerLegalDomicile,
                borrowerType,
                createdBy,
            })

            if (response.error && response.error.data && response.error.data.existingData) {
                const { error, existingData, newData } = response.error.data

                handleOpenDuplicateErrorModal({ existingData })
            } else if (response.data && response.data.newData) {
                const { error, existingData, newData } = response.data
                handleOpenCreateSuccessModal()
            }
        } catch (error) {
            console.error('Error creating borrower:', error)
        }
    }

    return (
        <Dialog open={isFormOpen} onClose={handleCloseForm}>
            <DialogTitle>Create New Borrower</DialogTitle>
            <Formik
                initialValues={{ ...initialBorrowerFormState }}
                validationSchema={borrowerFormValidation}
                onSubmit={(values) => {
                    handleBorrowerFormSubmit(values)
                }}
                validateOnMount={true}
            >
                {(values) => (
                    <Form>
                        <DialogContent dividers>
                            <DialogContentText sx={{ mb: 2 }}>
                                Please fill in the details of the New Borrower. Ensure that both the
                                Borrower Code and Borrower Name are not duplicated.
                            </DialogContentText>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='borrowerType'>
                                        Borrower Type *
                                    </InputLabelStyled>
                                    <SelectValidated name='borrowerType' id='borrowerType'>
                                        {borrowerTypeList.map((borrowerTypeListItem) => (
                                            <MenuItem
                                                key={borrowerTypeListItem}
                                                value={borrowerTypeListItem}
                                            >
                                                {borrowerTypeListItem}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='borrowerCode'>
                                        Borrower Code *
                                    </InputLabelStyled>
                                    <TextFieldValidated name='borrowerCode' id='borrowerCode' />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='borrowerName'>
                                        Borrower Name *
                                    </InputLabelStyled>
                                    <TextFieldValidated name='borrowerName' id='borrowerName' />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='borrowerDescription'>
                                        Description *
                                    </InputLabelStyled>
                                    <TextFieldValidated
                                        name='borrowerDescription'
                                        id='borrowerDescription'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='borrowerLegalDomicile'>
                                        Legal Domicile *
                                    </InputLabelStyled>
                                    <SelectValidated
                                        name='borrowerLegalDomicile'
                                        id='borrowerLegalDomicile'
                                    >
                                        {countryList.map((country) => (
                                            <MenuItem
                                                key={country.countryCode}
                                                value={country.countryName}
                                            >
                                                {country.countryName}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' onClick={handleCloseForm}>
                                Cancel
                            </Button>
                            <PreSubmitButton
                                values={values}
                                handleOpenMissingFieldModal={handleOpenMissingFieldModal}
                                handleOpenConfirmSubmitModal={handleOpenConfirmSubmitModal}
                            >
                                Submit
                            </PreSubmitButton>
                        </DialogActions>
                        <DuplicateErrorModal
                            duplicateEntry={duplicateEntry}
                            values={values}
                            isDuplicateErrorModalOpen={isDuplicateErrorModalOpen}
                            handleCloseDuplicateErrorModal={handleCloseDuplicateErrorModal}
                        />
                        <ConfirmSubmitModal
                            values={values}
                            isConfirmSubmitModalOpen={isConfirmSubmitModalOpen}
                            handleCloseConfirmSubmitModal={handleCloseConfirmSubmitModal}
                        />
                        <CreateSuccessModal
                            values={values}
                            isCreateSuccessModalOpen={isCreateSuccessModalOpen}
                            handleCloseCreateSuccessModal={handleCloseCreateSuccessModal}
                        />
                        <MissingFieldModal
                            isMissingFieldModalOpen={isMissingFieldModalOpen}
                            handleCloseMissingFieldModal={handleCloseMissingFieldModal}
                        />
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default CreateBorrowerForm
