import { apiSlice } from '../apiSlice'

export const borrowersApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getAllBorrowers: build.query({
            query: () => ({ url: `records/borrowers`, method: 'GET' }),
            providesTags: ['Borrowers'],
        }),
        createBorrower: build.mutation({
            query: (newRow) => ({
                url: `records/borrowers`,
                method: 'POST',
                body: newRow,
            }),
            invalidatesTags: ['Borrowers', 'Facilities', 'Positions'],
        }),
        updateBorrower: build.mutation({
            query: (updatedRow) => ({
                url: `records/borrowers`,
                method: 'PUT',
                body: updatedRow,
            }),
            invalidatesTags: ['Borrowers', 'Facilities', 'Positions'],
        }),
        deleteBorrower: build.mutation({
            query: (id) => ({
                url: `records/borrowers/${id}`,
                method: 'DELETE',
                body: id,
            }),
            invalidatesTags: ['Borrowers', 'Facilities', 'Positions'],
        }),
    }),
})

export const {
    useCreateBorrowerMutation,
    useGetAllBorrowersQuery,
    useUpdateBorrowerMutation,
    useDeleteBorrowerMutation,
} = borrowersApiSlice
