import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Button,
    Typography,
} from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'

import DeleteButton from '../buttons/DeleteButton'
import { formatCase, formatModalValue } from '../../../utils/helpers/formatValue'
import {
    lenderKeys,
    fundKeys,
    borrowerKeys,
    facilityKeys,
    positionKeys,
    transactionKeys,
    participationKeys,
    exchangeRateKeys,
    supplementaryInterestRateKeys,
    bankAccountKeys,
    userKeys,
} from '../../../utils/lists/modalKeys'

const ConfirmDeleteModal = ({
    handleDelete,
    toBeDeleted,
    isConfirmDeleteModalOpen,
    handleCloseConfirmDeleteModal,
}) => {
    const location = useLocation()

    let newRecord = { ...toBeDeleted }

    let requiredKeys
    if (location.pathname.includes('/records/lenders')) {
        requiredKeys = lenderKeys
    } else if (location.pathname.includes('/records/funds')) {
        requiredKeys = fundKeys
    } else if (location.pathname.includes('/records/borrowers')) {
        requiredKeys = borrowerKeys
    } else if (location.pathname.includes('/records/facilities')) {
        requiredKeys = facilityKeys
    } else if (location.pathname.includes('/records/positions')) {
        requiredKeys = positionKeys
    } else if (location.pathname.includes('/records/participations')) {
        requiredKeys = participationKeys
    } else if (location.pathname.includes('/records/transactions')) {
        requiredKeys = transactionKeys
    } else if (location.pathname.includes('/records/exchange-rates')) {
        requiredKeys = exchangeRateKeys
    } else if (location.pathname.includes('/records/supplementary-interest-rates')) {
        requiredKeys = supplementaryInterestRateKeys
    } else if (location.pathname.includes('/records/bank-accounts')) {
        requiredKeys = bankAccountKeys
    } else if (location.pathname.includes('/auth/users')) {
        requiredKeys = userKeys
    } else {
        requiredKeys = []
    }

    const newRecordFilteredValues = Object.entries(newRecord).filter(([key]) =>
        requiredKeys.includes(key)
    )
    const newRecordSortedValues = requiredKeys
        .filter((key) => newRecord[key] !== undefined)
        .map((key) => [key, newRecord[key]])
        .concat(newRecordFilteredValues.filter(([key]) => !requiredKeys.includes(key)))
    return (
        <Dialog open={isConfirmDeleteModalOpen} onClose={handleCloseConfirmDeleteModal}>
            <DialogTitle color='error'>Warning</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Confirm deletion of record below? This action cannot be undone
                </DialogContentText>
                {newRecordSortedValues.map(([key, value]) => (
                    <div key={key} style={{ marginTop: '8px' }}>
                        <Typography component='span' fontWeight='bold'>
                            {formatCase(key)}:
                        </Typography>
                        <br />
                        {key === 'funds' && Array.isArray(value)
                            ? value.map((fund, index) => (
                                  <Typography key={index} component='span'>
                                      {formatModalValue(formatCase(key), fund)}
                                      <br />
                                  </Typography>
                              ))
                            : formatModalValue(formatCase(key), value)}
                    </div>
                ))}
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleCloseConfirmDeleteModal}>
                    Cancel
                </Button>
                <DeleteButton handleDelete={handleDelete}>Delete</DeleteButton>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDeleteModal
