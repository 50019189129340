import { apiSlice } from '../apiSlice'

export const userLogsApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getFilteredUserLogs: build.query({
            query: (userLogsFilterParams) => ({
                url: `logs/user-logs`,
                method: 'GET',
                params: userLogsFilterParams,
            }),
            providesTags: ['UserLogs'],
        }),
    }),
})

export const { useGetFilteredUserLogsQuery } = userLogsApiSlice
