const recordsMenu = [
    {
        text: 'Records',
        type: 'header',
    },
    {
        text: 'Lenders',
        type: 'item',
    },
    {
        text: 'Funds',
        type: 'item',
    },
    {
        text: 'Borrowers',
        type: 'item',
    },
    {
        text: 'Facilities',
        type: 'item',
    },
    {
        text: 'Positions',
        type: 'item',
    },
    {
        text: 'Participations',
        type: 'item',
    },
    {
        text: 'Transactions',
        type: 'item',
    },
    {
        text: 'Exchange Rates',
        type: 'item',
    },
    {
        text: 'Supplementary Interest Rates',
        type: 'item',
    },
    {
        text: 'Bank Accounts',
        type: 'item',
    },
]

const reportsMenu = [
    {
        text: 'Reports',
        type: 'header',
    },
    {
        text: 'Holdings',
        type: 'item',
    },
    {
        text: 'Statement of Accounts',
        type: 'item',
    },
]

export { recordsMenu, reportsMenu }
