import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import dayjs from 'dayjs'
import StatementOfAccountPDF from '../../components/templates/StatementOfAccountPDF'

const createZip = async (statementOfAccountArrays, statementOfAccountSummaries) => {
    const generatePDF = async (doc) => {
        const blob = await pdf(doc).toBlob()
        return blob
    }

    const zip = new JSZip()

    // Loop through both arrays, generate PDFs, and add them to the zip
    const pdfBlobs = await Promise.all(
        statementOfAccountArrays.map(async (statementOfAccountArray, index) => {
            const statementOfAccountSummary = statementOfAccountSummaries[index] // Get corresponding summary

            const doc = (
                <StatementOfAccountPDF
                    data={{ statementOfAccountArray, statementOfAccountSummary }}
                />
            )
            const blob = await generatePDF(doc)
            const fileName = `${statementOfAccountSummary.facilityNumber}_${dayjs(
                statementOfAccountSummary.asOfDate
            ).format('YYYY-MM-DD')}.pdf`
            return { blob, fileName }
        })
    )

    pdfBlobs.forEach(({ blob, fileName }) => {
        zip.file(fileName, blob)
    })

    // Generate the zip file and download
    const zipFileName = `SOA_${dayjs(statementOfAccountSummaries[0].asOfDate).format(
        'YYYY-MM-DD'
    )}_Generated_on_${dayjs().format('YYYY-MM-DD')}.zip`
    zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, zipFileName)
    })
}

export default createZip
