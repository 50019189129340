import React, { useRef } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Button, CircularProgress, Grid } from '@mui/material'

import InputLabelStyled from '../../fields/InputLabelStyled'
import DatePickerValidated from '../../fields/DatePickerValidated'
import UserMultiSelect from '../../fields/select/UserMultiSelect'
import UserActionMultiSelect from '../../fields/select/UserActionMultiSelect'
import TextFieldValidated from '../../fields/TextFieldValidated'
import { formatDatePickerDate } from '../../../../utils/helpers/formatValue'
import { userActionList } from '../../../../utils/lists/userActionList'

import { useGetAllUsersQuery } from '../../../../app/api/auth/usersApiSlice'

const userLogsFilterFormValidation = Yup.object().shape({
    userLogsFromDate: Yup.date().test(
        'from-to-pair',
        'Both From and To dates are required',
        function (value) {
            const { userLogsToDate } = this.parent
            return (!value && !userLogsToDate) || (value && userLogsToDate)
        }
    ),
    userLogsToDate: Yup.date().test(
        'from-to-pair',
        'Both From and To dates are required',
        function (value) {
            const { userLogsFromDate } = this.parent
            return (!value && !userLogsFromDate) || (value && userLogsFromDate)
        }
    ),
    userIds: Yup.array(),
    actions: Yup.array(),
    url: Yup.string(),
})

const UserLogsFilterForm = ({
    onSubmit,
    isFetching,
    initialUserLogsFilterFormState,
    handleOpenInvalidDateModal,
}) => {
    const formikRef = useRef()

    const { data: users } = useGetAllUsersQuery()

    return (
        <Formik
            initialValues={{ ...initialUserLogsFilterFormState }}
            validationSchema={userLogsFilterFormValidation}
            onSubmit={(values) => {
                if (values.userLogsFromDate < values.userLogsToDate) {
                    const formattedValues = {
                        ...values,
                        userLogsFromDate: formatDatePickerDate(values.userLogsFromDate),
                        userLogsToDate: formatDatePickerDate(values.userLogsToDate),
                    }
                    onSubmit(formattedValues)
                } else {
                    handleOpenInvalidDateModal()
                }
            }}
            validateOnMount={true}
            innerRef={formikRef}
        >
            {(formik) => (
                <Form>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <InputLabelStyled htmlFor='userLogsFromDate'>
                                Logs From
                            </InputLabelStyled>
                            <DatePickerValidated name='userLogsFromDate' />
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabelStyled htmlFor='userLogsToDate'>Logs To</InputLabelStyled>
                            <DatePickerValidated name='userLogsToDate' />
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={3}>
                            <InputLabelStyled htmlFor='userIds'>User(s)</InputLabelStyled>
                            <UserMultiSelect
                                users={users}
                                onChange={(event) => {
                                    formik.setFieldValue('userIds', event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabelStyled htmlFor='actions'>Action(s)</InputLabelStyled>
                            <UserActionMultiSelect
                                userActions={userActionList}
                                onChange={(event) => {
                                    formik.setFieldValue('actions', event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabelStyled htmlFor='url'>URL</InputLabelStyled>
                            <TextFieldValidated name='url' />
                        </Grid>
                        <Grid item xs={12}>
                            {isFetching ? (
                                <Button
                                    disabled
                                    variant='contained'
                                    endIcon={
                                        <CircularProgress
                                            size={16}
                                            sx={{
                                                color: 'inherit',
                                                height: '16px',
                                                width: '16px',
                                            }}
                                        />
                                    }
                                >
                                    Filter
                                </Button>
                            ) : (
                                <Button
                                    variant='contained'
                                    onClick={formik.submitForm}
                                    disabled={!formik.isValid}
                                >
                                    Filter
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}

export default UserLogsFilterForm
