import React from 'react'
import { Select, FormHelperText } from '@mui/material'
import { useField } from 'formik'

const SelectValidated = ({ name, onChange, ...otherProps }) => {
    const [field, meta, helpers] = useField(name)

    const handleChange = (event) => {
        helpers.setValue(event.target.value)
        if (onChange) {
            onChange(event) // Forward the event to the parent component
        }
    }

    const configSelect = {
        ...field,
        ...otherProps,
        fullWidth: true,
        variant: 'outlined',
        size: 'small',
        onChange: handleChange,
    }

    if (meta.touched && meta.error) {
        configSelect.error = true
    }

    return (
        <>
            <Select {...configSelect} />
            {meta.touched && meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
        </>
    )
}

export default SelectValidated
