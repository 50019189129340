import React from 'react'
import { Button } from '@mui/material'
import { useFormikContext } from 'formik'

const SubmitButton = ({ values, handleCloseConfirmSubmitModal, children, ...otherProps }) => {
    const { submitForm } = useFormikContext()

    const handleSubmit = () => {
        handleCloseConfirmSubmitModal()
        submitForm()
    }

    const configSubmitButton = {
        variant: 'contained',
        color: 'primary',
        onClick: handleSubmit,
    }

    return <Button {...configSubmitButton}>{children}</Button>
}

export default SubmitButton
