import React from 'react'
import { MenuItem } from '@mui/material'
import SelectValidated from './SelectValidated'

const FundSelect = ({ funds, ...otherProps }) => {
    const sortedFunds = funds
        ? funds.slice().sort((a, b) => a.fundName.localeCompare(b.fundName))
        : []
    return (
        <SelectValidated name='fundId' id='fundId' {...otherProps}>
            {sortedFunds &&
                sortedFunds.map((fund) => (
                    <MenuItem key={fund.fundCode} value={fund._id}>
                        {fund.fundName}
                    </MenuItem>
                ))}
        </SelectValidated>
    )
}

export default FundSelect
