import React from 'react'
import { Box } from '@mui/material'
import Header from '../layout/Header'
import CreateTransactionForm from '../ui/forms/records/CreateTransactionForm'

const CreateTransactionTab = () => {
    return (
        <>
            <Header
                subtitle={
                    <>
                        Note:
                        <br />
                        1. Set up Positions <a href='/records/positions'>here</a> first.
                        <br />
                        2. Create Drawdowns and Restructure from Other Positions.
                    </>
                }
            />
            <Box
                sx={{
                    m: '1.5rem 2.5rem',
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    '& .MuiDataGrid-root': {
                        maxWidth: '95vw',
                    },
                }}
            >
                <CreateTransactionForm />
            </Box>
        </>
    )
}

export default CreateTransactionTab
