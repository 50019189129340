export const lenderKeys = ['lenderCode', 'lenderName', 'isActive']
export const lenderFlagKeys = ['lenderName', 'lenderCode']

export const fundKeys = ['fundCode', 'fundName', 'isActive']
export const fundFlagKeys = ['fundName', 'fundCode']

export const borrowerKeys = [
    'borrowerCode',
    'borrowerName',
    'borrowerDescription',
    'borrowerLegalDomicile',
    'borrowerType',
    'isActive',
]
export const borrowerFlagKeys = ['borrowerName', 'borrowerCode']

export const facilityKeys = [
    'lenderCode',
    'borrowerName',
    'facilityNumber',
    'facilityLegalId',
    'limit',
    'limitCurrency',
    'accrualMethod',
    'creditEnhancement',
    'role',
    'firstDrawdownDate',
    'expiryDate',
    'beneficiaryName',
    'accountNumber',
    'isActive',
]
export const facilityFlagKeys = ['facilityNumber', 'facilityLegalId']

export const positionKeys = [
    'lenderCode',
    'borrowerName',
    'facilityNumber',
    'taId',
    'loanId',
    'notionalAmount',
    'currency',
    'financingRatio',
    'ddFeeRate',
    'originalInterestRate',
    'drawdownRate',
    'originalMaturityDate',
    'approvedMaturityDate',
    'assetType',
    'tradeType',
    'goods',
    'sector',
    'sectorLgt',
    'originCountry',
    'destinationCountry',
    'isActive',
    'isDrawdown',
]
export const positionFlagKeys = ['taId', 'loanId']

export const participationKeys = [
    'fundCode',
    'taId',
    'participationRatio',
    'spreadRate',
    'drawdownDate',
    'originalMaturityDate',
    'approvedMaturityDate',
    'relationshipType',
]
export const participationFlagKeys = ['fundCode', 'taId']

export const transactionKeys = [
    'transactionDate',
    'borrowerName',
    'facilityNumber',
    'lenderCode',
    'fundCode',
    'taId',
    'fundTaId',
    'transactionPrincipal',
    'transactionInterest',
    'transactionFee',
    'transactionType',
    'remarks',
]
export const transactionFlagKeys = ['fundTaId']

export const exchangeRateKeys = ['asOfDate', 'currency', 'exchangeRate']
export const exchangeRateFlagKeys = ['asOfDate', 'currency']

export const supplementaryInterestRateKeys = ['taId', 'effectiveDate', 'supplementaryInterestRate']
export const supplementaryInterestRateFlagKeys = ['taId', 'effectiveDate']

export const bankAccountKeys = [
    'beneficiaryName',
    'accountNumber',
    'bankName',
    'bankAddress',
    'swiftCode',
    'reference',
]
export const bankAccountFlagKeys = ['accountNumber']

export const userKeys = ['firstName', 'lastName', 'email', 'role', 'isActive']
export const userFlagKeys = ['email']
