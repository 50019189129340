export const applyTransactionTypeList = [
    'Partial Repayment',
    'Full Repayment',
    'Charge Fee',
    'Mark to Market',
    'Capitalise',
    'Waive',
    'Partial Write Off',
    'Full Write Off',
    'Restructure to Other',
]

export const createTransactionTypeList = [
    'Drawdown',
    'Restructure from Other',
    'Participate from Existing (Future)',
]
