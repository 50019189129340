import React from 'react'
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useField, useFormikContext } from 'formik'
import dayjs from 'dayjs'

const DatePickerValidated = ({ name, ...otherProps }) => {
    const [field, meta, helpers] = useField(name)
    const { values, setFieldValue } = useFormikContext()

    const handleChange = (newValue) => {
        setFieldValue(name, newValue)

        const formattedDate = dayjs(newValue).toISOString()
        values.name = formattedDate
        helpers.setTouched(true)
    }

    const configDatePicker = {
        ...otherProps,
        format: 'DD/MM/YYYY',
        value: values[name],
        onChange: handleChange,
        slotProps: {
            textField: {
                fullWidth: true,
                disabled: true,
            },
        },
    }

    if (meta && meta.touched) {
        if (field.value === undefined || field.value === null || field.value === '') {
            configDatePicker.error = true
            configDatePicker.helperText = 'Required'
        } else if (meta.error) {
            configDatePicker.error = true
            configDatePicker.helperText = meta.error
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker {...configDatePicker} />
        </LocalizationProvider>
    )
}

export default DatePickerValidated
