import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import { EditOutlined, DeleteOutlined } from '@mui/icons-material'
import Header from '../../components/layout/Header'
import CustomToolbar from '../../components/ui/datagrid/CustomToolbar'
import CustomFilterForm from '../../components/ui/datagrid/CustomFilterForm'
import CustomNoRowOverlay from '../../components/ui/datagrid/CustomNoRowOverlay'

import { formatDataGridDate, formatMoney } from '../../utils/helpers/formatValue'

import { selectCurrentUser } from '../../app/api/auth/authSlice'
import {
    useDeleteFacilityMutation,
    useGetAllFacilitiesQuery,
} from '../../app/api/records/facilitiesApiSlice'
import { useGetAllLendersQuery } from '../../app/api/records/lendersApiSlice'

import ConfirmDeleteModal from '../../components/ui/modals/ConfirmDeleteModal'
import DeleteSuccessModal from '../../components/ui/modals/DeleteSuccessModal'
import ExistingRelationshipModal from '../../components/ui/modals/ExistingRelationshipModal'
import CreateFacilityForm from '../../components/ui/forms/records/CreateFacilityForm'
import UpdateFacilityForm from '../../components/ui/forms/records/UpdateFacilityForm'

const initialFacilityFormState = {
    _id: '',
    lenderId: '',
    borrowerId: '',
    facilityNumber: '',
    facilityLegalId: '',
    limit: '',
    limitCurrency: '',
    accrualMethod: '',
    creditEnhancement: '',
    role: '',
    isActive: null,
    firstDrawdownDate: null,
    expiryDate: null,
}

const Facilities = () => {
    const { data: initialRows, isLoading } = useGetAllFacilitiesQuery()
    const { data: lenders } = useGetAllLendersQuery()
    const [deleteFacility] = useDeleteFacilityMutation()

    const { role } = useSelector(selectCurrentUser)

    const [isFormOpen, setIsFormOpen] = useState(false)
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false)
    const [isDeleteSuccessModalOpen, setIsDeleteSuccessModalOpen] = useState(false)
    const [isExistingRelationshipModalOpen, setIsExistingRelationshipModalOpen] = useState(false)
    const [toBeUpdated, setToBeUpdated] = useState(initialFacilityFormState)
    const [isEditClicked, setIsEditClicked] = useState(false)
    const [toBeDeleted, setToBeDeleted] = useState([])

    const handleOpenForm = () => {
        setIsFormOpen(true)
    }

    const handleCloseForm = () => {
        setIsFormOpen(false)
        setToBeUpdated(initialFacilityFormState)
        setIsEditClicked(false)
    }

    const handleOpenConfirmDeleteModal = () => {
        setIsConfirmDeleteModalOpen(true)
    }

    const handleCloseConfirmDeleteModal = () => {
        setIsConfirmDeleteModalOpen(false)
    }

    const handleOpenDeleteSuccessModal = () => {
        setIsDeleteSuccessModalOpen(true)
    }

    const handleCloseDeleteSuccessModal = () => {
        setIsDeleteSuccessModalOpen(false)
    }

    const handleOpenExistingRelationshipModal = () => {
        setIsExistingRelationshipModalOpen(true)
    }

    const handleCloseExistingRelationshipModal = () => {
        setIsExistingRelationshipModalOpen(false)
    }

    const handleEdit = (params) => {
        const { row } = params
        setIsEditClicked(true)
        setToBeUpdated(row)
        handleOpenForm()
    }

    const handlePreDelete = (params) => {
        const { row } = params

        const updatedRow = { ...row }
        setToBeDeleted(updatedRow)
        handleOpenConfirmDeleteModal()
    }

    const handleDelete = async () => {
        try {
            const { _id } = toBeDeleted
            const response = await deleteFacility(_id)

            if (response.data) {
                handleOpenDeleteSuccessModal()
            } else if (response.error.data.relatedData) {
                handleOpenExistingRelationshipModal()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const columns = [
        ...(role === 'Admin' || role === 'Editor'
            ? [
                  {
                      field: 'actions',
                      headerName: 'Actions',
                      type: 'actions',
                      getActions: (params) => {
                          return [
                              <GridActionsCellItem
                                  icon={<EditOutlined />}
                                  label='Edit'
                                  onClick={() => {
                                      handleEdit(params)
                                  }}
                                  color='inherit'
                              />,
                              <GridActionsCellItem
                                  icon={<DeleteOutlined />}
                                  label='Delete'
                                  onClick={() => {
                                      handlePreDelete(params)
                                  }}
                                  color='inherit'
                              />,
                          ]
                      },
                  },
              ]
            : []),
        {
            field: '_id',
            headerName: '_id',
            width: 150,
        },
        {
            field: 'lenderCode',
            headerName: 'Lender Code',
            width: 120,
        },
        {
            field: 'borrowerName',
            headerName: 'Borrower Name',
            width: 200,
        },
        { field: 'facilityNumber', headerName: 'Facility Number', width: 180 },
        { field: 'facilityLegalId', headerName: 'Facility Legal ID', width: 160 },
        {
            field: 'limit',
            headerName: 'Limit',
            width: 110,
            valueFormatter: (params) => formatMoney(params.value),
        },
        { field: 'limitCurrency', headerName: 'Currency', width: 80 },
        { field: 'accrualMethod', headerName: 'Accrual Method', width: 100 },
        { field: 'creditEnhancement', headerName: 'Credit Enhancement', width: 200 },
        {
            field: 'firstDrawdownDate',
            headerName: 'First Drawdown Date',
            type: 'date',
            width: 150,
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
        {
            field: 'expiryDate',
            headerName: 'Expiry Date',
            type: 'date',
            width: 150,
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
        { field: 'role', headerName: 'Role', width: 100 },
        // {
        //     field: 'beneficiaryBankId',
        //     headerName: 'Beneficiary Bank ID',
        //     width: 200,
        // },
        {
            field: 'beneficiaryName',
            headerName: 'Beneficiary Name',
            width: 200,
        },
        {
            field: 'accountNumber',
            headerName: 'Account Number',
            width: 180,
        },
        { field: 'isActive', headerName: 'Is Active?', width: 100 },
        {
            field: 'createdBy',
            headerName: 'Created By',
            width: 150,
        },
        {
            field: 'lastUpdatedBy',
            headerName: 'Last Updated By',
            width: 150,
        },
        {
            field: 'validFrom',
            headerName: 'Valid From',
            type: 'date',
            width: 150,
            valueFormatter: (params) => formatDataGridDate(params.value),
        },
    ]

    return (
        <Box>
            <Header title='Facilities' subtitle='List of Facilities'></Header>
            <Box
                sx={{
                    m: '1.5rem 2.5rem',
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    height: '80vh',
                    '& .MuiDataGrid-root': {
                        maxWidth: '95vw',
                    },
                }}
            >
                <DataGrid
                    loading={isLoading}
                    getRowId={(row) => row._id}
                    rows={initialRows || []}
                    columns={columns}
                    pageSizeOptions={[20, 50, 100]}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 20 } },
                        columns: {
                            columnVisibilityModel: {
                                // facilityLegalId: false,
                                // creditEnhancement: false,
                                // firstDrawdownDate: false,
                                // role: false,
                                // isActive: false,
                                // createdBy: false,
                                // validFrom: false,
                            },
                        },
                        sorting: {
                            sortModel: [{ field: 'facilityNumber', sort: 'asc' }],
                        },
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                        noRowsOverlay: CustomNoRowOverlay,
                    }}
                    slotProps={{
                        filterPanel: {
                            filterFormProps: CustomFilterForm,
                        },
                    }}
                />
            </Box>
            <ConfirmDeleteModal
                handleDelete={handleDelete}
                toBeDeleted={toBeDeleted}
                isConfirmDeleteModalOpen={isConfirmDeleteModalOpen}
                handleCloseConfirmDeleteModal={handleCloseConfirmDeleteModal}
            />
            <DeleteSuccessModal
                isDeleteSuccessModalOpen={isDeleteSuccessModalOpen}
                handleCloseDeleteSuccessModal={handleCloseDeleteSuccessModal}
                handleCloseConfirmDeleteModal={handleCloseConfirmDeleteModal}
            />
            <ExistingRelationshipModal
                isExistingRelationshipModalOpen={isExistingRelationshipModalOpen}
                handleCloseExistingRelationshipModal={handleCloseExistingRelationshipModal}
            />
            {isEditClicked ? (
                <UpdateFacilityForm
                    isFormOpen={isFormOpen}
                    handleCloseForm={handleCloseForm}
                    toBeUpdated={toBeUpdated}
                    isEditClicked={isEditClicked}
                    setIsEditClicked={setIsEditClicked}
                />
            ) : (
                <CreateFacilityForm
                    lenders={lenders}
                    isFormOpen={isFormOpen}
                    handleCloseForm={handleCloseForm}
                    toBeUpdated={toBeUpdated}
                    isEditClicked={isEditClicked}
                    setIsEditClicked={setIsEditClicked}
                />
            )}
        </Box>
    )
}

export default Facilities
