import { apiSlice } from '../apiSlice'

export const exchangeRatesApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getAllExchangeRates: build.query({
            query: () => ({ url: `records/exchange-rates`, method: 'GET' }),
            providesTags: ['ExchangeRates'],
        }),
        createExchangeRate: build.mutation({
            query: (newRow) => ({
                url: `records/exchange-rates`,
                method: 'POST',
                body: newRow,
            }),
            invalidatesTags: ['ExchangeRates', 'Holdings'],
        }),
        updateExchangeRate: build.mutation({
            query: (updatedRow) => ({
                url: `records/exchange-rates`,
                method: 'PUT',
                body: updatedRow,
            }),
            invalidatesTags: ['ExchangeRates', 'Holdings'],
        }),
        deleteExchangeRate: build.mutation({
            query: (id) => ({
                url: `records/exchange-rates/${id}`,
                method: 'DELETE',
                body: id,
            }),
            invalidatesTags: ['ExchangeRates', 'Holdings'],
        }),
    }),
})

export const {
    useCreateExchangeRateMutation,
    useGetAllExchangeRatesQuery,
    useUpdateExchangeRateMutation,
    useDeleteExchangeRateMutation,
} = exchangeRatesApiSlice
