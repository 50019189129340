import { apiSlice } from '../apiSlice'

export const participationsApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getAllParticipations: build.query({
            query: () => ({ url: `records/participations`, method: 'GET' }),
            providesTags: ['Participations'],
        }),
        updateParticipation: build.mutation({
            query: (updatedRow) => ({
                url: `records/participations`,
                method: 'PUT',
                body: updatedRow,
            }),
            invalidatesTags: ['Participations', 'Holdings'],
        }),
    }),
})

export const { useGetAllParticipationsQuery, useUpdateParticipationMutation } =
    participationsApiSlice
