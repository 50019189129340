export const countryList = [
    { countryName: 'Afghanistan', countryCode: 'AFG' },
    { countryName: 'Albania', countryCode: 'ALB' },
    { countryName: 'Algeria', countryCode: 'DZA' },
    { countryName: 'American Samoa', countryCode: 'ASM' },
    { countryName: 'Andorra', countryCode: 'AND' },
    { countryName: 'Angola', countryCode: 'AGO' },
    { countryName: 'Anguilla', countryCode: 'AIA' },
    { countryName: 'Antarctica', countryCode: 'ATA' },
    { countryName: 'Antigua and Barbuda', countryCode: 'ATG' },
    { countryName: 'Argentina', countryCode: 'ARG' },
    { countryName: 'Armenia', countryCode: 'ARM' },
    { countryName: 'Aruba', countryCode: 'ABW' },
    { countryName: 'Australia', countryCode: 'AUS' },
    { countryName: 'Austria', countryCode: 'AUT' },
    { countryName: 'Azerbaijan', countryCode: 'AZE' },
    { countryName: 'Bahamas', countryCode: 'BHS' },
    { countryName: 'Bahrain', countryCode: 'BHR' },
    { countryName: 'Bangladesh', countryCode: 'BGD' },
    { countryName: 'Barbados', countryCode: 'BRB' },
    { countryName: 'Belarus', countryCode: 'BLR' },
    { countryName: 'Belgium', countryCode: 'BEL' },
    { countryName: 'Belize', countryCode: 'BLZ' },
    { countryName: 'Benin', countryCode: 'BEN' },
    { countryName: 'Bermuda', countryCode: 'BMU' },
    { countryName: 'Bhutan', countryCode: 'BTN' },
    { countryName: 'Bolivia', countryCode: 'BOL' },
    { countryName: 'Bosnia and Herzegovina', countryCode: 'BIH' },
    { countryName: 'Botswana', countryCode: 'BWA' },
    { countryName: 'Bouvet Island', countryCode: 'BVT' },
    { countryName: 'Brazil', countryCode: 'BRA' },
    { countryName: 'British Indian Ocean Territory', countryCode: 'IOT' },
    { countryName: 'Brunei', countryCode: 'BRN' },
    { countryName: 'Bulgaria', countryCode: 'BGR' },
    { countryName: 'Burkina Faso', countryCode: 'BFA' },
    { countryName: 'Burundi', countryCode: 'BDI' },
    { countryName: 'Cambodia', countryCode: 'KHM' },
    { countryName: 'Cameroon', countryCode: 'CMR' },
    { countryName: 'Canada', countryCode: 'CAN' },
    { countryName: 'Cape Verde', countryCode: 'CPV' },
    { countryName: 'Cayman Islands', countryCode: 'CYM' },
    { countryName: 'Central African Republic', countryCode: 'CAF' },
    { countryName: 'Chad', countryCode: 'TCD' },
    { countryName: 'Chile', countryCode: 'CHL' },
    { countryName: 'China', countryCode: 'CHN' },
    { countryName: 'Christmas Island', countryCode: 'CXR' },
    { countryName: 'Cocos (Keeling) Islands', countryCode: 'CCK' },
    { countryName: 'Colombia', countryCode: 'COL' },
    { countryName: 'Comoros', countryCode: 'COM' },
    { countryName: 'Congo', countryCode: 'COG' },
    { countryName: 'Congo, the Democratic Republic of the', countryCode: 'COD' },
    { countryName: 'Cook Islands', countryCode: 'COK' },
    { countryName: 'Costa Rica', countryCode: 'CRI' },
    { countryName: 'Ivory Coast', countryCode: 'CIV' },
    { countryName: 'Croatia', countryCode: 'HRV' },
    { countryName: 'Cuba', countryCode: 'CUB' },
    { countryName: 'Cyprus', countryCode: 'CYP' },
    { countryName: 'Czech Republic', countryCode: 'CZE' },
    { countryName: 'Denmark', countryCode: 'DNK' },
    { countryName: 'Djibouti', countryCode: 'DJI' },
    { countryName: 'Dominica', countryCode: 'DMA' },
    { countryName: 'Dominican Republic', countryCode: 'DOM' },
    { countryName: 'Ecuador', countryCode: 'ECU' },
    { countryName: 'Egypt', countryCode: 'EGY' },
    { countryName: 'El Salvador', countryCode: 'SLV' },
    { countryName: 'Equatorial Guinea', countryCode: 'GNQ' },
    { countryName: 'Eritrea', countryCode: 'ERI' },
    { countryName: 'Estonia', countryCode: 'EST' },
    { countryName: 'Ethiopia', countryCode: 'ETH' },
    { countryName: 'Falkland Islands (Malvinas)', countryCode: 'FLK' },
    { countryName: 'Faroe Islands', countryCode: 'FRO' },
    { countryName: 'Fiji', countryCode: 'FJI' },
    { countryName: 'Finland', countryCode: 'FIN' },
    { countryName: 'France', countryCode: 'FRA' },
    { countryName: 'French Guiana', countryCode: 'GUF' },
    { countryName: 'French Polynesia', countryCode: 'PYF' },
    { countryName: 'French Southern Territories', countryCode: 'ATF' },
    { countryName: 'Gabon', countryCode: 'GAB' },
    { countryName: 'Gambia', countryCode: 'GMB' },
    { countryName: 'Georgia', countryCode: 'GEO' },
    { countryName: 'Germany', countryCode: 'DEU' },
    { countryName: 'Ghana', countryCode: 'GHA' },
    { countryName: 'Gibraltar', countryCode: 'GIB' },
    { countryName: 'Greece', countryCode: 'GRC' },
    { countryName: 'Greenland', countryCode: 'GRL' },
    { countryName: 'Grenada', countryCode: 'GRD' },
    { countryName: 'Guadeloupe', countryCode: 'GLP' },
    { countryName: 'Guam', countryCode: 'GUM' },
    { countryName: 'Guatemala', countryCode: 'GTM' },
    { countryName: 'Guernsey', countryCode: 'GGY' },
    { countryName: 'Guinea', countryCode: 'GIN' },
    { countryName: 'Guinea-Bissau', countryCode: 'GNB' },
    { countryName: 'Guyana', countryCode: 'GUY' },
    { countryName: 'Haiti', countryCode: 'HTI' },
    { countryName: 'Heard Island and McDonald Islands', countryCode: 'HMD' },
    { countryName: 'Holy See (Vatican City State)', countryCode: 'VAT' },
    { countryName: 'Honduras', countryCode: 'HND' },
    { countryName: 'Hong Kong', countryCode: 'HKG' },
    { countryName: 'Hungary', countryCode: 'HUN' },
    { countryName: 'Iceland', countryCode: 'ISL' },
    { countryName: 'India', countryCode: 'IND' },
    { countryName: 'Indonesia', countryCode: 'IDN' },
    { countryName: 'Iran, Islamic Republic of', countryCode: 'IRN' },
    { countryName: 'Iraq', countryCode: 'IRQ' },
    { countryName: 'Ireland', countryCode: 'IRL' },
    { countryName: 'Isle of Man', countryCode: 'IMN' },
    { countryName: 'Israel', countryCode: 'ISR' },
    { countryName: 'Italy', countryCode: 'ITA' },
    { countryName: 'Jamaica', countryCode: 'JAM' },
    { countryName: 'Japan', countryCode: 'JPN' },
    { countryName: 'Jersey', countryCode: 'JEY' },
    { countryName: 'Jordan', countryCode: 'JOR' },
    { countryName: 'Kazakhstan', countryCode: 'KAZ' },
    { countryName: 'Kenya', countryCode: 'KEN' },
    { countryName: 'Kiribati', countryCode: 'KIR' },
    { countryName: "Korea, Democratic People's Republic of", countryCode: 'PRK' },
    { countryName: 'South Korea', countryCode: 'KOR' },
    { countryName: 'Kuwait', countryCode: 'KWT' },
    { countryName: 'Kyrgyzstan', countryCode: 'KGZ' },
    { countryName: "Lao People's Democratic Republic", countryCode: 'LAO' },
    { countryName: 'Latvia', countryCode: 'LVA' },
    { countryName: 'Lebanon', countryCode: 'LBN' },
    { countryName: 'Lesotho', countryCode: 'LSO' },
    { countryName: 'Liberia', countryCode: 'LBR' },
    { countryName: 'Libya', countryCode: 'LBY' },
    { countryName: 'Liechtenstein', countryCode: 'LIE' },
    { countryName: 'Lithuania', countryCode: 'LTU' },
    { countryName: 'Luxembourg', countryCode: 'LUX' },
    { countryName: 'Macao', countryCode: 'MAC' },
    { countryName: 'Macedonia, the former Yugoslav Republic of', countryCode: 'MKD' },
    { countryName: 'Madagascar', countryCode: 'MDG' },
    { countryName: 'Malawi', countryCode: 'MWI' },
    { countryName: 'Malaysia', countryCode: 'MYS' },
    { countryName: 'Maldives', countryCode: 'MDV' },
    { countryName: 'Mali', countryCode: 'MLI' },
    { countryName: 'Malta', countryCode: 'MLT' },
    { countryName: 'Marshall Islands', countryCode: 'MHL' },
    { countryName: 'Martinique', countryCode: 'MTQ' },
    { countryName: 'Mauritania', countryCode: 'MRT' },
    { countryName: 'Mauritius', countryCode: 'MUS' },
    { countryName: 'Mayotte', countryCode: 'MYT' },
    { countryName: 'Mexico', countryCode: 'MEX' },
    { countryName: 'Micronesia, Federated States of', countryCode: 'FSM' },
    { countryName: 'Moldova, Republic of', countryCode: 'MDA' },
    { countryName: 'Monaco', countryCode: 'MCO' },
    { countryName: 'Mongolia', countryCode: 'MNG' },
    { countryName: 'Montenegro', countryCode: 'MNE' },
    { countryName: 'Montserrat', countryCode: 'MSR' },
    { countryName: 'Morocco', countryCode: 'MAR' },
    { countryName: 'Mozambique', countryCode: 'MOZ' },
    { countryName: 'Myanmar', countryCode: 'MMR' },
    { countryName: 'Namibia', countryCode: 'NAM' },
    { countryName: 'Nauru', countryCode: 'NRU' },
    { countryName: 'Nepal', countryCode: 'NPL' },
    { countryName: 'Netherlands', countryCode: 'NLD' },
    { countryName: 'Netherlands Antilles', countryCode: 'ANT' },
    { countryName: 'New Caledonia', countryCode: 'NCL' },
    { countryName: 'New Zealand', countryCode: 'NZL' },
    { countryName: 'Nicaragua', countryCode: 'NIC' },
    { countryName: 'Niger', countryCode: 'NER' },
    { countryName: 'Nigeria', countryCode: 'NGA' },
    { countryName: 'Niue', countryCode: 'NIU' },
    { countryName: 'Norfolk Island', countryCode: 'NFK' },
    { countryName: 'Northern Mariana Islands', countryCode: 'MNP' },
    { countryName: 'Norway', countryCode: 'NOR' },
    { countryName: 'Oman', countryCode: 'OMN' },
    { countryName: 'Pakistan', countryCode: 'PAK' },
    { countryName: 'Palau', countryCode: 'PLW' },
    { countryName: 'Palestinian Territory, Occupied', countryCode: 'PSE' },
    { countryName: 'Panama', countryCode: 'PAN' },
    { countryName: 'Papua New Guinea', countryCode: 'PNG' },
    { countryName: 'Paraguay', countryCode: 'PRY' },
    { countryName: 'Peru', countryCode: 'PER' },
    { countryName: 'Philippines', countryCode: 'PHL' },
    { countryName: 'Pitcairn', countryCode: 'PCN' },
    { countryName: 'Poland', countryCode: 'POL' },
    { countryName: 'Portugal', countryCode: 'PRT' },
    { countryName: 'Puerto Rico', countryCode: 'PRI' },
    { countryName: 'Qatar', countryCode: 'QAT' },
    { countryName: 'Réunion', countryCode: 'REU' },
    { countryName: 'Romania', countryCode: 'ROU' },
    { countryName: 'Russia', countryCode: 'RUS' },
    { countryName: 'Rwanda', countryCode: 'RWA' },
    { countryName: 'Saint Helena, Ascension and Tristan da Cunha', countryCode: 'SHN' },
    { countryName: 'Saint Kitts and Nevis', countryCode: 'KNA' },
    { countryName: 'Saint Lucia', countryCode: 'LCA' },
    { countryName: 'Saint Pierre and Miquelon', countryCode: 'SPM' },
    { countryName: 'Saint Vincent and the Grenadines', countryCode: 'VCT' },
    { countryName: 'Samoa', countryCode: 'WSM' },
    { countryName: 'San Marino', countryCode: 'SMR' },
    { countryName: 'Sao Tome and Principe', countryCode: 'STP' },
    { countryName: 'Saudi Arabia', countryCode: 'SAU' },
    { countryName: 'Senegal', countryCode: 'SEN' },
    { countryName: 'Serbia', countryCode: 'SRB' },
    { countryName: 'Seychelles', countryCode: 'SYC' },
    { countryName: 'Sierra Leone', countryCode: 'SLE' },
    { countryName: 'Singapore', countryCode: 'SGP' },
    { countryName: 'Slovakia', countryCode: 'SVK' },
    { countryName: 'Slovenia', countryCode: 'SVN' },
    { countryName: 'Solomon Islands', countryCode: 'SLB' },
    { countryName: 'Somalia', countryCode: 'SOM' },
    { countryName: 'South Africa', countryCode: 'ZAF' },
    { countryName: 'South Georgia and the South Sandwich Islands', countryCode: 'SGS' },
    { countryName: 'South Sudan', countryCode: 'SSD' },
    { countryName: 'Spain', countryCode: 'ESP' },
    { countryName: 'Sri Lanka', countryCode: 'LKA' },
    { countryName: 'Sudan', countryCode: 'SDN' },
    { countryName: 'Suriname', countryCode: 'SUR' },
    { countryName: 'Svalbard and Jan Mayen', countryCode: 'SJM' },
    { countryName: 'Swaziland', countryCode: 'SWZ' },
    { countryName: 'Sweden', countryCode: 'SWE' },
    { countryName: 'Switzerland', countryCode: 'CHE' },
    { countryName: 'Syrian Arab Republic', countryCode: 'SYR' },
    { countryName: 'Taiwan', countryCode: 'TWN' },
    { countryName: 'Tajikistan', countryCode: 'TJK' },
    { countryName: 'Tanzania, United Republic of', countryCode: 'TZA' },
    { countryName: 'Thailand', countryCode: 'THA' },
    { countryName: 'Timor-Leste', countryCode: 'TLS' },
    { countryName: 'Togo', countryCode: 'TGO' },
    { countryName: 'Tokelau', countryCode: 'TKL' },
    { countryName: 'Tonga', countryCode: 'TON' },
    { countryName: 'Trinidad and Tobago', countryCode: 'TTO' },
    { countryName: 'Tunisia', countryCode: 'TUN' },
    { countryName: 'Turkey', countryCode: 'TUR' },
    { countryName: 'Turkmenistan', countryCode: 'TKM' },
    { countryName: 'Turks and Caicos Islands', countryCode: 'TCA' },
    { countryName: 'Tuvalu', countryCode: 'TUV' },
    { countryName: 'Uganda', countryCode: 'UGA' },
    { countryName: 'Ukraine', countryCode: 'UKR' },
    { countryName: 'United Arab Emirates', countryCode: 'ARE' },
    { countryName: 'United Kingdom', countryCode: 'GBR' },
    { countryName: 'United States', countryCode: 'USA' },
    { countryName: 'United States Minor Outlying Islands', countryCode: 'UMI' },
    { countryName: 'Uruguay', countryCode: 'URY' },
    { countryName: 'Uzbekistan', countryCode: 'UZB' },
    { countryName: 'Vanuatu', countryCode: 'VUT' },
    { countryName: 'Venezuela', countryCode: 'VEN' },
    { countryName: 'Vietnam', countryCode: 'VNM' },
    { countryName: 'Virgin Islands, British', countryCode: 'VGB' },
    { countryName: 'Virgin Islands, U.S.', countryCode: 'VIR' },
    { countryName: 'Wallis and Futuna', countryCode: 'WLF' },
    { countryName: 'Western Sahara', countryCode: 'ESH' },
    { countryName: 'Yemen', countryCode: 'YEM' },
    { countryName: 'Zambia', countryCode: 'ZMB' },
    { countryName: 'Zimbabwe', countryCode: 'ZWE' },
]
