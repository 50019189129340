import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    LightModeOutlined,
    DarkModeOutlined,
    Menu as MenuIcon,
    SettingsOutlined,
    LogoutOutlined,
    ManageAccountsOutlined,
    HistoryOutlined,
} from '@mui/icons-material'
import FlexBetween from './FlexBetween'
import { useDispatch, useSelector } from 'react-redux'
import { setMode } from '../../state/globalState'
import {
    AppBar,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    useTheme,
    ListItemButton,
    Typography,
    Avatar,
    Box,
    Divider,
} from '@mui/material'
import { selectCurrentUser } from '../../app/api/auth/authSlice'
import { useLogoutUserMutation } from '../../app/api/auth/authApiSlice'
import { formatLastLoginDate } from '../../utils/helpers/formatValue'

const Navbar = ({ isSidebarOpen, setIsSidebarOpen }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme()

    const [anchorEl, setAnchorEl] = useState(null)
    const isAccountMenuOpen = Boolean(anchorEl)
    const openAccountMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const closeAccountMenu = () => {
        setAnchorEl(null)
    }

    const [sendLogout] = useLogoutUserMutation()

    const handleLogout = () => {
        sendLogout()
        closeAccountMenu()
        navigate('/')
    }

    const handleManageUsersClick = () => {
        closeAccountMenu()
        navigate('/auth/users')
    }

    const handleViewLogsClick = () => {
        closeAccountMenu()
        navigate('/logs/user-logs')
    }

    const { firstName, lastName, email, role, lastLogin } = useSelector(selectCurrentUser)
    const initials = `${firstName?.charAt(0)}${lastName?.charAt(0)}`

    return (
        <AppBar
            sx={{
                position: 'static',
                boxShadow: 'none',
            }}
        >
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                {/* LEFT SIDE */}
                <FlexBetween>
                    <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                        <MenuIcon />
                    </IconButton>
                </FlexBetween>

                {/* RIGHT SIDE */}
                <FlexBetween gap='1.5rem'>
                    <IconButton onClick={() => dispatch(setMode())}>
                        {theme.palette.mode === 'dark' ? (
                            <DarkModeOutlined />
                        ) : (
                            <LightModeOutlined />
                        )}
                    </IconButton>
                    <IconButton
                        onClick={openAccountMenu}
                        sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
                    >
                        <Avatar
                            sx={{
                                bgcolor: 'inherit',
                                color: theme.palette.action.active,
                                border: `2px solid ${theme.palette.action.active}`,
                                width: 30,
                                height: 30,
                                fontSize: 14,
                                fontWeight: 'bold',
                            }}
                        >
                            {initials}
                        </Avatar>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={isAccountMenuOpen}
                        onClose={closeAccountMenu}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <MenuItem>
                            <ListItemButton
                                disabled
                                sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                                onClick={() => console.log('settings clicked')}
                            >
                                <SettingsOutlined />
                                <Typography sx={{ fontStyle: 'italic' }}>
                                    Settings (Future)
                                </Typography>
                            </ListItemButton>
                        </MenuItem>
                        {role === 'Admin' && (
                            <Box>
                                <MenuItem>
                                    <ListItemButton
                                        sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                                        onClick={() => handleManageUsersClick()}
                                    >
                                        <ManageAccountsOutlined />
                                        <Typography>Manage Users</Typography>
                                    </ListItemButton>
                                </MenuItem>
                                <MenuItem>
                                    <ListItemButton
                                        sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                                        onClick={() => handleViewLogsClick()}
                                    >
                                        <HistoryOutlined />
                                        <Typography>View Logs</Typography>
                                    </ListItemButton>
                                </MenuItem>
                            </Box>
                        )}
                        <MenuItem>
                            <ListItemButton
                                sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                                onClick={handleLogout}
                            >
                                <LogoutOutlined />
                                <Typography>Logout</Typography>
                            </ListItemButton>
                        </MenuItem>
                        <Divider />
                        <MenuItem>
                            <Box>
                                <Typography>{`${firstName} ${lastName}`}</Typography>
                                <Typography sx={{ mb: 1 }}>{email}</Typography>
                                <Typography
                                    variant='caption'
                                    sx={{ display: 'flex', flexDirection: 'column' }}
                                >
                                    <span>Last Login: {formatLastLoginDate(lastLogin)}</span>
                                </Typography>
                            </Box>
                        </MenuItem>
                    </Menu>
                </FlexBetween>
            </Toolbar>
        </AppBar>
    )
}

export default Navbar
