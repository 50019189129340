import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Button,
    MenuItem,
} from '@mui/material'
import dayjs from 'dayjs'

import TextFieldValidated from '../../fields/TextFieldValidated'
import MoneyFieldValidated from '../../fields/MoneyFieldValidated'
import InputLabelStyled from '../../fields/InputLabelStyled'
import SelectValidated from '../../fields/select/SelectValidated'
import DatePickerValidated from '../../fields/DatePickerValidated'
import PreSubmitButton from '../../buttons/PreSubmitButton'

import LenderSelect from '../../fields/select/LenderSelect'
import BorrowerSelect from '../../fields/select/BorrowerSelect'
import BankAccountSelect from '../../fields/select/BankAccountSelect'
import BooleanSelect from '../../fields/select/BooleanSelect'

import { selectCurrentUser } from '../../../../app/api/auth/authSlice'
import { getLenderCode, getBorrowerName } from '../../../../utils/helpers/getDetails'
import { formatDatePickerDate } from '../../../../utils/helpers/formatValue'
import { currencyList } from '../../../../utils/lists/currencyList'
import { accrualMethodList } from '../../../../utils/lists/accrualMethodList'
import { facilityRoleList } from '../../../../utils/lists/facilityRoleList'

import { useUpdateFacilityMutation } from '../../../../app/api/records/facilitiesApiSlice'
import { useGetAllLendersQuery } from '../../../../app/api/records/lendersApiSlice'
import { useGetAllBorrowersQuery } from '../../../../app/api/records/borrowersApiSlice'
import { useGetAllBankAccountsQuery } from '../../../../app/api/records/bankAccountsApiSlice'

import DuplicateErrorModal from '../../modals/DuplicateErrorModal'
import ConfirmSubmitModal from '../../modals/ConfirmSubmitModal'
import UpdateSuccessModal from '../../modals/UpdateSuccessModal'
import MissingFieldModal from '../../modals/MissingFieldModal'
import InvalidDateModal from '../../modals/InvalidDateModal'

const facilityFormValidation = Yup.object().shape({
    lenderId: Yup.string().required('Required'),
    lenderCode: Yup.string().required('Required'),
    borrowerId: Yup.string().required('Required'),
    borrowerName: Yup.string().required('Required'),
    facilityNumber: Yup.string().required('Required'),
    facilityLegalId: Yup.string().required('Required'),
    limit: Yup.number().required('Required'),
    limitCurrency: Yup.string().required('Required'),
    accrualMethod: Yup.string().required('Required'),
    creditEnhancement: Yup.string().required('Required'),
    role: Yup.string().required('Required'),
    beneficiaryBankId: Yup.string(),
    firstDrawdownDate: Yup.date().nullable().required('Required'),
    expiryDate: Yup.date().nullable().required('Required'),
})

const UpdateFacilityForm = ({ isFormOpen, handleCloseForm, toBeUpdated, isEditClicked }) => {
    const { firstName, lastName } = useSelector(selectCurrentUser)
    const userFullname = `${firstName} ${lastName}`

    const initialFacilityFormState = {
        _id: '',
        lenderId: '',
        lenderCode: '',
        borrowerId: '',
        borrowerName: '',
        facilityNumber: '',
        facilityLegalId: '',
        limit: '',
        limitCurrency: '',
        accrualMethod: '',
        creditEnhancement: '',
        role: '',
        isActive: null,
        firstDrawdownDate: dayjs(new Date().toISOString()),
        expiryDate: dayjs(new Date().toISOString()),
        beneficiaryBankId: '',
        lastUpdatedBy: '',
    }

    if (toBeUpdated && isEditClicked) {
        initialFacilityFormState._id = toBeUpdated._id
        initialFacilityFormState.lenderId = toBeUpdated.lenderId
        initialFacilityFormState.lenderCode = toBeUpdated.lenderCode
        initialFacilityFormState.borrowerId = toBeUpdated.borrowerId
        initialFacilityFormState.borrowerName = toBeUpdated.borrowerName
        initialFacilityFormState.borrowerType = toBeUpdated.borrowerType
        initialFacilityFormState.facilityNumber = toBeUpdated.facilityNumber
        initialFacilityFormState.facilityLegalId = toBeUpdated.facilityLegalId
        initialFacilityFormState.limit = toBeUpdated.limit
        initialFacilityFormState.limitCurrency = toBeUpdated.limitCurrency
        initialFacilityFormState.accrualMethod = toBeUpdated.accrualMethod
        initialFacilityFormState.creditEnhancement = toBeUpdated.creditEnhancement
        initialFacilityFormState.role = toBeUpdated.role
        initialFacilityFormState.isActive = toBeUpdated.isActive
        initialFacilityFormState.firstDrawdownDate = dayjs(new Date(toBeUpdated.firstDrawdownDate))
        initialFacilityFormState.expiryDate = dayjs(new Date(toBeUpdated.expiryDate))
        initialFacilityFormState.beneficiaryBankId = toBeUpdated.beneficiaryBankId || ''
        initialFacilityFormState.lastUpdatedBy = userFullname
    }

    if (!isEditClicked) {
        initialFacilityFormState._id = ''
        initialFacilityFormState.lenderId = ''
        initialFacilityFormState.lenderCode = ''
        initialFacilityFormState.borrowerId = ''
        initialFacilityFormState.borrowerName = ''
        initialFacilityFormState.borrowerType = ''
        initialFacilityFormState.facilityNumber = ''
        initialFacilityFormState.facilityLegalId = ''
        initialFacilityFormState.limit = ''
        initialFacilityFormState.limitCurrency = ''
        initialFacilityFormState.accrualMethod = ''
        initialFacilityFormState.creditEnhancement = ''
        initialFacilityFormState.role = ''
        initialFacilityFormState.isActive = null
        initialFacilityFormState.firstDrawdownDate = dayjs(new Date().toISOString())
        initialFacilityFormState.expiryDate = dayjs(new Date().toISOString())
        initialFacilityFormState.beneficiaryBankId = ''
        initialFacilityFormState.lastUpdatedBy = ''
    }

    const [updateFacility] = useUpdateFacilityMutation()

    const [duplicateEntry, setDuplicateEntry] = useState([])
    const [selectedBankAccount, setSelectedBankAccount] = useState('')

    const [isDuplicateErrorModalOpen, setIsDuplicateErrorModalOpen] = useState(false)
    const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] = useState(false)
    const [isUpdateSuccessModalOpen, setIsUpdateSuccessModalOpen] = useState(false)
    const [isMissingFieldModalOpen, setIsMissingFieldModalOpen] = useState(false)
    const [isInvalidDateModalOpen, setIsInvalidDateModalOpen] = useState(false)

    const formikRef = useRef()

    const { data: lenders = [] } = useGetAllLendersQuery()
    const { data: borrowers = [] } = useGetAllBorrowersQuery()
    const { data: bankAccounts = [] } = useGetAllBankAccountsQuery()

    const handleOpenDuplicateErrorModal = ({ duplicateData }) => {
        setIsDuplicateErrorModalOpen(true)
        setDuplicateEntry(duplicateData)
    }

    const handleCloseDuplicateErrorModal = () => {
        setIsDuplicateErrorModalOpen(false)
    }

    const handleOpenConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(true)
    }

    const handleCloseConfirmSubmitModal = () => {
        setIsConfirmSubmitModalOpen(false)
    }

    const handleOpenUpdateSuccessModal = () => {
        setIsUpdateSuccessModalOpen(true)
    }

    const handleCloseUpdateSuccessModal = () => {
        setIsUpdateSuccessModalOpen(false)
        handleCloseForm()
    }

    const handleOpenMissingFieldModal = () => {
        setIsMissingFieldModalOpen(true)
    }

    const handleCloseMissingFieldModal = () => {
        setIsMissingFieldModalOpen(false)
    }

    const handleOpenInvalidDateModal = () => {
        setIsInvalidDateModalOpen(true)
    }

    const handleCloseInvalidDateModal = () => {
        setIsInvalidDateModalOpen(false)
    }

    const handleSelectBankAccount = (beneficiaryBankId, formik) => {
        if (beneficiaryBankId) {
            formik.setFieldValue('beneficiaryBankId', beneficiaryBankId)
            setSelectedBankAccount(beneficiaryBankId)
            const selectedBankAccountDetails = bankAccounts.find(
                (selectedBankAccount) => selectedBankAccount._id === beneficiaryBankId
            )
            formik.setFieldValue('beneficiaryName', selectedBankAccountDetails.beneficiaryName)
            formik.setFieldValue('accountNumber', selectedBankAccountDetails.accountNumber)
        } else {
            formik.setFieldValue('beneficiaryBankId', '')
            formik.setFieldValue('beneficiaryName', '')
            formik.setFieldValue('accountNumber', '')
        }
    }

    const handleFacilityFormSubmit = async (values) => {
        if ((values.beneficiaryBankId && !values.beneficiaryName) || !values.accountNumber) {
            const selectedBankAccountDetails = bankAccounts.find(
                (selectedBankAccount) => selectedBankAccount._id === values.beneficiaryBankId
            )
            values.beneficiaryName = selectedBankAccountDetails.beneficiaryName
            values.accountNumber = selectedBankAccountDetails.accountNumber
        }

        const {
            _id,
            lenderId,
            lenderCode,
            borrowerId,
            borrowerName,
            facilityNumber,
            facilityLegalId,
            limit,
            limitCurrency,
            accrualMethod,
            creditEnhancement,
            role,
            isActive,
            firstDrawdownDate,
            expiryDate,
            beneficiaryBankId,
            beneficiaryName,
            accountNumber,
            lastUpdatedBy,
        } = values

        try {
            const response = await updateFacility({
                _id,
                lenderId,
                lenderCode,
                borrowerId,
                borrowerName,
                facilityNumber,
                facilityLegalId,
                limit,
                limitCurrency,
                accrualMethod,
                creditEnhancement,
                role,
                isActive,
                firstDrawdownDate,
                expiryDate,
                beneficiaryBankId,
                beneficiaryName,
                accountNumber,
                lastUpdatedBy,
            })

            if (response.error && response.error.data && response.error.data.duplicateData) {
                const { error, duplicateData, newData } = response.error.data
                handleOpenDuplicateErrorModal({ duplicateData })
            } else if (response.data && response.data.newData) {
                const { error, duplicateData, newData } = response.data
                handleOpenUpdateSuccessModal()
            }
        } catch (error) {
            console.error('Error updating facility:', error)
        }
    }

    const handleUpdateFacilityFormClose = () => {
        handleCloseForm()
        if (formikRef.current) {
            formikRef.current.resetForm()
        }
    }

    return (
        <Dialog open={isFormOpen} onClose={handleUpdateFacilityFormClose}>
            <DialogTitle>Update Facility</DialogTitle>
            <Formik
                initialValues={{ ...initialFacilityFormState }}
                validationSchema={facilityFormValidation}
                onSubmit={(values) => {
                    handleFacilityFormSubmit(values)
                }}
                validateOnMount={true}
                innerRef={formikRef}
            >
                {(formik) => (
                    <Form>
                        <DialogContent dividers>
                            <DialogContentText sx={{ mb: 2 }}>
                                Please fill in details of the Updated Facility. Ensure that both
                                Facility Code and Facility Legal ID are not duplicated.
                            </DialogContentText>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <InputLabelStyled disabled htmlFor='lenderId'>
                                        Lender
                                    </InputLabelStyled>
                                    <LenderSelect disabled lenders={lenders} />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled disabled htmlFor='borrowerType'>
                                        Borrower Type
                                    </InputLabelStyled>
                                    <TextFieldValidated
                                        disabled
                                        name='borrowerType'
                                        id='borrowerType'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled disabled htmlFor='borrowerId'>
                                        Borrower
                                    </InputLabelStyled>
                                    <BorrowerSelect disabled borrowers={borrowers} />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='facilityNumber'>
                                        Facility Code *
                                    </InputLabelStyled>
                                    <TextFieldValidated name='facilityNumber' id='facilityNumber' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='facilityLegalId'>
                                        Facility Legal ID *
                                    </InputLabelStyled>
                                    <TextFieldValidated
                                        name='facilityLegalId'
                                        id='facilityLegalId'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='limit'>Limit *</InputLabelStyled>
                                    <MoneyFieldValidated name='limit' id='limit' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='limitCurrency'>
                                        Currency *
                                    </InputLabelStyled>
                                    <SelectValidated name='limitCurrency' id='limitCurrency'>
                                        {currencyList.map((currencyListItem) => (
                                            <MenuItem
                                                key={currencyListItem}
                                                value={currencyListItem}
                                            >
                                                {currencyListItem}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='accrualMethod'>
                                        Accrual Method *
                                    </InputLabelStyled>
                                    <SelectValidated name='accrualMethod' id='accrualMethod'>
                                        {accrualMethodList.map((accrualMethodListItem) => (
                                            <MenuItem
                                                key={accrualMethodListItem}
                                                value={accrualMethodListItem}
                                            >
                                                {accrualMethodListItem}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='role'>Role *</InputLabelStyled>
                                    <SelectValidated name='role' id='role'>
                                        {facilityRoleList.map((facilityRoleListItem) => (
                                            <MenuItem
                                                key={facilityRoleListItem}
                                                value={facilityRoleListItem}
                                            >
                                                {facilityRoleListItem}
                                            </MenuItem>
                                        ))}
                                    </SelectValidated>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled disabled htmlFor='isActive'>
                                        Is Active?
                                    </InputLabelStyled>
                                    <BooleanSelect disabled name='isActive' id='isActive' />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='creditEnhancement'>
                                        Credit Enhancement *
                                    </InputLabelStyled>
                                    <TextFieldValidated
                                        name='creditEnhancement'
                                        id='creditEnhancement'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabelStyled htmlFor='beneficiaryBankId'>
                                        Beneficiary Bank
                                    </InputLabelStyled>
                                    <BankAccountSelect
                                        bankAccounts={bankAccounts}
                                        onChange={(event) => {
                                            handleSelectBankAccount(event.target.value, formik)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='firstDrawdownDate'>
                                        First Drawdown Date *
                                    </InputLabelStyled>
                                    <DatePickerValidated name='firstDrawdownDate' />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabelStyled htmlFor='expiryDate'>
                                        Expiry Date *
                                    </InputLabelStyled>
                                    <DatePickerValidated name='expiryDate' />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' onClick={handleCloseForm}>
                                Cancel
                            </Button>
                            <PreSubmitButton
                                values={{
                                    ...formik,
                                    values: {
                                        ...formik.values,
                                        firstDrawdownDate: formatDatePickerDate(
                                            formik.values.firstDrawdownDate
                                        ),
                                        expiryDate: formatDatePickerDate(formik.values.expiryDate),
                                    },
                                }}
                                handleOpenMissingFieldModal={handleOpenMissingFieldModal}
                                handleOpenInvalidDateModal={handleOpenInvalidDateModal}
                                handleOpenConfirmSubmitModal={handleOpenConfirmSubmitModal}
                            >
                                Submit
                            </PreSubmitButton>
                        </DialogActions>
                        <DuplicateErrorModal
                            duplicateEntry={duplicateEntry}
                            values={{
                                ...formik,
                                values: {
                                    ...formik.values,
                                    // Lender & borrower _id converted in duplicate error modal to handle backend returned _id
                                    firstDrawdownDate: formatDatePickerDate(
                                        formik.values.firstDrawdownDate
                                    ),
                                    expiryDate: formatDatePickerDate(formik.values.expiryDate),
                                },
                            }}
                            isDuplicateErrorModalOpen={isDuplicateErrorModalOpen}
                            handleCloseDuplicateErrorModal={handleCloseDuplicateErrorModal}
                        />
                        <ConfirmSubmitModal
                            values={{
                                ...formik,
                                values: {
                                    ...formik.values,
                                    lender: getLenderCode(lenders, formik.values.lenderId),
                                    borrower: getBorrowerName(borrowers, formik.values.borrowerId),
                                    firstDrawdownDate: formatDatePickerDate(
                                        formik.values.firstDrawdownDate
                                    ),
                                    expiryDate: formatDatePickerDate(formik.values.expiryDate),
                                },
                            }}
                            isConfirmSubmitModalOpen={isConfirmSubmitModalOpen}
                            handleCloseConfirmSubmitModal={handleCloseConfirmSubmitModal}
                        />
                        <UpdateSuccessModal
                            isUpdateSuccessModalOpen={isUpdateSuccessModalOpen}
                            handleCloseUpdateSuccessModal={handleCloseUpdateSuccessModal}
                        />
                        <InvalidDateModal
                            isInvalidDateModalOpen={isInvalidDateModalOpen}
                            handleCloseInvalidDateModal={handleCloseInvalidDateModal}
                        />
                        <MissingFieldModal
                            isMissingFieldModalOpen={isMissingFieldModalOpen}
                            handleCloseMissingFieldModal={handleCloseMissingFieldModal}
                        />
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default UpdateFacilityForm
