import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Button,
} from '@mui/material'
import React from 'react'

const MissingFieldModal = ({ isMissingFieldModalOpen, handleCloseMissingFieldModal }) => {
    return (
        <Dialog open={isMissingFieldModalOpen} onClose={handleCloseMissingFieldModal}>
            <DialogTitle color='error'>Warning</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Missing field(s). Fill in all required fields!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleCloseMissingFieldModal}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default MissingFieldModal
