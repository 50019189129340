import dayjs from 'dayjs'

export const formatModalValue = (key, value) => {
    // Check for empty string value
    if (value === '') {
        return ''
    }

    // Case-insensitive check for 'amount' in the key
    if (/\bamount\b/i.test(key)) {
        return (
            '$' +
            parseFloat(value)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        )
    }

    // Case-insensitive check for 'limit' in the key
    if (/^limit$/i.test(key)) {
        return (
            '$' +
            parseFloat(value)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        )
    }

    // Case-insensitive check for 'transactionAmount' in the key
    if (/^transaction (principal|interest|fee)$/i.test(key)) {
        return (
            '$' +
            parseFloat(value)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        )
    }

    // Case-insensitive check for 'exchangeRate' in the key
    if (/\bexchange rate\b/i.test(key)) {
        return parseFloat(value).toFixed(6)
    }

    // Case-insensitive check for 'rate' and 'ratio' in the key
    if (/\brate\b|\bratio\b/i.test(key)) {
        return parseFloat(value).toFixed(2) + '%'
    }

    // Case-insensitive check for 'id' in the key. Return without formatting
    if (/\bid\b/i.test(key)) {
        return value
    }

    // Check if the value is a boolean
    if (typeof value === 'boolean') {
        return value ? 'True' : 'False'
    }

    // Check if the value is a date or date string
    if (value instanceof Date || /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(value)) {
        return dayjs(value).format('DD/MM/YYYY')
    }

    // Default case: return the value unchanged
    return value
}

export const formatDataGridDate = (value) => {
    if (value) {
        return dayjs(value).format('DD/MM/YYYY')
    }
    return null
}

export const formatDataGridDateTime = (value) => {
    if (value) {
        return dayjs(value).format('DD/MM/YYYY HH:mm:ss')
    }
    return null
}

export const formatDatePickerDate = (date) => {
    if (date === null) {
        return null
    } else {
        const formattedDate = dayjs(date).toISOString()
        return formattedDate
    }
}

export const formatLastLoginDate = (value) => {
    if (value) {
        return dayjs(value).format('DD MMM YYYY HH:mm:ss')
    }
    return null
}

export const formatMoney = (value) => {
    if (value !== null && value !== undefined) {
        const isNegative = value < 0
        const absValue = Math.abs(value)

        const formattedValue = `${isNegative ? '-$ ' : '$ '}${parseFloat(absValue).toLocaleString(
            'en-US',
            {
                minimumFractionDigits: 4,
                maximumFractionDigits: 4,
            }
        )}`

        return formattedValue
    }
    return null
}

export const formatPercent = (value) => {
    if (value !== null && value !== undefined) {
        return parseFloat(value).toFixed(2) + '%'
    }
    return null
}

export const formatCase = (value) => {
    return value.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase())
}
