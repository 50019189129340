import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { DataGrid, gridClasses } from '@mui/x-data-grid'

import { formatMoney } from '../../../utils/helpers/formatValue'
import CustomNoRowsOverlay from '../datagrid/CustomNoRowOverlay'

import { selectCurrentUser } from '../../../app/api/auth/authSlice'
import { useCreateTransactionMutation } from '../../../app/api/records/transactionsApiSlice'

import CreateSuccessModal from './CreateSuccessModal'

const TransactionAllocationModal = ({
    values: transactionData,
    selectedRowsData,
    selectedTransactionDate,
    handleCloseApplyTransactionForm,
    isTransactionAllocationModalOpen,
    handleCloseTransactionAllocationModal,
}) => {
    const { firstName, lastName } = useSelector(selectCurrentUser)
    const userFullName = `${firstName} ${lastName}`

    const [createTransaction] = useCreateTransactionMutation()

    const [allocatedRows, setAllocatedRows] = useState([])
    const [isCreateSuccessModalOpen, setIsCreateSuccessModalOpen] = useState(false)

    useEffect(() => {
        const newAllocatedRows = allocateTransaction(selectedRowsData, transactionData)
        setAllocatedRows(newAllocatedRows)
    }, [selectedRowsData, transactionData])

    const allocateTransaction = (selectedRowsData, transactionData) => {
        if (!selectedRowsData || selectedRowsData.length === 0) return []

        // Calculate sums
        const sumPrincipal = parseFloat(transactionData.sumPrincipal) || 0
        const sumInterest = parseFloat(transactionData.sumInterest) || 0
        const sumFee = parseFloat(transactionData.sumFee) || 0

        // Function to calculate and return updated row
        const addTransactionAmount = (
            row,
            transactionPrincipal,
            transactionInterest,
            transactionFee
        ) => {
            const rowOutstandingPrincipal = parseFloat(row.outstandingPrincipal) || 0
            const rowTotalInterest = parseFloat(row.totalInterest) || 0
            const rowTotalFee = parseFloat(row.totalFee) || 0

            const newOutstandingPrincipal = rowOutstandingPrincipal + transactionPrincipal
            const newTotalInterest = rowTotalInterest + transactionInterest
            const newTotalFee = rowTotalFee + transactionFee

            return {
                ...row,
                transactionPrincipal,
                transactionInterest,
                transactionFee,
                newPrincipal: newOutstandingPrincipal,
                newInterest: newTotalInterest,
                newFee: newTotalFee,
            }
        }

        return selectedRowsData.map((row) => {
            const rowOutstandingPrincipal = parseFloat(row.outstandingPrincipal) || 0
            const rowTotalInterest = parseFloat(row.totalInterest) || 0
            const rowTotalFee = parseFloat(row.totalFee) || 0

            let transactionPrincipal = 0
            let transactionInterest = 0
            let transactionFee = 0

            if (
                transactionData.transactionType === 'Partial Repayment' ||
                transactionData.transactionType === 'Full Repayment' ||
                transactionData.transactionType === 'Partial Write Off' ||
                transactionData.transactionType === 'Full Write Off' ||
                transactionData.transactionType === 'Waive' ||
                transactionData.transactionType === 'Restructure to Other'
            ) {
                transactionPrincipal =
                    sumPrincipal > 0
                        ? (rowOutstandingPrincipal / sumPrincipal) *
                          parseFloat(transactionData.transactionPrincipal)
                        : 0
                transactionInterest =
                    sumInterest > 0
                        ? (rowTotalInterest / sumInterest) * // To consider spread transfers during accrual
                          parseFloat(transactionData.transactionInterest)
                        : 0
                transactionFee =
                    sumFee > 0
                        ? (rowTotalFee / sumFee) * // Same ratio as (rowOutstandingPrincipal / sumPrincipal)
                          parseFloat(transactionData.transactionFee)
                        : 0
            } else if (transactionData.transactionType === 'Capitalise') {
                transactionInterest =
                    sumInterest > 0
                        ? (rowTotalInterest / sumInterest) *
                          parseFloat(transactionData.transactionInterest)
                        : 0
                transactionFee =
                    sumFee > 0
                        ? (rowTotalFee / sumFee) * parseFloat(transactionData.transactionFee)
                        : 0
                transactionPrincipal = -(transactionInterest + transactionFee)
            } else if (transactionData.transactionType === 'Charge Fee') {
                const allocationWeight = rowOutstandingPrincipal / sumPrincipal

                transactionPrincipal =
                    allocationWeight * parseFloat(transactionData.transactionPrincipal)
                transactionInterest =
                    allocationWeight * parseFloat(transactionData.transactionInterest)
                transactionFee = allocationWeight * parseFloat(transactionData.transactionFee)
            } else if (transactionData.transactionType === 'Mark to Market') {
                const allocationWeight = rowOutstandingPrincipal / sumPrincipal

                transactionPrincipal = 0
                transactionInterest = 0
                transactionFee = allocationWeight * parseFloat(transactionData.transactionFee)
            }

            return addTransactionAmount(
                row,
                transactionPrincipal,
                transactionInterest,
                transactionFee
            )
        })
    }

    const columns = [
        { field: 'fundCode', headerName: 'Fund Code', width: 110 },
        { field: 'taId', headerName: 'TA ID', width: 220 },
        { field: 'facilityNumber', headerName: 'Facility Number', width: 120 },
        { field: 'currency', headerName: 'Currency', width: 90 },
        {
            field: 'outstandingPrincipal',
            headerName: 'Outstanding Principal',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'transactionPrincipal',
            headerName: '∆ Principal',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'newPrincipal',
            headerName: 'New Principal',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'totalInterest',
            headerName: 'Total Interest',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'transactionInterest',
            headerName: '∆ Interest',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'newInterest',
            headerName: 'New Interest',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'totalFee',
            headerName: 'Total Fee',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'transactionFee',
            headerName: '∆ Fee',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
        {
            field: 'newFee',
            headerName: 'New Fee',
            width: 150,
            valueFormatter: (params) => formatMoney(params.value),
        },
    ]

    const handleOpenCreateSuccessModal = () => {
        setIsCreateSuccessModalOpen(true)
    }

    const handleCloseCreateSuccessModal = () => {
        setIsCreateSuccessModalOpen(false)
        handleCloseTransactionAllocationModal()
        handleCloseApplyTransactionForm()
    }

    const handleConfirm = async () => {
        const updatedRows = allocatedRows.map((row) => ({
            transactionDate: selectedTransactionDate,
            borrowerName: row.borrowerName,
            facilityNumber: row.facilityNumber,
            lenderCode: row.lenderCode,
            positionId: row.positionId,
            participationId: row.participationId,
            fundCode: row.fundCode,
            taId: row.taId,
            fundTaId: row.fundTaId,
            currency: row.currency,
            transactionPrincipal: row.transactionPrincipal,
            transactionInterest: row.transactionInterest,
            transactionFee: row.transactionFee,
            transactionType: transactionData.transactionType,
            remarks: transactionData.remarks,
            createdBy: userFullName,
            lastUpdatedBy: userFullName,
        }))

        try {
            const response = await createTransaction(updatedRows)

            if (!response.error && response.data.newData.length > 0) {
                handleOpenCreateSuccessModal()
            }
        } catch (error) {
            console.error('Error creating transaction:', error)
        }
    }

    return (
        <Dialog
            open={isTransactionAllocationModalOpen}
            onClose={handleCloseTransactionAllocationModal}
            maxWidth={false}
        >
            <DialogTitle>Transaction Allocation</DialogTitle>
            <DialogContent dividers>
                <Box
                    sx={{
                        m: '1.5rem 2.5rem',
                        '& .MuiDataGrid-cell': {
                            borderBottom: 'none',
                        },
                        height: '40vh',
                        '& .MuiDataGrid-root': {
                            maxWidth: '95vw',
                        },
                        [`.${gridClasses.cell}.isNegative`]: {
                            color: '#f44336',
                        },
                        [`.${gridClasses.cell}.isPositive`]: {
                            color: '#66bb6a',
                        },
                    }}
                >
                    <DataGrid
                        getRowId={(row) => row._id}
                        rows={allocatedRows}
                        columns={columns || []}
                        getCellClassName={(params) => {
                            if (
                                params.field === 'transactionPrincipal' ||
                                params.field === 'transactionInterest' ||
                                params.field === 'transactionFee'
                            ) {
                                return params.value < 0
                                    ? 'isNegative'
                                    : params.value > 0
                                    ? 'isPositive'
                                    : null
                            }
                        }}
                        pageSizeOptions={[20, 50, 100]}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 20 } },
                        }}
                        slots={{
                            noRowsOverlay: CustomNoRowsOverlay,
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='outlined'
                    onClick={() => {
                        handleCloseTransactionAllocationModal()
                    }}
                >
                    Cancel
                </Button>
                <Button variant='contained' onClick={handleConfirm}>
                    Confirm
                </Button>
            </DialogActions>
            <CreateSuccessModal
                isCreateSuccessModalOpen={isCreateSuccessModalOpen}
                handleCloseCreateSuccessModal={handleCloseCreateSuccessModal}
            />
        </Dialog>
    )
}

export default TransactionAllocationModal
